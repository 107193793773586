/* stylelint-disable property-no-vendor-prefix */
/* stylelint-disable selector-class-pattern */
@import "tui-calendar/dist/tui-calendar.css";
@import "tui-date-picker/dist/tui-date-picker.css";
@import "../au-scss/abstracts/variables/";

.justify-content-center {
  justify-content: center;
}

.anchor-nav a.nav-link:first-child {
  border-top-left-radius: 50px;
  border-bottom-left-radius: 50px;
  margin-left: -1px;
}

nav.anchor-nav {
  border: 1px solid $au-color-secondary-light;
  margin-bottom: 5px;
}

nav.anchor-nav:first-child {
  border-radius: 50px;
}

.info-btn {
  font-weight: $font-weight-medium;
  color: $au-color-white;
  font-size: $au-fs-2 !important;
  border-radius: $btnBorderRadius;
}

.mh-315 {
  max-height: 315px;
}

.badge {
  color: $au-color-white !important;
  padding: 5px 10px;
  border-radius: 20px;
}

.green-bg {
  background-color: $au-color-green-1;
}

.blue-border {
  border-color: $au-color-dark-blue-2;
  color: $au-color-secondary !important;
  background-color: $au-color-white;
}

.pl-25 {
  padding-left: 25px !important;
}

.pl-2px {
  padding-left: 2px !important;
}

.ql-editor {
  min-height: 48px !important;
}

.p-0-5 {
  padding: $spacer-0 5px;
}

table td,
th {
  white-space: nowrap;
}

.border-radius-none {
  border-radius: $spacer-0 !important;
}

.info-title,
.chart-title {
  white-space: nowrap;
}

.col-lg-2 .select-corner-curved {
  width: 70% !important;
}

.progress {
  /* stylelint-disable-next-line color-plugin/variable, color-function-notation, alpha-value-notation */
  background-color: rgba(87, 87, 87, 0.2);
  background-image: none;
  box-shadow: none;
  border-radius: 0.25rem !important;
  width: 60px;
  height: 60px;
  /* stylelint-disable-next-line declaration-block-no-shorthand-property-overrides */
  background: none;
  position: relative;
}

.table .progress {
  height: 20px !important;
  margin-bottom: 0;
}

.progress-bar {
  box-shadow: none;
  border-radius: 0;
  color: $au-color-secondary !important;
  background-image: none;
  transition-timing-function: cubic-bezier(0.785, 0.135, 0.15, 0.86);
}

.h-220 {
  height: 220px !important;
}

.ml_n10 {
  margin-left: -10px !important;
}

.dashboard-stats {
  display: flex;
  flex-wrap: wrap;
}

.dashboard-stats .item {
  flex-grow: 1;
  min-width: 15%;
}

.dashboard-stats .item:not(:first-child) {
  padding-left: 2px;
}

.dashboard-stats .item .card {
  border-radius: 0;
}

@media (max-width: 1200px) {
  .dashboard-stats .item {
    min-width: 18.33%;
  }
}

@media (max-width: 767px) {
  .dashboard-stats .item {
    min-width: 50%;
  }
}

.p-l-15 {
  padding-left: 15px !important;
}

.border-radius-5 {
  border-radius: 4px !important;
  -webkit-border-radius: 4px !important;
  -moz-border-radius: 4px !important;
}

.border-radius-5r {
  border-radius: 5rem !important;
  -webkit-border-radius: 5rem !important;
  -moz-border-radius: 5rem !important;
}

.highlight {
  background-color: $au-color-primary-dark !important;
  color: $au-color-white !important;
}

.p-b-15 {
  padding-bottom: 15px !important;
}

.font-poppins {
  font-family: $poppins;
}

.display-5 {
  font-size: 2.2rem !important;
  line-height: 1;
}

.progress::after {
  content: "";
  width: 100%;
  height: 100%;
  border-radius: 50%;
  border: 4px solid $au-color-white-light-6;
  position: absolute;
  top: 0;
  left: 0;
}

.progress-active::after {
  border: 4px solid $au-color-blue !important;
}

.progress > span {
  width: 50%;
  height: 100%;
  overflow: hidden;
  position: absolute;
  top: 0;
  z-index: 1;
}

.progress .progress-left {
  left: 0;
}

.progress .progress-bar {
  width: 100%;
  height: 100%;
  background: none;
  border-width: 4px;
  border-style: solid;
  position: absolute;
  top: 0;
}

.progress .progress-left .progress-bar {
  left: 100%;
  border-top-right-radius: 80px;
  border-bottom-right-radius: 80px;
  border-left: 0;
  -webkit-transform-origin: center left;
  transform-origin: center left;
}

.progress .progress-right {
  right: 0;
}

.progress .progress-right .progress-bar {
  left: -100%;
  border-top-left-radius: 80px;
  border-bottom-left-radius: 80px;
  border-right: 0;
  -webkit-transform-origin: center right;
  transform-origin: center right;
}

.progress .progress-value {
  position: absolute;
  top: 0;
  left: 0;
}

.font-14 {
  font-size: $au-fs-3;
}

.border-white {
  border-color: $au-color-white;
}

.border-red {
  border-color: $au-color-red;
}

.border-green {
  border-color: $au-color-d1;
}

.border-yellow {
  border-color: $au-color-orange-1;
}

.border-cyan {
  border-color: $au-color-green-2;
}

.fs-20 {
  font-size: $au-fs-6;
}

.fs-16 {
  font-size: $au-fs-4;
}

.display-3 {
  font-size: 2.5rem !important;
}

.display-scenario {
  font-size: 1.5rem !important;
}

.m-t-5 {
  margin-top: 5px !important;
}

.float-right {
  float: right;
}

.page-title {
  white-space: nowrap;
}

.pr-15 {
  padding-right: 15px !important;
}

.pl-15 {
  padding-left: 15px !important;
}

.mb-15 {
  margin-bottom: 15px;
}

.mt-15 {
  margin-top: 15px !important;
}

.sidebar-link img {
  width: 1.2rem !important;
}

.selected .side-menu-img img {
  -webkit-filter: brightness(0) invert(1);
  filter: brightness(0) invert(1);
}

.side-menu-img {
  height: 30px;
  font-style: normal;
  line-height: 25px;
  font-size: $au-fs-6;
  color: $au-color-black-1;
  display: inline-block;
  text-align: right;
  padding: 4px 5px;
  margin: 0 20px 0 0;
  width: 40px;
  border-radius: 0 25px 25px 0;
}

.selected .side-menu-img {
  height: 30px;
  background: $au-color-primary-dark;
  color: $au-color-white;
  border-radius: 0 25px 25px 0;
  -webkit-border-radius: 0 25px 25px 0;
  -moz-border-radius: 0 25px 25px 0;
  -ms-border-radius: 0 25px 25px 0;
  -o-border-radius: 0 25px 25px 0;
}

@media (max-width: 767px) {
  .pr-15 {
    padding-right: $spacer-0 !important;
  }

  .pl-15 {
    padding-right: $spacer-0 !important;
  }
}

a[aria-label="Previous"] {
  span {
    display: none !important;
  }

  // &::before {
  //     content: "Prev" !important;
  // }
}

a[aria-label="Next"] {
  span {
    display: none !important;
  }

  // &::before {
  //     content: "Next" !important;
  // }
}

.span-block {
  position: relative;
  width: 25px;
  height: 20px;
  align-items: center;
}

.svg-icons {
  width: 18px;
  height: 18px;
  color: $au-color-secondary;
}

.sidebar-svg-icons {
  width: 20px;
  height: 20px;
}

.selected .side-menu-img .sidebar-svg-icons {
  -webkit-filter: brightness(0) invert(1);
  filter: brightness(0) invert(1);
}

.file-manager.attachment {
  display: inline-block;
  min-width: 20px;
  vertical-align: baseline;
  border-radius: 2px;
  padding: 0.45em 0.35em;
  background-color: $au-color-gray-light-2;
  font-family: Helvetica, arial, sans-serif;
  font-size: $au-fs-3;
  font-weight: $font-weight-normal;
  line-height: normal;
}

.modal-header-text {
  font-size: 1.2rem;
  color: $au-color-secondary;
  font-weight: $font-weight-light;
}

.modal-header {
  border-top-left-radius: $spacer-0;
  border-top-right-radius: $spacer-0;
  padding: 1em;
  border-bottom: 1px solid $au-color-gray-light-5;
}

.widget-table .table-responsive {
  min-height: 180px;
  text-align: left;
}

/* ******** */

.uploadfilecontainer {
  background-repeat: no-repeat;
  background-size: 100px;
  background-position: center;
  height: 200px;
  margin: 20px auto;
  border: 2px dashed #e9ecef;
  background-color: $au-color-secondary-light-2 !important;
  border-radius: 10px;
}

.uploadfilecontainer:hover {
  cursor: pointer;
  background-color: $au-color-secondary-light-2 !important;
  opacity: 0.8;
}

.files-list-an {
  display: flex;
  width: 30%;
  margin: 5px;
  background: $au-color-white;
  border: 2px dashed #e9ecef;
  border-radius: 10px;
  padding: 5px;
  color: $au-color-blue-1;
  background-color: $au-color-secondary-light-2;
}

.files-list-an .delete-file {
  background: transparent;
  border: none;
  cursor: pointer;
}

.files-list-an .delete-file img {
  width: 30px;
}

.files-list {
  display: flex;
  width: 60%;
  margin: 5px;
  background: $au-color-white;
  border: 1px dashed #e9ecef;
  border-radius: 10px;
  padding: 5px;
  color: $au-color-blue-1;
  background-color: $au-color-secondary-light-2;
}

.files-list .delete-file {
  background: transparent;
  border: none;
  cursor: pointer;
}

.files-list .delete-file img {
  width: 30px;
}

.j-f-s {
  justify-content: flex-start;
}

/** Don't remove this */

.left,
.right {
  margin-right: 15px !important;
}

.copy-right {
  font-size: 1.2rem;
}

.ps__thumb-y {
  display: none;
}

.font-weight-400 {
  font-weight: $font-weight-normal !important;
}

.shadow-none {
  box-shadow: none !important;
}

.h-95 {
  height: 95% !important;
}

.description-editor {
  color: $au-color-black;
  font-size: $au-fs-2;
  letter-spacing: 0.12px;
  -webkit-text-stroke-color: $au-color-black;
}

.min-h-185 {
  min-height: 185px;
}

.ngx-dropdown-button {
  border: 1px solid $input-border-color !important;
  border-radius: 4px !important;
  min-height: $input-min-height !important;
  color: $au-color-black !important;
}

.ql-toolbar.ql-snow {
  border: 1px solid $input-border-color !important;
  border-radius: 4px !important;
  border-bottom-left-radius: 0 !important;
  border-bottom-right-radius: 0 !important;
}

.ql-container.ql-snow {
  border: 1px solid $input-border-color !important;
  border-radius: 4px !important;
  border-top: 0 !important;
  border-top-left-radius: 0 !important;
  border-top-right-radius: 0 !important;
  max-height: 350px;
  overflow-y: auto;
}

/* stylelint-disable-next-line selector-id-pattern */
#id_expense_for {
  margin: 0 !important;
}

/* stylelint-disable-next-line selector-id-pattern */
#id_expense_for li {
  display: inline;
  margin-right: 25px;
  list-style: none;
}

.min-height-30 {
  min-height: 30px !important;
}

.bck-danger {
  background-color: $au-color-red-1 !important;
}

.css-badge {
  font-size: 1.1rem;
  padding: 0.5em 1em;
  border-radius: 20px;
  width: 70px;
  font-weight: $font-weight-semi;
  color: $au-color-white;
}

.dark-bg {
  background-color: $au-color-dark-blue-2;
}

.low-bg {
  background-color: $au-color-green-2;
}

.medium_bg {
  background-color: $au-color-orange-1;
}

.mat-progress-bar {
  height: 2px !important;
}

.mat-progress-bar-fill::after {
  background-color: $au-color-primary-dark !important;
}

.mat-progress-bar .mat-progress-bar-fill::after {
  background-color: $au-color-primary-dark !important;
}

.mat-progress-bar .mat-progress-bar-fill {
  animation-direction: reverse !important;
}

.mat-progress-bar-buffer {
  background-color: $au-color-secondary-light !important;
}

#d3-donut-chart svg {
  // max-height: 240px;
  max-width: 250px;
}

/* stylelint-disable-next-line selector-id-pattern */
#d3Gauge svg {
  // max-height: 220px;
  // max-width: 240px;
  max-width: 300px;
}

#d3-gauge svg {
  // max-height: 290px;
  max-width: 315px;
}

#chart svg {
  // max-height: 240px;
  max-width: 313px;
}

.home-info-progress-bars {
  vertical-align: baseline;
  display: flex;
  overflow-x: scroll;
  overflow-y: hidden;
  -webkit-overflow-scrolling: touch;
  flex-flow: row wrap;
}

.home-info-progress-bars::-webkit-scrollbar {
  display: none;
}

.justify-content-se {
  justify-content: space-evenly;
}

.justify-content-space-between {
  justify-content: space-between;
}

.font-size-22 {
  font-size: 2.2rem !important;
}

.budget-balence {
  min-height: 21px;
}

line,
path.domain {
  display: none !important;
}

.web-line {
  display: inline !important;
}

.step-chart path.domain {
  display: inline !important;
  stroke: $au-color-white-light-6;
}

.mt-m-35 {
  margin-top: -35px;
}

.min-h-440 {
  min-height: 440px !important;
}

.tui-full-calendar-month-week-item .tui-full-calendar-today .tui-full-calendar-weekday-grid-date-decorator {
  background: $au-color-primary-dark !important;
}

.align-items-end {
  align-items: end;
}

.tui-full-calendar-dayname-name {
  font-weight: normal !important;
}

.tui-full-calendar-time-schedule-content {
  color: $au-color-white;
  font-weight: normal;
}

.font-13 {
  font-size: 1.3rem !important;
}

span.tui-full-calendar-icon.tui-full-calendar-ic-location-b,
span.tui-full-calendar-icon.tui-full-calendar-ic-state-b,
span.tui-full-calendar-icon.tui-full-calendar-ic-repeat-b {
  display: none !important;
}

/* css for global filter starts */

.filtersBadge {
  display: inline-flex;
}

.universal-filterbadge {
  width: 99%;
  display: inline-flex;
  justify-content: flex-end;
}

@media only screen and (max-device-width: 533px) {
  .universal-filterbadge {
    display: block;
    text-align: center;
    clear: both;
  }
}

/* css for global filter ends */

.tool-tip-marker {
  height: 8px;
  width: 10px;
  left: 0;
  top: 0;
  border-width: 0;
  border-color: $au-color-white;
  position: relative;
  display: inline-block;
  cursor: pointer;
  margin-right: 5px;
  border-style: solid;
}

.tool-tip-text {
  color: $au-color-white;
  font-size: $au-fs-2;
  font-weight: $font-weight-normal;
  margin-bottom: 0;
}

.tool-tip {
  /* stylelint-disable-next-line color-function-notation, alpha-value-notation, color-plugin/variable */
  background-color: rgba(0, 0, 0, 0.75);
  padding: 5px;
  border-radius: 2px;
  font-family: sans-serif;
  color: $au-color-white;
  pointer-events: none;
  box-shadow: 0 0 5px $au-color-secondary-medium;
  min-width: 50px;
  align-items: center;
  justify-content: center;
}

.subtitle {
  color: $au-color-primary;
}

.card-h3 {
  margin-top: 1.2rem !important;
}

// .notification-icon-header {
//   width: 22px;
//   height: 22px;
// }
.absolute-Center {
  margin: auto;
  position: absolute;
  left: 0;
  right: 0;
}

.overflow-scroll {
  overflow: scroll !important;
}

.flex-auto {
  flex: auto;
  text-align: center;
}

.default {
  cursor: default !important;
}

.remove-btn {
  background-color: $au-color-white;
  border: solid 1px $au-color-red;
  color: $au-color-red;
  font-size: $au-fs-2;
  margin-left: 8px;
  border-radius: 25px;
  padding: 2px 15px !important;
}

.ng-select.ng-select-single .ng-select-container {
  min-height: $input-min-height;

  .ng-value {
    color: $au-color-black;
    font-size: $au-fs-3;
    font-weight: $font-weight-normal;
  }
}

.ng-select.au-h-30 .ng-select-container {
  min-height: 30px;
  height: 30px;
}

.text-success {
  color: $au-color-green-3 !important;
}

.calender-icon {
  position: absolute;
  left: 15px;
  top: 6px;
  font-size: 2.5rem;
}

.single-calender-icon {
  position: absolute;
  left: 15px;
  font-size: 2.5rem;
}

.page-filter-icon {
  display: flex;
  justify-content: flex-end;
  position: relative;
  bottom: 25px;
  left: 10px;
  height: 0;
  text-transform: uppercase;
}

input[type="checkbox"]:disabled + label::before {
  cursor: not-allowed;
}

input[type="checkbox"]:checked:disabled + label::before {
  background-color: $au-color-gray-light-6;
  border-color: $au-color-gray-light-6;
  color: $au-color-white;
  cursor: not-allowed;
}

.text-auraa {
  color: $au-color-primary-dark !important;
}

.help {
  font-size: $au-fs-1;
  margin-left: 5px;
  /* stylelint-disable-next-line color-plugin/variable, color-function-notation, alpha-value-notation */
  color: rgba(87, 87, 87, 0.55);
}

.pl-14 {
  padding-left: 14px !important;
}

.dropdown-menu {
  // padding: 0px !important;
  cursor: pointer;
}

// .dropdown-item {
//     padding-bottom: 0px !important;
// }
.page-filter-sub {
  margin-bottom: $spacer-0;
  margin-right: $spacer-0;
  height: calc(100vh - 120px);
}

.max-h-310 {
  max-height: 310px !important;
}

.table-responsive::-webkit-scrollbar {
  width: 0;
  height: 7px;
  border-radius: 10px;
  background-color: transparent;
}

.table-responsive::-webkit-scrollbar-thumb {
  background: $au-color-secondary-medium;
  border-radius: 10px;
}

.table-footer {
  th,
  td {
    border-bottom: 0 none !important;
  }
}

.pt-15 {
  padding-top: 15px !important;
}

label {
  font-weight: $font-weight-normal !important;
}

.btn {
  font-size: $au-fs-3 !important;
}

.p-1-10 {
  padding: 1px 10px !important;
}

.h-64 {
  height: 64px !important;
}

.disabled {
  cursor: not-allowed;
  pointer-events: all !important;
}

.disable {
  cursor: not-allowed !important;
  pointer-events: none !important;
}

.greadient-bar {
  .mat-progress-bar .mat-progress-bar-fill::after {
    background-color: $au-color-red !important;
  }
}

.question-display-wrap {
  border-top: 1px solid #e1e1e1;
}

/* stylelint-disable-next-line no-descending-specificity */
.question-display span {
  color: $au-color-secondary-medium;
}

.audit-details {
  border-radius: 0 !important;
  margin-top: -10px;
  padding-top: 10px;
  padding-bottom: 12px;
}

.audit-details .breadcrumb {
  background-color: $au-color-white !important;
}

.lh-30 {
  line-height: 30px !important;
}

.audit-card-grids {
  height: 522px;
  opacity: 1;
  transition: all 0.5s;
  -webkit-transition: all 0.5s;
  -moz-transition: all 0.5s;
}

@media (min-width: 1700px) {
  .audit-card-grids {
    height: 550px;
  }
}

.audit-card-grids-hide {
  height: 0;
  opacity: 0;
  transition: all 0.5s;
  -webkit-transition: all 0.5s;
  -moz-transition: all 0.5s;
  overflow: hidden;
}

.sub-nav-bar {
  background-color: $au-color-white !important;
  padding: 10px !important;
  padding-bottom: $spacer-0 !important;
}

.prio-navs {
  display: flex;
  flex: 1;
}

.prio-navs button {
  background: none !important;
  border: none;
  color: $au-color-secondary;
  font-size: $au-fs-2;
  font-family: $poppins !important;
  font-weight: $font-weight-normal;
  padding: 5px $spacer-0;
  border-bottom: 3px solid $au-color-white;
  border-radius: 0;
  margin: $spacer-0 6px;
  cursor: pointer;
  margin-right: 25px;
  display: flex;
  justify-content: space-between;
}

.more-btn {
  background: none !important;
  border: none;
  color: $au-color-secondary;
  font-size: $au-fs-2;
  font-weight: $font-weight-normal;
  padding: 5px $spacer-0;
  border-radius: 0;
  border-bottom: 3px solid $au-color-white;
  cursor: pointer;
}

.prio-navs button:hover,
.more-btn:hover {
  color: $au-color-q7;
  border-bottom: 3px solid $au-color-primary-dark;
}

.menu-dropdown-item {
  background: none !important;
  border: none;
  color: $au-color-secondary;
  font-size: $au-fs-2;
  font-weight: $font-weight-normal;
  padding: 5px;
  border-radius: 0;
  border-bottom: 3px solid $au-color-white;
  cursor: pointer;
  width: 100%;
  text-align: left;
}

.menu-dropdown-item:hover {
  color: $au-color-q7;
}

.sidenav-container {
  background-color: $au-color-white;
}

.prio-navs button:first-child {
  padding-left: $spacer-0;
  margin-left: $spacer-0;
}

.mat-drawer-content {
  height: 60px !important;
  padding: $spacer-0;
  margin-left: $spacer-0;
  display: unset !important;
}

.button-active {
  color: $au-color-q7 !important;
  border-bottom: 3px solid $au-color-primary-dark !important;
}

.no_pointer {
  pointer-events: none;
}

.button-drop-active {
  color: $au-color-q7 !important;
  border-bottom: 3px solid $au-color-primary-dark !important;
}

.question-display h4 {
  color: $au-color-q7;
}

.answer-display {
  position: relative;
}

.answer-type-matrix,
.answer-type-rating {
  overflow-x: auto;
}

.custom-class-example .pure-checkbox {
  display: flex !important;
  align-items: left !important;
}

.custom-class-example .select-all,
.custom-class-example .arrow-up,
.custom-class-example .arrow-down {
  display: none !important;
}

.custom-class-example .cuppa-dropdown {
  border: 1px solid;
  color: $au-color-gray-light-4;
  border-radius: 4px !important;
}

.custom-class-example .c-token {
  background: $au-color-secondary-medium !important;
}

.custom-class-example .text-color {
  color: $au-color-secondary-medium !important;
}

/* stylelint-disable-next-line no-descending-specificity */
.custom-class-example .pure-checkbox label::before {
  border-color: $au-color-secondary-medium !important;
}

.custom-class-example .pure-checkbox input[type="checkbox"]:checked + label[_ngcontent-c1]::before {
  background: $au-color-secondary-medium !important;
}

.dropdown-list.tagToBody {
  top: initial !important;
  left: initial !important;
  bottom: 50px;
  right: 0;
  position: absolute !important;
}

.dropdown-list.list-area {
  position: relative !important;
}

.main-div .ngx-dropdown-list-container {
  bottom: 40px !important;
}

@media (max-width: 767px) {
  .dropdown-list.tagToBody {
    bottom: 130px;
  }
}

// @media (max-width:767px) {
//   .dropdown-list.tagToBody {
//     bottom: 130px;
//   }
// }
// @media (min-width:1533px) and(max-width:1800px) {
//   .dropdown-list.tagToBody {
//     bottom: 115px;
//   }
// }
// @media (min-width:1800px) and(max-width:2000px) {
//   .dropdown-list.tagToBody {
//     bottom: 139px;
//   }
// }
.colColor {
  color: $au-color-secondary-medium;
  font-size: $au-fs-4;
  font-weight: $font-weight-medium;
}

.rowColor {
  color: $au-color-q7;
  font-size: $au-fs-4;
  font-weight: $font-weight-medium;
  text-align: left;
  padding-left: 50px !important;
}

.matrixRow:nth-child(odd) {
  background-color: $au-color-secondary-light-2;
}

.custom-control-inline {
  margin-right: $spacer-0;
}

.custom-control {
  min-height: 1rem;
}

.custom-control-label::after {
  left: 3.2px;
}

.cdk-overlay-pane {
  width: 239px;
}

.app-file-preview-dialog-panel {
  width: 100%;
}

@media (max-width: 767px) {
  .audit-card-grids {
    height: 1610px;
  }

  .audit-card-grids-hide {
    height: 0;
  }
}

.vertical-line {
  border-left: 2px solid #e2e2e2;
  height: 170px;
}

.count-indicator {
  width: 16px;
  height: 15px;
  border-radius: 50%;
  background-color: $au-color-blue-2;
  position: absolute;
  font-size: $au-fs-1;
  color: $au-color-white;
  font-weight: $font-weight-semi;
  line-height: 17px;
  text-align: center;
}

.covers-item {
  border-radius: 17px;
  /* stylelint-disable-next-line color-function-notation, alpha-value-notation */
  border: 1px solid rgba(0, 0, 0, 0.25);
  font-weight: $font-weight-normal !important;
  color: $au-color-black;
}

.node--internal text {
  fill: $au-color-primary-dark;
}

.node--leaf text {
  fill: $au-color-primary-dark;
}

.ballG text {
  fill: $au-color-black;
}

.xAxis .tick text {
  fill: $au-color-black;
}

.svgLines .gaxis .tick text {
  font-size: $au-fs-1;
  fill: $au-color-black;
}

.grid .tick line {
  stroke: grey;
  stroke-dasharray: 5, 10;
  opacity: 0.7;
}

.grid path {
  stroke-width: 0;
}

.node circle {
  fill: $au-color-secondary-medium;
}

.node--internal circle {
  fill: $au-color-secondary;
}

.shadow {
  -webkit-filter: drop-shadow(-1.5px -1.5px 1.5px $au-color-black);
  filter: drop-shadow(-1.5px -1.5px 1.5px $au-color-black);
}

.valid-expression {
  border-color: $au-color-dark-green !important;
}

.valid-expression-dashed {
  border: dashed 1.8px $au-color-dark-green !important;
}

.invalid-expression {
  border-color: $au-color-red !important;
}

.invalid-expression-dashed {
  border: dashed 1.8px $au-color-red !important;
}

.min-h-570 {
  min-height: 570px !important;
}

.cdk-drag-preview {
  box-sizing: border-box;
}

.cdk-drag-placeholder {
  opacity: 0;
}

.cdk-drag-animating {
  transition: transform 250ms cubic-bezier(0, 0, 0.2, 1);
}

.example-box:last-child {
  border: none;
}

.example-list.cdk-drop-list-dragging .example-box:not(.cdk-drag-placeholder) {
  transition: transform 250ms cubic-bezier(0, 0, 0.2, 1);
}

.text-display-grid {
  text-align: end;
  padding-right: 20px !important;
}

app-dynamic-field-widget {
  display: contents !important;
}

.text-display-id-align {
  padding-right: 370px !important;
}

.fw {
  font-weight: $font-weight-medium;
}

.status_pos {
  margin-top: 30px;
  margin-left: 10px;
}

@media (min-width: 1400px) {
  .add-label {
    margin-top: 0.5rem;
  }
}

.language_dropdown {
  cursor: pointer;
  border: 1px solid $au-color-secondary-light-3;
  border-radius: 4px;
  background-color: $au-color-white;
  overflow-x: hidden;
  text-overflow: ellipsis;
  color: $au-color-secondary;
  width: 100px;
}

.card-title-style {
  font-size: 1rem;
  font-family: Poppins;
  font-weight: $font-weight-normal;
  fill: rgb (85, 85, 85);
  text-align: center;
  margin-top: -30px;
  padding-bottom: 11px;

  .language-box {
    display: flex;
    padding-left: 20px;
    position: relative;
    margin-left: 10px;

    /* stylelint-disable-next-line no-descending-specificity */
    svg {
      width: 18px;
      position: absolute;
      top: 2px;
      left: 0;

      path {
        fill: $au-color-secondary;
      }
    }

    .language_dropdown {
      cursor: pointer;
      border: 1px solid transparent;
      border-radius: 4px;
      background-color: $au-color-white;
      overflow-x: hidden;
      text-overflow: ellipsis;
      color: $au-color-secondary;
      width: 100px;
    }
  }
}

.pointer {
  cursor: pointer !important;
}

.pos_table_text {
  vertical-align: middle !important;
}

@media (min-width: 1400px) {
  .add-label {
    margin-top: 0.5rem;
  }
}

.m-l-3 {
  margin-left: 3px;
}

@media (min-width: 1400px) {
  .add-label {
    margin-top: 0.5rem;
  }
}

@media (min-width: 1400px) {
  .add-label {
    margin-top: 0.5rem;
  }
}

.breadcrumb-align {
  margin-left: -10px;
}

.m-l_-8 {
  margin-left: -8px;
}

.m-r-25 {
  margin-right: 20px;
}

.mt-40 {
  margin-right: 20px;
}

.mr_-25 {
  margin-right: -25px;
}

.plr-c {
  margin: 0;
  padding: 10px 1.25rem 13px !important;
}

.delete-icon {
  font-size: $au-fs-6;
  margin-top: 10px;
}

.active-head {
  color: $au-color-q7 !important;
}

.cover-item {
  background-color: $au-color-secondary-light;
  border-radius: 17px;
  /* stylelint-disable-next-line color-function-notation, alpha-value-notation */
  border: 1px solid rgba(0, 0, 0, 0.25);
  font-weight: $font-weight-normal !important;
  color: $au-color-black;
}

.cover-item-clickable {
  border: 1px solid $au-color-red-1 !important;
}

.custom-mutiselect .ng-select.ng-select-multiple .ng-select-container .ng-value-container .ng-value {
  /* stylelint-disable-next-line color-plugin/variable */
  background-color: unset !important;
}

.ng-select.ng-select-focused:not(.ng-select-opened) > .ng-select-container {
  /* stylelint-disable-next-line color-plugin/variable, color-function-notation, alpha-value-notation */
  box-shadow: unset !important;
}

/* stylelint-disable-next-line no-descending-specificity */
.ng-select .ng-select-container {
  border: 1px solid $au-color-gray-light-3 !important;
}

.user-img {
  border-radius: 50%;
  width: 23px;
  height: 23px;
}

.ng-dropdown-panel .ng-dropdown-panel-items .ng-option.ng-option-marked {
  /* stylelint-disable-next-line color-plugin/variable */
  background-color: unset !important;
}

.ng-dropdown-panel .ng-dropdown-panel-items .ng-option.ng-option-selected,
.ng-dropdown-panel .ng-dropdown-panel-items .ng-option.ng-option-selected.ng-option-marked {
  background-color: $au-color-blue-3 !important;
  margin-top: 2px;
  color: $au-color-white;
  padding: 7px 10px;
}

.audit-card-grids-hide ~ .row > .card {
  box-shadow: none;
  border: none;
}

.white-bg {
  background-color: $au-color-white;
}

.border-dashed-blue {
  border: dashed 1.8px $au-color-primary-dark;
  -webkit-border-radius: 4rem;
}

.link {
  fill: none;
  stroke: $au-color-secondary;
  /* stylelint-disable-next-line alpha-value-notation */
  stroke-opacity: 0.4;
  stroke-width: 1px;
}

/* stylelint-disable-next-line no-descending-specificity */
text {
  font-family: $poppins;
  font-weight: $font-weight-normal;
  font-size: $au-fs-2;
}

.horizontal-y-axis-text {
  font-family: $poppins;
  font-weight: $font-weight-normal;
  font-size: 1.1rem;
}

.dougnut-render-size {
  height: 75px;
  width: 75px;
}

.dougnut-render-position {
  margin-top: -13px !important;
  margin-bottom: -9px;
}

.mb_-4 {
  margin-bottom: -4px !important;
}

@media (min-width: 1400px) {
  .add-label {
    margin-top: 0.5rem;
  }
}

.dropdown-menu.show {
  border-radius: 8px;
}

.survey_request {
  font-weight: $font-weight-medium;
  font-size: $au-fs-2;
  color: $au-color-blue-4;
  padding-top: 5px;
  margin-left: 40px;
}

.survey_request_read {
  font-weight: normal;
  font-size: $au-fs-2;
  color: $au-color-blue-4;
  padding-top: 5px;
  margin-left: 40px;
}

.notification-icons {
  font-weight: $font-weight-normal;
  color: $au-color-blue-4;
  padding-left: $spacer-0 !important;
}

.notification-box {
  border: 0 solid black;
  background-color: $au-color-white-light-4;
  margin-top: $spacer-0;
}

.notification-detail-box {
  border: none;
  text-align: left;
  margin-top: 20px;
}

.notification-card-unread {
  font-weight: 300 !important;
  font-size: $au-fs-3;
}

.notification-list-box {
  padding-top: 10px;
  padding-bottom: 10px;
  background-color: $au-color-white;
}

.notification-list-box-color {
  padding-top: 10px;
  padding-bottom: 10px;
  background-color: $au-color-white-light-4;
}

.notification-list-box-selected {
  padding-top: 10px;
  padding-bottom: 10px;
  background-color: $au-color-white-light-5;
}

.icon-styles {
  display: flex;
  cursor: pointer;
  margin-right: auto;
  margin-left: auto;
  width: 70px;
  color: $au-color-blue-4;
  font-size: $au-fs-3;
  font-family: $poppins;
}

.notify-box {
  margin-top: 200px;
}

.nofication-detail-heading {
  font-size: $au-fs-3;
  font-weight: $font-weight-medium;
}

.new-table {
  border: 1px solid none;
  border-collapse: collapse;
}

.new-table-header {
  padding: 10px;
  font-weight: $font-weight-medium;
  color: $au-color-primary;
}

.new-table-row {
  font-weight: $font-weight-normal;
  color: $au-color-black;
}

.comment-box {
  background-color: $au-color-gray-light-2;
  color: $au-color-black;
  font-weight: $font-weight-normal;
  margin-top: 20px;
  padding-left: 10px;
  width: 650px;
}

.comment-text {
  color: $au-color-black;
  font-weight: $font-weight-normal;
}

.height-btw-row {
  height: 3px;
}

.filter-drop-box {
  background-color: $au-color-secondary-light-2;
  border-radius: 5px;
  padding: 10px;
}

.no-notification {
  background-color: $au-color-white;
  padding-top: 10px;
  height: 40px;
}

.notification-arrow {
  margin-left: 282px;
}

.color-viewall {
  color: $au-color-primary !important;
}

.notification-list-type {
  color: $au-color-grey7 !important;
  font-weight: $font-weight-medium;
  font-size: 1.3rem;
}

.new-tab {
  position: fixed;
  top: 50px;
  width: 83%;
  left: 260px;
  z-index: 10;
  background-color: $au-color-secondary-light-2;
}

.notification-checked {
  position: fixed;
  width: 50%;
  padding: 10px;
  margin-top: 200px !important;
}

.notification-count-indicator {
  width: 21px;
  height: 20px;
  margin-left: -10px;
  margin-top: -8px;
  border-radius: 50%;
  background-color: $au-color-primary-dark;
  position: absolute;
  font-size: $au-fs-1;
  color: $au-color-white;
  font-weight: $font-weight-normal;
  line-height: 17px;
  text-align: center;
  padding-top: 1px;
}

.option-menu .icon-color {
  color: $au-color-primary !important;
}

.report-text-align {
  text-align: end;
  padding-right: 40px !important;
  padding-left: 350px !important;
}

.table-padding {
  padding-left: 10px;
  padding-right: 8px;
}

.ngx-dropdown-list-container::-webkit-scrollbar {
  width: 12px;
}

.ngx-dropdown-list-container::-webkit-scrollbar-track {
  background: $au-color-gray-light-7;
}

.ngx-dropdown-list-container::-webkit-scrollbar-thumb {
  border-radius: 10px;
  background: $au-color-gray-light-8;
}

.comment-box-align {
  padding-left: 3px;
  padding-right: 3px;
}

.width-icon-markasunread {
  width: 118px;
}

.width-icon-markasread {
  width: 140px;
}

.search-border {
  border-radius: 8px !important;
}

.font-size-12 {
  font-size: $au-fs-2;
}

.notification-box-width {
  width: 650px;
  margin-left: 10px;
}

.notification-box-bgcolor {
  background-color: $au-color-gray-light-9;
}

.cursor-noblink {
  caret-color: $au-color-black;
}

.cursor-on {
  caret-color: $au-color-secondary-medium !important;
}

.header-width {
  width: 200px;
}

.notification-height {
  height: 200px;
}

[dir="rtl"] .slider-arrow {
  right: auto !important;
  left: 0 !important;
}

[dir="rtl"] .slider-prev {
  right: 30px;
}

[dir="rtl"] .slider-next {
  right: 30px;
  margin-left: 20px;
}

[dir="rtl"] .arslider-space {
  margin-right: 0 !important;
  padding-left: 0 !important;
}

.m-t-13 {
  margin-top: 13px !important;
}

.z-index-1 {
  z-index: 1;
}

.color-icon {
  color: $au-color-primary;
}

.notification-container {
  max-height: 100vh !important;
  overflow: scroll;
}

.notification-container::-webkit-scrollbar-thumb {
  border-radius: 10px;
  background: #c9d0dd;
}

.notification-container::-webkit-scrollbar {
  width: 8px;
}

.hover-button {
  display: block;
  width: 100%;
  clear: both;
  font-weight: $font-weight-normal;
  white-space: nowrap;
  border: 0;
}

.toast-close-button {
  font-weight: lighter;
  top: -15px;
  right: -10px;
}

.fs-12 {
  font-size: $au-fs-2 !important;
}

.mt-_20 {
  margin-top: 20px;
}

.gantt-chart-container {
  overflow: auto !important;
  max-height: 400px !important;
  overflow-y: scroll !important;
}

.gantt-chart-container::-webkit-scrollbar {
  width: 8px !important;
}

.gantt-chart-container::-webkit-scrollbar-thumb {
  border-radius: 10px;
  background: $au-color-white-light-9;
}

.overflow-unset {
  overflow-x: unset !important;
}

.ml-_5 {
  margin-left: -5px;
}

.bg-white-notification {
  background-color: $au-color-white;
  position: absolute;
  right: 0;
  height: 100%;
}

.la-sliders {
  font-size: 1.8rem !important;
}

@media (max-width: 760px) {
  .notification-detail-box {
    display: none !important;
  }

  .bg-white-notification {
    display: none !important;
  }
}

.notifcation-checkbox::before {
  height: 16px !important;
  width: 16px !important;
}

.la-icon-notification {
  font-size: 1.12rem !important;
}

.text-break .float-right {
  visibility: hidden;
  opacity: 0;
  transition: visibility 0s linear 0.33s, opacity 0.33s linear;
}

.text-break:hover .float-right {
  visibility: visible;
  opacity: 1;
  transition-delay: 0s;
}

.ngx-dropdown-container .ngx-dropdown-button .display-text {
  overflow: hidden;
}

/* stylelint-disable-next-line no-descending-specificity */
.label-size .ngx-dropdown-container .ngx-dropdown-list-container .available-items li {
  line-height: 1.3 !important;
  padding-left: 2px !important;
  margin-top: 5px !important;
}

.la-print {
  font-size: 1.8rem !important;
}

.svgLines .xaxis .tick text {
  font-size: $au-fs-1;
  fill: $au-color-black;
}

.calendar-dropdown .ngx-dropdown-container .ngx-dropdown-button {
  border-radius: 8px !important;
  min-height: 30px !important;
  color: $au-color-secondary !important;
}

.date-range-h-24 {
  height: 24px !important;
  min-height: 24px !important;
}

.print-logo {
  display: none;
}

.plr {
  padding-left: 21px !important;
  padding-right: 21px !important;
}

.p-20 {
  padding: 20px !important;
}

.border {
  border: dotted $au-color-d5 !important;
}

.mb-23 {
  margin-bottom: 23px;
}

.svg-container {
  .half-donut {
    width: 100%;
    max-width: 220px;
    height: auto;
  }
}
