// Typography

@mixin h1 {
  font-size: $h1-font-size;
  line-height: $default-line-height;
}

@mixin h2 {
  font-size: $h2-font-size;
  line-height: $default-line-height;
  color: $au-color-black;
  font-weight: $font-weight-medium;
}

@mixin h3 {
  color: $au-color-black;
  font-size: $h3-font-size;
  font-weight: $font-weight-medium;
  line-height: $default-line-height;
  margin-bottom: $grid-gutter-space * 0.5;
}

@mixin h4 {
  font-size: $h4-font-size;
  line-height: $default-line-height;
}

@mixin h5 {
  font-size: $h5-font-size;
  line-height: $default-line-height;
}

@mixin h6 {
  font-size: $h6-font-size;
  line-height: $default-line-height;
}
