.au-ie-only{
  display: none !important;
}

@include ie-browser {
  .au-ie-only{
    display: block !important;
  }
  .au-ie{
    padding: $spacer-5 $spacer-4;
    height: 100vh;
    text-align: center;
    display: flex !important;
    align-items: center;
    justify-content: center;
    &__inner{
      width: 100%;
      max-width: 700px;
      margin: $spacer-0 auto;
      padding: $spacer-6;
      box-shadow: 0px 0px 24px rgba(0, 0, 0, 0.16);
      .au-browser-alert{
        &__item{
          img{
            height: 45px;
          }
        }
        &__item-text{
          font-size: 1.2rem;
          margin-top: $spacer-2;
        }
      }
    }
    &__logo{
      width: 140px;
      height: auto;
      display: block;
      margin: $spacer-0 auto $spacer-6;
    }
    &__head{
      font-size: 2.4rem;
      line-height: 1.3;
      color: $au-color-primary;
      font-weight: 500;
      margin-bottom: $spacer-4;
    }
    &__desc{
      line-height: 1.3;
    }
  }
}
