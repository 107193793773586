/* stylelint-disable max-nesting-depth */
/* stylelint-disable no-descending-specificity */
/* stylelint-disable font-family-no-missing-generic-family-keyword */
/* stylelint-disable color-plugin/variable */
/* stylelint-disable selector-class-pattern */
// Accordion
.au-accordion {
  overflow: visible;

  &--au-accordion-no-toggle {
    .accordion-item {
      .accordion-header {
        button {
          pointer-events: none !important;
        }
      }
    }
  }

  &--full-width-left-arrow {
    .accordion-item {
      .accordion-header {
        button {
          width: 100% !important;
        }
      }
    }
  }

  &--static {
    .accordion-header {
      button {
        position: relative !important;
      }
    }
  }

  .accordion-item {
    border: 0;
    border-top: 1px solid $au-color-secondary-light;
    box-shadow: none !important;
    margin-bottom: 0;

    .accordion-header {
      position: relative;
      background: $au-color-white;
      padding: 0;
      border-bottom: none;

      button {
        border: none;
        z-index: 2;
        background-color: transparent;
        font-size: 1.4rem !important;
        box-shadow: unset;
        display: block;
        padding: 20px 20px 20px 25px !important;
        text-decoration: none;
        text-align: left;
        height: auto !important;
        margin-bottom: 0 !important;
        width: 24%;
        white-space: initial;
        position: absolute;

        @include h3;

        &::before {
          content: "\a0018";
          font-family: au-icon-font;
          font-size: 1.2rem;
          position: absolute;
          color: $au-color-white;
          left: 0;
          top: 18px;
          background: $au-color-black;
          width: 18px;
          height: 18px;
          border-radius: 50px;
          padding-top: 3px;
          padding-left: 3px;
          transform: rotate(180deg);
          transition: 0.3s;
        }

        &.collapsed {
          width: 100%;
          position: relative;

          &::before {
            transform: rotate(0deg);
          }
        }

        @include mobile {
          position: relative;
          width: 100%;
        }

        @media print {
          display: block !important;
        }
      }
    }

    .accordion-body {
      // font-size: $font-size-md;
      // padding: 20px 0;
      position: relative;
      z-index: 1;
      display: flex;
      flex-wrap: wrap;
      padding: 20px $spacer-0;

      @include mobile {
        display: block;
      }

      .au-left {
        width: 25%;
        position: relative;

        // margin-top: -20px;
        @include mobile {
          width: 100%;

          // margin-top: 0;
        }

        p {
          white-space: pre-wrap;
        }
      }

      .au-right {
        position: relative;
        width: 75%;
        margin-left: auto;
        padding-left: 30px;

        @include mobile {
          width: 100%;
          margin-top: 0;
          padding-top: 0;
          padding-left: 0;
        }
      }

      .au-center {
        position: relative;
        width: 100%;

        &:first-child {
          padding-top: $spacer-6;
        }
      }
    }
  }

  &--full-width {
    .accordion-item {
      .accordion-header {
        button {
          position: relative;
          width: 100%;
          padding: 20px 40px 20px 20px !important;
          transition: none;

          &[aria-expanded="true"] {
            background-color: var(--au-color-gray-light-2);
          }

          &::before {
            top: 22px;
            left: auto;
            right: 20px;
          }
        }
      }

      .accordion-body {
        background-color: var(--au-color-gray-light-2);
        padding: 10px 20px 20px;
        display: block;
      }
    }
  }

  &.au-full-width {
    .accordion-item {
      .accordion-body {
        display: block;
      }
    }
  }

  .accordion {
    .accordion-item {
      &:first-child {
        border-top: 0;
      }
    }
  }

  &--modal {
    .au-accordion__body {
      width: 100%;
      margin-top: -20px;
    }

    .accordion-button {
      width: 100% !important;
      position: relative !important;
    }
  }
}

// top bottom alignment fixing
.au-card {
  .accordion {
    .accordion-item {
      &:first-child {
        margin-top: -15px;
      }

      &:last-child {
        margin-bottom: -19px;
      }
    }
  }
}

.au-card-accordion {
  .au-card {
    margin-bottom: 4px;
  }
}

// Arabic
[dir="rtl"] {
  .au-accordion .accordion-item .accordion-header button {
    text-align: right;
  }

  .au-accordion .accordion-item .accordion-header button::before {
    padding-right: 3px;
    left: auto;
    right: 0;
  }
}
