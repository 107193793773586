// step-wizard
.au-wizard {
  &__step {
    display: flex;
    gap: 10px;
    margin-bottom: $spacer-4;
  }

  &__item {
    @include shadow;

    border-radius: 100px;
    width: 100%;
    overflow: hidden;
  }

  &__link {
    display: block;
    width: 100%;
    font-size: $au-fs-3;
    text-align: center;
    padding: 10px 20px;
    background: $au-color-white;

    /* stylelint-disable-next-line selector-class-pattern */
    &.active,
    &.au-active {
      color: $au-color-white !important;
      background: $au-color-primary;
    }
  }

  &__content {
    position: relative;
  }
}
