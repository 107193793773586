.au-checkbox {
  input {
    display: none !important;
  }

  label {
    cursor: pointer !important;
    position: relative !important;
    padding-left: 28px !important;
    font-size: 1.4rem;
    min-height: 18px;

    &::after {
      content: "" !important;
      display: block !important;
      width: 18px !important;
      height: 18px !important;
      position: absolute !important;
      left: 0 !important;
      top: 0 !important;
      border: 1px solid $au-color-grey11 !important;
      border-radius: 2px;
    }

    &::before {
      content: "" !important;
      display: none !important;
      position: absolute !important;
      width: 12px !important;
      height: 5px !important;
      left: 3px !important;
      top: 5px !important;
      z-index: 2 !important;
      border-left: 1px solid $au-color-primary !important;
      border-bottom: 1px solid $au-color-primary !important;
      background-color: $au-color-transparent !important;
      border-top: none !important;
      border-right: none !important;
      border-radius: 0 !important;
      transform: rotate(312deg);
    }
  }

  input:checked + label {
    &::before {
      display: block !important;
    }
  }

  // Multi checkbox
  &-multi {
    input {
      &[type="checkbox"] {
        opacity: 0;
      }
    }

    &__view {
      display: inline-block;
      width: 80px;
      height: 25px;
      border: 1px solid transparent;
      border-radius: 2px;
      position: relative;
      cursor: pointer;

      &::after {
        /* stylelint-disable-next-line font-family-name-quotes, string-quotes */
        font-family: 'au-icon-font', sans-serif;
        content: "";
        font-size: $au-fs-3;
        position: absolute;
        line-height: 1;
        top: 4px;
        right: 0;
        left: 0;
        margin: 0 auto;
        text-align: center;
        display: none;
      }

      &::before {
        display: none !important;
      }
    }

    input[status="checked"] + .au-checkbox-multi__view,
    .au-checkbox-multi__view[status="checked"] {
      background-color: $au-color-q11;
      border-color: $au-color-q11;
      color: $au-color-q7;

      &::after {
        content: "\d012";
        display: block;
      }
    }

    input[status="crossed"] + .au-checkbox-multi__view,
    .au-checkbox-multi__view[status="crossed"] {
      background-color: $au-color-d8;
      border-color: $au-color-d8;
      color: $au-color-q7;

      &::after {
        content: "\e957";
        font-size: $au-fs-2;
        display: block;
      }
    }

    input[status="unchecked"] + .au-checkbox-multi__view,
    .au-checkbox-multi__view[status="unchecked"] {
      background-color: $au-color-white;
      border-color: $au-color-secondary-medium;
      color: $au-color-q7;
    }
  }
}
