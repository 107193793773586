.au-form-matrix,
.au-form-rating {
  td {
    padding: 10px 6px !important;
    white-space: normal;
  }

  &__item {
    .au-radio {
      width: 18px;
      height: 18px;

      label {
        display: block;
      }
    }
  }

  &__side {
    font-size: $au-fs-5;
    color: $au-color-primary;
  }

  &__number {
    font-size: $au-fs-5;
    color: $au-color-secondary-medium;
    text-align: center;
  }

  .au-form-matrix__head th {
    border: 0 !important;
  }
}
