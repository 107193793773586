.au-suggestion-list {
  z-index: 99996 !important;
  max-height: 240px;
  overflow-y: auto;
  overflow-x: hidden;
  color: $au-color-secondary-dark;
  text-align: left;
  list-style: none;
  background-color: $au-color-white;
  background-clip: padding-box;
  /* stylelint-disable-next-line color-function-notation, alpha-value-notation */
  border: 1px solid rgba(0, 0, 0, 0.15);

  &__main {
    padding: 5px 0;
    margin: 0;
    font-size: 1.2rem;
  }

  &__items:hover {
    background-color: $au-color-gray-light-3;
  }

  &__text {
    cursor: pointer;
    padding: 6px 10px;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }

  &__opacity {
    &--0 {
      opacity: 0;
    }

    &--1 {
      opacity: 1;
    }
  }
}
