/* stylelint-disable selector-class-pattern */
// Google Fonts
// @import "https://fonts.googleapis.com/css?family=Poppins:100;200;300,400,500,600,700";

// icon fonts
@font-face {
  font-family: au-icon-font;
  src: url("/assets/au-iconfont/au-icons.eot?llwpox");
  src:
    url("/assets/au-iconfont/au-icons.eot?llwpox#iefix") format("embedded-opentype"),
    url("/assets/au-iconfont/au-icons.ttf?llwpox") format("truetype"),
    url("/assets/au-iconfont/au-icons.woff?llwpox") format("woff"),
    url("/assets/au-iconfont/au-icons.svg?llwpox#icomoon") format("svg");
  font-weight: normal;
  font-style: normal;
  font-display: block;
}

// Fonts
$font-display: swap;

.ultra-thin-italic {
  font-family: $default-font-family;
  font-weight: 100;
  font-style: italic;
  font-stretch: normal;
}

.light-italic {
  font-family: $default-font-family;
  font-weight: 300;
  font-style: italic;
  font-stretch: normal;
}

.thin {
  font-family: $default-font-family;
  font-weight: 200;
  font-style: normal;
  font-stretch: normal;
}

.regular {
  font-family: $default-font-family;
  font-weight: 400;
  font-style: normal;
  font-stretch: normal;
}

.ultra-thin {
  font-family: $default-font-family;
  font-weight: 100;
  font-style: normal;
  font-stretch: normal;
}

.thin-italic {
  font-family: $default-font-family;
  font-weight: 200;
  font-style: italic;
  font-stretch: normal;
}

.bold {
  font-family: $default-font-family;
  font-weight: 700;
  font-style: normal;
  font-stretch: normal;
}

.italic {
  font-family: $default-font-family;
  font-weight: 400;
  font-style: italic;
  font-stretch: normal;
}

.light {
  font-family: $default-font-family;
  font-weight: 300;
  font-style: normal;
  font-stretch: normal;
}

.bold-italic {
  font-family: $default-font-family;
  font-weight: 700;
  font-style: italic;
  font-stretch: normal;
}

[class^="au-icon-"],
[class*=" au-icon-"] {
  /* use !important to prevent issues with browser extensions that change fonts */
  /* stylelint-disable-next-line font-family-no-missing-generic-family-keyword */
  font-family: au-icon-font !important;
  speak: never;
  font-style: normal;
  font-weight: 500;
  font-variant: normal;
  text-transform: none;
  line-height: 1;

  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.au-icon--drag::before {
  content: "\c002";
  color: $au-color-currentcolor;
}

.au-icon--info::before {
  content: "\d001";
  color: $au-color-currentcolor;
}

.au-icon--mail::before {
  content: "\d003";
  color: $au-color-currentcolor;
}

.au-icon--option::before {
  content: "\d004";
  color: $au-color-currentcolor;
}

.au-icon--pin::before {
  content: "\d005";
  color: $au-color-currentcolor;
}

.au-icon--copy::before {
  content: "\d006";
  color: $au-color-currentcolor;
}

.au-icon--minus::before {
  content: "\d007";
  color: $au-color-currentcolor;
}

.au-icon--signature::before {
  content: "\d010";
  color: $au-color-currentcolor;
}

.au-icon--dot::before {
  content: "\d011";
  color: $au-color-currentcolor;
}

.au-icon--tick::before {
  content: "\d012";
  color: $au-color-currentcolor;
}

.au-icon--hash::before {
  content: "\d013";
  color: $au-color-currentcolor;
}

.au-icon--clean::before {
  content: "\d015";
  color: $au-color-currentcolor;
}

.au-icon--add-data::before {
  content: "\d016";
  color: $au-color-currentcolor;
}

.au-icon--arrow-bottom::before {
  content: "\d017";
  color: $au-color-currentcolor;
}

.au-icon--arrow-left::before {
  content: "\d018";
  color: $au-color-currentcolor;
}

.au-icon--arrow-right::before {
  content: "\d019";
  color: $au-color-currentcolor;
}

.au-icon--arrow-top::before {
  content: "\d020";
  color: $au-color-currentcolor;
}

.au-icon--change::before {
  content: "\d021";
  color: $au-color-currentcolor;
}

.au-icon--cloud-upload::before {
  content: "\d022";
  color: $au-color-currentcolor;
}

.au-icon--file-open::before {
  content: "\d024";
  color: $au-color-currentcolor;
}

.au-icon--instance::before {
  content: "\d025";
  color: $au-color-currentcolor;
}

.au-icon--ml::before {
  content: "\d026";
  color: $au-color-currentcolor;
}

.au-icon--none::before {
  content: "\d027";
  color: $au-color-currentcolor;
}

.au-icon--reactivate::before {
  content: "\d028";
  color: $au-color-currentcolor;
}

.au-icon--read-tick::before {
  content: "\d029";
  color: $au-color-currentcolor;
}

.au-icon--schedule::before {
  content: "\d030";
  color: $au-color-currentcolor;
}

.au-icon--time::before {
  content: "\d031";
  color: $au-color-currentcolor;
}

.au-icon--user::before {
  content: "\d032";
  color: $au-color-currentcolor;
}

.au-icon--object-management::before {
  content: "\d033";
  color: $au-color-currentcolor;
}

.au-icon--sort-text::before {
  content: "\d034";
  color: $au-color-currentcolor;
}

.au-icon--redo::before {
  content: "\d035";
  color: $au-color-currentcolor;
}

.au-icon--undo::before {
  content: "\d036";
  color: $au-color-currentcolor;
}

.au-icon--policy-management::before {
  content: "\d037";
  color: $au-color-currentcolor;
}

.au-icon--business-continuity::before {
  content: "\d039";
  color: $au-color-currentcolor;
}

.au-icon--globe::before {
  content: "\d041";
  color: $au-color-currentcolor;
}

.au-icon--abc::before {
  content: "\d114";
  color: $au-color-currentcolor;
}

.au-icon--file::before {
  content: "\d123";
  color: $au-color-currentcolor;
}

.au-icon--top-icon::before {
  content: "\e900";
  color: $au-color-currentcolor;
}

.au-icon--user-management::before {
  content: "\e901";
  color: $au-color-currentcolor;
}

.au-icon--human-resources::before {
  content: "\e903";
  color: $au-color-currentcolor;
}

.au-icon--inventory::before {
  content: "\e904";
  color: $au-color-currentcolor;
}

.au-icon--left-icon::before {
  content: "\e905";
  color: $au-color-currentcolor;
}

.au-icon--link-arrow::before {
  content: "\e906";
  color: $au-color-currentcolor;
}

.au-icon--logistics::before {
  content: "\e907";
  color: $au-color-currentcolor;
}

.au-icon--loglout::before {
  content: "\e908";
  color: $au-color-currentcolor;
}

.au-icon--logo-configure::before {
  content: "\e909";
  color: $au-color-currentcolor;
}

.au-icon--manufacturing::before {
  content: "\e90a";
  color: $au-color-currentcolor;
}

.au-icon--mark-read::before {
  content: "\e90b";
  color: $au-color-currentcolor;
}

.au-icon--modules::before {
  content: "\e90d";
  color: $au-color-currentcolor;
}

.au-icon--my-time::before {
  content: "\e90e";
  color: $au-color-currentcolor;
}

.au-icon--notification::before {
  content: "\e90f";
  color: $au-color-currentcolor;
}

.au-icon--object-manage::before {
  content: "\e910";
  color: $au-color-currentcolor;
}

.au-icon--overview::before {
  content: "\e911";
  color: $au-color-currentcolor;
}

.au-icon--procurement::before {
  content: "\e912";
  color: $au-color-currentcolor;
}

.au-icon--profile::before {
  content: "\e913";
  color: $au-color-currentcolor;
}

.au-icon--reply::before {
  content: "\e914";
  color: $au-color-currentcolor;
}

.au-icon--right-icon::before {
  content: "\e915";
  color: $au-color-currentcolor;
}

.au-icon--risk-aggregation::before {
  content: "\e916";
  color: $au-color-currentcolor;
}

.au-icon--risk-controls::before {
  content: "\e917";
  color: $au-color-currentcolor;
}

.au-icon--risk-management::before {
  content: "\e918";
  color: $au-color-currentcolor;
}

.au-icon--risk-universe::before {
  content: "\e919";
  color: $au-color-currentcolor;
}

.au-icon--sales::before {
  content: "\e91a";
  color: $au-color-currentcolor;
}

.au-icon--security::before {
  content: "\e91c";
  color: $au-color-currentcolor;
}

.au-icon--sessions-history::before {
  content: "\e91d";
  color: $au-color-currentcolor;
}

.au-icon--snapshot::before {
  content: "\e91f";
  color: $au-color-currentcolor;
}

.au-icon--teams-time::before {
  content: "\e920";
  color: $au-color-currentcolor;
}

.au-icon--time-management::before {
  content: "\e921";
  color: $au-color-currentcolor;
}

.au-icon--time-management-1::before {
  content: "\e922";
  color: $au-color-currentcolor;
}

.au-icon--down-icon::before {
  content: "\e923";
  color: $au-color-currentcolor;
}

.au-icon--edit-home::before {
  content: "\e924";
  color: $au-color-currentcolor;
}

.au-icon--administration::before {
  content: "\e926";
  color: $au-color-currentcolor;
}

.au-icon--analytics-scenarios::before {
  content: "\e927";
  color: $au-color-currentcolor;
}

.au-icon--ask-auraa::before {
  content: "\e928";
  color: $au-color-currentcolor;
}

.au-icon--audit-analytics::before {
  content: "\e929";
  color: $au-color-currentcolor;
}

.au-icon--audit-issue-tracker::before {
  content: "\e92a";
  color: $au-color-currentcolor;
}

.au-icon--audit-management::before {
  content: "\e92b";
  color: $au-color-currentcolor;
}

.au-icon--audit-planning::before {
  content: "\e92c";
  color: $au-color-currentcolor;
}

.au-icon--audit-reporting::before {
  content: "\e92d";
  color: $au-color-currentcolor;
}

.au-icon--back-home::before {
  content: "\e92e";
  color: $au-color-currentcolor;
}

.au-icon--budget-management::before {
  content: "\e92f";
  color: $au-color-currentcolor;
}

.au-icon--budget-management-1::before {
  content: "\e930";
  color: $au-color-currentcolor;
}

.au-icon--camera::before {
  content: "\e932";
  color: $au-color-currentcolor;
}

.au-icon--connection::before {
  content: "\e933";
  color: $au-color-currentcolor;
}

.au-icon--education::before {
  content: "\e934";
  color: $au-color-currentcolor;
}

.au-icon--file-download::before {
  content: "\e935";
  color: $au-color-currentcolor;
}

.au-icon--file-history::before {
  content: "\e936";
  color: $au-color-currentcolor;
}

.au-icon--flag::before {
  content: "\e937";
  color: $au-color-currentcolor;
}

.au-icon--flag-selected::before {
  content: "\e938";
  color: $au-color-currentcolor;
}

.au-icon--job::before {
  content: "\e939";
  color: $au-color-currentcolor;
}

.au-icon--read::before {
  content: "\e93a";
  color: $au-color-currentcolor;
}

.au-icon--sand-clock::before {
  content: "\e93b";
  color: $au-color-currentcolor;
}

.au-icon--time-left::before {
  content: "\e93c";
  color: $au-color-currentcolor;
}

.au-icon--timer::before {
  content: "\e93d";
  color: $au-color-currentcolor;
}

.au-icon--trophy::before {
  content: "\e93e";
  color: $au-color-currentcolor;
}

.au-icon--sort-za::before {
  content: "\e93f";
  color: $au-color-currentcolor;
}

.au-icon--menu-manage::before {
  content: "\e940";
  color: $au-color-currentcolor;
}

.au-icon--celery-manage::before {
  content: "\e946";
  color: $au-color-currentcolor;
}

.au-icon--clients::before {
  content: "\e947";
  color: $au-color-currentcolor;
}

.au-icon--dashboards::before {
  content: "\e948";
  color: $au-color-currentcolor;
}

.au-icon--delete::before {
  content: "\e949";
  color: $au-color-currentcolor;
}

.au-icon--edit::before {
  content: "\e94a";
  color: $au-color-currentcolor;
}

.au-icon--edit-box::before {
  content: "\e94b";
  color: $au-color-currentcolor;
}

.au-icon--expand-down::before {
  content: "\e94c";
  color: $au-color-currentcolor;
}

.au-icon--features::before {
  content: "\e94d";
  color: $au-color-currentcolor;
}

.au-icon--feedback::before {
  content: "\e94e";
  color: $au-color-currentcolor;
}

.au-icon--feedback-1::before {
  content: "\e94f";
  color: $au-color-currentcolor;
}

.au-icon--filter::before {
  content: "\e950";
  color: $au-color-currentcolor;
}

.au-icon--filter-mob::before {
  content: "\e951";
  color: $au-color-currentcolor;
}

.au-icon--finance-accounts::before {
  content: "\e952";
  color: $au-color-currentcolor;
}

.au-icon--actions::before {
  content: "\e954";
  color: $au-color-currentcolor;
}

.au-icon--actions-collapse::before {
  content: "\e955";
  color: $au-color-currentcolor;
}

.au-icon--help::before {
  content: "\e956";
  color: $au-color-currentcolor;
}

.au-icon--close::before {
  content: "\e957";
  color: $au-color-currentcolor;
}

.au-icon--plus::before {
  content: "\e958";
  color: $au-color-currentcolor;
}

.au-icon--down-fill::before {
  content: "\a0001";
  color: $au-color-currentcolor;
}

.au-icon--left-fill::before {
  content: "\a0002";
  color: $au-color-currentcolor;
}

.au-icon--right-fill::before {
  content: "\a0003";
  color: $au-color-currentcolor;
}

.au-icon--top-fill::before {
  content: "\a0004";
  color: $au-color-currentcolor;
}

.au-icon--calendar::before {
  content: "\a0005";
  color: $au-color-currentcolor;
}

.au-icon--attach::before {
  content: "\a0006";
  color: $au-color-currentcolor;
}

.au-icon--chat::before {
  content: "\a0007";
  color: $au-color-currentcolor;
}

.au-icon--database::before {
  content: "\a0017";
  color: $au-color-currentcolor;
}

.au-icon--down::before {
  content: "\a0018";
  color: $au-color-currentcolor;
}

.au-icon--export::before {
  content: "\a0019";
  color: $au-color-currentcolor;
}

.au-icon--left::before {
  content: "\a001f";
  color: $au-color-currentcolor;
}

.au-icon--hide::before {
  content: "\a0020";
  color: $au-color-currentcolor;
}

.au-icon--import::before {
  content: "\a0021";
  color: $au-color-currentcolor;
}

.au-icon--link::before {
  content: "\a0023";
  color: $au-color-currentcolor;
}

.au-icon--lock::before {
  content: "\a0024";
  color: $au-color-currentcolor;
}

.au-icon--message::before {
  content: "\a0025";
  color: $au-color-currentcolor;
}

.au-icon--print::before {
  content: "\a0026";
  color: $au-color-currentcolor;
}

.au-icon--refresh::before {
  content: "\a0027";
  color: $au-color-currentcolor;
}

.au-icon--reload::before {
  content: "\a0028";
  color: $au-color-currentcolor;
}

.au-icon--right::before {
  content: "\a0029";
  color: $au-color-currentcolor;
}

.au-icon--search::before {
  content: "\a0030";
  color: $au-color-currentcolor;
}

.au-icon--settings::before {
  content: "\a0031";
  color: $au-color-currentcolor;
}

.au-icon--sort-grid::before {
  content: "\a0032";
  color: $au-color-currentcolor;
}

.au-icon--sort-list::before {
  content: "\a0033";
  color: $au-color-currentcolor;
}

.au-icon--top::before {
  content: "\a0035";
  color: $au-color-currentcolor;
}

.au-icon--typing::before {
  content: "\a0036";
  color: $au-color-currentcolor;
}

.au-icon--unlock::before {
  content: "\a0037";
  color: $au-color-currentcolor;
}

.au-icon--view::before {
  content: "\a0038";
  color: $au-color-currentcolor;
}

.au-icon--warning::before {
  content: "\a0039";
  color: $au-color-currentcolor;
}

.au-icon--zoom-in::before {
  content: "\a0040";
  color: $au-color-currentcolor;
}

.au-icon--zoom-out::before {
  content: "\a0041";
  color: $au-color-currentcolor;
}

.au-icon--compliance::before {
  content: "\f900";
  color: $au-color-currentcolor;
}

.au-icon--assessment::before {
  content: "\f901";
  color: $au-color-currentcolor;
}

.au-icon--incident-reporting::before {
  content: "\f902";
  color: $au-color-currentcolor;
}

.au-icon--down-line::before {
  content: "\f903";
  color: $au-color-currentcolor;
}

.au-icon--left-line::before {
  content: "\f904";
  color: $au-color-currentcolor;
}

.au-icon--right-line::before {
  content: "\f905";
  color: $au-color-currentcolor;
}

.au-icon--top-bottom-change::before {
  content: "\f906";
  color: $au-color-currentcolor;
}

.au-icon--top-line::before {
  content: "\f907";
  color: $au-color-currentcolor;
}

.au-icon--list-view::before {
  content: "\f908";
  color: $au-color-currentcolor;
}

.au-icon--run::before {
  content: "\f910";
  color: $au-color-currentcolor;
}

.au-icon--h-bar-chart-1::before {
  content: "\e90c";
  color: $au-color-currentcolor;
}

.au-icon--h-bar-chart-2::before {
  content: "\e95d";
  color: $au-color-currentcolor;
}

.au-icon--round-chart::before {
  content: "\e91e";
  color: $au-color-currentcolor;
}

.au-icon--half-round-chart::before {
  content: "\e925";
  color: $au-color-currentcolor;
}

.au-icon--table-view::before {
  content: "\e931";
  color: $au-color-currentcolor;
}

.au-icon--round-loader::before {
  content: "\e941";
  color: $au-color-currentcolor;
}

.au-icon--horizontal-bar-cart::before {
  content: "\e942";
  color: $au-color-currentcolor;
}

.au-icon--dasboard-card::before {
  content: "\e943";
  color: $au-color-currentcolor;
}

.au-icon--move::before {
  content: "\e944";
  color: $au-color-currentcolor;
}

.au-icon--next-tab::before {
  content: "\e945";
  color: $au-color-currentcolor;
}

.au-icon--graph::before {
  content: "\e959";
  color: $au-color-currentcolor;
}

.au-icon--filter-grid::before {
  content: "\e95a";
  color: $au-color-currentcolor;
}

.au-icon--file-upload::before {
  content: "\e95f";
  color: $au-color-currentcolor;
}

.au-icon--approval-requst::before {
  content: "\e95e";
  color: $au-color-currentcolor;
}

.au-icon--requst::before {
  content: "\e960";
  color: $au-color-currentcolor;
}

.au-icon--view-template::before {
  content: "\e961";
  color: $au-color-currentcolor;
}

.au-icon--move-square::before {
  content: "\e962";
  color: $au-color-currentcolor;
}

.au-icon--one-line::before {
  content: "\e963";
  color: $au-color-currentcolor;
}

.au-icon--swich-arrow::before {
  content: "\e964";
  color: $au-color-currentcolor;
}

.au-icon--two-line::before {
  content: "\e965";
  color: $au-color-currentcolor;
}

.au-icon--trend-arrow::before {
  content: "\e966";
  color: $au-color-currentcolor;
}

.au-icon--search-shot::before {
  content: "\e91b";
  color: $au-color-currentcolor;
}

.au-icon--assisted-modelling::before {
  content: "\e967";
  color: $au-color-currentcolor;
}

.au-icon-pdf-generate::before {
  content: "\e968";
  color: $au-color-currentcolor;
}
