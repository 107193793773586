/* stylelint-disable selector-class-pattern */
// Cards
.au-card {
  @include card;

  display: flex;
  align-items: stretch;
  flex-direction: column;

  &__header,
  &__body,
  &__footer {
    width: 100%;
  }

  &__footer {
    display: flex;
    align-items: flex-end;
    margin-top: auto;
  }

  .au-card,
  .au-grid {
    padding: 0 !important;
    box-shadow: none !important;

    &.au-style-view {
      @include shadow-2;

      padding: $grid-gutter-space !important;
    }
  }

  app-horizontal-stacked-chart {
    display: block;
    height: 318px;
    overflow: auto;

    @include hide-scroll;
  }

  &.au-header-card {
    margin-top: -15px;
  }
}

.au-nospace-col {
  width: calc(100% + 40px) !important;
  margin-left: -20px;
  margin-right: -20px;
  margin-bottom: -21px;

  >.row {
    margin-right: 0;
    margin-left: 0;
  }

  div[class^="col"] {
    padding: 0 !important;
  }

  .au-card {
    padding: 20px !important;
    background: $au-color-secondary-light-2;
    border: 1px solid $au-color-secondary-light;
    flex-direction: column;

    &__head {
      min-height: 34px;
    }

    &__footer {
      margin-top: auto;
    }
  }

  .au-card__header {
    padding: 0;
  }
}

@media print {
  .au-card {
    &__footer {
      margin-top: $spacer-3;
      padding-bottom: $spacer-3;
      height: 100%;
    }
  }
}
