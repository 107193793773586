// Typography
// Font size
@use "sass:math";

$font-size-base: 1rem !default; // Assumes the browser default, typically `16px`
$font-size-xl: $font-size-base * 3.2 !default; // 32px
$font-size-lg: $font-size-base * 2.8 !default; // 28px
$font-size-md: $font-size-base * 1.4 !default; // 14px
$font-size-sm: $font-size-base * 1.2 !default; // 12px
$font-size-xs: $font-size-base * 1 !default; // 10px
// Font weight
$font-weight-thin: 200 !default;
$font-weight-light: 300 !default;
$font-weight-normal: 400 !default;
$font-weight-medium: 500 !default;
$font-weight-semi: 600 !default;
$font-weight-bold: 700 !default;
$line-height-lg: 1.4 !default; // Larger line height for smaller text elements, below font size 18px
$line-height-md: 1.2 !default;
$line-height-sm: 1.05 !default; // Smaller line height for larger text elements, above 19px
$letter-spacing-lg: 0.04em; // Larger letter spacing for smaller text elements, below font size 18px
$letter-spacing-sm: 0.02em; // Smaller letter spacing for smaller text elements, above 19px
$h1-font-size: $font-size-base * 3.8 !default;
$h2-font-size: $font-size-base * 2.4 !default;
$h3-font-size: $font-size-base * 1.9 !default;
$h4-font-size: $font-size-base * 1.6 !default;
$h5-font-size: $font-size-base * 1.4 !default;
$h6-font-size: $font-size-base * 1 !default;
$h1-font-size-desktop: $font-size-base * 2.125 !default;
$h2-font-size-desktop: $font-size-base * 1.875 !default;
$h3-font-size-desktop: $font-size-base * 1.5 !default;
$h4-font-size-desktop: $font-size-base * 1.375 !default;
$h5-font-size-desktop: $font-size-base * 1.25 !default;
$h6-font-size-desktop: $font-size-base * 1.125 !default;

// Other fonts
$au-fs-1: $font-size-base * 1 !default;
$au-fs-2: $font-size-base * 1.2 !default;
$au-fs-3: $font-size-base * 1.4 !default;
$au-fs-4: $font-size-base * 1.6 !default;
$au-fs-5: $font-size-base * 1.8 !default;
$au-fs-6: $font-size-base * 2 !default;
$au-fs-7: $font-size-base * 2.2 !default;
$au-fs-8: $font-size-base * 2.4 !default;
$au-fs-9: $font-size-base * 2.6 !default;
$au-fs-10: $font-size-base * 2.8 !default;
$au-fs-11: $font-size-base * 3 !default;
$au-fs-12: $font-size-base * 3.2 !default;
$au-fs-13: $font-size-base * 3.4 !default;

// Fonts
$poppins: "Poppins",sans-serif;
$roboto: "Roboto",sans-serif;

// defaults
$default-font-family: $poppins !default;
$default-font-size: $font-size-base !default;
$default-line-height: $line-height-md !default;
$default-font-weight: $font-weight-normal !default;
$default-font-color: $au-color-black !default;
$default-background-color: $au-color-secondary-light-2 !default;

// px to rem function
@function calculate-rem($size) {
  @return math.div($size, 16px) * 1rem;
}

@mixin font-size($size) {
  /* stylelint-disable-next-line font-plugin/font-check */
  font-size: calculate-rem($size);
}
