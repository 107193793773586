// Text color
.au-color {
  &--primary,
  &--blue {
    color: $au-color-primary !important;
  }

  &--primary-light,
  &--blue-light {
    color: $au-color-primary-light !important;
  }

  &--primary-dark,
  &--blue-dark {
    color: $au-color-primary-dark !important;
  }

  &--secondary,
  &--gray {
    color: $au-color-secondary !important;
  }

  &--secondary-dark,
  &--gray-dark {
    color: $au-color-secondary-dark !important;
  }

  &--secondary-medium {
    color: $au-color-secondary-medium !important;
  }

  &--secondary-light {
    color: $au-color-secondary-light !important;
  }

  &--secondary-light-2 {
    color: $au-color-secondary-light-2 !important;
  }

  &--black {
    color: $au-color-black !important;

    &-50 {
      /* stylelint-disable-next-line color-plugin/variable, color-function-notation, alpha-value-notation */
      color: rgba(0, 0, 0, 0.5);
    }
  }

  &--white {
    color: $au-color-white !important;
  }

  &--green {
    color: $au-color-green !important;
  }

  &--red {
    color: $au-color-red !important;
  }

  &--yellow {
    color: $au-color-yellow !important;
  }

  &--orange {
    color: $au-color-orange !important;
  }

  // Qualitative Palette
  &--q1 {
    color: $au-color-q1 !important;
  }

  &--q2 {
    color: $au-color-q2 !important;
  }

  &--q3 {
    color: $au-color-q3 !important;
  }

  &--q4 {
    color: $au-color-q4 !important;
  }

  &--q5 {
    color: $au-color-q5 !important;
  }

  &--q6 {
    color: $au-color-q6 !important;
  }

  &--q7 {
    color: $au-color-q7 !important;
  }

  &--q8 {
    color: $au-color-q8 !important;
  }

  &--d1 {
    color: $au-color-d1 !important;
  }

  &--d2 {
    color: $au-color-d2 !important;
  }

  &--d3 {
    color: $au-color-d3 !important;
  }

  &--d4 {
    color: $au-color-d4 !important;
  }

  &--d5 {
    color: $au-color-d5 !important;
  }

  &--d6 {
    color: $au-color-d6 !important;
  }

  &--d7 {
    color: $au-color-d7 !important;
  }

  &--s1 {
    color: $au-color-s1 !important;
  }

  &--s2 {
    color: $au-color-s2 !important;
  }

  &--s3 {
    color: $au-color-s3 !important;
  }

  &--s4 {
    color: $au-color-s4 !important;
  }

  &--s5 {
    color: $au-color-s5 !important;
  }

  &--s6 {
    color: $au-color-s6 !important;
  }

  &--s7 {
    color: $au-color-s7 !important;
  }
}

// Background color
.au-bg {
  &--primary,
  &--blue {
    background-color: $au-color-primary !important;
  }

  &--primary-light,
  &--blue-light {
    background-color: $au-color-primary-light !important;
  }

  &--primary-dark,
  &--blue-dark {
    background-color: $au-color-primary-dark !important;
  }

  &--secondary,
  &--gray {
    background-color: $au-color-secondary !important;
  }

  &--secondary-dark,
  &--gray-dark {
    background-color: $au-color-secondary-dark !important;
  }

  &--secondary-medium {
    background-color: $au-color-secondary-medium !important;
  }

  &--secondary-light {
    background-color: $au-color-secondary-light !important;
  }

  &--secondary-light-2 {
    background-color: $au-color-secondary-light-2 !important;
  }

  &--black {
    background-color: $au-color-black !important;
  }

  &--white {
    background-color: $au-color-white !important;
  }

  &--green {
    background-color: $au-color-green !important;
  }

  &--red {
    background-color: $au-color-red !important;
  }

  &--yellow {
    background-color: $au-color-yellow !important;
  }

  &--orange {
    background-color: $au-color-orange !important;
  }

  &--q1 {
    background-color: $au-color-q1 !important;
  }

  &--q2 {
    background-color: $au-color-q2 !important;
  }

  &--q3 {
    background-color: $au-color-q3 !important;
  }

  &--q4 {
    background-color: $au-color-q4 !important;
  }

  &--q5 {
    background-color: $au-color-q5 !important;
  }

  &--q6 {
    background-color: $au-color-q6 !important;
  }

  &--q7 {
    background-color: $au-color-q7 !important;
  }

  &--q8 {
    color: $au-color-q8 !important;
  }

  &--d1 {
    background-color: $au-color-d1 !important;
  }

  &--d2 {
    background-color: $au-color-d2 !important;
  }

  &--d3 {
    background-color: $au-color-d3 !important;
  }

  &--d4 {
    background-color: $au-color-d4 !important;
  }

  &--d5 {
    background-color: $au-color-d5 !important;
  }

  &--d6 {
    background-color: $au-color-d6 !important;
  }

  &--d7 {
    background-color: $au-color-d7 !important;
  }

  &--s1 {
    background-color: $au-color-s1 !important;
  }

  &--s2 {
    background-color: $au-color-s2 !important;
  }

  &--s3 {
    background-color: $au-color-s3 !important;
  }

  &--s4 {
    background-color: $au-color-s4 !important;
  }

  &--s5 {
    background-color: $au-color-s5 !important;
  }

  &--s6 {
    background-color: $au-color-s6 !important;
  }

  &--s7 {
    background-color: $au-color-s7 !important;
  }
}
