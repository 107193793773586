// Right menu
.au-right-menu {
  position: fixed;
  width: 280px;
  height: calc(100vh - 50px);
  right: 0;
  bottom: 0;
  z-index: 99991;
  background: $au-color-white;
  display: flex;

  @include ani-d-flex;

  flex-wrap: wrap;

  @include transition(0.3s);

  // display: none;
  &.au-show {
    display: block;
  }

  &.au-lg {
    width: 600px;
  }

  &.au-md {
    width: 450px;
  }

  &.au-sm {
    width: 320px;
  }

  @include shadow;

  &__header {
    width: 100%;
    position: relative;
    padding: 15px;
    padding-bottom: 0;
    height: 85px;

    h3 {
      overflow-wrap: break-word;
      margin-bottom: 0;
    }

    .au-close {
      position: absolute;
      right: 10px;
      top: 15px;
      cursor: pointer;

      .au-icon {
        font-size: $au-fs-3;
      }
    }
  }

  &__body {
    width: 100%;
    height: calc(100% - 140px);
    overflow: auto;
    padding: 15px;

    &:not(.au-scroll-y) {
      &::-webkit-scrollbar {
        width: 0;

        /* Remove scrollbar space */
        background: transparent;

        /* Optional: just make scrollbar invisible */
      }
    }
  }

  &__footer {
    width: 100%;
    height: 60px;
    display: flex;
    align-items: center;
    gap: 5px;
    padding: 15px;
  }

  &__item {
    &.au-hide {
      display: none;
    }
  }

  &.au-right-menu-2 {
    width: 400px;
    display: flex;

    .au-right-menu__item {
      width: 50%;
      border-right: 1px solid $au-color-secondary-light;
    }
  }

  &.au-aside-filter,
  &.au-aside-askauraa {
    .au-right-menu__header {
      height: 35px;
    }
  }
}

// Arabic
[dir="rtl"] {
  .au-right-menu {
    right: auto;
    left: 0;

    /* stylelint-disable-next-line no-descending-specificity */
    &__header {
      .au-close {
        right: auto;
        left: 10px;
      }
    }
  }
}
