/* stylelint-disable selector-class-pattern */
// Print styles

@media print {
  .au-btn,
  .au-dropdown {
    display: none !important;
  }

  .col-sm-1,
  .col-md-1,
  .col-lg-1 {
    flex: 1 0 8.3333%;
  }

  .col-sm-2,
  .col-md-2,
  .col-lg-2 {
    flex: 1 0 16.6667%;
  }

  .col-sm-3,
  .col-md-3,
  .col-lg-3 {
    flex: 1 0 25%;
  }

  .col-sm-4,
  .col-md-4,
  .col-lg-4 {
    flex: 1 0 33.3333%;
  }

  .col-sm-5,
  .col-md-5,
  .col-lg-5 {
    flex: 1 0 41.6667%;
  }

  .col-sm-6,
  .col-md-6,
  .col-lg-6 {
    flex: 1 0 50%;
  }

  .col-sm-7,
  .col-md-7,
  .col-lg-7 {
    flex: 1 0 58.3333%;
  }

  .col-sm-8,
  .col-md-8,
  .col-lg-8 {
    flex: 1 0 66.6667%;
  }

  .col-sm-9,
  .col-md-9,
  .col-lg-9 {
    flex: 1 0 75%;
  }

  .col-sm-10,
  .col-md-10,
  .col-lg-10 {
    flex: 1 0 83.3333%;
  }

  .col-sm-11,
  .col-md-11,
  .col-lg-11 {
    flex: 1 0 91.6667%;
  }

  .col-sm-12,
  .col-md-12,
  .col-lg-12 {
    flex: 1 0 100%;
  }

  app-kri-process,
  .au-chart {
    .row {
      display: flex !important;

      .col-md-3 {
        width: 25% !important;
      }

      .col-md-4 {
        width: 33.33% !important;
      }

      .col-md-5 {
        width: 41.5% !important;
      }

      .col-md-6 {
        width: 50% !important;
      }

      .col-md-7 {
        width: 58.3% !important;
      }

      .col-md-8 {
        width: 66.5% !important;
      }
    }
  }

  .au-status-card {
    .au-status-card__item {
      width: 100% !important;
      min-width: unset !important;
    }
  }

  // page load issue fix
  %hide-print {
    display: block !important;
  }

  .au-grid {
    &__header {
      .row {
        .col-12:last-child {
          @extend %hide-print;
        }
      }
    }

    &__responsive {
      max-height: 100% !important;
      overflow: visible !important;
    }
  }

  .au-tag {
    display: inline-block;
    overflow: hidden;
    text-overflow: ellipsis;
    max-width: 100%;
    height: auto;
  }

  table .au-tag {
    display: inline-block !important;
    min-height: 19px;
  }

  .au-filter {
    &__block {
      @extend %hide-print;

      .au-tag {
        margin-right: 5px;
      }
    }
  }

  // styles

  body {
    /* stylelint-disable-next-line color-plugin/variable */
    -webkit-print-color-adjust: exact;
  }

  header.topbar,
  aside.left-sidebar,
  .table-footer,
  .search-row,
  .header-breadcrumb,
  .breadcrumb {
    display: none !important;
  }

  button {
    display: inline-block !important;

    .au-icon {
      display: none !important;
    }

    &.au-btn-clr {
      display: none !important;
    }
  }

  .comments-floating-icon-div-open {
    display: none !important;
  }

  .au-comment-bubble {
    display: none !important;
  }

  .print-logo {
    margin: 10px;
    display: block !important;
  }

  a {
    text-decoration: none !important;
  }

  .page-wrapper {
    margin: 0 !important;
    background: white !important;
  }

  .breadcrumbPadding {
    padding: 25px;
  }

  // Hide elements in print
  #main-wrapper {
    .left-sidebar {
      display: none !important;
    }

    &[data-layout="vertical"][data-sidebartype="full"] {
      .page-wrapper {
        margin-left: 0 !important;
      }
    }
  }

  .au-navbar {
    &__menu-item {
      display: none;
    }

    &__list {
      .au-navbar__item {
        &:first-child {
          display: none;
        }
      }
    }
  }

  .topbar .top-navbar {
    border-bottom: 0;
  }

  // to remove page issue in print
  .au-gap {
    &--0 {
      row-gap: unset !important;
    }

    &--1 {
      row-gap: unset !important;
    }

    &--2 {
      row-gap: unset !important;
    }

    &--3 {
      row-gap: unset !important;
    }

    &--4 {
      row-gap: unset !important;
    }

    &--5 {
      row-gap: unset !important;
    }
  }

  // to aviod the page break inside the elements
  .au-page-break-inside {
    -webkit-column-break-inside: avoid;
    page-break-inside: avoid;
    break-inside: avoid;
    width: 100%;
  }

  .au-d3 {
    svg {
      height: auto;
    }
  }

  .au-form {
    * {
      gap: unset !important;
    }

    &__header {
      margin-bottom: $spacer-2;

      .au-label {
        margin-right: $spacer-1;
      }
    }
  }

  .au-page__action {
    * {
      gap: unset !important;
    }
  }

  .au-card {
    * {
      gap: unset !important;
    }

    .au-gap--2 {
      margin: $spacer-0 -5px;

      & > div {
        margin: $spacer-0 5px;
      }
    }
  }

  .au-box {
    &--3 .au-box__item {
      flex: 1 1 calc(33.3333% + 0.6667px);
    }

    .au-doughnut {
      overflow: visible;
      max-height: none;
      justify-content: flex-start;

      .au-content__item {
        margin: $spacer-1;
      }
    }
  }

  .au-box-row {
    width: auto;
  }

  .au-legend {
    &__text {
      padding-left: 6px;
    }
  }
}
