// Upload
.au-upload {
    &__body {
        padding: 50px;
        border: 2px dashed $au-color-secondary-light;
        border-radius: 10px;
        background-color: $au-color-gray-light-3 !important;
    }
    &__box {
        text-align: center;
    }
    &__footer {
        margin-top: 5px;
    }
    &__support {
        font-size: $font-size-xs;
        color: $au-color-secondary;
    }
    &__uploaded {
        display: flex;
        flex-wrap: wrap;
        margin-top: 10px;
        gap: 10px;
        &-item {
            padding: 4px 8px;
            border-radius: 10px;
            background: $au-color-secondary-light-2;
            color: $au-color-secondary;
            border: 1px solid $au-color-secondary-light;
            display: flex;
            align-items: center;
            gap: 5px;
        }
    }
    &__delete {
        border: 0;
        padding: 0;
        font-size: $font-size-md;
        background: transparent;
    }
    &__file-name {
        font-size: $font-size-sm;
    }
    &--sm {
        .au-upload__body {
            padding: 20px;
        }
    }
    &--disabled {
        pointer-events: none;
    }
}
