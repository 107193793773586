
//Table
table,
.au-table {
  width: 100%;
  .action-icons {
    min-width: 75px;
    text-align: center;
    padding: 3px 8px;
    border-radius: 50px;
    color: $color-white;
  }
  th, td {
    font-size: $font-size-sm;
    white-space: nowrap;
  }
  td {
    color: $color-secondary;
  }
  .icon-plus {
    @include font-size(14px);
  }
}
.au-table-responsive{
  display: block;
  width: 100%;
  overflow-x: auto;
  -webkit-overflow-scrolling: touch;
  -ms-overflow-style: -ms-autohiding-scrollbar;
}
