
//Radio
.au-radio {
  display: inline-block;
  input {
    display: none !important;
  }
  label {
    cursor: pointer !important;
    position: relative !important;
    padding-left: 24px !important;
    font-size: $font-size-md;
    min-height: 18px;
    &::after {
      content: "" !important;
      display: block !important;
      width: 18px !important;
      height: 18px !important;
      position: absolute !important;
      left: 0 !important;
      top: 0 !important;
      border: 1px solid $color-secondary-light !important;
      border-radius: 100px !important;
    }
    &::before {
      content: "" !important;
      display: none !important;
      position: absolute !important;
      width: 10px !important;
      height: 10px !important;
      left: 4px !important;
      top: 4px !important;
      z-index: 2 !important;
      background-color: $au-color-primary;
      border-radius: 100px !important;
    }
  }
  input:checked + label {
    &::before {
      display: block !important;
    }
  }
}

