/* stylelint-disable-next-line selector-class-pattern */
.tooltip {
  z-index: 99999;

  &[data-popper-placement="bottom-start"],
  &[data-popper-placement="top-start"] {

    /* stylelint-disable-next-line selector-class-pattern */
    .tooltip-arrow {
      &::before {
        transform: translateX(-100%);
      }
    }
  }

  &-inner {
    overflow: hidden;
    text-overflow: ellipsis;
    /* stylelint-disable-next-line value-no-vendor-prefix */
    display: -webkit-box;
    -webkit-line-clamp: 15;
    line-clamp: 2;
    -webkit-box-orient: vertical;
    max-width: 250px;
    max-height: 250px;
    line-height: 16px;
  }
}
