/* stylelint-disable */
@import "codemirror/lib/codemirror.css";
@import "codemirror/addon/hint/show-hint.css";
@import "codemirror/addon/lint/lint.css";
@import "./src/assets/au-scss/abstracts/variables/";
@import "./src/assets/au-scss/abstracts/mixins/";

.au-inActive-tab {
  color: $au-color-secondary-medium;
  padding: 10px 5px !important;
}

.au-chartInput {
  height: 35px;
  border: 1px solid $au-color-secondary-light;
  border-radius: 4px !important;
  padding: 8px 10px;
  color: $au-color-black !important;
  font-weight: $font-weight-normal !important;
  font-size: 1.2rem !important;
}

.au-draftsCard {
  min-height: 14rem;
  min-width: 17rem;
}

.au-draftsNoDataCard {
  width: 100%;
  margin-left: 16px;
  margin-right: 40px;
}

.au-flex-grow-0 {
  flex-grow: 0 !important;
}

.au-flex-grow-1 {
  display: flex;
  justify-content: space-between;
  flex-grow: 1 !important;
}

.au-dashboard-published-list-div-hidden {
  display: none;
}

.au-drafts-loader-item {
  min-width: 100%;
}

.au-dashboard-page-wrapper {
  background: $au-color-secondary-light-2;
  position: relative;
  transition: 0.2s ease-in;
}

.au-dashboard-page-wrapper > .container-fluid {
  padding: 10px;
  min-height: calc(100vh - 100px);
}

.au-left-dashboard-menu {
  position: absolute;
  width: 50px;
  height: 100%;
  top: 0;
  z-index: 10;
  padding-top: 49px;
  background: $au-color-white;
}

.au-cdkFieldDiv {
  background: $au-color-white;
  border-radius: 5px;
  max-height: 20px;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  width: 100%;
  text-align: left;
}

.au-cdkFieldDiv-data {
  width: calc(100% - 10px);
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

#main-wrapper[data-layout="vertical"] {
  .topbar .top-navbar .navbar-header {
    transition: 0.2s ease-in;
  }

  &[data-sidebartype="full"] {
    .au-dashboard-page-wrapper {
      margin-left: 65px;
    }
  }

  &[data-sidebartype="iconbar"] {
    .au-dashboard-page-wrapper {
      margin-left: 180px;
    }

    .au-left-dashboard-menu {
      width: 180px;
    }

    .topbar .top-navbar .navbar-header {
      width: 180px;
    }

    .sidebar-nav ul .sidebar-item {
      width: 180px;
    }

    .sidebar-nav {
      ul .sidebar-item {
        .sidebar-link {
          display: block;
          text-align: center;

          i {
            display: block;
            width: auto;
          }
        }
      }
    }
  }

  &[data-sidebartype="overlay"] {
    .topbar {
      box-shadow: 1px 0 20px rgb(0 0 0 / 8%);
    }

    .topbar .top-navbar .navbar-header {
      width: 65px;
    }

    .au-left-dashboard-menu {
      left: -65px;
    }

    .au-dashboard-page-wrapper {
      margin-left: 0;
    }

    &.show-sidebar {
      .au-left-dashboard-menu {
        left: 0;
      }
    }
  }

  &[data-sidebar-position="fixed"] {
    .topbar .top-navbar .navbar-header {
      position: fixed;
      z-index: 10;
    }
  }

  &[data-header-position="fixed"] {
    .topbar {
      position: fixed;
      width: 100%;
    }

    .au-dashboard-page-wrapper {
      padding-top: 49px;
    }
  }

  &[data-boxed-layout="boxed"] {
    max-width: 1200px;
    margin: 0 auto;
    position: relative;
    box-shadow: 1px 0 80px $au-color-black;
  }

  &[data-boxed-layout="boxed"][data-header-position="fixed"] {
    .topbar {
      max-width: 1200px;
    }
  }
}

.au-dashboard-icon-selected {
  stroke: $au-color-primary;
}

.au-selected-module {
  background: $au-color-q9;
}

.au-animateDiv {
  animation-name: fadeInOut;
  animation-duration: 0.5s;
}

@keyframes fadeInOut {
  from {
    opacity: 0;
  }

  to {
    opacity: 1;
  }
}

/****************
 drawflow starting
 ****************/

 .draw-flow-poc {
  margin: 0px;
  padding: 0px;
  width: 100vw;
  height: 100vh;
  overflow: hidden;
  font-family: 'Roboto', sans-serif;
}

.drag-drawflow {
  line-height: 50px;
  cursor: pointer;
  user-select: none;
}

#drawflow {
  position: relative;
  width: calc(100vw - 20px);
  height: calc(100% - 50px);
  background-size: 25px 25px;
}

@media only screen and (max-width: 768px) {
  #drawflow {
    width: calc(100vw - 51px);
  }
}

/* Editing Drawflow */

.drawflow .drawflow-node {
  padding: 0px;
  width: 200px;
}

.drawflow .drawflow-node.selected {
  background: white;
  border: 1px solid #4ea9ff;
}

.drawflow .drawflow-node.selected .title-box {
  color: #22598c;
}

.drawflow .connection .main-path {
  stroke: #4ea9ff;
  stroke-width: 3px;
}

.drawflow .drawflow-node .input,
.drawflow .drawflow-node .output {
  height: 15px;
  width: 15px;
}

.drawflow .drawflow-node .input:hover,
.drawflow .drawflow-node .output:hover {
  background: #4ea9ff;
}

.drawflow .drawflow-node .output {
  right: 10px;
}

.drawflow .drawflow-node .input {
  left: -10px;
  background: white;
}

.drawflow>.drawflow-delete {
  border: 2px solid #43b993;
  background: white;
  color: #43b993;
  -webkit-box-shadow: 0 2px 20px 2px #43b993;
  box-shadow: 0 2px 20px 2px #43b993;
}

.drawflow-delete {
  border: 2px solid #4ea9ff;
  background: white;
  color: #4ea9ff;
  -webkit-box-shadow: 0 2px 20px 2px #4ea9ff;
  box-shadow: 0 2px 20px 2px #4ea9ff;
}

.drawflow-node .title-box {
  height: 25px;
  line-height: 25px;
  border-radius: 4px 4px 0px 0px;
  padding-left: 6px;
}
.drawflow-node .au-nodeName{
    height: 10px;
    line-height: 76px;
    border-radius: 4px 4px 0 0;
    padding-left: 6px;
    pointer-events: none;
  }
.drawflow-node .title {
  height: 25px;
}

.drawflow .title-box svg {
  position: initial;
}

.drawflow-node .box {
  padding: 10px 20px 20px 20px;
  font-size: 14px;
  color: #555555;

}

.drawflow-node .box p {
  margin-top: 5px;
  margin-bottom: 5px;
}

.drawflow-node.welcome {
  width: 250px;
}

.drawflow-node.slack .title-box {
  border-radius: 4px;
}

.drawflow-node input,
.drawflow-node select,
.drawflow-node textarea {
  border-radius: 4px;
  height: 30px;
  line-height: 30px;
  font-size: 16px;
  width: 158px;
  color: #555555;
}

.drawflow-node textarea {
  height: 100px;
}


.drawflow-node.personalized {
  background: red;
  height: 200px;
  text-align: center;
  color: white;
}

.drawflow-node.personalized .input {
  background: yellow;
}

.drawflow-node.personalized .output {
  background: green;
}

.drawflow-node.personalized.selected {
  background: blue;
}

.drawflow .connection .point {
  stroke-width: 2;
  fill: white;

}

.drawflow .connection .point.selected,
.drawflow .connection .point:hover {
  fill: #4ea9ff;
}


/* Modal */


/* The Close Button */

.parent-drawflow {
  display: flex;
  overflow: hidden;
  touch-action: none;
  outline: none;
}

.drawflow {
  width: 100%;
  height: 100%;
  position: relative;
  user-select: none;
  perspective: 0;
  min-height: 475px;
  margin-left: 50px;
}

.drawflow .parent-node {
  position: relative;

}
.drawflow .drawflow-node {
  display: flex;
  align-items: center;
  position: absolute;
  background: #ffffff;
  width: 70px;
  min-height: 40px;
  max-height: 60px;
  border-radius: 4px;
  border: 1px solid #d4d4d4;
  color: black;
  z-index: 2;
  padding: 15px;
}

.drawflow .drawflow-node.selected {
  background: #ffffff;
}

.drawflow .drawflow-node:hover {
  cursor: move;
}

.drawflow .drawflow-node .inputs,
.drawflow .drawflow-node .outputs {
  width: 0px;
}

.drawflow .drawflow-node .drawflow_content_node {
  width: 100%;
  display: block;
}

.drawflow .drawflow-node .input,

.drawflow .drawflow-node .output {
  position: relative;
  width: 10px;
  height: 10px;
  background: white;
  border-radius: 50%;
  border: 1px solid black;
  cursor: crosshair;
  z-index: 1;
  margin-bottom: 5px;

}

.drawflow .drawflow-node .output_1{
  background: #7cfb59;
}

.drawflow .drawflow-node .output_2{
  background: #f00;
}

.drawflow .drawflow-node .input {
  left: -21px;
  top: 2px;
  background: #115CAA;
}
.drawflow .drawflow-node .input_2 {
 margin-top: 5px;
}

.drawflow .drawflow-node .output {
  right: -10px;
  top: 2px;
}

.drawflow svg {
  z-index: 0;
  position: absolute;
  overflow: visible !important;
}

.drawflow .connection {
  position: absolute;
  pointer-events: none;
}

.drawflow .connection .main-path {
  fill: none;
  stroke-width: 4px;
  stroke: #d4d4d4;
  pointer-events: all;
}

.drawflow .connection .main-path:hover {
  stroke: #1266ab;
  cursor: pointer;
}

.drawflow .connection .main-path.selected {
  stroke: #43b993;
}

.drawflow .connection .point {
  cursor: move;
  stroke: black;
  stroke-width: 2;
  fill: white;
  pointer-events: all;
}

.drawflow .connection .point.selected,
.drawflow .connection .point:hover {
  fill: #1266ab;
}

.drawflow .main-path {
  fill: none;
  stroke-width: 5px;
  stroke: steelblue;
}

.drawflow-delete {
  position: absolute;
  display: block;
  width: 18px;
  height: 18px;
  background: #4ea9ff;
  color: white;
  z-index: 4;
  border: 1px solid white;
  line-height: 14px;
  font-size: 12px;
  font-weight: 400;
  text-align: center;
  border-radius: 50%;
  font-family: monospace;
  cursor: pointer;
  box-shadow: none;
}

.drawflow>.drawflow-delete {
  margin-left: -15px;
  margin-top: 15px;
}

.parent-node .drawflow-delete {
  right: -12px;
  top: -9px;
}
