// Forms label
.au-label {
  @include label;

  &__inner {
    display: inline-block;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    max-width: 100%;

    &--adjust {
      max-width: calc(100% - 26px);
    }
  }

  span {
    line-height: 1;
  }
}
