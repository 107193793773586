.scroll-wrapper > .scroll-content {
  box-sizing: inherit !important;
}
.scroll-wrapper {
  overflow: hidden !important;
  padding: 0 !important;
  position: relative;
}
.filter-header {
  border-bottom:1px solid #999;
  color: #232830;
  font: normal 11px/14px Poppins, sans-serif;
  text-transform: uppercase;
  padding: 6px 0 5px 0px;

}
.m-t-10 {
  margin-top: 10px;
}
.scrollbar-dynamic {
  max-height: 230px;
}
.scroll-wrapper > .scroll-content {
  box-sizing: inherit !important;
}
.checkbox,
.radio {
  // margin-bottom: 10px;
  margin-top: 5px;
  padding-left: 0;
}
.checkbox label,
.radio label {
  display: inline-block;
  cursor: pointer;
  position: relative;
  padding-left: 25px;
  margin-right: 15px;
  min-width: 17px;
  min-height: 17px;
  font-size: 13px;
  line-height: 20px;
  margin-bottom: 0;
}
.filter-check-box-wrapper {
  // height: 66.6px;
  margin-bottom: 0px;
  margin-right: 0px;
  max-height: none;
}
.p-left-5{
  padding-left:5px !important
}

.blue-font{
  color:$primary !important;
}
.font-weight-300{
  font-weight: 300!important;
}
.font-weight-600{
  font-weight: 600!important;
}
.font-weight-400{
  font-weight: 400!important;
}
/*css for global filter starts*/
.filter-head{
  /* #B9B9B9*/
  background: #F0F0F0;
  color: #141212 !important;
  padding-left: 5%;
  border: 0px;
}
/*css for global filter end*/

