.au-aside-filter {
  .au-right-menu__header,
  .au-form__header {
    font-weight: 500;
    text-transform: uppercase;

    h4 {
      font-size: 1.4rem;
    }
  }

  .au-checkbox {
    color: $au-color-secondary-medium;
  }

  .au-nav-filter {
    max-height: 0;
    margin-bottom: $spacer-0;
    overflow: hidden;

    &.au-scroll-wrap {
      border-bottom: 1px solid $color-secondary-light;
    }

    &__item {
      margin-bottom: 10px;
      text-transform: none;
    }
  }

  &__accordion {
    display: none;

    & + .au-form__header {
      display: flex;
      align-items: center;
      cursor: pointer;
      margin: 0;
      padding: 8px 0;

      &::before {
        content: none;
      }
    }

    &:checked + .au-form__header {
      .au-icon {
        transform: rotate(180deg);
      }

      & ~ .au-nav-filter {
        max-height: 500vh;
        overflow: auto;
        margin-bottom: $spacer-1;

        @include hide-scroll;
      }
    }
  }

  .au-form {
    margin-bottom: $spacer-0;
  }
}
