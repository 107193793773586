/* stylelint-disable max-nesting-depth */
/* stylelint-disable selector-class-pattern */
// App
//  For all pages

#main-wrapper {
  width: 100%;
  background-color: $au-color-secondary-light-2;
}

.page-wrapper {
  background: $body-bg;
  position: relative;
  transition: 0.2s ease-in;
}

.page-wrapper > .container-fluid {
  padding: 0 10px;
  min-height: calc(100vh - 115px);
}

/*******************
 Footer
*******************/

.footer {
  padding: 15px 20px;
}

/*******************
 Stickey inner-left-part
*******************/

$left-part-width: 260px;

.left-part {
  height: calc(100% - 231px);
  width: $left-part-width;
  position: absolute;
  border: 1px solid $border-color;

  .show-left-part {
    position: absolute;
    top: 25%;
    right: -41px;
  }
}

.right-part {
  width: calc(100% - 260px);
  min-height: calc(100vh - 64px);
  overflow: auto;
  margin-left: $left-part-width;

  .dropdown-toggle::after {
    display: none;
  }
}

.reverse-mode {
  .left-part {
    right: 0;
    border-left: 1px solid $border-color;
  }

  .show-left-part {
    right: auto;
    left: -41px;
  }

  .right-part {
    margin-left: 0;
    margin-right: $left-part-width;
  }
}

/*******************
 Vertical layout
*******************/

#main-wrapper[data-layout="vertical"] {
  // Vertical

  .topbar .top-navbar .navbar-header {
    transition: 0.2s ease-in;
  }

  // sidebar type
  &[data-sidebartype="full"] {
    .page-wrapper {
      margin-left: $sidebar-width-full;
    }
  }

  &[data-sidebartype="iconbar"] {
    .page-wrapper {
      margin-left: $sidebar-width-iconbar;
    }

    .left-sidebar,
    .topbar .top-navbar .navbar-header,
    .sidebar-nav ul .sidebar-item {
      width: $sidebar-width-iconbar;
    }

    .sidebar-nav {
      ul .sidebar-item {
        .sidebar-link {
          display: block;
          text-align: center;

          i {
            display: block;
            width: auto;
          }
        }
      }
    }
  }

  &[data-sidebartype="overlay"] {
    .topbar {
      box-shadow: $shadow;
    }

    .topbar .top-navbar .navbar-header {
      width: $sidebar-width-full;
    }

    .left-sidebar {
      left: -$sidebar-width-full;
    }

    .page-wrapper {
      margin-left: 0;
    }

    &.show-sidebar {
      .left-sidebar {
        left: 0;
      }
    }
  }

  &[data-sidebartype="audit-overlay"] {
    .topbar {
      box-shadow: $shadow;
    }

    .topbar .top-navbar .navbar-header {
      width: $audit-sidebar-width-full;
    }

    /* stylelint-disable-next-line no-descending-specificity */
    .left-sidebar {
      left: -$audit-sidebar-width-full;
    }

    .page-wrapper {
      margin-left: 0;
    }

    &.show-sidebar {
      .left-sidebar {
        left: 0;
      }
    }
  }

  // Sidebar position
  &[data-sidebar-position="fixed"] {
    .topbar .top-navbar .navbar-header {
      position: fixed;
      z-index: 10;
    }
  }

  // Header position
  &[data-header-position="fixed"] {
    .topbar {
      position: fixed;
      width: 100%;
      z-index: 9999;
    }

    .page-wrapper {
      padding-top: $topbar-height;
      padding-bottom: 40px;
    }
  }

  // Boxed Layout
  &[data-boxed-layout="boxed"] {
    max-width: $boxed-width;
    margin: 0 auto;
    position: relative;
    /* stylelint-disable-next-line color-function-notation, alpha-value-notation */
    box-shadow: 1px 0 80px rgba(0, 0, 0, 0.2);
  }

  &[data-boxed-layout="boxed"][data-header-position="fixed"] {
    .topbar {
      max-width: $boxed-width;
    }
  }
}

/*******************
 Vertical responsive layout
*******************/

@media (min-width: 767.98px) {
  #main-wrapper[data-layout="vertical"] {
    // Vertical
    &[data-sidebartype="full"] {
      .topbar .top-navbar .navbar-header {
        width: $sidebar-width-full;
      }
    }

    // Sidebar position
    &[data-sidebar-position="fixed"][data-sidebartype="full"],
    &[data-sidebar-position="fixed"][data-sidebartype="overlay"] {
      .topbar .top-navbar .navbar-collapse {
        margin-left: $sidebar-width-full;
        margin-left: 0;
      }
    }

    &[data-sidebar-position="fixed"][data-sidebartype="audit-overlay"] {
      .topbar .top-navbar .navbar-collapse {
        margin-left: $audit-sidebar-width-full;
        margin-left: 0;
      }
    }

    &[data-sidebar-position="fixed"][data-sidebartype="mini-sidebar"] {
      .topbar .top-navbar .navbar-collapse {
        margin-left: 0;
      }
    }

    &[data-sidebar-position="fixed"][data-sidebartype="iconbar"] {
      .topbar .top-navbar .navbar-collapse {
        margin-left: $sidebar-width-iconbar;
      }
    }

    &[data-sidebar-position="fixed"][data-sidebartype="audit-sidebar"] {
      .topbar .top-navbar .navbar-collapse {
        margin-left: 0;
      }
    }

    // Vertical with mini-sidebar
    &[data-sidebartype="mini-sidebar"] {
      .page-wrapper {
        margin-left: 50px;
      }

      .topbar .top-navbar .navbar-header {
        width: $sidebar-width-mini;

        .logo-text {
          display: none;
        }

        &.expand-logo {
          width: $sidebar-width-full;

          .logo-text {
            display: block;
          }

          .logo-icon {
            display: none;
          }
        }
      }
    }

    &[data-sidebartype="audit-sidebar"] {
      .topbar .top-navbar .navbar-header {
        width: $audit-sidebar-width-mini;

        /* stylelint-disable-next-line no-descending-specificity */
        .logo-text {
          display: none;
        }

        &.expand-logo {
          width: $audit-sidebar-width-full;

          .logo-text {
            display: block;
          }

          .logo-icon {
            display: none;
          }
        }
      }

      .page-wrapper {
        margin-left: 0;

        .container-fluid {
          app-landing-page {
            .bottom-nav {
              width: 100% !important;
            }
          }
        }
      }

      .sidebar-nav {
        .hide-menu,
        .has-arrow::after {
          display: none;
        }

        .nav-small-cap {
          justify-content: center;
        }
      }

      .left-sidebar {
        width: $audit-sidebar-width-mini;

        // transition: 0.3s;
        &:hover {
          width: $audit-sidebar-width-full;

          // transition: 0.2s;
          .sidebar-nav {
            .hide-menu,
            .has-arrow::after {
              display: block;
            }

            .nav-small-cap {
              justify-content: flex-start;
            }
          }

          .first-level.in {
            display: block;
          }
        }
      }
    }
  }
}

@media (max-width: 766px) {
  #main-wrapper {
    &[data-sidebartype="mini-sidebar"] {
      .left-sidebar {
        left: -$sidebar-width-full;
      }
    }

    &[data-sidebartype="audit-sidebar"] {
      .left-sidebar {
        left: -$audit-sidebar-width-full;
      }
    }

    &.show-sidebar {
      .left-sidebar {
        left: 0;
      }
    }

    &[data-layout="vertical"][data-sidebar-position="fixed"] {
      .topbar .top-navbar {
        .navbar-collapse {
          position: relative;
          top: $topbar-height;
        }
      }
    }

    // Header position
    &[data-layout="vertical"][data-header-position="fixed"][data-sidebar-position="fixed"] {
      .topbar .top-navbar {
        .navbar-collapse {
          position: relative;
          top: $topbar-height;
        }
      }
    }
  }

  .left-part {
    left: -$left-part-width;
    background: $body-bg;
    z-index: 1;
    transition: 0.1s ease-in;

    &.show-panel {
      left: 0;
    }
  }

  .reverse-mode .left-part {
    right: -$left-part-width;
    left: auto;

    &.show-panel {
      right: 0;
    }
  }

  .right-part {
    width: 100%;
    margin-left: 0;
  }
}
