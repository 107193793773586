// buttons
.au-btn {
  height: fit-content;
  padding: 10px 18px;
  font-size: $au-fs-2;
  font-weight: $font-weight-normal;
  text-align: center;
  white-space: nowrap;
  vertical-align: middle;
  user-select: none;
  border-radius: $au-btn-round;
  line-height: 100%;
  display: inline-flex;
  gap: 5px;
  cursor: pointer;

  @include border(1px, solid, $au-color-primary);
  @include transition(0.3s);

  &--primary {
    background: $au-color-primary;
    color: $au-color-white;
    border-color: $au-color-primary;

    i {
      &::before {
        color: $au-color-white;
      }
    }

    &:hover {
      background: $au-color-primary-dark;
      border: 1px solid $au-color-primary;
      color: $au-color-white;
    }

    &.au-btn--icon {
      background: $au-color-primary;
      color: $au-color-white;
      border-color: $au-color-primary;
      font-size: $au-fs-3;
    }
  }

  &--secondary {
    background: $au-color-white;
    color: $au-color-primary;
    /* stylelint-disable-next-line value-keyword-case */
    border: 1px solid currentColor;

    &:hover {
      background: $au-color-gray-light-3;
    }

    &.au-btn--icon {
      background: $au-color-white;
      color: $au-color-primary;
      /* stylelint-disable-next-line value-keyword-case */
      border: 1px solid currentColor;
      font-size: $au-fs-3;
    }
  }

  &--link,
  &--icon {
    background: transparent;
    color: $au-color-primary;
    border: 1px solid transparent;
    padding: 3px 8px !important;
  }

  &--sm {
    padding: 2px 4px !important;
  }

  &--md {
    padding: 3px 8px !important;
  }

  &--lg {
    padding: 10px 20px !important;
  }
}

.au-print {
  background: transparent;
  color: $au-color-secondary;
  border: 1px solid transparent;
  padding: 3px 8px !important;

  i,
  em {
    font-size: $au-fs-3;
  }
}

:disabled,
.au-disabled {
  opacity: 0.5;
  cursor: default;
}

.au-link {
  cursor: pointer;
}

.au-checkbox-width {
  width: 20px;
}
