// Toggle button
.au-toggle {
  position: relative;
  display: inline-block;
  width: 60px;
  height: 34px;
  &__checkbox {
    opacity: 0;
    width: 100%;
    height: 100%;
    position: absolute;
    z-index: 2;
    cursor: pointer;
    &:checked + .au-toggle__slider {
      background-color: $au-color-secondary-light-2;
    }
    &:focus + .au-toggle__slider {
      box-shadow: 0 0 1px $au-color-secondary-light;
    }
    &:checked + .au-toggle__slider:before {
      background-color: $au-color-primary;
      -webkit-transform: translateX(26px);
      -ms-transform: translateX(26px);
      transform: translateX(26px);
    }
  }
  &__slider {
    position: absolute;
    cursor: pointer;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: $au-color-secondary-light-2;
    @include transition(.4s);
    border: 1px solid $au-color-secondary-light;
    border-radius: 100px;
    &:before {
      position: absolute;
      content: "";
      height: 32px;
      width: 32px;
      left: 0;
      bottom: 0;
      background-color: $au-color-secondary-light;
      -webkit-transition: .4s;
      transition: .4s;
      border-radius: 100px;
    }
  }
}
