// Font size

.au-fs {
  &--1 {
    font-size: $au-fs-1;
  }
  &--2 {
    font-size: $au-fs-2;
  }
  &--3 {
    font-size: $au-fs-3;
  }
  &--4 {
    font-size: $au-fs-4;
  }
  &--5 {
    font-size: $au-fs-5;
  }
  &--6 {
    font-size: $au-fs-6;
  }
  &--7 {
    font-size: $au-fs-7;
  }
  &--8 {
    font-size: $au-fs-8;
  }
  &--9 {
    font-size: $au-fs-9;
  }
  &--10 {
    font-size: $au-fs-10;
  }
  &--11 {
    font-size: $au-fs-11;
  }
  &--12 {
    font-size: $au-fs-12;
  }
  &--13 {
    font-size: $au-fs-13;
  }
}

// Font Weight

.au-fw--bold {
  font-weight: $font-weight-bold;
}
.au-fw--semi {
  font-weight: $font-weight-semi;
}
.au-fw--semi {
  font-weight: $font-weight-medium;
}
.au-fw--normal {
  font-weight: $font-weight-normal;
}
.au-fw--light {
  font-weight: $font-weight-light;
}
.au-fw--thin {
  font-weight: $font-weight-thin;
}
