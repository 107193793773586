// time
.au-time {
  position: relative;

  input {
    background: $au-color-gray-light-3;
    border: 1px solid $au-color-gray-light-3;
    padding-right: 32px;
    line-height: 1.6;

    @include focus;

    @include moz {
      padding-right: 34px;
    }
  }

  &::after {
    content: "\d031";
    /* stylelint-disable-next-line font-family-name-quotes */
    font-family: "au-icon-font", sans-serif;
    font-size: 1.8rem;
    color: $au-color-black;
    position: absolute;
    top: 9px;
    right: 11px;
  }

  &.au-time-picker {
    &::after {
      content: none;
    }
  }
}

// Arabic
[dir="rtl"] {
  .au-time {
    input {
      padding-right: 37px;
    }

    &::after {
      right: auto;
      left: 11px;
    }
  }
}
