/* stylelint-disable selector-class-pattern */
.au-tag {
  min-width: 56px;
  height: fit-content;
  padding: 4px 12px;
  font-size: 1.4rem;
  align-items: center;
  justify-content: center;
  border-radius: 50px;
  display: inline-flex;
  text-align: center;
  white-space: nowrap;
  gap: 5px;
  cursor: pointer;
  line-height: 1;

  &--sm {
    padding: 2px 8px;
    font-size: 1.2rem;
  }

  &--md {
    padding: 5px 8px;
    font-size: 1.2rem;
  }

  &--lg {
    padding: 10px 20px;
    font-size: 1.2rem;
  }

  &--line {
    color: $au-color-secondary-medium;
    border: 1px solid $au-color-secondary-medium;
    background: transparent;

    &.green,
    &.au-tag-green,
    &.Low,
    &.low {
      border: 1px solid $au-color-green;
      color: $au-color-green;
    }

    &.orange,
    &.au-tag-orange,
    &.Medium,
    &.medium {
      border: 1px solid $au-color-orange;
      color: $au-color-orange;
    }

    &.red,
    &.au-tag-red,
    &.High,
    &.high {
      border: 1px solid $au-color-red;
      color: $au-color-red;
    }

    &.gray,
    &.au-tag-grey {
      border: 1px solid $color-secondary;
      color: $au-color-secondary;
    }

    &.blue,
    &.au-tag-blue {
      border: 1px solid $au-color-primary;
      color: $au-color-primary;
    }
  }

  &--fill {
    border: 1px solid $au-color-secondary-medium;
    background: $au-color-secondary-medium;
    color: $au-color-white;
    cursor: auto;

    &.green,
    &.au-badge-green,
    &.Low,
    &.low {
      border: 1px solid $au-color-green;
      background: $au-color-green;
    }

    &.orange,
    &.au-badge-orange,
    &.Medium,
    &.medium {
      border: 1px solid $au-color-orange;
      background: $au-color-orange;
    }

    &.red,
    &.au-badge-red,
    &.High,
    &.high {
      border: 1px solid $au-color-red;
      background: $au-color-red;
    }

    &.gray,
    &.au-badge-gray {
      border: 1px solid $au-color-secondary;
      background: $au-color-secondary;
    }

    &.blue,
    &.au-badge-blue {
      border: 1px solid $au-color-primary;
      background: $au-color-primary;
    }
  }

  &--img {
    display: flex;
    align-items: center;
    border: 1px solid $au-color-primary-light;
    position: relative;

    img {
      position: absolute;
      width: 100%;
      left: 0;
    }
  }

  &--number {
    padding: 0;
  }

  &__number {
    display: flex;
    align-items: center;
    background: $au-color-q9;
    padding: 1px;
    border-radius: 50px;
    text-decoration: none;

    @include transition(0.4s);

    &:hover {
      background: $au-color-q10;
    }

    &-box {
      font-size: $au-fs-2;
      padding: 4px;
      background: $au-color-primary;
      color: $au-color-white;
      border-radius: 50px;
      line-height: 1;
      min-width: 24px;
      display: flex;
      align-items: center;
      justify-content: center;
      text-align: center;
    }

    &-text {
      padding: 0 5px;
      color: $au-color-primary;
      display: flex;
      align-items: center;

      span {
        font-size: $au-fs-2;
        line-height: 1;
      }

      .au-icon {
        margin-left: 3px;
        color: $au-color-primary;
      }
    }
  }

  &--square {
    border-radius: 0;
  }

  &--empty {
    min-height: 17px;
  }
}

[dir="rtl"] {
  .au-tag {
    &__number {
      &-text {
        /* stylelint-disable-next-line max-nesting-depth */
        .au-icon {
          transform: rotate(180deg);
          margin-left: 0;
          margin-right: 3px;
        }
      }
    }
  }
}
