/* stylelint-disable max-nesting-depth */
/* stylelint-disable no-descending-specificity */
/* stylelint-disable no-eol-whitespace */
/* stylelint-disable selector-class-pattern */
@import "./src/assets/au-scss/abstracts/variables/";
@import "./src/assets/au-scss/abstracts/mixins/";

.au-grid {
  @include card;

  &__body {
    overflow: hidden;
  }

  &__table {
    width: 100%;

    tr {
      th {
        /* stylelint-disable-next-line alpha-value-notation, color-function-notation */
        border-bottom: 1px solid rgba(0, 0, 0, 0.25);
        font-size: $au-fs-2 !important;
        font-weight: $font-weight-normal !important;
        color: $au-color-secondary !important;
      }

      th,
      td {
        padding: 10px 5px !important;
        color: $au-color-secondary;
        font-size: $au-fs-3;
        border-top: 1px solid #dee2e6;
        vertical-align: middle;
      }
    }

    .dropdown {
      &.show {
        & > .au-icon {
          color: $au-color-black;
        }
      }
    }

    &--border {
      border-spacing: 2px;
      border-collapse: inherit;

      th {
        background-color: $au-color-gray-light-2;

        span {
          font-weight: 500;
          color: $au-color-black;
          text-transform: capitalize;
        }
      }

      th,
      td {
        border: 1px solid $au-color-white-light-7 !important;
        border-radius: 5px;
      }

      .au-grid__headtitle {
        font-weight: 500;
      }

      tbody {
        td {
          padding: 0 !important;
        }
      }

      .au-input {
        height: 30px;
        background-color: transparent;
        border: 0;

        &:hover {
          background-color: $au-color-gray-light-3;
        }

        &:focus {
          border: 0;
        }
      }
    }

    &--border-grid {
      border-spacing: 2px;
      border-collapse: inherit;

      td {
        border: 1px solid #dee2e6 !important;
        border-radius: 1px;
      }

      th {
        background: var(--au-color-gray-light-3) !important;
        border-left: 1px solid #dee2e6 !important;
        border-right: 1px solid #dee2e6 !important;

        span {
          font-weight: 500;
          color: $au-color-black;
          text-transform: capitalize;
        }
      }

      tr:nth-child(even) {
        background: var(--au-color-gray-light-3);
      }

      .au-grid__headtitle {
        font-weight: 500;
      }

      tbody {
        td {
          padding: 25 !important;
        }
      }

      .au-input {
        height: 30px;
        background-color: transparent;
        border: 0;

        &:focus {
          border: 0;
        }
      }
    }

    td {
      &.au-link {
        cursor: default;

        > div {
          cursor: pointer;
          display: inline-block;
        }
      }
    }
  }

  &__select {
    width: 100px;

    select {
      width: 100%;
    }
  }

  &__search {
    width: calc(100% - 100px);

    @include grid-search;
  }

  &__table-move {
    display: flex;
    align-items: center;
    background-color: $au-color-gray-light-3;
    cursor: move;

    &-icon {
      padding: $spacer-2 $spacer-1;
      background-color: $au-color-s11;
      color: $au-color-white;
    }
  }

  &__table-block {
    width: 100%;
    border-spacing: 3px;
    border-collapse: inherit;

    tr th {
      font-size: $au-fs-2;
      background-color: $au-color-gray-light-2;
      box-shadow: none;
      color: $au-color-black;
      padding: $spacer-2;
      font-weight: $font-weight-medium;

      span {
        font-weight: 500;
        color: $au-color-black;
        text-transform: capitalize;
      }
    }

    th,
    td {
      padding: 10px 5px;
      color: $au-color-secondary;
      font-size: $au-fs-2;
      border: 1px solid $au-color-white-light-7;
      border-radius: 5px;
      vertical-align: middle;
    }

    tbody td {
      background-color: $au-color-white;
      padding: 6px 8px !important;
      color: $au-color-black;
      position: relative;
    }

    .dropdown {
      &.show {
        & > .au-icon {
          color: $au-color-black;
        }
      }
    }
  }
}

.cdk-drag-preview {
  &.au-grid__body,
  &.au-grid__tr {
    border-spacing: 2px;
    border-collapse: inherit;
    background-color: $au-color-white;
    display: inline-table;

    td {
      border: 1px solid #dee2e6 !important;
      border-radius: 5px;
      min-width: 40px;

      .au-input {
        height: 30px;
        background-color: transparent;
        border: 0;
      }
    }

    .au-table-more,
    .au-table-checkbox {
      width: 40px !important;
      min-width: 40px !important;
      vertical-align: middle;
    }

    .au-grid__table-more-option {
      padding-right: 8px;
    }
  }

  .au-table-checkbox {
    app-au-grid {
      app-au-table-checkbox-option {

        /* stylelint-disable-next-line rule-empty-line-before */
        .au-checkbox {
          label {
            width: 18px;
            height: 18px;
            padding: 0 !important;
          }
        }
      }
    }
  }
}
