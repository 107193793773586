// Header menu
header {
    .nav-link {
        display: flex;
        align-items: center;
    }
    .nav-item-user-name {
        font-size: 1.4rem;
    }
    .nav-item {
        margin-bottom: 0;
    }
    .ti-angle-down {
        font-size: 1.4rem;
    }
    .navbar-nav {
        &.core-links {
            gap: 4px;
            margin-top: 14px !important;
        }
    }
    .logo-icon {
        img {
            margin-top: 15px !important;
        }
    }
    .dropdown-menu-header {
        i {
            font-size: 1rem;
        }
    }
    .notification-arrow {
        margin-left: 239px;
    }
    .notifi-line-height {
        line-height: 1.8rem;
        .notification-list-type {
            small {
                font-size: 1.2rem;
            }
        }
    }
}

//Left side menu
.sidebar-nav {
    .sidebar-item {
        margin-bottom: 0;
    }
}

// Left menu collapse fix
.collapse.in {
    display: block;
}

// Title & page top area
.page-breadcrumb {
    margin-bottom: 10px !important;
}

// Filter
.globalfilter {
    .card-header {
        padding: 1.2rem 1.6rem;
        background-color: #ffffff;
    }
    .card-footer {
        width: 100%;
        .background-dark-btn {
            background-color: #0b3a62;
            color: #fff;
            border-radius: 25px;
            margin-left: 10px;
            padding: 6px 15px !important;
        }
        .background-white-btn {
            background-color: #fff;
            color: #0b3a62;
            margin-left: 8px;
            border-radius: 25px;
            border: 1px solid #0b3a62;
        }
    }
}

// Comment left slide
.commentSectionOfSidebar {
    .transitionForTextarea {
        height: 40px;
        min-height: 40px !important;
        max-height: 300px;
    }
    .btn.disabled,
    .btn:disabled {
        opacity: .65;
    }
    .comment-sidebar-add-btn {
        padding: 0 15px!important;
        height: 21px!important;
    }
    .background-dark-btn {
        background-color: #0b3a62;
        color: #fff;
        border-radius: 25px;
        margin-left: 10px;
        padding: 6px 15px !important;
        border: 1px solid #0b3a62;
        font-size: 11px !important;
    }
    .background-dark-btn,
    .background-white-btn {
        border-radius: 25px;
        padding: 2px 15px !important;
        border: 1px solid #0b3a62;
    }
    .editCheckbox {
        font-size: 11px !important;
    }
    .comment-wrapper {
        margin-bottom: 10px;
    }
    .comment-link {
        margin-top: 5px;
        display: block;
    }
}

.swal2-container {
    z-index: 99992 !important;
}

// dropdown
.dropup {
    z-index: 99991 !important;
}

.copy-right {
    font-size: 1.2rem;
}

//page level filter button alignment
.filter-btn {
    padding: 2px 20px !important;
}

//space in scrolling
.filter-space {
    margin-bottom: 8px !important;
}

.cover-item span img {
    margin-right: 5px;
}
//border in card footer for page level filter
.card-border {
  border: 0 !important;
}
//bottom space card footer for page level filter
.card-bottom {
  margin-bottom: 1.5rem !important;
}

// Table 1st action button
.au-table-action-menu {
  line-height: 100%;
}

//toster
.toast-message {
  font-size: 14px !important;
}

//cursor in ng-select
.ng-select .ng-select-container .ng-value-container .ng-input>input {
  caret-color: transparent;
}

// breadcrumb
.breadcrumb {
  display: flex;
  flex-wrap: wrap;
  padding: 0.75rem 1rem;
  margin-bottom: 1.5rem;
  list-style: none;
  background-color: #f9f9f9;
  border-radius: 2px;
}

.breadcrumb-item {
  + .breadcrumb-item {
    padding-left: 0.5rem;
    &::before {
      display: inline-block;
      padding-right: 0.5rem;
      color: #6c757d;
      content: ">";
    }
  }
  + .breadcrumb-item:hover::before {
    text-decoration: underline;
  }
  + .breadcrumb-item:hover::before {
    text-decoration: none;
  }

}
.audit-details .breadcrumb {
  padding-left: 16px;
}


