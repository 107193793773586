// Timeline
// Vertical timeline
.au-timeline {
    &__item {
        display: flex;
        align-items: flex-start;
        &:last-child {
            .au-timeline__right {
                border-left: 1px dashed transparent;
            }
        }
        &.au-active {
            .au-timeline__left {
                &::after {
                    background: $au-color-primary;
                }
            }
        }
    }
    &.au-dynamic {
        .au-timeline__left {
            &::after {
                display: none;
            }
        }
    }
    &__left {
        position: relative;
        padding-right: 20px;
        padding-bottom: 35px;
        span {
            font-size: $font-size-sm;
            color: $au-color-secondary-medium;
        }
        &::after {
            content: "";
            display: block;
            width: 18px;
            height: 18px;
            border: 1px solid $au-color-primary-light;
            background: $au-color-white;
            border-radius: 100px;
            position: absolute;
            right: -10px;
            top: 0;
        }
        .au-dot {
            display: block;
            width: 18px;
            height: 18px;
            border: 1px solid $au-color-primary-light;
            background: transparent;
            border-radius: 100px;
            position: absolute;
            right: -10px;
            top: 0;
        }
    }
    &__right {
        padding-left: 35px;
        padding-bottom: 35px;
        border-left: 1px dashed $au-color-primary-light;
        h4 {
            color: $au-color-primary;
            margin-bottom: 5px;
        }
    }
}

// Horizontal timeline
.au-h-timeline {
    display: flex;
    width: 100%;
    min-width: 300px;
    padding: 5px 0;
    &__list {
        display: flex;
        width: 100%;
    }
    &__item {
        list-style-type: none;
        width: 25%;
        float: left;
        font-size: 12px;
        position: relative;
        text-align: center;
        text-transform: uppercase;
        color: $au-color-black;
        &:before {
            width: 10px;
            height: 10px;
            content: "";
            line-height: 10px;
            border: 2px solid $au-color-secondary-medium;
            display: block;
            text-align: center;
            margin: 0 auto 3px auto;
            border-radius: 50%;
            position: relative;
            z-index: 2;
            background-color: $au-color-white;
        }
        &:after {
            width: 100%;
            height: 1px;
            content: '';
            position: absolute;
            border-bottom: 1px dotted $au-color-secondary-medium;
            top: 4px;
            left: -50%;
            z-index: 0;
        }
        &:first-child {
            &:after {
                content: none;
            }
        }
        &.au-active {
            color: $au-color-black;
            font-weight: bold;
            &:before {
                border-color: $au-color-d1;
                background: $au-color-green;
            }
            +.au-h-timeline__item:after {
                background-color: $au-color-d1;
            }
        }
    }
}

// Arabic
[dir="rtl"] {
    .au-timeline {
        &__item {
            &:last-child {
                .au-timeline__right {
                    border-right: 0;
                }
            }
        }
        &__right {
            padding-left: 0;
            padding-right: 35px;
            border-left: 0;
            border-right: 1px dashed var(--au-color-primary-light);
        }
        &__left {
            padding-right: 0;
            padding-left: 20px;
            &::after {
                right: auto;
                left: -10px;
            }
        }
    }
}