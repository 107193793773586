// Spacing

:root {
  --space-0: 0;
  --space-1: 5px;
  --space-2: 10px;
  --space-3: 15px;
  --space-4: 20px;
  --space-5: 25px;
}

.au-m {
  &--0 {
    margin: #{var(--space-0)} !important;
  }
  &--1 {
    margin: #{var(--space-1)} !important;
  }
  &--2 {
    margin: #{var(--space-2)} !important;
  }
  &--3 {
    margin: #{var(--space-3)} !important;
  }
  &--4 {
    margin: #{var(--space-4)} !important;
  }
  &--5 {
    margin: #{var(--space-5)} !important;
  }
  &--auto{
    margin: auto;
  }
  &t {
    &--0 {
      margin-top: #{var(--space-0)} !important;
    }
    &--1 {
      margin-top: #{var(--space-1)} !important;
    }
    &--2 {
      margin-top: #{var(--space-2)} !important;
    }
    &--3 {
      margin-top: #{var(--space-3)} !important;
    }
    &--4 {
      margin-top: #{var(--space-4)} !important;
    }
    &--5 {
      margin-top: #{var(--space-5)} !important;
    }
    &--auto{
      margin-top: auto;
    }
  }
  &r {
    &--0 {
      margin-right: #{var(--space-0)} !important;
    }
    &--1 {
      margin-right: #{var(--space-1)} !important;
    }
    &--2 {
      margin-right: #{var(--space-2)} !important;
    }
    &--3 {
      margin-right: #{var(--space-3)} !important;
    }
    &--4 {
      margin-right: #{var(--space-4)} !important;
    }
    &--5 {
      margin-right: #{var(--space-5)} !important;
    }
    &--auto{
      margin-right: auto;
    }
  }
  &b {
    &--0 {
      margin-bottom: #{var(--space-0)} !important;
    }
    &--1 {
      margin-bottom: #{var(--space-1)} !important;
    }
    &--2 {
      margin-bottom: #{var(--space-2)} !important;
    }
    &--3 {
      margin-bottom: #{var(--space-3)} !important;
    }
    &--4 {
      margin-bottom: #{var(--space-4)} !important;
    }
    &--5 {
      margin-bottom: #{var(--space-5)} !important;
    }
    &--auto{
      margin-bottom: auto;
    }
  }
  &l {
    &--0 {
      margin-left: #{var(--space-0)} !important;
    }
    &--1 {
      margin-left: #{var(--space-1)} !important;
    }
    &--2 {
      margin-left: #{var(--space-2)} !important;
    }
    &--3 {
      margin-left: #{var(--space-3)} !important;
    }
    &--4 {
      margin-left: #{var(--space-4)} !important;
    }
    &--5 {
      margin-left: #{var(--space-5)} !important;
    }
    &--auto{
      margin-left: auto;
    }
  }
}

.au-p {
  &--0 {
    padding: #{var(--space-0)} !important;
  }
  &--1 {
    padding: #{var(--space-1)} !important;
  }
  &--2 {
    padding: #{var(--space-2)} !important;
  }
  &--3 {
    padding: #{var(--space-3)} !important;
  }
  &--4 {
    padding: #{var(--space-4)} !important;
  }
  &--5 {
    padding: #{var(--space-5)} !important;
  }
  &t {
    &--0 {
      padding-top: #{var(--space-0)} !important;
    }
    &--1 {
      padding-top: #{var(--space-1)} !important;
    }
    &--2 {
      padding-top: #{var(--space-2)} !important;
    }
    &--3 {
      padding-top: #{var(--space-3)} !important;
    }
    &--4 {
      padding-top: #{var(--space-4)} !important;
    }
    &--5 {
      padding-top: #{var(--space-5)} !important;
    }
  }
  &r {
    &--0 {
      padding-right: #{var(--space-0)} !important;
    }
    &--1 {
      padding-right: #{var(--space-1)} !important;
    }
    &--2 {
      padding-right: #{var(--space-2)} !important;
    }
    &--3 {
      padding-right: #{var(--space-3)} !important;
    }
    &--4 {
      padding-right: #{var(--space-4)} !important;
    }
    &--5 {
      padding-right: #{var(--space-5)} !important;
    }
  }
  &b {
    &--0 {
      padding-bottom: #{var(--space-0)} !important;
    }
    &--1 {
      padding-bottom: #{var(--space-1)} !important;
    }
    &--2 {
      padding-bottom: #{var(--space-2)} !important;
    }
    &--3 {
      padding-bottom: #{var(--space-3)} !important;
    }
    &--4 {
      padding-bottom: #{var(--space-4)} !important;
    }
    &--5 {
      padding-bottom: #{var(--space-5)} !important;
    }
  }
  &l {
    &--0 {
      padding-left: #{var(--space-0)} !important;
    }
    &--1 {
      padding-left: #{var(--space-1)} !important;
    }
    &--2 {
      padding-left: #{var(--space-2)} !important;
    }
    &--3 {
      padding-left: #{var(--space-3)} !important;
    }
    &--4 {
      padding-left: #{var(--space-4)} !important;
    }
    &--5 {
      padding-left: #{var(--space-5)} !important;
    }
  }
}

.au-gap {
  &--0 {
    gap: #{var(--space-0)} !important;
  }
  &--1 {
    gap: #{var(--space-1)} !important;
  }
  &--2 {
    gap: #{var(--space-2)} !important;
  }
  &--3 {
    gap: #{var(--space-3)} !important;
  }
  &--4 {
    gap: #{var(--space-4)} !important;
  }
  &--5 {
    gap: #{var(--space-5)} !important;
  }
  &-col {
    &--0 {
      column-gap: #{var(--space-0)} !important;
    }
    &--1 {
      column-gap: #{var(--space-1)} !important;
    }
    &--2 {
      column-gap: #{var(--space-2)} !important;
    }
    &--3 {
      column-gap: #{var(--space-3)} !important;
    }
    &--4 {
      column-gap: #{var(--space-4)} !important;
    }
    &--5 {
      column-gap: #{var(--space-5)} !important;
    }
  }
  &-row {
    &--0 {
      row-gap: #{var(--space-0)} !important;
    }
    &--1 {
      row-gap: #{var(--space-1)} !important;
    }
    &--2 {
      row-gap: #{var(--space-2)} !important;
    }
    &--3 {
      row-gap: #{var(--space-3)} !important;
    }
    &--4 {
      row-gap: #{var(--space-4)} !important;
    }
    &--5 {
      row-gap: #{var(--space-5)} !important;
    }
  }
}

