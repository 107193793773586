/* stylelint-disable max-nesting-depth */
/* stylelint-disable selector-class-pattern */
// Dropdown
.au-dropdown {
  // Overwriting plugin style
  .ngx-dropdown-container {
    .ngx-dropdown-button {
      min-height: 39px !important;
      height: 39px !important;
    }

    input {
      padding: 5px 0 !important;
      border-bottom: $au-color-secondary-light;
    }

    input,
    label {
      font-size: $au-fs-3 !important;
    }

    .available-items {
      li {
        font-size: $au-fs-3 !important;
        padding: 0 !important;
        color: $au-color-secondary !important;
      }
    }

    span {
      font-size: $au-fs-3 !important;
      white-space: break-spaces;
    }

    .selected-items {
      span {
        font-size: $au-fs-3 !important;
      }

      li {
        padding: 10px !important;
      }
    }

    button {
      @include form-field;

      background-color: $au-color-gray-light-3 !important;
      border: 1px solid $au-color-gray-light-3 !important;

      .nsdicon-angle-down::before {
        display: none !important;

        @include arrow(8px !important, 8px !important);

        color: $au-color-secondary-medium;
        top: 3px !important;
      }

      .nsdicon-angle-down::after {
        content: "\a0001";
        font-family: au-icon-font, sans-serif;
        position: absolute;
        top: 0;
        right: 0;
        color: $au-color-secondary;
      }
    }
  }

  // New dropdown styles
  .ng-select-container {
    background-color: $au-color-gray-light-3 !important;
    border: 1px solid $au-color-grey11 !important;
    min-height: 39px !important;
    height: 39px !important;

    input,
    .ng-placeholder,
    .ng-value-label,
    .ng-value-icon {
      font-size: $au-fs-2;
    }

    .ng-value-icon {
      &.right {
        margin-right: 0 !important;
      }
    }

    .ng-placeholder {
      opacity: 0;
    }

    .ng-arrow-wrapper {
      margin-top: -2px;
    }
  }

  &--autoheight {
    .ng-select-container {
      max-height: 100px;
      height: auto !important;

      .ng-value-container .ng-input > input {
        caret-color: $au-color-black !important;
      }
    }
  }

  .ng-select {
    &.ng-select-single {
      .ng-select-container .ng-value-container .ng-input {
        top: 8px;
      }
    }

    .ng-value {
      position: relative;
      z-index: 1;
    }

    &.ng-select-multiple {
      .ng-value-label,
      .ng-value-icon {
        border: 1px solid $au-color-s8;
      }

      .ng-value-icon.right {
        border: 1px solid $au-color-s8;
        border-left: 0 !important;
      }
    }

    .ng-select-container .ng-value-container .ng-input > input {
      position: relative;
      top: 0;
      white-space: nowrap;
      text-overflow: ellipsis;
    }
  }

  &--icon {
    .ng-select-container {
      min-height: 30px !important;
      background-color: $au-theme-white !important;
    }

    .ng-select.ng-select-multiple .ng-select-container .ng-value-container {
      padding-top: 0;

      .ng-value {
        margin-bottom: 0;
        display: flex;
        align-items: center;
        border: 1px solid $au-color-secondary-light;
        border-radius: 30px;
        overflow: hidden;
        background-color: $au-color-transparent;

        .ng-value-label {
          display: flex;
          align-items: center;
          border: 0;
          padding-left: 0;
        }

        .ng-value-icon {
          &:hover {
            background-color: $au-color-transparent;
          }

          &.right {
            border: 0;
          }
        }
      }
    }
  }

  &--white {
    .ng-select-container {
      background-color: $au-color-white !important;
    }
  }

  &--small {
    .ng-select-container {
      min-height: 30px !important;
    }
  }
}

.ng-select-multiple {
  .ng-select-container {
    height: auto !important;
  }
}

.ng-dropdown-panel {
  z-index: 99999 !important;

  .ng-dropdown-panel-items {
    .ng-option {
      font-size: $au-fs-2;
      color: $au-color-secondary-medium;

      .tooltip-inner {
        word-break: break-all;
      }

      .ng-pristine {
        position: relative;
        top: 2px;
      }
    }
  }
}
