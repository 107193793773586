// Find

.au-find {
  display: flex;
  column-gap: 10px;
  @include tablet {
    margin-bottom: 8px;
  }
  .au-select {
    width: 100px;
  }
  app-au-search {
    display: block;
    width: calc(100% - 120px);
  }
}
