// Flex

.au-flex {
  display: flex;

  &--right {
    justify-content: flex-end;
  }

  &--left {
    justify-content: flex-start;
  }

  &--center {
    justify-content: center;
  }

  &--space-between,
  &--left-right {
    justify-content: space-between;
  }

  &--start {
    justify-content: start;
  }

  &--stretch {
    justify-content: stretch;
  }

  &--inherit {
    justify-content: inherit;
  }

  &--top {
    align-items: flex-start;
  }

  &--bottom {
    align-items: flex-end;
  }

  &--middle {
    align-items: center;
  }

  &--vh-center {
    justify-content: center;
    align-items: center;
  }

  &--dr {
    flex-direction: row;
  }

  &--dc {
    flex-direction: column;
  }

  &--wrap {
    flex-wrap: wrap;
  }

  &--nowrap {
    flex-wrap: nowrap;
  }

  &--flex-1 {
    flex: 1;
  }

  &--self-center {
    align-self: center;
  }

  &--self-end {
    align-self: flex-end;
  }
}

.au-block {
  display: block !important;
}
