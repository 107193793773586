.au-breadcrumb {
  &__body {
    list-style: none;
    display: flex;
    flex-wrap: wrap;
    margin-bottom: 15px;
  }

  &__item {
    font-size: $au-fs-2;
    position: relative;
    margin: 0;
    padding-left: 14px;
    padding-right: 6px;

    a {
      font-size: $au-fs-2;
      color: $au-color-primary-light;
    }

    &:last-child {
      color: $au-color-secondary-medium;
    }

    &::before {
      content: "";
      display: block;
      position: absolute;
      left: 0;
      top: 9px;
      color: $au-color-secondary;

      @include arrow (6px, 6px);
      @include rotate(45deg);
    }

    &:first-child {
      padding-left: $spacer-0;

      &::before {
        display: none;
      }
    }
  }
}

// Arabic
[dir="rtl"] {
  .au-breadcrumb {
    &__item {
      padding-left: 6px;
      padding-right: 14px;

      &:first-child {
        padding-right: $spacer-0;
      }

      &::before {
        right: 0;
        left: auto;

        @include rotate(224deg);
      }
    }
  }
}
