/* stylelint-disable */
@import url("../css/text-to-image-fonts.css");
@import "~daterangepicker/daterangepicker.css";
@import "~ngx-toastr/toastr";
@import "~ng-pick-datetime/assets/style/picker.min.css";
@import "~@ng-select/ng-select/themes/default.theme.css";
@import "~nouislider/dist/nouislider.min.css";

/* Required file */

@import "variable";
@import "../au-scss/abstracts/variables/";

@import "theme-colors/dark-theme";


@import "components";

@import "pages";

@import "widgets/widgets";

@import "horizontal/horizontal";

@import "responsive";

@import "custom";
@import "filter";

@import "icons/font-awesome/css/fontawesome-all.scss";
@import "icons/simple-line-icons/css/simple-line-icons-all.scss";
@import "icons/weather-icons/css/weather-icons.min.css";
@import "icons/themify-icons/themify-icons.scss";
@import "icons/flag-icon-css/flag-icon.min.css";
@import "icons/material-design-iconic-font/css/materialdesignicons.min.css";
@import "icons/crypto-icons/cryptocoins.scss";
@import "icons/iconmind/iconmind.scss";

@import "~perfect-scrollbar/css/perfect-scrollbar.css";
@import "@angular/material/prebuilt-themes/deeppurple-amber.css";

.cdk-overlay-backdrop.cdk-overlay-backdrop-showing {
  &.dark-backdrop {
    background: $au-color-black;
    opacity: 0.85 !important;
  }
}

.pointer {
  cursor: pointer;
}

.download {
  cursor: pointer;
  color: $au-color-q7;
  font-size: 2.5rem;
}

.save-icon {
  background: $au-color-white;
}

.chart-legend .legend-title-text {
  visibility: hidden;
}

.action-icons {
  line-height: 1;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen, Ubuntu, Cantarell, "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif;
  font-weight: $font-weight-semi;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  border-radius: 20px;
  padding: 5px 11px 6px;
  color: $au-color-white;
  font-size: 1.1rem;
  cursor: text !important;
  min-width: 70px;
}

.action-icons-square {
  line-height: 1;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen, Ubuntu, Cantarell, "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif;
  font-weight: $font-weight-semi;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  border-radius: 3px;
  padding: 5px 11px 6px;
  color: $au-color-white;
  font-size: 1.1rem;
  cursor: pointer;
  min-width: 80px;
  height: 30px;
}

.audit-area-icons {
  background-color: $color-secondary-light;
  color: $au-color-black;
}

.multi-rendor-icons {
  min-width: "40px";
}

.rating-button {
  border-radius: 25px;
  color: $color-secondary;
}

/***
*! Account  component
*/

.notification_dropdown_border {
  border-radius: 0.7rem;
}

.min-height-90 {
  min-height: 90vh;
}

.notification-card-read {
  font-weight: $font-weight-normal !important;
  font-size: 1.4rem !important;
  padding: 0;
}

.notification-dropdown-read {
  padding: 2rem;
  background-color: $au-color-white;
  cursor: pointer;
}

.notification-card-title {
  font-weight: $font-weight-semi;
}

.font-color-primary {
  color: $au-color-primary;
}

.notification-prof-img {
  width: 4rem;
  height: 4rem;
  border-radius: 2rem;
}

.notification-detail-card {
  padding: 2rem;
  padding-right: 0;
  position: relative;
}

.attend-survey-btn {
  background-color: $au-color-primary;
  color: $au-color-white;
  padding: 0.5rem;
  padding-left: 1rem;
  padding-right: 1rem;
  margin-top: 1rem;
}

.notification-reject-btn {
  background-color: $au-color-red-1;
  color: $au-color-white;
  padding: 0.5rem;
  padding-left: 1rem;
  padding-right: 1rem;
  margin-top: 1rem;
}

.notification-detail-text-section {
  max-width: 650px;
  color: $au-color-black;
  font-weight: $font-weight-normal;
  margin-left: 10px;
}

.notification-vertical-scroll {
  max-height: 80vh;
  overflow: scroll;
}

.nav-notification-prof {
  width: 2.5rem;
  height: 2.5rem;
  margin-top: 0.8rem;
  border-radius: 2rem;
}

.notif-card {
  padding-left: 0;
  padding-top: 0.3rem;
  padding-bottom: 0.3rem;
  border-bottom: 1px solid $au-color-gray-light-5;
}

.mailbox {
  width: 20rem;
}

.notifi-line-height {
  line-height: 1.2rem;
}

.badge-notifications::after {
  content: "";
  border-radius: 100%;
  height: 8px;
  width: 8px;
  background-color: $color-primary-dark;
  color: $au-color-white;
  position: absolute;
  top: 10px;
  margin-left: -1rem;
  float: right;
}

.notif-middle {
  display: flex;
  justify-content: center;
  margin-top: 250px;
}

.notifiDetailmt {
  color: $au-color-black;
  font-family: $poppins;
}

/***
*! Risk universe component
*/

.active-graph {
  color: $au-color-black;
  background-color: transparent !important;
  border-color: transparent !important;
  padding: 10px 5px !important;
  font-weight: $font-weight-medium;
  font-size: 1.3rem;
}

.shadow-graph {
  color: $au-color-grey4;
  font-size: 1.3rem;
  padding: 10px 5px !important;
}

.title-card {
  margin: 10px 0;
  color: $au-color-primary;
  font-size: 1.125rem;
  font-weight: $font-weight-normal;
  line-height: 35px;
}

.graph-card-subtitle {
  margin-top: 15px;
  justify-content: flex-end;
  display: inline-flex;
  padding: 0 30px;
  font-size: 1.4rem;
  font-weight: $font-weight-normal;
  color: $color-secondary;
}

.graph-card-subtitle span:nth-child(1) {
  margin: 0 10px;
}

.graph-card-subtitle span:nth-child(2) {
  margin: 0 0 0 10px;
}

.card {
  border-radius: 8px;
}

.m-10-0 {
  margin: 10px 0;
}

.d-flex-center {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.background-dark-btn {
  background-color: $au-color-primary;
  color: $au-color-white;
  border-radius: $btnBorderRadius;
  margin-left: 10px;
  padding: 6px 15px !important;
  border: 1px solid $au-color-primary;
}

.background-white-btn {
  background-color: $au-color-white;
  color: $au-color-primary;
  font-size: 1.2rem;
  margin-left: 8px;
  border-radius: $btnBorderRadius;
  border: 1px solid $au-color-primary;
  padding: 6px 15px !important;
}

.filter-block-icon {
  padding: 0;
}

.filter-block-container {
  padding: 0 0 0 2px;
}

.networkTitle {
  height: 38px;
  background-color: rgba(0, 0, 0, 0.5) !important;
  font-weight: $font-weight-medium;
  color: $au-color-grey6;
}

/********
*? ******************************************************************!
****/

.login-text {
  color: $color-secondary;
  font-size: 1.3rem;
}

.loginform {
  padding: 0;
}

.login-logo {
  padding-bottom: 35px;
  text-align: center;
}

button.btn-login {
  padding: 6px 20px;
}

.mt-15 {
  margin-top: 15px;
}

.graph-legend-wrapper {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
}

.donut-chart-wrapper {
  display: flex;
  justify-content: center;
}

.btn-login {
  margin-right: 5px;
  min-width: 120px;
  color: $au-color-white;
  background-color: $au-color-primary;
  border-color: $au-color-primary;
  border-radius: $btnBorderRadius;
}

.forgot-pwd {
  color: $color-secondary;
  font-size: 1.2rem;
  height: 20px;
}

/**
*! Risk info component
*/

.la-1_5x {
  font-size: 1.5rem;
}

.vertical-middle {
  vertical-align: middle;
}

.card-subtitle {
  font-size: 1.2rem;
}

.active-risk-info {
  height: 80px;
  background-color: $au-color-primary-dark;
  border-top: none !important;
  color: $au-color-white;

  .bottom-arrow::after {
    content: "";
    position: absolute;
    left: 15px;
    top: 100%;
    width: 0;
    height: 0;
    border-left: 10px solid transparent;
    border-right: 10px solid transparent;
    border-top: 10px solid $au-color-primary-dark;
    clear: both;
  }

  .card-subtitle {
    color: $au-color-white !important;
  }
}

button,
select,
.select-curved {
  border-radius: $btnBorderRadius;
}

.login-image {
  max-width: 75%;
}

.homepage-logo {
  max-width: 75%;
}

.login-bg {
  background: url("/assets/images/login_cover.png") no-repeat left center;
  background-size: cover;
  overflow: hidden;
  margin-top: 0;
}

.form-bottom {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.danger {
  color: $au-color-red-1;
}

.border-bottom {
  border-bottom: 1px solid $au-color-grey6;
}

hr.text_underline {
  border-bottom: 1px solid $au-color-blue-5;
  border-top: none;
  margin-top: 0;
}

.dropdown-toggle::after {
  border: none !important;
}

.dropdwon-menu.show {
  height: auto;
  background: $au-color-white;
  border: solid 1px $au-color-secondary-light-3;
  padding: 8px;
  position: absolute;
}

.dropdown-toggle.show {
  min-width: 12rem !important;
}

.drop-down-sort-btn-wrapper {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.dropdown-text {
  color: $color-secondary;
  font-size: 1.4rem;
}

.drop-down-sort-btn {
  padding: 0 1em;
  height: 28px;
  line-height: 28px;
  color: $au-color-grey8;
  background-color: $au-color-gray-light-7;
  border-color: rgba(87, 87, 87, 0.27);
  text-transform: none;
  border-radius: 50px;
}

.curved {
  border-radius: 20px;
}

.c3-target-Tablet text {
  fill: $au-color-black;
}

.c3-chart-arc text {
  fill: $au-color-white;
  font-size: 1.4rem;
}

.c3-chart-arcs-title {
  font-size: 3rem;
  fill: $au-color-blue-5 !important;
}

.apexcharts-toolbar {
  visibility: hidden;
}

.full {
  .light-icon,
  .dark-icon {
    display: none !important;
  }

  .dark-logo,
  .light-logo {
    display: inline;
  }
}

.mini-sidebar {
  .dark-logo,
  .light-logo {
    display: none;
  }

  .light-icon,
  .dark-icon {
    display: inline;
  }
}

.audit-sidebar {
  .dark-logo,
  .light-logo {
    display: none;
  }

  .light-icon,
  .dark-icon {
    display: inline;
  }
}

.info-title {
  font-size: 1.2rem;
}

.info-value {
  font-size: 2rem;
  line-height: 1;
}

.filter-block {
  padding: 4px 0;
}

.info-card-body {
  padding-left: 5px !important;
  padding-top: 8px !important;
  padding-bottom: 8px !important;
}

.info-card-value-icon {
  margin-top: -9px;
}

.corner-curved {
  border-radius: 5px;
  padding: 5px 8px;
  border: 1px solid $au-color-white-light-4;
}

.select-corner-curved {
  border-radius: 5px;
  padding: 5px 8px 4px;
  border: 1px solid $au-color-white-light-4;
}

.pagination-select {
  width: 94px;
  padding: 0 10px;
}

.apex-legend {
  padding: 0 5px;
  margin: 0 0 7px;
}

.apex-legend-series {
  line-height: 5px;
}

.apex-legend-marker {
  background: $au-color-white;
  height: 6px;
  width: 20px;
  left: 0;
  top: 0;
  border-width: 0;
  border-color: $au-color-white;
  position: relative;
  display: inline-block;
  cursor: pointer;
  margin-right: 5px;
  border-style: solid;
}

.no-padding {
  padding: 0 !important;
}

.margin-top-15-percentage {
  margin-top: 15%;
}

.apex-legend-text {
  color: $au-color-secondary-medium;
  font-size: 1.2rem;
  font-weight: $font-weight-normal;
  margin-bottom: 0;
}

li.sidebar-item.active > a > span {
  color: $color-primary-dark;
  font-weight: $font-weight-medium;
}

.option-menu {
  color: $au-color-secondary-light-3;
}

.table-dark-text {
  color: $color-secondary;
  font-weight: $font-weight-normal;
}

.col-6-donut-chart {
  margin-top: -40px;
  text-align: center;
}

.margin-top-m {
  margin-top: -25px;
}

.card-footer,
.card-header {
  padding: 0.75rem 1.25rem;
  background-color: transparent;
}

.card .card-header {
  background: 0 0;
  border-bottom: 0;
  padding-top: 0;
  padding-bottom: 0;
  position: relative;
  z-index: 1;
  min-height: 48px;
}

.card .card-header .card-controls {
  float: right;
  margin-top: -3px;
}

.m-t-5 {
  margin-top: 5px;
}

.font-weight-400 {
  font-weight: $font-weight-normal;
}

h5 {
  color: $au-color-grey9;
  margin: 10px 0;
}

table {
  thead {
    tr {
      th {
        font-size: 1.2rem;
        color: $color-secondary;
        padding: 9px 6px 8px !important;
        vertical-align: middle !important;
        border-bottom: 1px solid rgba(0, 0, 0, 0.25) !important;
        border-top: 1px solid rgba(0, 0, 0, 0.25) !important;
      }
    }
  }

  td {
    color: $color-secondary;
    padding: 11px 6px 10px;
  }
}

/* Style northward tooltips specifically */

.small-scroll {
  max-height: 275px;
  overflow: hidden;
  overflow-y: scroll;
  padding-bottom: 35px !important;
}

.border-0 {
  border: 0 !important;
}

.d-flex-cc {
  display: flex;
  align-items: center;
}

.irs-min {
  display: none !important;
}

.irs--flat .irs-max {
  display: none !important;
}

.j-f-e {
  justify-content: flex-end;
}

.scroll {
  overflow-y: scroll;
}

.filter-block .btn {
  font-size: 1.2rem !important;
  padding: 3px 15px;
}

.scroll::-webkit-scrollbar {
  width: 2px;
}

.scroll::-webkit-scrollbar-track {
  box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
}

.scroll::-webkit-scrollbar-thumb {
  background-color: $au-color-secondary-medium;
  outline: 1px solid $au-color-grey6;
}

.pagination {
  justify-content: flex-end;
  align-items: center;
}

.graph-wrapper {
  display: flex;
  align-items: center;
  height: 100%;
  justify-content: space-around;
}

.no-box-shadow {
  box-shadow: none;
}

.nav-item-user-name {
  color: $color-secondary;
}

.equal-height {
  display: -webkit-box;
  display: flex;
  flex-wrap: wrap;
}

.eq-content {
  height: 95%;
}

.d3-tip {
  font-family: Verdana, sans-serif;
  background: rgba(0, 0, 0, 0.8);
  padding: 8px;
  color: $au-color-white;
  z-index: 5070;
  text-transform: capitalize;
}

/* Style northward tooltips specifically */

.d3-tip.n::after {
  margin: -2px 0 0;
  top: 100%;
  left: 0;
}

.item {
  display: inline-block;
  padding: 0 2px;
  font-family: $poppins;
  font-weight: $font-weight-medium;
}

.donut-legend {
  min-height: 42px;
  list-style: none;
  margin-bottom: 10px;
  display: flex;
  flex-wrap: wrap;
  align-items: flex-end;
}

.h-90 {
  height: 100%;
}

.mt-m-30 {
  margin-top: -30px !important;
}

.f-w-100 {
  font-weight: 100;
}

.scroll-chart {
  max-height: 250px;
  overflow: hidden;
  overflow-y: scroll;
}

.small-scroll-chart {
  max-height: 275px;
  overflow: hidden;
  overflow-y: scroll;
  padding-bottom: 20px !important;
}

.scroll-chart-risk-control {
  max-height: 310px;
  overflow: hidden;
}

.min-h-310 {
  min-height: 310px;
}

.min-h-375 {
  min-height: 375px;
}

.p-0-15 {
  padding: 0 15px;
}

.border-none {
  border: none !important;
}

.card .card-header.separator::after {
  content: "";
  height: 1px;
  background: rgba(0, 0, 0, 0.8);
  left: 16px;
  right: 16px;
  position: absolute;
  bottom: 0;
}

.card .card-header.px-0.separator::after {
  left: 0;
}

.separator {
  border-bottom: 1px solid rgba(0, 0, 0, 0.8);
}

.comment-wrapper {
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
}

.flex-grow-1 {
  flex-grow: 1;
}

.card-body .card {
  box-shadow: none !important;
}

.card .card-header .chart-title {
  font-size: 1.125rem !important;
  color: $color-primary;
  font-weight: $font-weight-normal;
}

.overflow-hidden {
  overflow: hidden !important;
}

.d-flex {
  display: flex !important;
}

.flex-column {
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  flex-direction: column;
}

.align-items-center {
  -ms-flex-align: center !important;
  align-items: center !important;
}

.justify-content-end {
  -ms-flex-pack: end !important;
  justify-content: flex-end !important;
}

.bg-light-gray {
  background-color: rgba(0, 0, 0, 0.5) !important;
}

.font-size-20 {
  font-size: 2rem !important;
}

.text-primary {
  color: $au-color-q7 !important;
}

.text-black-50 {
  color: rgba(0, 0, 0, 0.5) !important;
}

.border-radius-5 {
  border-radius: 4px !important;
}

.comment {
  list-style: none;
  margin-left: 0;
  padding-bottom: 9px;
  margin-bottom: 10px;
}

.comment:first-child {
  border-top: none;
}

ul > li,
ol > li {
  line-height: 24px;
}

.bg-secondary {
  background-color: $au-color-gray-light-9 !important;
}

.pull-left {
  float: left !important;
}

.comment-by {
  color: $au-color-q7;
  text-transform: capitalize;
  margin-right: 5px;
  font-weight: $font-weight-normal;
}

@media (max-width: 1199px) {
  .item {
    padding: 0 2px;
  }
}

.ml_-10 {
  margin-left: -10px;
}

.ml_-4 {
  margin-left: -4px;
}

.input-label-text {
  color: $au-color-secondary-medium !important;
  font-weight: $font-weight-normal;
}

/* css for disabling the cursor pointer in budget management (host and status) */

.bottom-marker-overdue {
  margin-left: -27px;
  font-size: 1.3rem;
  font-weight: $font-weight-medium !important;
}

.innerDropDownLast {
  transform: translate(-161px, -42px) !important;
}

.bg-light-blue {
  background-color: $au-color-white-light-8 !important;
}

.bg-light-blue-2 {
  background-color: $au-color-blue-3 !important;
}

.text-capitalize {
  text-transform: capitalize !important;
}

.font-size-24 {
  font-size: 2.4rem !important;
}

.font-size-16 {
  font-size: 1.6rem !important;
}

.font-size-12 {
  font-size: 1.2rem !important;
}

.font-size-14 {
  font-size: 1.4rem !important;
}

.font-size-10 {
  font-size: 1rem !important;
}

.card .card-header .card-title .heading {
  font-size: 1rem !important;
}

.btn-tag.btn-tag-light {
  background: $au-color-white-light-4;
  color: $au-color-blue-light-2;
  border: 1px solid $au-color-white-light-5;
}

.btn-tag.btn-tag-rounded {
  border-radius: 3px !important;
}

.badge {
  text-shadow: none;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen, Ubuntu, Cantarell, "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif;
  font-weight: $font-weight-semi;
  background-color: $au-color-gray-light-7;
  font-size: 1.1rem;
  padding: 0.5em 1em;
  color: $au-color-grey8;
  border-radius: 20px;
}

.light-text,
.light-text a {
  color: $au-color-white !important;
}

.font-weight-bold {
  font-weight: $font-weight-bold !important;
}

.risk-duration {
  padding: 25px 50px !important;
}

.p-t-10 {
  padding-top: 10px !important;
}

.p-l-10 {
  padding-left: 10px !important;
}

.p-l-30 {
  padding-left: 30px;
}

.p-l-25 {
  padding-left: 25px;
}

.min-height {
  height: 375px;
  overflow-y: auto;
}

.card .card-header.bg-header {
  background-color: $color-primary !important;
  color: $au-color-white;
}

.card-header:first-child {
  border-radius: calc(0.25rem - 1px) calc(0.25rem - 1px) 0 0;
}

.padding-none {
  padding: 0 !important;
}

.m-t-m-65 {
  margin-top: -65px;
}

.m-t-m-15 {
  margin-top: -15px;
}

.page-title {
  color: $au-color-primary;
  font-weight: $font-weight-medium;
}

.fw-600 {
  font-weight: $font-weight-semi;
}

.fw-400 {
  font-weight: $font-weight-normal;
}

.fw-500 {
  font-weight: $font-weight-medium;
}

.font-size-18 {
  font-size: 1.8rem !important;
}

.detail-box {
  min-height: 40px;
  border: 1px solid $input-border-color;
  padding: 8px 12px;
  overflow-y: auto;
}

.detail-box-2 {
  min-height: 40px;
  border: 1px solid $au-color-white-light-6;
  padding: 8px 12px;
  max-height: 450px;
  overflow-y: auto;
}

.text-break label {
  font-size: 1.4rem !important;
  line-height: 5px !important;
}

.custom-control label {
  color: $au-color-q7;
  font-size: 1.6rem !important;
  line-height: 20px !important;
  cursor: pointer;
}

.custom-control-label::before {
  top: 0;
  left: 0;
  width: 20px;
  height: 20px;
  background-color: transparent;
  border: 2px solid $color-secondary-light;
}

.custom-control-label::after {
  top: 3px;
  left: 3px;
  width: 14px;
  height: 14px;
  border-radius: 20px;
}

.custom-radio .custom-control-input:checked ~ .custom-control-label::before {
  background: none;
}

.custom-radio .custom-control-input:checked ~ .custom-control-label::after {
  background: $color-primary-dark;
}

.form-check-input {
  height: 20px;
  width: 20px;
  margin: 0 0 0 -1.5em;
  border: 1px solid $color-secondary-light;
}

.border-dashed {
  border: dashed 1.8px $au-color-gray-light-6;
  border-radius: 4rem !important;
}

.border-dashed-1 {
  border: dashed 1.8px $au-color-white-light-6 !important;
}

.pull-right {
  float: right !important;
}

.comment .avatar {
  float: left;
  margin-right: 20px;
  margin-bottom: 20px;
  width: 46px;
}

.comment-link {
  font-size: 1.1rem;
  line-height: 1;
  color: $au-color-secondary-medium;
  padding-left: 10px;
}

span.switch.switch-medium.checked {
  background-color: $au-color-primary !important;
}

.justify-content-s-b {
  justify-content: space-between;
}

@media (max-width: 1199px) {
  .item {
    padding: 0 2px;
  }
}

line,
.apexcharts-xaxis.apexcharts-yaxis-inversed {
  display: none;
}

.card-footer {
  min-height: 54px;
  display: inline-flex;
  align-items: flex-end;
  flex-wrap: wrap;
  padding-top: 0;
  border-top: 0 solid rgba(0, 0, 0, 0.125);
}

#d3HeatMap {
  display: flex;
  align-items: center;
  flex-direction: column;
}

.dir-ltr {
  direction: ltr;
}

.dir-rtl {
  direction: rtl;
}

.pl-10 {
  padding-left: 10px;
}

.mb_-25 {
  margin-bottom: -25px;
}

.mb_-20 {
  margin-bottom: -20px;
}

.mb_-15 {
  margin-bottom: -15px;
}

.mb_-10 {
  margin-bottom: -10px;
}

.mb_-8 {
  margin-bottom: -8px;
}

.mb_-5 {
  margin-bottom: -5px;
}

.form-control {
  border-radius: 4px !important;
  height: 40px !important;
  font-size: 1.4rem;
  vertical-align: middle;
}

input[type="radio"]::after {
  width: 18px;
  height: 18px;
  border-radius: 15px;
  top: -2px;
  left: -1px;
  position: relative;
  background-color: $au-color-gray-light-6;
  content: "";
  display: inline-block;
  visibility: visible;
  border: 2px solid $au-color-white;
}

input[type="radio"]:checked::after {
  width: 18px;
  height: 18px;
  border-radius: 15px;
  top: -2px;
  left: -1px;
  position: relative;
  background-color: $color-primary-dark;
  content: "";
  display: inline-block;
  visibility: visible;
  border: 2px solid $au-color-white;
}

.input-text-style {
  font-size: 1.4rem;
  color: $au-color-grey10;
  font-weight: normal;
}

.file-name-uploder {
  color: $au-color-q7;
}

.nsdicon-close {
  display: none !important;
}

.search-icon-position {
  position: relative;
  right: 25px;
  top: 13px;
  width: 12px;
  height: 12px;
  z-index: 3;
}

.text-blue {
  color: $au-color-q7 !important;
}

.text-blue-2 {
  color: $au-color-primary !important;
}

.text-blue-3 {
  color: $au-color-blue-1 !important;
}

.text-blue-4 {
  color: rgba(75 186 216 / 100%) !important;
}

.text-blue-5 {
  color: $au-color-blue-light-1 !important;
}

.text-black {
  color: $au-color-secondary;
}

.step-wizard {
  color: $au-color-q7;
}

.step-wizard-step {
  text-align: center;
  padding: 0;
}

.step-wizard-button {
  padding: 10px 30px;
  font-size: 1.4rem;
  font-weight: $font-weight-normal;
  border-radius: 25px;
  pointer-events: none;
  background-color: $au-color-white;
  color: $au-color-q7;
  box-shadow: 0 1px 5px 0 rgba(0, 0, 0, 0.1);
}

.step-wizard-button-active {
  background-color: $au-color-q7;
  color: $au-color-white;
}

.font-w-500 {
  font-weight: $font-weight-medium;
}

.input-text-box,
.form-control {
  min-height: $input-min-height;
  border: 1px solid $input-border-color;
  padding: 8px 10px;
  color: $au-color-black !important;
  font-weight: $font-weight-normal !important;
  font-size: 1.4rem !important;
}

.date-range-picker {
  text-align: left !important;
  padding-left: 37px;
  width: 100%;
}

.text-dark {
  color: $au-color-black !important;
}

.text-grey {
  color: $au-color-grey4 !important;
}

.text-blue-dark {
  color: $au-color-primary;
}

.pagination-select select {
  /* Safari and Chrome */
  height: unset !important;
  appearance: none;
  background-image: url("data:image/svg+xml;utf8,<svg fill='black' height='24' viewBox='0 0 24 24' width='24' xmlns='http://www.w3.org/2000/svg'><path d='M7 10l5 5 5-5z'/><path d='M0 0h24v24H0z' fill='none'/></svg>");
  background-repeat: no-repeat;
  background-position-x: 100%;
  background-position-y: 6px;
}

.pagination-select i {
  left: 59px;
  top: 8px;
}

.swal2-icon.swal2-warning {
  border-color: $au-color-secondary-medium !important;
  color: $au-color-secondary-medium !important;
  font-size: 1.2rem !important;
}

.swal2-icon .swal2-icon-content {
  font-size: 4rem !important;
}

.swal2-title {
  color: $au-color-q7 !important;
  font-weight: $font-weight-medium !important;
  font-size: 2.4rem !important;
}

.swal2-content {
  font-size: 1.4rem !important;
}

.swal2-styled.swal2-confirm {
  background-color: $au-color-primary !important;
  color: $au-color-white !important;
  border-radius: 25px !important;
  font-size: 1.4rem !important;
  height: 32px;
  padding: 2px 15px !important;
  border: 1px solid $au-color-primary !important;
}

.swal2-styled.swal2-cancel {
  background-color: $au-color-white !important;
  color: $au-color-secondary !important;
  border-radius: 25px !important;
  font-size: 1.4rem !important;
  height: 32px;
  padding: 2px 15px !important;
  border: 1px solid $au-color-primary !important;
}

.swal2-styled:focus {
  box-shadow: unset !important;
}

.modal-header {
  border-bottom: unset !important;
}

.modal-footer {
  border-top: unset !important;
}

button:focus {
  outline: unset !important;
}

.exp-heading {
  color: $au-color-q7 !important;
  font-weight: $font-weight-medium !important;
  font-size: 2.4rem;
}

.exp-subheading {
  color: $au-color-grey8;
  font-size: 1.4rem;
  font-weight: $font-weight-normal;
}

.export-icons i {
  font-size: 8.6rem;
  color: $au-color-secondary-medium;
}

.export-dark-btn {
  background-color: $au-color-primary;
  color: $au-color-white;
  border-radius: 25px;
  font-size: 1.4rem;
  height: 32px;
  padding: 2px 15px;
  border: 1px solid $au-color-primary;
}

.export-light-btn {
  background-color: $au-color-white;
  color: $au-color-primary;
  border-radius: 25px;
  font-size: 1.4rem;
  height: 32px;
  padding: 2px 15px;
  border: 1px solid $au-color-primary;
}

.export-content {
  overflow: hidden;
}

.modal-content {
  border-radius: 5px;
}

.swal2-actions {
  margin-bottom: 30px !important;
}

.breadcrumb {
  font-size: 1.2rem !important;
}

.nsdicon-angle-down::before {
  top: 3px !important;
}

input[type="date"]::-webkit-calendar-picker-indicator {
  background: transparent;
  bottom: 0;
  color: transparent;
  cursor: pointer;
  height: auto;
  left: 0;
  position: absolute;
  right: 0;
  top: 0;
  width: auto;
}

.daterangepicker .drp-calendar {
  max-width: 287px !important;
}

.ngx-dropdown-button:focus {
  border-color: rgba(0, 0, 0, 0.25) !important;
}

.search-input {
  height: 32px !important;
}

.show-more-link {
  color: $color-primary-dark;
  font-size: 1.2rem;
  font-weight: $font-weight-medium;
}

.form-sub-title {
  font-size: 1.8rem !important;
  color: $au-color-primary;
  font-weight: $font-weight-normal;
  margin-top: 20px;
  margin-bottom: 0;
}

.form-title {
  font-size: 1.8rem !important;
  color: $au-color-primary;
  font-weight: $font-weight-medium;
  margin-top: 20px;
  margin-bottom: 0;
}

.form-section-title {
  font-size: 1.6rem !important;
  color: $au-color-primary;
  font-weight: 450;
  margin-top: 20px;
  margin-bottom: 0;
}

.radio-list-item {
  border: 1px solid $au-color-white-light-7;
  padding: 10px 15px;
  border-radius: 4px !important;
  margin-bottom: 15px;
  height: 100%;
}

.radio-list-label {
  margin-left: 16px;
  margin-bottom: 0;
}

.radio-list-des {
  margin-left: 30px;
}

.radio-list-item input[type="radio"]::after {
  width: 22px;
  height: 22px;
  background-color: $au-color-white;
  border: 1px solid $au-color-white-light-7;
}

.radio-list-item input[type="radio"]:checked::after {
  width: 22px;
  height: 22px;
  background-color: $color-primary-dark;
  border: 3px solid $au-color-white-light-7;
}

.daterange-sm .irs--flat.irs-with-grid {
  max-width: 555px;
  margin: auto;
}

.h-175 {
  height: 175px !important;
}

/* css for disabling the cursor pointer in budget management (host and status) */

.audit-model-popup {
  .modal-lg {
    max-width: 1200px;
  }
}

.action-width {
  padding-left: 10px;
  padding-right: 10px;
}

.working-paper label {
  font-size: 1.6rem !important;
}

input[type="checkbox"] + label {
  display: block;
  margin: 0.1rem;
  padding: 0.1rem;
}

input[type="checkbox"] {
  display: none;
}

input[type="checkbox"] + label::before {
  content: "\2714";
  font-size: 1.2rem;
  line-height: 19px;
  text-align: center;
  border: 1px solid $au-color-secondary-medium;
  border-radius: 0.2rem;
  display: inline-block;
  top: 0;
  left: 0;
  height: 19px;
  width: 20px;
  margin-right: 0.5rem;
  vertical-align: text-bottom;
  color: transparent;
}

input[type="checkbox"]:checked + label::before {
  background-color: $au-color-q7;
  border-color: $au-color-q7;
  color: $au-color-white;
}

.arc text {
  font: 1rem sans-serif;
  text-anchor: middle;
}

.label-text-gaugenopointer {
  margin-top: -3rem;
  font-size: 2rem;
  color: $color-secondary;
  font-weight: $font-weight-bold;

  &:empty {
    margin-top: 0;
  }
}

.labeltextleft {
  margin-left: -8px;
}

.centerText {
  font-size: 1.6rem;
  font-family: $poppins;
  font-weight: $font-weight-normal;
  color: $color-secondary;
  margin-top: 1rem;
  margin-left: -1em;
}

.p-1-10 {
  padding: 1px 10px !important;
  height: unset;
}

.ptb-15 {
  padding-top: 15px;
  padding-bottom: 15px;
}

.pt-2r {
  padding-top: 2rem !important;
}

.pb-36 {
  padding-bottom: 36px !important;
}

.lh-18 {
  line-height: 18px !important;
}

.sm-label {
  font-size: 1.2rem;
  color: $au-color-q7;
  margin: 0;
}

.bottom-marker {
  top: 8px;
  width: 22px;
  height: 3px;
}

.bottom-marker-text {
  margin-left: -27px;
}

.darkBg {
  background-color: $color-secondary-light-2;
}

.fz-20 {
  font-size: 2rem;
}

.timeline-item {
  flex-grow: 100;
  text-align: center;
  margin-right: 2px;
}

.audit-timeline-row div:first-child {
  border-top-left-radius: 15px;
  border-bottom-left-radius: 15px;
}

.audit-timeline-row div:last-child {
  border-top-right-radius: 15px;
  border-bottom-right-radius: 15px;
  margin-right: unset;
}

.innerDropDown {
  position: absolute;
  will-change: transform;
  transform: translate(195px, -42px);
  z-index: 1000;
  float: right;
  min-width: 10rem;
  padding: 0.65rem 0;
  margin: 0.125rem 0 0;
  font-size: 0.875rem;
  color: $au-color-secondary;
  text-align: left;
  list-style: none;
  background-color: $au-color-white;
  background-clip: padding-box;
  border: 1px solid $au-color-white-light-7;
  border-radius: 2px;
}

.f-7px {
  font-size: 0.7rem !important;
}

.section-remove {
  position: absolute;
  right: 4px;
  top: -20px;
}

.section-remove button {
  background-color: $au-color-white;
  cursor: pointer;
  padding: 5px;
  top: -6px;
  position: relative;
}

.cdk-overlay-pane {
  z-index: 999999;
}

.cdk-overlay-container {
  z-index: 999999;
}

.accordion-main .card {
  border-radius: 8px !important;
}

.cdk-drop-dragging .cdk-drag {
  transition: transform 200ms cubic-bezier(0, 0, 0.005, 0.77);
}

.cdk-drag-animating {
  transition: transform 225ms cubic-bezier(0, 0, 0.01, 0.05);
}

.cdk-drag-placeholder {
  background: $au-color-gray-light-7;
}

.min-card-height {
  height: auto;
  margin-bottom: 20px;
}

.card-height-6r {
  height: 6rem !important;
  background-color: rgba(249 249 249 / 100%);
}

.card-height-10r {
  height: 10rem !important;
  background-color: rgba(249 249 249 / 100%);
}

.card-height-12r {
  height: 12rem !important;
  background-color: rgba(249 249 249 / 100%);
}

.card-height-20r {
  height: 20rem !important;
  background-color: rgba(249 249 249 / 100%);
}

.border-solid-black {
  border: solid 1px $au-color-black;
  border-radius: 5px !important;
}

.border-solid-grey {
  border: solid 1px $au-color-grey4;
  border-radius: 5px !important;
}

.card-height-22r {
  height: 22rem;
}

.card-height-30r {
  height: 30rem;
}

.expression-card {
  height: 18rem;
  background-color: $au-color-gray-light-4;
  border: dashed 1.8px $au-color-gray-light-8;
  border-radius: 7px !important;
}

.axis-card {
  height: 6rem;
  background: $au-color-gray-light-9;
  border: 1.8px dashed $color-secondary-light;
  border-radius: 4px !important;
}

.search-icon {
  position: absolute;
  right: 10px;
  top: 6px;
  width: 12px;
  height: 12px;
}

.search-style {
  border-radius: 24px !important;
  border-color: $au-color-gray-light-8 !important;
}

.search-style-2 {
  background: $au-color-white 0% 0% no-repeat padding-box;
  border: 1px solid $color-secondary-light;
  border-radius: 4px !important;
  padding: 8px 10px;
  height: 35px;
  font-size: 1.2rem;
}

svg#Abc:hover {
  fill: $au-color-q7 !important;
}

.dynamic-filter-height {
  max-height: 76vh;
  overflow-y: scroll;
}

svg#_123:hover {
  fill: $au-color-q7 !important;
}

svg#calendar:hover {
  fill: $au-color-q7 !important;
}

.border-gray {
  border: $au-color-secondary !important;
}

.vertical-align {
  display: flex;
  position: relative;
  top: 50%;
  transform: translateY(-50%);
}

.background-white {
  background-color: $au-color-white !important;
}

.z-3 {
  z-index: 3;
}

.fill-blue {
  fill: $au-color-q7 !important;
}

.fill-blue-2 {
  fill: $color-primary-dark !important;
}

.stroke-blue-2 {
  stroke: $color-primary-dark !important;
}

.dashboard-filter {
  background: $theme-light;
  position: fixed;
  height: 100%;
  width: 360px;
  top: $topbar-height;
  right: -360px;
  z-index: 100;
  box-shadow: 1px 0 20px rgba(0, 0, 0, 0.08);
  transition: 0.3s ease-in;

  .service-panel-toggle {
    background: $au-color-red-1;
    padding: 12px 20px;
    color: $au-color-white;
    position: absolute;
    top: 30%;
    left: -54px;
  }

  &.show-service-panel {
    right: 0;
  }

  .customizer-body {
    position: relative;
    height: 100%;
  }

  .customizer-tab {
    display: flex;

    .nav-item {
      width: 33.33%;
      text-align: center;

      .nav-link {
        padding: 15px 12px;
        color: $color-secondary;
        border-bottom: 3px solid transparent;

        &:hover,
        &.active {
          border-bottom: 3px solid $au-color-q7;
          color: $au-color-q7;
        }
      }
    }
  }
}

.border-error {
  color: $au-color-red-2 !important;
  border-radius: 5px;
  border: solid 5px $au-color-red-2 !important;
}

.border-warning {
  color: $au-color-orange !important;
  border-radius: 5px;
  border: solid 5px $au-color-orange !important;
}

.border-success {
  border: $au-color-dark-green !important;
}

.fa-1_5x {
  font-size: 1.5rem;
}

.mr-15 {
  margin-right: 15px;
}

.mt_-2 {
  margin-top: -2px;
}

.local {
  color: $au-color-secondary-medium;
  font-size: 1.2rem;
  width: 177px;
  overflow: hidden;
  text-overflow: ellipsis;
}

.root {
  color: $au-color-secondary-medium;
  font-size: 1.2rem;
  width: 177px;
  overflow: hidden;
  text-overflow: ellipsis;
}

.setup {
  font-size: 1.8rem;
  color: $au-color-black;
  text-align: left;
}

.label-connection {
  color: $au-color-grey4;
  font-size: 1.2rem;
  text-align: left;
}

.bold-value {
  font-weight: $font-weight-medium;
}

.label-left-head {
  text-align: left;
  color: $au-color-black;
  font-weight: $font-weight-medium;
}

.pencil-position {
  position: absolute;
  right: 20px;
  top: 9px;
  font-size: 1rem;
}

.trash-position {
  position: absolute;
  right: 10px;
  top: 9px;
  font-size: 1rem;
}

.mr-545 {
  margin-right: 545px;
}

.ml-69 {
  margin-left: 69px;
}

.load-label {
  color: $au-color-black;
  font-size: 1.4rem !important;
  font-weight: $font-weight-normal;
}

.rightSidebarWidth {
  width: 750px;
}

.headerRightsiebar {
  margin-left: 20px;
  width: 26rem;
}

.rightSidebarValuetext {
  font-size: 1.8rem;
  font-weight: $font-weight-normal;
}

.descriptionRightsidebar p {
  width: 26rem !important;
  word-wrap: wrap;
  word-break: break-word;
}

.rightSidebarFooter {
  width: 100%;
  height: 5rem;
  background-color: $au-color-white;
}

.scrollRighSidebar {
  overflow-y: scroll;
  height: auto;
  padding-bottom: 4rem;
}

.contributorcard_home {
  height: 334px;
}

.issueslistingsidebar p {
  padding-top: 8px;
  width: 23rem !important;
  word-wrap: wrap;
  word-break: break-word;
}

.testingProcedure1 p {
  max-width: 70vw;
  word-wrap: wrap;
  word-break: break-word;
}

.create {
  margin-left: 785px;
  margin-top: 22px;

  .paddingContributor-top {
    margin-left: 1px;
    margin-right: 1px;
  }

  .paddingDashCard {
    padding-right: 3px !important;
  }

  .padingContributorCard {
    padding-right: 11px;
  }

  .breadcrumbPadding {
    margin-top: -2rem;
    margin-left: -0.9rem;
  }

  .pagetitleContributor {
    margin-left: -0.9rem;
    font-size: 2.2rem !important;
  }

  .uploadFileComments {
    margin-top: 3em;
  }

  .file-upload-comment-button {
    border-radius: 10% $au-color-grey4;
    height: 2rem;
    overflow: hidden;
    position: relative;
    width: 5rem;
    border-color: $au-color-gray-light-7;
    border: thin;

    &::after {
      content: "Browse";
      color: "black";
      font-size: 1.3rem;
      text-align: center;
    }

    input[type="file"] {
      font-size: 10rem;
      left: 0;
      opacity: 0;
      position: absolute;
      top: 0;
    }
  }

  .file-info {
    color: $au-color-secondary-medium;
    margin-left: 0.5rem;
  }

  .filter-block-container.active {
    height: 32px;
    overflow: visible;
  }

  .issueTracker {
    font-size: 2.4rem;
  }

  .text-display-end {
    text-align: end;
  }

  .historyTableicon {
    font-size: 2.2rem;
  }

  .rightSidebarWidth {
    width: 750px;
  }

  .headerRightsiebar {
    margin-left: 20px;
    width: 26rem;
  }

  .rightSidebarValuetext {
    font-size: 1.8rem;
    font-weight: $font-weight-normal;
  }

  .descriptionRightsidebar p {
    width: 26em;
    word-wrap: wrap;
    word-break: break-word;
  }

  .rightSidebarFooter {
    width: 100%;
    height: 5rem;
    background-color: $au-color-white;
  }

  .scrollRighSidebar {
    overflow-y: scroll;
    height: auto;
    padding-bottom: 4rem;
  }

  .contributorcard_home {
    height: 334px;
  }
}

.ml--20 {
  margin-left: -20px;
}

.border-grey-solid {
  border: solid 1px $au-color-grey4;
}

.min-height-10v {
  min-height: 10vh;
}

.max-height-24v {
  max-height: 24vh;
}

.descriptiontextRightSidebar {
  font-size: 1.6rem;
  font-weight: $font-weight-normal;
  height: auto;
  padding-bottom: 6rem;
}

.askauraaInputbtn {
  border-radius: 45px !important;
  border-color: $au-color-grey5;
  padding: 0 15px;
  height: 53px !important;
  margin-left: 0 !important;
}

.buttonChip {
  border-color: $au-color-grey5;
  padding: 3px 10px;
}

.suggestedQstTitle {
  margin-bottom: 1.4rem;
}

.auditProgramSidebarValuetext {
  font-size: 1.6rem;
  font-weight: $font-weight-normal;
}

.issuesSect {
  padding-top: 10px;
  border-top: 1px solid $au-color-white-light-4;
  border-bottom: 1px solid $au-color-white-light-4;
}

.apsidebarscroll {
  width: 28rem !important;
  overflow-y: scroll;
  padding-bottom: 3rem;
}

.ob-legend {
  min-height: unset;
  justify-content: flex-end;
}

.dashboard-stats-scroll {
  display: flex;
  overflow-y: scroll;
}

.todayLines {
  display: inline !important;
}

.svgLines {
  overflow: visible !important;
}

.lines {
  display: block !important;
}

.askauraa_titlesect {
  margin-top: -2rem;
}

.askAuraaicon {
  font-size: 2.2rem;
  margin-top: 0 !important;
}

.askauraabtn {
  display: flex;
  border: 1px solid rgba(0, 0, 0, 0.1);
  top: -3px;
  position: relative;
  padding: 1px 7px;
  align-items: center;
  border-style: inset;
  border-radius: 2rem;
}

.dropdownBasicml {
  margin-left: 8px !important;
}

.mat-expansion-panel:not([class*="mat-elevation-z"]) {
  box-shadow: none !important;
}

.mat-autocomplete-panel {
  border-radius: 1rem !important;
  max-height: 377px !important;
}

.min-h-2r {
  min-height: 2rem;
}

.scrollableColumnsDiv {
  overflow: scroll;
  max-height: 250px;
}

.disable-a-tag {
  pointer-events: none;
  min-height: 2rem;
}

.min-h-12r-imp {
  min-height: 12rem !important;
}

.h-16r-imp {
  height: 16rem !important;
}

.border-light {
  border: solid 1.8px $au-color-white-light-7;
  border-radius: 6px !important;
  border-radius: 4rem !important;
}

.background-blue {
  background: $au-color-white-light-8;
}

.background-light-grey {
  background: $color-secondary-light;
}

.overflow-auto {
  overflow: auto;
}

textarea#expressionArea::placeholder {
  /* Chrome, Firefox, Opera, Safari 10.1+ */
  color: $au-color-q7 !important;
  opacity: 1;

  /* Firefox */
}

#fieldSearchDiv {
  width: 0;
  opacity: 0;
  transition: all 0.3s;
}

#fieldSearchDiv.active {
  animation: change-width 550ms;
  animation-timing-function: ease-in-out;
  width: 100%;
  opacity: 1;
}

#calculationSearchDiv {
  width: 0;
  opacity: 0;
  transition: all 0.3s;
}

#calculationSearchDiv.active {
  animation: change-width 550ms;
  animation-timing-function: ease-in-out;
  width: 100%;
  opacity: 1;
}

@keyframes change-width {
  0% {
    opacity: 0;
    width: 0;
  }

  100% {
    opacity: 1;
    width: 100%;
  }
}

.m-l-487 {
  margin-left: 487px;
}

.m-l-126 {
  margin-left: 126px;
}

.m-l-434 {
  margin-left: 434px;
}

.mb_-15-imp {
  margin-bottom: -15px !important;
}

.min-w-10p {
  min-width: 10% !important;
}

.fill-gray-1 {
  fill: $au-color-secondary-medium !important;
  color: $au-color-secondary-medium !important;
}

.default-label {
  padding: 0 15px !important;
  height: 22px;
  font-size: 1.2rem !important;
  font-weight: $font-weight-normal;
  min-width: 70px;
}

.sm-button {
  height: 26px;
  padding: 0 15px !important;
  font-size: 1.2rem !important;
}

.lh-20 {
  line-height: 20px !important;
}

.mt-n10 {
  margin-top: -10px !important;
}

.testingProcedure {
  max-width: 70vw;
}

.apsidebarfield {
  font-size: 1.6rem;
  font-weight: $font-weight-normal;
  color: $au-color-black;
}

.pls_icon {
  font-size: 2.2rem;
  margin-right: 9px;
}

.p-3-15 {
  padding: 3px 15px !important;
}

.testingProceduretextarea {
  width: 100%;
  border-color: $au-color-white;
  height: auto !important;
}

.textAreaAPSidebar {
  width: 100%;
  border-color: $au-color-white;
  min-height: 26px !important;
  font-size: 1.6rem;
  font-weight: $font-weight-normal;
  color: $au-color-black;
}

.tpTextarea {
  width: 100%;
  border-color: $au-color-white;
  min-height: 26px !important;
  border-radius: 5px;
  padding: 1rem;
}

.boxfieldwidth {
  width: 27rem;
}

.issuesListing {
  width: 28rem !important;
}

.button-input {
  margin-right: 10px;
  color: $au-color-secondary !important;
  background-color: $au-color-white;
  border: 1px solid $au-color-secondary;
}

.button-input-active {
  color: $au-color-white !important;
}

.plain-label {
  color: $au-color-black !important;
  font-weight: $font-weight-normal !important;
  font-size: 1.4rem !important;
}

#tick-mark {
  position: relative;
  display: inline-block;
  width: 18px;
  height: 18px;
  margin-left: -3px;
}

#tick-mark::before {
  position: absolute;
  left: 0;
  top: 50%;
  height: 50%;
  width: 2px;
  background-color: $au-color-green-1;
  content: "";
  transform: translateX(10px) rotate(-45deg);
  transform-origin: left bottom;
}

#tick-mark::after {
  position: absolute;
  left: 0;
  bottom: 0;
  height: 2px;
  width: 100%;
  background-color: $au-color-green-1;
  content: "";
  transform: translateX(10px) rotate(-45deg);
  transform-origin: left bottom;
}

.p-15-0 {
  padding: 15px 0 !important;
}

.textarea-auto-height {
  height: 100% !important;
  display: inline-table;
  border: none;
}

.textarea-auto-height-wrapper {
  border: 1px solid $au-color-white-light-7;
  padding-bottom: 10px;
  margin-top: 0.25rem;
}

.mh-300 {
  min-height: 300px !important;
}

.mh-145 {
  min-height: 145px !important;
}

.mh-200 {
  min-height: 200px !important;
}

.uploadbtncmnts {
  height: 28px !important;
  background: none;
  line-height: 1;
  padding: 6px 10px !important;
}

.submitbtncmnts {
  font-size: 1.6rem !important;
  height: 37px;
  padding: 5px 27px !important;
}

.h-50 {
  height: 50px !important;
}

.legends-bottom {
  padding-bottom: 16px !important;
}

.default-badge-label {
  background-color: $color-secondary-light;
  color: $au-color-secondary;
}

.color-badge {
  height: 15px !important;
  min-width: 50px !important;
}

.mw-150 {
  min-width: 150px !important;
}

.mXW-100 {
  max-width: 100px !important;
}

.reset-btn {
  background-color: $au-color-white;
  color: $au-color-primary;
  border: none;
  padding-right: 0 !important;
}

.label-blue {
  background-color: rgba(0, 0, 0, 0.2);
  border-radius: 5px;
}

.spinner-style {
  width: 100%;
  margin-right: -350px;
  margin-top: -50px;
}

.card-style {
  width: 100%;
  margin-top: -70px;
}

.card1-style {
  width: 100%;
  margin-right: -350px;
}

.thumb-image-style {
  height: 200px;
  width: 200px;
}

.card-footer-filter {
  background-color: $au-color-white;
  padding-right: 56px;
  padding-top: 12px;
}

.table-head-width-fixed {
  min-width: 180px;
  max-width: 200px;
}

.comments-floating-icon-div-open {
  position: fixed;
  width: 60px;
  height: 60px;
  bottom: 27px;
  left: 255px;
  background-color: $au-color-d5;
  color: $au-color-white;
  border-radius: 50px;
  text-align: center;
  box-shadow: 2px 2px 3px $au-color-secondary-medium;
  z-index: 999;
  cursor: pointer;
}

.comments-floating-icon-div-close {
  position: fixed;
  width: 60px;
  height: 60px;
  bottom: 27px;
  left: 320px;
  background-color: $au-color-d5;
  color: $au-color-white;
  border-radius: 50px;
  text-align: center;
  box-shadow: 2px 2px 3px $au-color-secondary-medium;
  z-index: 999;
  cursor: pointer;
}

.comments-floating-icon {
  cursor: pointer;
  margin-left: 3px;
}

.w-14 {
  width: 14%;
}

.comment-sidebar-close-icon {
  cursor: pointer;
  position: absolute;
  right: 0;
  z-index: 999;
}

.comment-sidebar-add-btn {
  padding: 0 15px !important;
  height: 21px !important;
}

.pl-15px {
  padding-left: 15px;
}

.commentFileSelectIcon {
  margin-bottom: 0;
  position: relative;
  top: 7px;
  font-size: 1.8rem;
  color: $color-secondary-medium;
  cursor: pointer;
}

.pt-3px {
  padding-top: 3px;
}

.comment-count-indicator {
  border-radius: 50%;
  background-color: $au-color-blue-1;
  position: absolute;
  top: 13px;
  right: 10px;
  color: $au-color-white;
  font-weight: $font-weight-normal;
  text-align: center;
}

.commentSectionOfSidebar {
  background: $au-color-white;
  margin-top: -10px;
  padding-top: 10px;
  overflow-y: auto;
  overflow-x: hidden;
  transition: 0.3s ease-in;
  border-radius: 0 !important;
  box-shadow: 0 1px 5px 0 rgba(0, 0, 0, 0.15);
  height: 95vh;
}

.word-break-break-all {
  word-break: break-word;
}

.pt-12px {
  padding-top: 12px;
}

.pt-16px {
  padding-top: 16px;
}

.pt-15px {
  padding-top: 15px;
}

.pr-5px {
  padding-right: 5px;
}

.h-3r {
  height: 3rem !important;
}

.mt-8px {
  margin-top: 8px !important;
}

.pos-rel-top-1px {
  position: relative;
  top: 1px;
}

.color-999 {
  color: $au-color-secondary-medium;
}

.cancelTextEdit {
  padding-left: 10px;
  position: relative;
  top: 3px;
}

.cancelTextReply {
  position: relative;
  top: 4px;
  padding-left: 10px;
}

.transitionForTextarea {
  height: 40px !important;
  transition: all 0.4s;
  border: 1px solid $input-border-color;
  padding: 8px 10px;
  color: $au-color-black !important;
  font-weight: $font-weight-normal !important;
  border-radius: 4px !important;
  resize: none !important;
  line-height: 1.84;
  display: block;
  width: 100%;
  background-color: $au-color-white;
  background-clip: padding-box;
}

.h-75px {
  height: 75px !important;
}

.pos-abs-r_-4 {
  position: absolute;
  right: -4px;
}

.mt_-5 {
  margin-top: -5px;
}

.w-86 {
  width: 86% !important;
}

.mb-5px {
  margin-bottom: 5px !important;
}

.comment-item {
  padding-top: 6px;
  padding-bottom: 6px;
}

.comment-separator {
  border-bottom: 1px solid $au-color-white-light-7;
  padding-left: 10px;
  padding-right: 10px;
}

.animateDiv {
  animation-name: fadeInOut;
  animation-duration: 0.5s;
}

@keyframes fadeInOut {
  from {
    opacity: 0;
  }

  to {
    opacity: 1;
  }
}

.fs-14 {
  font-size: 1.4rem;
}

.breakText {
  white-space: pre-wrap;
}

.tab-title {
  font-size: 1.6rem !important;
}

.p-0-4 {
  padding: 0 4px;
}

.p-0-14 {
  padding: 0 14px;
}

.mr-2px {
  margin-right: 2px;
}

.line-height-ellipsis {
  display: -webkit-box;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
}

.color-grey {
  color: $au-color-secondary-medium;
}

.mini-sidebar-comments-floating-icon-div {
  left: 45px;
}

.line-width-ellipsis {
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
}

.title-spacing {
  line-height: 20px;
  margin-top: 18px !important;
}

.search-box-plans {
  width: calc(100% - 98px);
}

.fixed-width {
  width: 50px;
}

.table-text {
  padding-top: 26px !important;
  overflow: scroll !important;
}

.networkSidebarWidth {
  width: 450px !important;
}

.ml-20px {
  margin-left: 20px;
}

.ml-20px-en {
  margin-left: 20px;
}

.word-wrap-wrap-word-break-breakall {
  word-wrap: wrap;
  word-break: break-word;
}

.networkSidebarFooter {
  width: 100%;
  background-color: $au-color-white;
}

.printIcon {
  font-size: 2.4rem;
  cursor: pointer;
}

.pl-10px {
  padding-left: 10px;
}

.pr-10px {
  padding-right: 10px;
}

.mt-20px {
  margin-top: 20px;
}

.mt-40px {
  margin-top: 40px;
}

.mb-20px {
  margin-bottom: 20px;
}

.networkGraphPdfHiddenDiv {
  display: none;
}

.networkGraphTitle {
  text-align: center;
  font-size: 2.4rem;
  font-weight: $font-weight-medium;
  color: $au-color-primary;
}

.name {
  color: $au-color-q7;
  font-weight: $font-weight-normal;
}

.scroll-modal {
  overflow-y: scroll;
}

.position-relative {
  position: relative;
}

.overdueBadge {
  background: $au-color-red-2;
}

.pt-0-65r {
  padding-top: 0.65rem;
}

.cursor-text {
  cursor: text;
}

.margin-left-0-5rem {
  margin-left: 0.5rem;
}

.auditProgramRightSidebarWidth {
  width: 460px !important;
}

.auditProgramSidebarCloseIcon {
  position: relative;
  left: 21px;
}

.white-space-normal {
  white-space: normal !important;
}
