/* stylelint-disable selector-class-pattern */
// Textarea
.au-textarea {
  @include form-field;

  margin: 0;
  white-space: break-spaces;
  word-break: break-word;
}

textarea {
  min-height: 48px;
  max-height: 300px;

  &.au-textarea {
    background: $au-color-gray-light-3;
    border: 1px solid $au-color-grey11;

    &.au-textarea-auto {
      height: 100% !important;
      display: inline-table;
      max-height: auto;
      max-height: none;
    }

    &.au-textarea-auto-right {
      height: 100% !important;
      display: block;
      max-height: auto;
      resize: none;
      min-height: 280px;
    }
  }

  width: 100%;
  display: block;

  // @include transition (all,0.4s);
  &.w-auto {
    width: auto;
  }

  @include focus;
}

.au-textarea--print {
  display: none;
}

// Auto height
.cdk-textarea-autosize {
  &.au-textarea {
    max-height: 100%;
    overflow: hidden;
    box-sizing: content-box;
    width: calc(100% - 26px);
  }
}

@media print {
  textarea {
    display: none;
  }

  .au-textarea {
    &--print {
      background: $au-color-gray-light-3;
      border: 1px solid $au-color-gray-light-3;
      display: block;
      min-height: 66px;
    }
  }
}
