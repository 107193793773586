/* stylelint-disable selector-class-pattern */
// Card
@mixin card {
  width: 100%;
  height: 100%;
  border: 0;

  @include shadow-2;

  padding: $grid-gutter-space;
  background: $au-color-white;

  &__body {
    padding: 0;
  }

  &__header {
    border: 0;
    background: transparent;
    padding: 0;
    margin-bottom: 20px;
  }

  &__footer {
    margin-top: 5px;
  }

  &__pagination {
    width: 100%;

    .pagination {
      display: flex;
      gap: 5px;

      li {
        margin-bottom: 0;
      }
    }

    p {
      font-size: $au-fs-2;
    }
  }

  &__item {
    padding: 40px 0;
    border-top: 1px solid $au-color-secondary-light;

    &:first-child {
      padding-top: 0;
      border-top: none;
    }

    &:last-child {
      padding-bottom: 0;
    }

    p {
      white-space: normal;
    }
  }
}

// Flex
@mixin flex($display, $x, $y) {
  display: $display;
  justify-content: $x;
  align-items: $y;
}

// Border
@mixin border($size, $style, $au-color) {
  border-width: $size;
  border-style: $style;
  border-color: $au-color;
}

// Border Dashed Blue
@mixin border-dashed-blue {
  border: 1px dashed $au-color-primary;
}

// Border Dashed Grey
@mixin border-dashed-grey {
  border: 1px dashed $au-color-secondary-light;
}

// Shadow
@mixin shadow {
  box-shadow: 0 0 8px $color-secondary-light;
}

// Shadow for new style
@mixin shadow-2 {
  /* stylelint-disable-next-line color-function-notation, alpha-value-notation, number-leading-zero */
  box-shadow: 0 0 8px rgba(225, 225, 225, .46);
}

// Search
@mixin grid-search {
  border-radius: 5px;
  border: 1px solid $color-secondary-light;
  position: relative;

  input {
    &[placeholder="Search"],
    &[placeholder="search"] {
      padding-right: 50px;
      background: url("/assets/images/svg/search.svg") no-repeat;
      background-size: 15px;
      background-position: 98% 12px;
    }
  }

  .form-control {
    height: 34px;
    border-radius: 0;
    border: none;
  }

  .search-icon {
    position: absolute;
    width: 15px;
    height: 15px;
    right: 10px;
    top: 11px;
    display: none;
  }
}

// transition effect
@mixin transition($value) {
  transition: all $value;
}

// Rotate
@mixin rotate($value) {
  transform: rotate($value);
}

// Arrow
@mixin arrow($width, $height) {
  width: $width;
  height: $height;
  border: 1px solid $au-color-currentcolor;
  border-left: 0;
  border-bottom: 0;
}

// Label
@mixin label {
  font-size: $au-fs-2;
  color: $au-color-secondary-medium;
  text-transform: uppercase;
}

// Form field
@mixin form-field {
  border: 1px solid $au-color-secondary-light;
  padding: 8px 12px;
  display: block;
  border-radius: 5px;
  font-size: $au-fs-3;
  color: $au-color-secondary-dark;
}

// Form field focus
@mixin focus {
  &:focus {
    border: 1px solid $au-color-secondary-light;
  }
}

// Display none
@mixin d-none {
  display: none;
  animation: d-none 250ms ease-in-out both;
}

// Animate display none
@mixin ani-d-none {
  animation: ani-d-none 250ms ease-in both !important;
  display: none !important;
}

// Animate display block
@mixin ani-d-block {
  animation: ani-d-block 250ms ease-in-out both !important;
}

// Animate display flex
@mixin ani-d-flex {
  animation: ani-d-flex 250ms ease-in-out both;
}

// Hide scrollbar
@mixin hide-scroll {
  scrollbar-width: none;

  &::-webkit-scrollbar {
    width: 0;
    height: 0;
    background: transparent;
  }
}

// Mozilla
@mixin moz {
  @-moz-document url-prefix("") {
    @content;
  }
}
