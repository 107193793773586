// Forms Input
.au-form {
  margin-bottom: 20px;

  &__header {
    display: flex;
    justify-content: space-between;
    gap: 10px;
    width: 100%;
    margin-bottom: $spacer-2;

    .au-reload,
    .au-plus {
      font-size: 1.4rem;
      line-height: 100%;
      cursor: pointer;

      @include tablet {
        display: block;
      }
    }
  }

  &__body {
    font-size: 1.4rem;

    .au-input {
      font-size: 1.2rem;
    }
  }

  &__error,
  &__warning {
    font-size: $au-fs-2;
    display: block;
    margin-top: 4px;
    margin-bottom: 0;
  }

  &__error {
    color: $au-color-red;
  }

  &__warning {
    color: $au-color-orange;
  }

  &__controller,
  &__controls {
    display: flex;
    opacity: 0;
    align-items: center;
    gap: 5px;

    @include transition(0.4s);
  }

  &:hover {
    .au-form__controller,
    .au-form__controls {
      opacity: 1;
    }
  }

  .au-label {
    position: relative;
    max-width: 100%;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;

    // .au-icon--info {
    //   position: absolute;
    //   top: 50%;
    //   right: 0;
    //   transform: translateY(-50%);
    // }

    span {
      margin-left: $spacer-1;
    }
  }

  .au-flex {
    overflow: hidden;
  }

  &--right {
    justify-content: end;

    .au-form__header {
      justify-content: end;
    }
  }

  &--control {
    .au-label {
      display: block;
      text-overflow: unset;
      width: 100%;

      .au-label__inner {
        margin-left: $spacer-0;
      }

      .au-icon--info {
        position: static;
        margin-left: $spacer-1;
      }
    }
  }
}
