// Responsive

/*
Template Name:
Author:

File: scss
*/


/*==============================================================
 For Desktop & above all (1650px)
 ============================================================== */


/*==============================================================
 For Laptop & above all (1370px)
 ============================================================== */


/*-- ==============================================================
 Small Desktop & above all (1024px)
 ============================================================== */

@media (min-width: 800px) {
    .page-wrapper {
        margin-left: $sidebar-width-full;
    }
    // .graph-chart {
    //   max-height: none;
    // }
}

@media (min-width: 1401px) {
    .graph-chart {
        min-height: 98%;
    }
}

@media (min-width: 1400px) {
    .additional-options {
        margin-top: 0.75rem !important;
    }
}

@media (max-width: 1400px) {
    .graph-chart {
        // max-height: 350px;
        min-height: 350px;
    }
    .risk-universe-card-height {
        max-height: 420px;
        // overflow: scroll;
    }
    .title-card {
        font-size: 1.125rem;
    }
}

@media (min-width: 1301px) {
    .apex-legend {
        width: 41%;
    }
}

@media (min-width: 767px) {
  .ml-350px {
    margin-left: 350px !important;
  }
  .left-sidebar-comments {
    width: 350px !important;
  }
  .comments-floating-icon-div-open {
    width:60px;
    height:60px;
  }
  .comments-floating-icon-div-close {
    width:60px;
    height:60px;
  }
  .comments-floating-icon {
    font-size: 22px;
    margin-top: 20px;
  }

.comment-count-indicator {
  width: 15px;
  height: 16px;
  font-size: 10px;
  line-height: 17px;
}
.commentSidebarNavTabPadding {
  padding-left: 10px;
}

}

@media (max-width: 1300px) {
    .apex-legend {
        width: 48%;
    }
}

@media (max-width: 1199px) {
    .apex-legend {
        width: 50%;
    }
    .title-card {
        font-size: 15px !important;
    }
    .item {
        padding: 0 6px 0 2px;
    }
}

@media (max-width:992px) {
    .m-t-mobile {
        margin-top: 0.5rem !important;
    }
    .notification-card-unread {
        padding: 1rem;
    }
    .notification-card-read {
        padding: 1rem;
    }
    .cardPadding {
        padding: 1rem;
    }
    .commentstextarea {
        width: 38vw;
    }
    .editCheckbox {
        width: 47vw;
    }
}

@media (max-width: 991px) {
    span.apex-legend-marker {
        width: 4px;
        margin: 0px 6px 0px 0px;
    }
    .card-footer.graph-card-footer {
        padding: 0;
    }
    .title-card {
        font-size: 15px !important;
    }
    .scroll-chart-risk-control,
    .small-scroll,
    .small-scroll-chart {
        max-height: initial !important;
        overflow: visible !important;
        overflow-y: visible !important;
    }
    .m-t-mobile {
        margin-top: 10px;
    }
    .mobile-click {
        margin-right: 23px !important;
        padding-right: 0rem !important;
    }
}

@media (max-width: 767px) {
    .graph-chart {
        max-height: initial;
    }
    .apexcharts-canvas {
        margin: 0 auto;
    }
    .apex-legend {
        width: auto;
    }
    .logo-icon {
        display: none;
    }
    .tui-full-calendar-dayname-date {
        font-size: 14px;
    }
    .tui-full-calendar-dayname-name {
        font-weight: normal !important;
        font-size: 10px;
        max-width: 0.6rem;
    }
    .display-desktop {
        display: block !important;
    }
    .label-left-host {
        text-align: left;
        margin-top: 12px !important;
        color: black;
        font-weight: 500;
    }
    .label-left {
        text-align: left;
        margin-top: 10px !important;
        color: black;
        font-weight: 500 !important;
    }
    .mobile-none-display {
        display: none !important;
    }
    .mobile-block-display {
        display: block !important;
    }
    .mobile-delete {
        text-align: right !important;
    }
    .mobile-click {
        margin-right: 0px !important;
        padding-right: 0rem !important;
    }
    .word-wrap {
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        display: block;
    }
    .mobile-delete {
        text-align: right !important;
    }
    .mobile-none-display {
        display: none !important;
    }
    .mobile-block-display {
        display: block !important;
    }
    .mobile-click {
        margin-right: 0rem !important;
        padding-right: 0rem !important;
    }
    .left-sidebar-comments {
      left: 0 !important;
    }
    .comments-floating-icon-div-open {
      left: 22px !important;
    }
    .comments-floating-icon-div-close {
      left: 22px !important;
    }
    .left-sidebar-comments {
      width: 350px !important;
    }
}


/* Media Queries Start*/

@media screen and (max-width: 426px) {
    // Connected data source page styles customization starts
    .scenario-color {
        display: contents;
    }
    .scenario-text {
        color: black !important;
        margin-left: 1.25rem !important;
    }
    .connected-data-name {
        font-size: 15px !important;
    }
    .connected-data-table-header {
        display: none;
    }
    .connected-data-table-small-items {
        display: block;
        margin-left: 1rem !important;
    }
    .connected-table-cols {
        width: 50%;
        position: initial;
        left: 50%;
        display: inline;
    }
    .connected-table-rows {
        display: contents;
    }
    .connected-data-table-icons {
        display: none;
    }
    .connected-data-table-small-icons {
        display: block;
    }
    .connected-push-from-left-1 {
        margin-left: 0.5rem !important;
    }
    .connected-push-from-left-2 {
        margin-left: 1.05rem !important;
        // margin-left: 1.25rem !important;
    }
    // .connected-push-from-left-3 {
    //     margin-left: 1.5rem !important;
    // }
    .connected-push-from-left-4 {
        margin-left: 1.25rem !important;
    }
    .switch-analytic-tabs {
        // font-size: 16px !important;
        padding-left: 0.25rem !important;
        padding-top: 0.5rem !important;
        margin-top: 0.5rem !important;
    }
    .text-right-data {
        text-align: right;
        color: #2ec0f8;
        font-weight: 450;
        display: flex;
    }
    // Connected data source page styles customization starts
    // Scenario List page styles customized starts
    .scenario-name {
        font-size: 15px !important;
    }
    .scenario-list-header {
        display: none;
    }
    .scenario-list-table-icons {
        display: none;
    }
    .scenario-list-table-small-icons {
        display: block;
    }
    .scenario-list-table-small-items {
        display: block;
        margin-top: 1rem !important;
    }
    .scenario-list-table-cols {
        width: 50%;
        position: initial;
        left: 50%;
        display: inline;
    }
    .scenario-list-table-rows {
        // display: inline-flex;
        // width: 100%;
        display: contents;
    }
    .scenario-push-from-left-1 {
        margin-left: 0.5rem !important;
    }
    .scenario-push-from-left-2 {
        margin-left: 1.05rem !important;
    }
    // Scenario List page styles customized ends
    // Schedule List page specific styles start
    .schedule-name {
        font-size: 15px !important;
    }
    .schedule-list-header {
        display: none;
    }
    .schedule-list-table-icons {
        display: none;
    }
    .schedule-list-table-small-icons {
        display: block;
    }
    .schedule-list-table-small-items {
        display: block;
        width: 100%;
    }
    .schedule-list-table-cols {
        width: 50%;
        position: initial;
        left: 50%;
        display: inline;
    }
    .schedule-list-table-rows {
        display: contents;
    }
    .schedule-push-from-left-1 {
        margin-left: 0.5rem !important;
    }
    .schedule-push-from-left-2 {
        margin-left: 1.05rem !important;
    }
    .schedule-push-from-left-3 {
        margin-left: 1.6rem !important;
    }
    // Schedule List page specific styles end
    // Job List page specific styles start
    .job-name {
        font-size: 15px !important;
        margin-left: 1.35rem !important;
    }
    .job-list-header {
        display: none;
    }
    .job-list-table-icons {
        display: none;
    }
    .job-list-table-small-icons {
        display: block;
    }
    .job-list-table-small-items {
        display: block;
        margin-left: 1rem !important;
    }
    .job-list-table-cols {
        width: 50%;
        position: initial;
        left: 50%;
        display: inline;
    }
    .job-list-table-rows {
        display: contents;
    }
    .job-push-from-left-1 {
        margin-left: 0rem !important;
    }
    .job-push-from-left-2 {
        margin-left: 0.5rem !important;
    }
    .job-push-from-left-3 {
        margin-left: 1.25rem !important;
    }
    .job-push-from-left-4 {
        margin-left: 0.5rem !important;
    }
    .job-push-from-left-5 {
        margin-left: 1rem !important;
    }
    .job-push-from-left-6 {
        margin-left: 0.75rem !important;
    }
    .job-push-from-top-1 {
        // margin-top: 2.4rem !important;
    }
    // Job List page specific styles end
    // Scenario publish specific styles start
    .additional-options {
        margin-top: 0.5rem !important;
        padding-top: 0.25rem !important;
    }
    // Scenario publish specific styles end
    .left-sidebar-comments {
      width: 275px !important;
    }
    .comments-floating-icon-div-open {
      width:45px !important;
      height:45px !important;
    }
    .comments-floating-icon-div-close {
      width:45px !important;
      height:45px !important;
    }
    .comments-floating-icon {
      font-size: 16px !important;
      margin-top: 16px !important;
    }
    .comment-count-indicator {
      width: 11px;
      height: 11px;
      font-size: 6px;
      line-height: 13px;
    }
    .commentSidebarNavTabPadding {
      padding-left: 5px;
    }
}

@media screen and (min-width: 426px) and (max-width: 767px) {
    // Connected data source page styles customized starts
    .scenario-color {
        display: contents;
    }
    .scenario-text {
        color: black !important;
        margin-left: 0.75rem !important;
    }
    .connected-data-name {
        font-size: 15px !important;
    }
    .connected-data-table-header {
        display: none;
    }
    .connected-data-table-small-items {
        display: block;
        margin-left: 0.5rem !important;
    }
    .connected-table-cols {
        width: 50%;
        position: absolute;
        left: 50%;
        display: inline;
    }
    .connected-table-rows {
        display: inline-flex;
        width: 100%;
    }
    .connected-data-table-icons {
        display: none;
    }
    .connected-data-table-small-icons {
        display: block;
    }
    .connected-push-from-left-1 {
        margin-left: 0rem !important;
    }
    .connected-push-from-left-2 {
        margin-left: 0.6rem !important;
    }
    // .connected-push-from-left-3 {
    //     margin-left: 1.05rem !important;
    // }
    .connected-push-from-left-4 {
        margin-left: 1.25rem !important;
    }
    .connected_push_from_left_5 {
        margin-left: 0.5rem !important;
    }
    .text-right-data {
        text-align: right;
        color: #2ec0f8;
        font-weight: 450;
        display: flex;
    }
    .connectedTableRows_2 {
        display: contents;
    }
    // Connected data source page styles customized ends
    // Scenario List page styles customized starts
    .scenario-name {
        font-size: 15px !important;
    }
    .scenario-list-header {
        display: none;
    }
    .scenario-list-table-icons {
        display: none;
    }
    .scenario-list-table-small-icons {
        display: block;
    }
    .scenario-list-table-small-items {
        display: block;
    }
    .scenario-list-table-cols {
        width: 50%;
        position: absolute;
        left: 50%;
        display: inline;
    }
    .scenario-list-table-rows {
        display: inline-flex;
        width: 100%;
    }
    .scenario-push-from-left-1 {
        margin-left: 0rem !important;
    }
    .scenario-push-from-left-2 {
        margin-left: 0.6rem !important;
    }
    // Scenario List page styles customized ends
    // Schedule List page specific styles start
    .schedule-name {
        font-size: 15px !important;
    }
    .schedule-list-header {
        display: none;
    }
    .schedule-list-table-icons {
        display: none;
    }
    .schedule-list-table-small-icons {
        display: block;
    }
    .schedule-list-table-small-items {
        display: block;
        margin-left: 0.6rem !important;
    }
    .schedule-list-table-cols {
        width: 50%;
        position: absolute;
        left: 50%;
        display: inline;
    }
    .schedule-list-table-rows {
        display: inline-flex;
        width: 100%;
    }
    .schedule-push-from-left-1 {
        margin-left: 0rem !important;
    }
    .schedule-push-from-left-2 {
        margin-left: 0.6rem !important;
    }
    .schedule-push-from-left-3 {
        margin-left: 1.2rem !important;
    }
    // Schedule List page specific styles end
    // Job List page specific styles start
    .job-name {
        font-size: 15px !important;
        margin-left: 1.35rem !important;
    }
    .job-list-header {
        display: none;
    }
    .job-list-table-icons {
        display: none;
    }
    .job-list-table-small-icons {
        display: block;
    }
    .job-list-table-small-items {
        display: block;
    }
    .job-list-table-cols {
        width: 50%;
        position: absolute;
        left: 50%;
        display: inline;
    }
    .job-list-table-rows {
        display: inline-flex;
        width: 100%;
    }
    .job-push-from-left-1 {
        margin-left: -0.5rem !important;
        width: 50%;
    }
    .job-push-from-left-2 {
        margin-left: 0rem !important;
    }
    .job-push-from-left-3 {
        margin-left: 0.7rem !important;
    }
    .job-push-from-left-4 {
        margin-left: 0.5rem !important;
    }
    .job-push-from-left-5 {
        margin-left: 1rem !important;
    }
    .job-push-from-top-1 {
        margin-top: 2.4rem !important;
    }
    // Job List page specific styles end
    // Scenario publish specific styles start
    .additional-options {
        margin-top: 0.5rem !important;
        padding-top: 0.25rem !important;
    }
    // Scenario publish specific styles end
    .comments-floating-icon-div-open {
      width:60px;
      height:60px;
    }
    .comments-floating-icon-div-close {
      width:60px;
      height:60px;
    }
    .comments-floating-icon {
      font-size: 22px;
      margin-top: 20px;
    }
    .comment-count-indicator {
      width: 15px;
      height: 16px;
      font-size: 10px;
      line-height: 17px;
    }
    .commentSidebarNavTabPadding {
      padding-left: 10px;
    }
}


/* Media Queries End*/

@media (max-width: 625px) {
    .auth-wrapper .auth-box {
        width: 100%;
        max-width: 100%;
    }
}

@media (max-width: 575px) {
    .xs-w-100 {
        max-width: 100% !important;
        flex: 0 0 100% !important;
    }
}

@media (max-width: 480px) {
    .graph-wrapper {
        flex-direction: column;
    }
}

@media (max-width: 541px) {
    .search-flex-display {
        display: block !important;
    }
}

@media (max-width: 992px) {
  .popup-mobile{
    background-color: $hover-background;
    position: fixed !important;
    transform: translate(0px, 0px) !important;
    right: 0px !important;
    margin: 0 auto !important;
    top: 30px !important;
    width: 80%;
    .card-wrapper1{
      min-width: 100% !important;
    }
    .card-wrapper2{
      min-width: 100% !important;
    }
    .card-wrapper3{
      min-width: 100% !important;
    }
    .card-wrapper4{
      min-width: 100% !important;
    }
  }
}
