/* stylelint-disable selector-class-pattern */
// Search
// Forms Input
.au-search {
  @include form-field;

  padding: 8px 12px !important;
  width: 100%;
  display: block;

  @include transition (0.4s);

  &.w-auto {
    width: auto;
  }

  // Input focus effect
  @include focus;
}

// With Bg
input {
  &.au-search {
    background-color: $au-color-gray-light-3;
    background-image: url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 30 30"><g id="search"><path d="M11.06,20.62a9.56,9.56,0,1,1,9.56-9.56A9.57,9.57,0,0,1,11.06,20.62Zm0-18a8.44,8.44,0,1,0,8.44,8.44A8.45,8.45,0,0,0,11.06,2.62Z"/><path d="M27.94,28.5a.55.55,0,0,1-.4-.16L17,17.83a.57.57,0,0,1,.8-.8L28.34,27.54a.59.59,0,0,1,0,.8A.57.57,0,0,1,27.94,28.5Z"/></g></svg>');
    background-position: calc(100% - 10px) 50%;
    background-size: 16px;
    background-repeat: no-repeat;
    border: 1px solid $au-color-grey11;
    padding-right: 37px !important;
  }
}

// Arabic
[dir="rtl"] {
  input.au-search {
    background-position: calc(100% - 98%) 50%;
  }
}
