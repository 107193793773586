.au-calendar {
  position: relative;

  input {
    background: $au-color-gray-light-3;
    border: 1px solid $au-color-gray-light-3;
    padding-right: 32px;
    line-height: 1.6;

    @include focus;

    @include moz {
      padding-right: 34px;
    }
  }

  &::after {
    content: "\a0005";
    /* stylelint-disable-next-line font-family-name-quotes, string-quotes */
    font-family: 'au-icon-font', sans-serif;
    font-size: 1.8rem;
    color: $au-color-black;
    position: absolute;
    top: 9px;
    right: 11px;
    pointer-events: none;
  }
}

// Arabic
[dir="rtl"] {
  .au-calendar {
    input {
      padding-right: 37px;
    }

    &::after {
      right: auto;
      left: 11px;
    }
  }
}
