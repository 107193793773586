

// Legend
.au-legend {
  display: block;
  &__item {
      display: inline-flex;
      padding: 2px;
      column-gap: 5px;
      margin-right: 5px;
  }
  &__marker {
      background: #fefefe;
      height: 6px;
      width: 20px;
      min-width: 20px;
      margin-top: 4px;
      display: inline-block;
      border: 0 none;
  }
  &__text {
      color: $au-color-secondary;
      font-size: 1.2rem;
      margin: 0;
  }
}
