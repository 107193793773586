/* stylelint-disable selector-class-pattern */
// Forms Input
.au-input {
  @include form-field;

  width: 100%;
  height: 39px;
  display: inline-flex;
  flex-wrap: wrap;
  align-items: center;

  @include transition (0.4s);

  margin: 0;
  word-break: break-word;

  &.w-auto {
    width: auto;
  }

  &[type="radio"],
  &[type="checkbox"] {
    width: auto;
  }

  // Input focus effect
  @include focus;

  &.au-disabled {
    pointer-events: none;
  }
}

// With Bg
input {
  &.au-input {
    display: block;
    background: $au-color-gray-light-3;
    border: 1px solid $au-color-grey11;
  }
}
