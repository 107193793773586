/* stylelint-disable selector-class-pattern */
@import "./src/assets/au-scss/abstracts/variables/";

// Reset style for ngx-toastr
.ngx-toastr {
  background-image: url("data:image/svg+xml,%3Csvg width='30' height='30' viewBox='0 0 30 30' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M28.9987 14.8125C28.8924 7.08193 22.543 0.901205 14.8124 1.0012C7.08189 1.10119 0.901205 7.4569 1.0012 15.1875C1.10744 22.9181 7.45686 29.0988 15.1874 28.9988C22.918 28.8926 29.1049 22.5431 28.9987 14.8125ZM14.9999 5.99452C16.1061 5.99452 16.9997 6.88819 16.9997 7.99435C16.9997 9.1005 16.1061 9.99418 14.9999 9.99418C13.8938 9.99418 13.0001 9.1005 13.0001 7.99435C13.0001 6.88819 13.8938 5.99452 14.9999 5.99452ZM17.7497 23.6868H12.2502V22.9993H13.625V13.0002H12.2502V12.2502H16.3748V22.9993H17.7497V23.6868Z' fill='%232c65ae'/%3E%3C/svg%3E%0A");

  &::before {
    background-color: $au-color-blue-4;
  }

  &.toast-success {
    background-image: url("data:image/svg+xml,%3Csvg width='30' height='30' viewBox='0 0 30 30' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M15 1C12.2311 1 9.52431 1.82109 7.22202 3.35942C4.91973 4.89776 3.12532 7.08427 2.06569 9.64243C1.00607 12.2006 0.728819 15.0155 1.26901 17.7313C1.80921 20.447 3.14258 22.9416 5.10051 24.8995C7.05845 26.8574 9.55301 28.1908 12.2687 28.731C14.9845 29.2712 17.7994 28.9939 20.3576 27.9343C22.9157 26.8747 25.1022 25.0803 26.6406 22.778C28.1789 20.4757 29 17.7689 29 15C29 11.287 27.525 7.72601 24.8995 5.1005C22.274 2.475 18.713 1 15 1ZM22.2635 12.0816L13.3544 20.9907C13.1157 21.2293 12.792 21.3634 12.4545 21.3634C12.1171 21.3634 11.7934 21.2293 11.5547 20.9907L7.73655 17.1725C7.50471 16.9325 7.37643 16.611 7.37933 16.2773C7.38223 15.9436 7.51608 15.6244 7.75205 15.3884C7.98803 15.1524 8.30724 15.0186 8.64095 15.0157C8.97465 15.0128 9.29615 15.1411 9.53619 15.3729L12.4545 18.2913L20.4638 10.282C20.7039 10.0502 21.0254 9.92188 21.3591 9.92478C21.6928 9.92768 22.012 10.0615 22.248 10.2975C22.4839 10.5335 22.6178 10.8527 22.6207 11.1864C22.6236 11.5201 22.4953 11.8416 22.2635 12.0816Z' fill='%236b6'/%3E%3C/svg%3E");

    &::before {
      background-color: $au-color-green;
    }
  }

  &.toast-error {
    background-image: url("data:image/svg+xml,%3Csvg width='30' height='30' viewBox='0 0 30 30' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M15 29C22.732 29 29 22.732 29 15C29 7.26801 22.732 1 15 1C7.26801 1 1 7.26801 1 15C1 22.732 7.26801 29 15 29Z' fill='%23e53535'/%3E%3Cpath d='M20.194 8.81674L21.1618 9.78466C21.4959 10.1187 21.4959 10.6603 21.1618 10.9944L11.0014 21.1547C10.6673 21.4888 10.1257 21.4888 9.79163 21.1547L8.82377 20.1868C8.48973 19.8528 8.48973 19.3112 8.82377 18.9771L18.9842 8.81663C19.3182 8.48265 19.8599 8.48265 20.194 8.81674Z' fill='white'/%3E%3Cpath d='M21.1618 20.1869L20.1939 21.1548C19.8599 21.4888 19.3182 21.4888 18.9842 21.1548L8.82377 10.9944C8.48973 10.6603 8.48973 10.1187 8.82377 9.7846L9.79168 8.81669C10.1257 8.48265 10.6673 8.48265 11.0014 8.81669L21.1618 18.9771C21.4959 19.3112 21.4959 19.8528 21.1618 20.1869Z' fill='white'/%3E%3C/svg%3E%0A");

    &::before {
      background-color: $au-color-red;
    }
  }

  &.toast-warning {
    background-image: url("data:image/svg+xml,%3Csvg width='30' height='30' viewBox='0 0 30 30' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath fill-rule='evenodd' clip-rule='evenodd' d='M15 1C7.3 1 1 7.3 1 15C1 22.7 7.3 29 15 29C22.7 29 29 22.7 29 15C29 7.3 22.7 1 15 1ZM16.4 22H13.6V19.2H16.4V22ZM16.4 16.4H13.6V8H16.4V16.4Z' fill='%23ffa600'/%3E%3C/svg%3E%0A");

    &::before {
      background-color: $au-color-yellow;
    }
  }
}

.toast-container .ngx-toastr {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  background-color: $au-color-white;
  background-size: 20px;
  background-position: 13px center;
  color: $au-color-black;
  padding: $spacer-3 30px $spacer-3 44px;
  width: 320px;
  min-height: 60px;
  /* stylelint-disable-next-line color-function-notation, alpha-value-notation */
  box-shadow: 0 0 20px rgba(0, 0, 0, 0.25);
  animation: slide-down 0.3s linear forwards;
  word-break: break-word;

  &::before {
    content: "";
    width: 3px;
    height: calc(100% - 25px);
    border-radius: 0 10px 10px 0;
    position: absolute;
    top: 50%;
    left: 0;
    transform: translateY(-50%);
  }

  &:hover {
    /* stylelint-disable-next-line color-function-notation, alpha-value-notation */
    box-shadow: 0 0 20px rgba(0, 0, 0, 0.35);
  }
}

.toast-title {
  width: 100%;
  font-size: $au-fs-3;
  font-weight: $font-weight-semi;
  margin-bottom: $spacer-1;
}

.toast-close-button {
  color: $au-color-secondary;
  position: absolute;
  font-weight: 400;
  top: 50%;
  right: 10px;
  transform: translateY(-50%);
  opacity: 0.6;
  transition: color 0.3s;

  &:hover {
    opacity: 1;
  }
}

@keyframes slide-down {
  from {
    transform: translateY(-10px);
  }

  to {
    transform: translateY(0);
  }
}

@media print {
  .toast-container {
    display: none;
  }
}
