// left menu

#main-wrapper {
  &.mini-sidebar {
    .left-sidebar {
      width: 60px;
    }
    .au-menu {
      &__text {
        opacity: 0;
        @include transition(0.3s);
      }
      &__sub {
        &-title {
          @include d-none;
        }
        .au-menu__sub-text {
          opacity: 0;
        }
      }
    }
  }
  .left-sidebar {
    width: 265px;
    @include transition(0.3s);
    &:hover {
      width: 265px;
      .au-menu {
        &__text {
          opacity: 1;
        }
        &__item {
          &.active {
            .au-menu__sub {
              .au-menu__sub-text {
                opacity: 1;
              }
            }
          }
          .au-menu__sub-title {
            display: block;
          }
        }
      }
    }
  }
}


