/* stylelint-disable */

/****************************** */

// Custom Variables

/****************************** */

/* Theme Colors */

$themecolor: #003b6d;
$themecolor-dark: #028ee1;
$themecolor-alt: #26c6da;
$theme-light: #fff;
$theme-dark: #212529;
$title-color: #0b3a62;
$skin1: #3587d8;
$skin2: #d83939;
$skin3: #17a991;
$skin4: #6659f7;
$skin5: #f9f9f9;
$skin6: #fff;

/* Topbar Colors */

$topbar: $theme-light;
$topbar-height: 49px;
$topbar-navlink-padding: 0 15px;
$topbar-navlink-font-size: 0.875rem;
$topbar-navlink-height: 49px;
$topbar-navbrand-padding: 0 15px 0 15px;

/* Sidebar Colors */

$sidebar: $theme-light;
$sidebar-alt: #e8eff0;
$sidebar-text: $title-color;
$sidebar-icons: #0f0f0f;
$sidebar-text-dark: #212529;
$sidebar-icons-dark: #4f5467;
$sidebar-width-full: 265px;
$sidebar-width-iconbar: 180px;
$sidebar-width-mini: 60px;
$audit-sidebar-width-mini: 10px;
$audit-sidebar-width-full: 325px;

/* Boxed layout width */

$boxed-width: 1200px;

/* Shadow */

$shadow: 1px 0 20px rgba(0 0 0 / 0.08);

/* transitions */

$transitions: 0.2s ease-in;

/* Dark transparent bg */

$transparent-dark-bg: rgba(0 0 0 / 0.05);
$lft: left;
$rgt: right;
$card-alt: #e4e9ef;
%square {
  border-radius: 0;
}

%rotate45 {
  transform: rotate(45deg);
  -ms-transform: rotate(45deg);
  -webkit-transform: rotate(45deg);
  -o-transform: rotate(45deg);
  -moz-transform: rotate(45deg);
}

/* ***************************** */

// Bootstrap overrides

/* ***************************** */

/* *
 * Table Of Content
 *
 *  1. Color system
 *  2. Options
 *  3. Body
 *  4. Typography
 *  5. Breadcrumbs
 *  6. Cards
 *  7. Dropdowns
 *  8. Buttons
 *  9. Typography
 *  10. Progress bars
 *  11. Tables
 *  12. Forms
 *  14. Component */

// Color system
$white: #fff !default;
$gray-100: #f8f9fa !default;
$gray-200: #e9ecef !default;
$gray-300: #dee2e6 !default;
$gray-400: #ced4da !default;
$gray-500: #a1aab2 !default;
$gray-600: #6c757d !default;
$gray-700: #4f5467 !default;
$gray-800: #343a40 !default;
$gray-900: #212529 !default;
$black: #000 !default;
$blue: #003b6d !default;
$indigo: #6610f2 !default;
$purple: #7460ee !default;
$pink: #e83e8c !default;
$red: #ef6e6e !default;
$light-info: #e6f2fa !default;
$light-danger: #fae6e6 !default;
$light-success: #e9f9f7 !default;
$light-warning: #f8efd7 !default;
$orange: #fb8c00 !default;
$yellow: #ffbc34 !default;
$green: #22c6ab !default;
$teal: #20c997 !default;
$cyan: #01c0c8 !default;
$active-card-bg: #115caa;
$primary: $active-card-bg !default;
$text-muted: $gray-500 !default;
$colors: (
  blue: $blue,
  indigo: $indigo,
  purple: $purple,
  pink: $pink,
  red: $red,
  orange: $orange,
  yellow: $yellow,
  green: $green,
  teal: $teal,
  cyan: $cyan,
  white: $white,
  gray: $gray-600,
  gray-dark: $gray-800,
  light-info: $light-info,
  light-danger: $light-danger,
  light-success: $light-success,
  light-warning: $light-warning,
);
$white-txt: #fff;
$primary: $blue !default;
$secondary: $gray-600 !default;
$success: $green !default;
$info: $blue !default;
$warning: $yellow !default;
$danger: $red !default;
$light: $gray-100 !default;
$dark: $gray-800 !default;
$cyan: $cyan !default;
$orange: $orange !default;

// Quickly modify global styling by enabling or disabling optional features.
$enable-caret: true !default;
$enable-rounded: true !default;
$enable-shadows: false !default;
$enable-gradients: false !default;
$enable-transitions: true !default;
$enable-hover-media-query: false !default;
$enable-grid-classes: true !default;
$enable-print-styles: true !default;

// Body
// Settings for the  element.
$main-body-bg: #fff !default;
$body-bg: #f9f9f9;
$body-color: #555 !default;
$grid-gutter-width: 20px !default;
$light-text-gray: #999;

// Typography
// Font, line-height, and color for body text, headings, and more.
$font-size-base: 0.875rem;
$font-family-sans-serif: "Poppins" !default;
$font-weight-light: 300 !default;
$font-weight-normal: 400 !default;
$font-weight-medium: 500 !default;
$font-weight-bold: 600 !default;
$h1-font-size: 36px !default;
$h2-font-size: 30px !default;
$h3-font-size: 22px !default;
$h4-font-size: 18px !default;
$h5-font-size: 16px !default;
$h6-font-size: 14px !default;
$small-font-size: 12px !default;
$card-title: 1.2rem;
$headings-margin-bottom: (1rem * 0.5) !default;
$headings-font-weight: 500 !default;
$headings-color: inherit !default;

// Breadcrumbs
$breadcrumb-bg: $body-bg;
$breadcrumb-margin-bottom: 1.5rem;

// Cards
$card-border-width: 1px !default;
$card-border-color: $gray-200 !default;
$card-border-radius: 0 !default;

// Dropdowns
$dropdown-item-padding-x: 1rem !default;
$dropdown-item-padding-y: 0.65rem !default;
$dropdown-border-color: $gray-200;
$dropdown-divider-bg: $gray-100;

// Buttons
$btn-secondary-border: $gray-300;

// Progress bars
$progress-bg: $gray-100;

// Tables
$table-bg-accent: $gray-100;
$table-bg-hover: $gray-100;
$table-hover-bg: $gray-100 !default;
$table-cell-padding: 10px 12px 9px !default;
$table-bg-level: -10 !default;
$table-bg-level2: 1 !default;
$table-bg-level3: -5 !default;

// Components
$component-active-color: $white !default;
$component-active-bg: $themecolor !default;
$badge-pill-padding-x: 0.2em !default;
$badge-pill-padding-y: 1em !default;

// Forms
$input-group-addon-bg: $gray-100;
$input-border-color: $gray-200;
$input-group-addon-border-color: $gray-200;
$input-btn-focus-color: rgba(0 0 0 / 0.25) !default;
$input-focus-border-color: rgba(0 0 0 / 0.25) !default;
$custom-control-indicator-active-bg: rgba(0 0 0 / 35%) !default;
$custom-select-indicator: url("../../assets/images/custom-select.png") !default;
$input-btn-focus-box-shadow: transparent !default;
$custom-select-focus-box-shadow: transparent !default;
$custom-select-indicator: str-replace(url(""), "%23") !default;

// Define common padding and border radius sizes and more.
$border-width: 1px !default;
$border-color: $gray-200 !default;
$border-radius: 2px !default;
$border-radius-lg: 2px !default;
$border-radius-sm: 1px !default;

// Progress bars
$progress-height: 5px !default;

// Tabs
$nav-tabs-link-active-bg: $white !default;

// Grid breakpoints
// Define the minimum dimensions at which your layout will change,
// adapting to different screen sizes, for use in media queries.
$grid-breakpoints: (
  xs: 0,
  sm: 576px,
  md: 768px,
  lg: 992px,
  xl: 1600px,
) !default;

// Navbar
$navbar-dark-color: rgba(255, 255, 255, 0.8) !default;
$navbar-dark-hover-color: rgba(255, 255, 255, 1) !default;

/**
*! Border
*/

$btnBorderRadius: 25px;

/***
*?Risk status
*/

$possible: #76b77e;
$likely: #76b77e;
$possible: #de7036;
$certain: #cc290e;
$medium: #f59607;
$low: #76b77e;
$critical: #f72904;

/**
*!Blue combination
*/

$bgBlue: #2962ff;
$text-bg: #315374;
$btn-blue-bg: $title-color;

/**
*! info card border
*/

$card0: #8191bd;
$card1: #a0b7f8;
$card2: #de7036;
$card3: #d8315b;
$card4: #f8ac61;
$card5: #76b77e;
$card-info-height: 62px;
$font-color: #555;
$inactive-font-color: #777;
$title-font-size: 1.125rem;
$secondary-font: #2c2c2c;
$normal-font: 13px;
$aura-text: #115caa;
$input-min-height: 40px;
$hover-background: #eee;
$table-header-bg: #e0e5e8;

/* Dashboard layout width variables start */

$dashboard-sidebar-width-full: 65px;
$dashboard-sidebar-width-iconbar: 180px;
$dashboard-sidebar-width-mini: 60px;

/* Dashboard layout width variables end */

// dashboard-builder start
$dashboardMenuBar-width: 275px;

// dashboard-builder end
// analytics
$black-color: #000;
$blue-dark: #07477f;
$file-close: #4aafff;
$chart-btn: #5a5a5a;
$data-color-label: #024683;
$text-blue-three: #3698d9;
$text-blue-four: rgba(75, 186, 216, 255);
$text-blue-five: #a3c8e4;
$text-grey: #747474;
$text-grey-two: #b9b6b6;
$text-white: #dbe7f2;
$relation-filter-head: #073e83;
$live-color: #66bb66;
$light-gray: #aeaeae;
$chart-color: #e1e1e1;
$text-right: #2ec0f8;
$text-warning: rgb(238, 204, 14);
$file-dark-blue: #1c8adb;
$box-shadow-black: rgba(0, 0, 0, 0.2);
$table-border: #ccc;
$table-bottom-border: #d6d6d6;
