// Sidebar
$sidebar-link-padding: 5px 0px 5px 0;
$sidebar-icon-size: 20px;
$sidebar-first-level-padding: 0 0 10px 0;
$sidebar-first-level-link-padding: 4px 0px;
$sidebar-first-level-icon-size: 14px;

/*******************
Main sidebar
******************/

.left-sidebar {
  position: fixed;
  width: $sidebar-width-full;
  // width: 235px;
  height: 100%;
  top: 0px;
  // border-right: 1px solid rgba(0, 0, 0, 0.1);
  z-index: 10;
  padding-top: $topbar-height;
  background: $sidebar;
  //box-shadow: $shadow;
  // transition: 0.2s ease-in;
}
.full .left-sidebar{
  -webkit-transform: translate3d(0px, 0px, 0px);
  transform: translate3d(0px, 0px, 0px);
  visibility: visible;
}

/*******************
sidebar navigation
******************/
.scroll-sidebar {
  height: calc(100% - 20px);
  position: relative;
}
.sidebar-nav {
  padding-top: 10px;
}

.sidebar-nav {
  ul {
    .sidebar-item {
      .sidebar-link {
        color: $sidebar-text;
        padding: $sidebar-link-padding;
        padding-left: 0px !important;
        display: flex;
        white-space: nowrap;
        align-items: center;
        line-height: 25px;
        opacity: 1;
        font-size: 14px;
        i {
          font-style: normal;
          // width: 50px;
          // line-height: 12px;
          font-size: $sidebar-icon-size;
          color: $sidebar-icons;
          display: inline-block;
          text-align: center;
          padding: 8px 0;
          margin: 0 10px 0 0;
          width: 40px;
        }
        &.active,
        &:hover {
          opacity: 1;
        }
      }
      &.selected > .sidebar-link {
        background: rgba(0, 0, 0, 0.04);
        background: none;
        opacity: 1;
      }
      &.selected > .sidebar-link > i {
        background: #115caa;
        color: #fff;
        border-radius: 0px 25px 25px 0px;
        -webkit-border-radius: 0px 25px 25px 0px;
        -moz-border-radius: 0px 25px 25px 0px;
        -ms-border-radius: 0px 25px 25px 0px;
        -o-border-radius: 0px 25px 25px 0px;

        // color: #115caa;
}
      /*******************
      First level
      ******************/
      .first-level {
        padding: $sidebar-first-level-padding;
        padding-left: 20px;

        .sidebar-item.active .sidebar-link {
          opacity: 1;
        }
        .sidebar-item .sidebar-link {
          padding: $sidebar-first-level-link-padding;
          i {
            font-size: $sidebar-first-level-icon-size;
            visibility: hidden;
          }
        }
      }
    }

    /*******************
         Small cap
         ******************/
    .nav-small-cap {
      font-size: 12px;
      padding: $sidebar-link-padding;
      white-space: nowrap;
      display: flex;
      align-items: center;
      line-height: 30px;
      margin-top: 10px;
      color: $sidebar-text;
      opacity: 0.5;
      text-transform: uppercase;
      i {
        line-height: 30px;
        margin: 0 5px;
      }
    }
  }
}
/*******************
 common m-p removed
******************/
.sidebar-nav {
  ul {
    margin: 0px;
    padding: 0px;
    li {
      list-style: none;
    }
  }
  > ul > li.selected {
    padding: 0;
    > a {
      // border-radius: 60px 0 0 60px;
      background: $themecolor;
      color: $white;
        .hide-menu {
          // font-weight: 600;
        }
    }
  }
}
.collapse.in {
  display: block;
}
/*******************
Down arrow
******************/
// .sidebar-nav .has-arrow {
//   position: relative;
//   &::after {
//     position: absolute;
//     content: '';
//     width: 7px;
//     height: 7px;
//     border-width: 1px 0 0 1px;
//     border-style: solid;
//     border-color: $sidebar-text;
//     margin-left: 10px;
//     -webkit-transform: rotate(135deg) translate(0, -50%);
//     -ms-transform: rotate(135deg) translate(0, -50%);
//     -o-transform: rotate(135deg) translate(0, -50%);
//     transform: rotate(135deg) translate(0, -50%);
//     -webkit-transform-origin: top;
//     -ms-transform-origin: top;
//     -o-transform-origin: top;
//     transform-origin: top;
//     top: 23px;
//     right: 15px;
//     -webkit-transition: all 0.3s ease-out;
//     -o-transition: all 0.3s ease-out;
//     transition: all 0.3s ease-out;
//   }
// }

/*.sidebar-nav .active > .has-arrow::after,*/

/*.sidebar-nav li.selected > .has-arrow::after,*/
.sidebar-nav li.active > .has-arrow::after,
.sidebar-nav li > .has-arrow.active::after,
.sidebar-nav .has-arrow[aria-expanded='true']::after {
  -webkit-transform: rotate(-135deg) translate(0, -50%);
  -ms-transform: rotate(-135deg) translate(0, -50%);
  -o-transform: rotate(-135deg) translate(0, -50%);
  transform: rotate(-135deg) translate(0, -50%);
}

/*******************
User Profile
******************/
.user-profile {
  padding: 15px;
  text-align: center;
  .user-content,
  .user-name,
  .user-email {
    color: $theme-light;
    position: relative;
    white-space: nowrap;
  }
  .user-pic {
    img {
      width: 60px;
    }
  }
  .btn-circle.btn-sm {
    width: 25px;
    height: 25px;
    font-size: 10px;
    padding: 5px 0;
    background: rgba(0, 0, 0, 0.2);
    color: $white;
    opacity: 0.7;
    &:hover {
      opacity: 1;
    }
  }
  .dropdown-menu {
    width: 220px;
    margin-top: 15px;
    left: 0!important;
  }
  .dropdown-toggle::after {
    display: none;
  }
}

.bullet + i{
width: 40px;
}

.bullet{
width: 8px;
    height: 8px;
    background-color: #777;
    border-radius: 50%;
    opacity: 0.7;
}
li.sidebar-item.active > a > .bullet{
    background-color: #115caa;
    opacity: 1;
}


.mini-sidebar .sidebar-nav {
  ul {
  .sidebar-item{
        .sidebar-item .sidebar-link {
//           padding: 9px 0px 8px;
            min-height: 33px;
        }
      }
    }

  }
