//Progress
// Circle progress
.au-progress {
  width: 60px;
  height: 60px;
  background: none;
  position: relative;
  &::after {
    content: "";
    width: 100%;
    height: 100%;
    border-radius: 50%;
    border: 4px solid $au-color-secondary-light-2;
    position: absolute;
    top: 0;
    left: 0;
  }
  &--active {
    &::after {
      border: 4px solid $au-color-primary;
    }
  }
  > span {
    width: 50%;
    height: 100%;
    overflow: hidden;
    position: absolute;
    top: 0;
    z-index: 1;
  }
  &__left {
    left: 0;
    &-bar {
      left: 100%;
      border-top-right-radius: 80px;
      border-bottom-right-radius: 80px;
      border-left: 0;
      -webkit-transform-origin: center left;
      transform-origin: center left;
    }
  }
  &__right {
    right: 0;
    &-bar {
      left: -100%;
      border-top-left-radius: 80px;
      border-bottom-left-radius: 80px;
      border-right: 0;
      -webkit-transform-origin: center right;
      transform-origin: center right;
    }
  }
  &__left,
  &__right {
    &-bar {
      width: 100%;
      height: 100%;
      background: none;
      border-width: 4px;
      border-style: solid;
      border-right: 0;
      position: absolute;
      top: 0;
      border-color: $au-color-white;
    }
  }
  &__value {
    position: absolute;
    top: 0;
    left: 0;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 100%;
  }
}
