/*******************
 Breadcrumb and page title
*******************/
@import "./src/assets/au-scss/abstracts/variables/";
@import "./src/assets/au-scss/abstracts/mixins/";

/* stylelint-disable-next-line selector-class-pattern */
.page-breadcrumb {
  position: relative;
  padding: 20px 20px 0 10px;

  /* stylelint-disable-next-line selector-class-pattern */
  .page-title {
    margin-bottom: $spacer-0;
    font-size: 2.4rem;
    font-weight: $font-weight-medium;
    color: $au-color-primary;
    font-family: $font-family-sans-serif;
  }

  /* stylelint-disable-next-line selector-class-pattern */
  .breadcrumb {
    padding: $spacer-0;
    margin: $spacer-0;
    background: transparent;
    font-size: $au-fs-2;

    /* stylelint-disable-next-line selector-class-pattern */
    .breadcrumb-item + .breadcrumb-item::before {
      content: "\e649";
      font-family: themify;
      color: $au-color-grey5;
      font-size: 1.1rem;
    }
  }
}
