/* stylelint-disable custom-property-no-missing-var-function */
/* stylelint-disable color-plugin/variable */
// Color system

// Primary Colors
$color-primary: #0b3a62 !default;
$color-primary-light: #4aafff;
$color-primary-dark: #115caa;

// Secondary Colors
$color-secondary: #555;
$color-secondary-dark: #000;
$color-secondary-medium: #999;
$color-secondary-light: #e1e1e1;
$color-secondary-light-2: #f9f9f9;

// Other Colors
$color-black: #000;
$color-white: #fff;
$color-green: #6b6;
$color-red: #e53535;
$color-yellow: #ffa600;
$color-orange: #fdae61;
$blue-violet: #8a2be2;
$color-transparent: transparent;

// Color system

:root {
  // Primary Colors
  --au-color-primary: #0b3a62;
  --au-color-primary-light: #4aafff;
  --au-color-primary-dark: #115caa;

  // Secondary Colors
  --au-color-secondary: #555;
  --au-color-secondary-dark: #000;
  --au-color-secondary-medium: #999;
  --au-color-secondary-light: #e1e1e1;
  --au-color-secondary-light-2: #f9f9f9;
  --au-color-secondary-light-3: #ccc;
  --au-color-secondary-light-4: #efefef;

  // Other Colors
  --au-color-black: #000;
  --au-color-black-1: #0f0f0f;
  --au-color-dark-blue: #011826;
  --au-color-dark-blue-1: #212529;
  --au-color-dark-blue-2: #0c3a62;
  --au-color-dark-blue-3: #1f3953;
  --au-color-white: #fff;
  --au-color-green: #6b6;
  --au-color-green-1: #7dbb42;
  --au-color-green-2: #61dbb5;
  --au-color-green-3: #1dbb99;
  --au-color-red: #e53535;
  --au-color-red-1: #ef6e6e;
  --au-color-red-2: #ad0000;
  --au-color-yellow: #ffa600;
  --au-color-orange: #fdae61;
  --au-color-orange-1: #fcce56;
  --au-color-orange-2: #ffdeb4;
  --au-blue-violet: #8a2be2;
  --au-blue-green: #91d8e4;
  --au-color-gray-light-3: #f2f4f5;
  --au-color-gray-light-2: #f5f5f5;
  --au-color-gray-light-4: #eee;
  --au-color-gray-light-5: #dedbda;
  --au-color-gray-light-6: #d1d1d1;
  --au-color-gray-light-7: #f1f1f1;
  --au-color-gray-light-8: #c4c2c2;
  --au-color-gray-light-9: #f6f6f6;
  --au-color-currentcolor: currentcolor;
  --au-color-grey: #333;
  --au-color-grey2: #323338;
  --au-color-grey3: #5b5b5b;
  --au-color-grey4: #777;
  --au-color-grey5: #ced4da;
  --au-color-grey6: #6c757d;
  --au-color-grey7: #5c5957;
  --au-color-grey8: #575757;
  --au-color-grey9: #2c2c2c;
  --au-color-grey10: #4f5467;
  --au-color-grey11: #e8e8e8;
  --au-color-white-light-4: #eaeef4;
  --au-color-white-light-5: #cde0f0;
  --au-color-white-light-6: #e5e5e5;
  --au-color-white-light-7: #dee2e6;
  --au-color-white-light-8: #dee2e6;
  --au-color-white-light-9: #efefef;
  --au-color-blue: #134c88;
  --au-color-blue-1: #1c8adb;
  --au-color-blue-2: #1f91f2;
  --au-color-blue-3: #337ab7;
  --au-color-blue-4: #2c65ae;
  --au-color-blue-5: #2962ff;
  --au-color-blue-light-1: #a3c8e4;
  --au-color-blue-light-2: #5b8ca5;
  --au-color-blue-light-3: #115caa33;
  --au-color-dark-green: #008000;

  // Qualitative Palette
  --au-color-q1: #115caa;
  --au-color-q2: #8559b4;
  --au-color-q3: #cf4fa3;
  --au-color-q4: #ff537c;
  --au-color-q5: #ff764b;
  --au-color-q6: #ffa600;
  --au-color-q7: #003b6d;
  --au-color-q8: #4aafff;
  --au-color-q9: #ccd9e9;
  --au-color-q10: #aec4df;
  --au-color-q11: #c9ecce;

  // Diverging Palette
  --au-color-d1: #6b6;
  --au-color-d2: #9ecc6c;
  --au-color-d3: #d0dc7a;
  --au-color-d4: #d0dc7a;
  --au-color-d5: #f9b859;
  --au-color-d6: #f27e3a;
  --au-color-d7: #e53535;
  --au-color-d8: #ffbfc1;

  // Sequential Palette
  --au-color-s1: #115caa;
  --au-color-s2: #0878bc;
  --au-color-s3: #2a94cb;
  --au-color-s4: #4fb0d7;
  --au-color-s5: #77cae3;
  --au-color-s6: #a0e4f0;
  --au-color-s7: #cafeff;
  --au-color-s8: #b8dbff;
  --au-color-s9: #cde0f0;
  --au-color-s10: #cee8ff;
  --au-color-s11: #e2e9ec;
  --au-color-s12: #ebf5ff;

  // Transparent
  --au-color-transparent: transparent;

  // Theme Colors for dark & light mode
  --au-theme-white: #fff;
  --au-theme-black: #000;
}

// Primary Colors
$au-color-primary: #{var(--au-color-primary)} !default;
$au-color-primary-light: #{var(--au-color-primary-light)} !default;
$au-color-primary-dark: #{var(--au-color-primary-dark)} !default;

// Secondary Colors
$au-color-secondary: #{var(--au-color-secondary)} !default;
$au-color-secondary-dark: #{var(--au-color-secondary-dark)} !default;
$au-color-secondary-medium: #{var(--au-color-secondary-medium)} !default;
$au-color-secondary-light: #{var(--au-color-secondary-light)} !default;
$au-color-secondary-light-2: #{var(--au-color-secondary-light-2)} !default;
$au-color-secondary-light-3: #{var(--au-color-secondary-light-3)} !default;
$au-color-secondary-light-4: #{var(--au-color-secondary-light-4)} !default;

// Other Colors
$au-color-black: #{var(--au-color-black)} !default;
$au-color-black-1: #{var(--au-color-black-1)} !default;
$au-color-dark-blue: #{var(--au-color-dark-blue)} !default;
$au-color-dark-blue-1: #{var(--au-color-dark-blue-1)} !default;
$au-color-dark-blue-2: #{var(--au-color-dark-blue-2)} !default;
$au-color-dark-blue-3: #{var(--au-color-dark-blue-3)} !default;
$au-color-white: #{var(--au-color-white)} !default;
$au-color-green: #{var(--au-color-green)} !default;
$au-color-green-1: #{var(--au-color-green-1)} !default;
$au-color-green-2: #{var(--au-color-green-2)} !default;
$au-color-green-3: #{var(--au-color-green-3)} !default;
$au-color-red: #{var(--au-color-red)} !default;
$au-color-red-1: #{var(--au-color-red-1)} !default;
$au-color-red-2: #{var(--au-color-red-2)} !default;
$au-color-yellow: #{var(--au-color-yellow)} !default;
$au-color-orange: #{var(--au-color-orange)} !default;
$au-color-orange-1: #{var(--au-color-orange-1)} !default;
$au-color-orange-2: #{var(--au-color-orange-2)} !default;
$au-color-gray-light-2: #{var(--au-color-gray-light-2)} !default;
$au-color-gray-light-4: #{var(--au-color-gray-light-4)} !default;
$au-color-gray-light-5: #{var(--au-color-gray-light-5)} !default;
$au-color-gray-light-6: #{var(--au-color-gray-light-6)} !default;
$au-color-gray-light-7: #{var(--au-color-gray-light-7)} !default;
$au-color-gray-light-8: #{var(--au-color-gray-light-8)} !default;
$au-color-gray-light-9: #{var(--au-color-gray-light-9)} !default;
$au-blue-violet: #{var(--au-blue-violet)} !default;
$au-blue-green: #{var(--au-blue-green)} !default;
$au-color-gray-light-3: #{var(--au-color-gray-light-3)} !default;
$au-color-currentcolor: #{var(--au-color-currentcolor)} !default;
$au-color-grey: #{var(--au-color-grey)} !default;
$au-color-grey2: #{var(--au-color-grey2)} !default;
$au-color-grey3: #{var(--au-color-grey3)} !default;
$au-color-grey4: #{var(--au-color-grey4)} !default;
$au-color-grey5: #{var(--au-color-grey5)} !default;
$au-color-grey6: #{var(--au-color-grey6)} !default;
$au-color-grey7: #{var(--au-color-grey7)} !default;
$au-color-grey8: #{var(--au-color-grey8)} !default;
$au-color-grey9: #{var(--au-color-grey9)} !default;
$au-color-grey10: #{var(--au-color-grey10)} !default;
$au-color-grey11: #{var(--au-color-grey11)} !default;
$au-color-white-light-4: #{var(--au-color-white-light-4)} !default;
$au-color-white-light-5: #{var(--au-color-white-light-5)} !default;
$au-color-white-light-6: #{var(--au-color-white-light-6)} !default;
$au-color-white-light-7: #{var(--au-color-white-light-7)} !default;
$au-color-white-light-8: #{var(--au-color-white-light-8)} !default;
$au-color-white-light-9: #{var(--au-color-white-light-9)} !default;
$au-color-blue: #{var(--au-color-blue)} !default;
$au-color-blue-1: #{var(--au-color-blue-1)} !default;
$au-color-blue-2: #{var(--au-color-blue-2)} !default;
$au-color-blue-3: #{var(--au-color-blue-3)} !default;
$au-color-blue-4: #{var(--au-color-blue-4)} !default;
$au-color-blue-5: #{var(--au-color-blue-5)} !default;
$au-color-blue-light-1: #{var(--au-color-blue-light-1)} !default;
$au-color-blue-light-2: #{var(--au-color-blue-light-2)} !default;
$au-color-blue-light-3: #{var(--au-color-blue-light-3)} !default;
$au-color-dark-green: #{var(--au-color-dark-green)} !default;

// Qualitative Palette
$au-color-q1: #{var(--au-color-q1)};
$au-color-q2: #{var(--au-color-q2)};
$au-color-q3: #{var(--au-color-q3)};
$au-color-q4: #{var(--au-color-q4)};
$au-color-q5: #{var(--au-color-q5)};
$au-color-q6: #{var(--au-color-q6)};
$au-color-q7: #{var(--au-color-q7)};
$au-color-q8: #{var(--au-color-q8)};
$au-color-q9: #{var(--au-color-q9)};
$au-color-q10: #{var(--au-color-q10)};
$au-color-q11: #{var(--au-color-q11)};

// Sequential Palette
$au-color-d1: #{var(--au-color-d1)};
$au-color-d2: #{var(--au-color-d2)};
$au-color-d3: #{var(--au-color-d3)};
$au-color-d4: #{var(--au-color-d4)};
$au-color-d5: #{var(--au-color-d5)};
$au-color-d6: #{var(--au-color-d6)};
$au-color-d7: #{var(--au-color-d7)};
$au-color-d8: #{var(--au-color-d8)};

// Sequential Palette
$au-color-s1: #{var(--au-color-s1)};
$au-color-s2: #{var(--au-color-s2)};
$au-color-s3: #{var(--au-color-s3)};
$au-color-s4: #{var(--au-color-s4)};
$au-color-s5: #{var(--au-color-s5)};
$au-color-s6: #{var(--au-color-s6)};
$au-color-s7: #{var(--au-color-s7)};
$au-color-s8: #{var(--au-color-s8)};
$au-color-s9: #{var(--au-color-s9)};
$au-color-s10: #{var(--au-color-s10)};
$au-color-s11: #{var(--au-color-s11)};
$au-color-s12: #{var(--au-color-s12)};

// Transparent
$au-color-transparent: #{var(--au-color-transparent)};

// Theme Colors for dark & light mode
$au-theme-white: #{var(--au-theme-white)} !default;
$au-theme-black: #{var(--au-theme-black)} !default;
$au-color-transparent: #{var(--au-color-transparent)};
