@charset "UTF-8";
/* stylelint-disable custom-property-no-missing-var-function */
/* stylelint-disable color-plugin/variable */
:root {
  --au-color-primary: #0b3a62;
  --au-color-primary-light: #4aafff;
  --au-color-primary-dark: #115caa;
  --au-color-secondary: #555;
  --au-color-secondary-dark: #000;
  --au-color-secondary-medium: #999;
  --au-color-secondary-light: #e1e1e1;
  --au-color-secondary-light-2: #f9f9f9;
  --au-color-secondary-light-3: #ccc;
  --au-color-secondary-light-4: #efefef;
  --au-color-black: #000;
  --au-color-black-1: #0f0f0f;
  --au-color-dark-blue: #011826;
  --au-color-dark-blue-1: #212529;
  --au-color-dark-blue-2: #0c3a62;
  --au-color-dark-blue-3: #1f3953;
  --au-color-white: #fff;
  --au-color-green: #6b6;
  --au-color-green-1: #7dbb42;
  --au-color-green-2: #61dbb5;
  --au-color-green-3: #1dbb99;
  --au-color-red: #e53535;
  --au-color-red-1: #ef6e6e;
  --au-color-red-2: #ad0000;
  --au-color-yellow: #ffa600;
  --au-color-orange: #fdae61;
  --au-color-orange-1: #fcce56;
  --au-color-orange-2: #ffdeb4;
  --au-blue-violet: #8a2be2;
  --au-blue-green: #91d8e4;
  --au-color-gray-light-3: #f2f4f5;
  --au-color-gray-light-2: #f5f5f5;
  --au-color-gray-light-4: #eee;
  --au-color-gray-light-5: #dedbda;
  --au-color-gray-light-6: #d1d1d1;
  --au-color-gray-light-7: #f1f1f1;
  --au-color-gray-light-8: #c4c2c2;
  --au-color-gray-light-9: #f6f6f6;
  --au-color-currentcolor: currentcolor;
  --au-color-grey: #333;
  --au-color-grey2: #323338;
  --au-color-grey3: #5b5b5b;
  --au-color-grey4: #777;
  --au-color-grey5: #ced4da;
  --au-color-grey6: #6c757d;
  --au-color-grey7: #5c5957;
  --au-color-grey8: #575757;
  --au-color-grey9: #2c2c2c;
  --au-color-grey10: #4f5467;
  --au-color-grey11: #e8e8e8;
  --au-color-white-light-4: #eaeef4;
  --au-color-white-light-5: #cde0f0;
  --au-color-white-light-6: #e5e5e5;
  --au-color-white-light-7: #dee2e6;
  --au-color-white-light-8: #dee2e6;
  --au-color-white-light-9: #efefef;
  --au-color-blue: #134c88;
  --au-color-blue-1: #1c8adb;
  --au-color-blue-2: #1f91f2;
  --au-color-blue-3: #337ab7;
  --au-color-blue-4: #2c65ae;
  --au-color-blue-5: #2962ff;
  --au-color-blue-light-1: #a3c8e4;
  --au-color-blue-light-2: #5b8ca5;
  --au-color-blue-light-3: #115caa33;
  --au-color-dark-green: #008000;
  --au-color-q1: #115caa;
  --au-color-q2: #8559b4;
  --au-color-q3: #cf4fa3;
  --au-color-q4: #ff537c;
  --au-color-q5: #ff764b;
  --au-color-q6: #ffa600;
  --au-color-q7: #003b6d;
  --au-color-q8: #4aafff;
  --au-color-q9: #ccd9e9;
  --au-color-q10: #aec4df;
  --au-color-q11: #c9ecce;
  --au-color-d1: #6b6;
  --au-color-d2: #9ecc6c;
  --au-color-d3: #d0dc7a;
  --au-color-d4: #d0dc7a;
  --au-color-d5: #f9b859;
  --au-color-d6: #f27e3a;
  --au-color-d7: #e53535;
  --au-color-d8: #ffbfc1;
  --au-color-s1: #115caa;
  --au-color-s2: #0878bc;
  --au-color-s3: #2a94cb;
  --au-color-s4: #4fb0d7;
  --au-color-s5: #77cae3;
  --au-color-s6: #a0e4f0;
  --au-color-s7: #cafeff;
  --au-color-s8: #b8dbff;
  --au-color-s9: #cde0f0;
  --au-color-s10: #cee8ff;
  --au-color-s11: #e2e9ec;
  --au-color-s12: #ebf5ff;
  --au-color-transparent: transparent;
  --au-theme-white: #fff;
  --au-theme-black: #000;
}

/* stylelint-disable property-no-vendor-prefix */
/* Fixed Spacing Variables */
/* Em Based Spacing Variables */
/* stylelint-disable-next-line color-function-notation, alpha-value-notation */
/* stylelint-disable-next-line color-function-notation, alpha-value-notation */
/* stylelint-disable-next-line scss/dollar-variable-pattern */
/* stylelint-disable selector-class-pattern */ /*
* DO NOT EDIT THIS FILE
*/
/* To adjust column spacing according to bootstrap 5. Can be removed once updated to bootstrap 5 */
.row > [class^=col] {
  position: relative;
}

.tooltip {
  font-size: 1.2rem;
  font-weight: 400;
}

.dropdown-item {
  padding: 0.65rem 1rem;
}
.dropdown-item:hover, .dropdown-item:focus {
  background-color: var(--au-color-gray-light-3);
}

.btn.focus,
.btn:focus {
  outline: none;
  box-shadow: none;
}

.accordion .collapsing {
  transition: none;
}
.accordion > .accordion-item {
  overflow: initial;
}
.accordion .au-accordion .accordion-item {
  margin-bottom: 1px;
}

html,
body,
div,
span,
applet,
object,
iframe,
h1,
h2,
h3,
h4,
h5,
h6,
p,
blockquote,
pre,
a,
abbr,
acronym,
address,
big,
cite,
code,
del,
dfn,
em,
img,
ins,
kbd,
q,
s,
samp,
small,
strike,
strong,
sub,
sup,
tt,
var,
b,
u,
i,
center,
dl,
dt,
dd,
ol,
ul,
li,
fieldset,
form,
label,
legend,
table,
caption,
tbody,
tfoot,
thead,
tr,
th,
td,
article,
aside,
canvas,
details,
embed,
figure,
figcaption,
footer,
header,
hgroup,
menu,
nav,
output,
ruby,
section,
summary,
time,
mark,
audio,
video {
  margin: 0;
  padding: 0;
  border: 0;
  font-size: 100%;
  vertical-align: baseline;
  font-weight: inherit;
}

article,
aside,
details,
figcaption,
figure,
footer,
header,
hgroup,
menu,
nav,
section {
  display: block;
}

ol,
ul {
  list-style: none;
}

blockquote,
q {
  quotes: none;
}

blockquote:before, blockquote:after {
  content: "";
  content: none;
}

q:before, q:after {
  content: "";
  content: none;
}

table {
  border-collapse: collapse;
  border-spacing: 0;
}

a {
  text-decoration: none;
  cursor: pointer;
}

input,
textarea,
select {
  margin: 0;
}

input:focus,
textarea:focus,
select:focus {
  outline: none;
}

textarea {
  overflow: auto;
}

button::-moz-focus-inner,
input[type=button]::-moz-focus-inner,
input[type=submit]::-moz-focus-inner,
input[type=reset]::-moz-focus-inner {
  padding: 0 !important;
  border: 0 none !important;
}

mark {
  background: none;
  color: inherit;
}

strong {
  font-weight: bold;
}

*,
*:before,
*:after {
  box-sizing: border-box;
}

/* stylelint-disable selector-class-pattern */
/* stylelint-disable selector-max-type */
html {
  background-color: var(--au-color-secondary-light-2);
  font-family: "Poppins", sans-serif;
  width: 100%;
  min-height: 100%;
  overflow: initial;
  /* stylelint-disable-next-line font-plugin/font-check */
  font-size: 62.5%;
}

body {
  background-color: var(--au-color-secondary-light-2);
  color: var(--au-color-black);
  font-family: "Poppins", sans-serif;
  font-weight: 400;
  line-height: 1.2 !important;
  width: 100%;
  min-height: 100%;
  overflow-x: hidden;
  min-width: auto !important;
}

button,
input {
  font-family: "Poppins", sans-serif;
}

a,
button,
input {
  outline: none;
}

h1,
h2,
h3,
h4,
h5,
h6,
p,
span,
a,
label {
  word-break: break-word;
}

a {
  color: var(--au-color-primary);
  text-decoration: none;
}
a:hover {
  color: var(--au-color-primary);
  text-decoration: none;
}

.cover,
.background {
  position: relative;
  overflow: hidden;
}

.clearfix {
  clear: both;
}

.hide {
  display: none !important;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  color: var(--au-color-black);
}

h1,
.au-h1 {
  font-size: 3.8rem;
  line-height: 1.2;
}

h2,
.au-h2 {
  font-size: 2.4rem;
  line-height: 1.2;
  color: var(--au-color-black);
  font-weight: 500;
}

h3,
.au-h3 {
  color: var(--au-color-black);
  font-size: 1.9rem;
  font-weight: 500;
  line-height: 1.2;
  margin-bottom: 10px;
}
@media (max-width: 1023.98px) {
  h3,
.au-h3 {
    font-size: 1.6rem;
  }
}

h4,
.au-h4 {
  font-size: 1.6rem;
  line-height: 1.2;
}
@media (max-width: 1023.98px) {
  h4,
.au-h4 {
    font-size: 1.4rem;
  }
}

h5,
.au-h5 {
  font-size: 1.4rem;
  line-height: 1.2;
}

h6,
.au-h6 {
  font-size: 1rem;
  line-height: 1.2;
}

p,
li {
  font-size: 1.4rem;
  color: var(--au-color-secondary);
  margin-bottom: 8px;
  line-height: 1.2;
}

.au-content-wrapper {
  width: 100%;
  padding-left: 16px;
  padding-right: 16px;
  max-width: 1404px;
  margin-left: auto;
  margin-right: auto;
  box-sizing: border-box;
}
@media (min-width: 600px) {
  .au-content-wrapper {
    padding-left: 32px;
    padding-right: 32px;
  }
}

.helper-svg-gradients {
  position: absolute;
  overflow: hidden;
  clip: rect(0 0 0 0);
  width: 1px;
  height: 1px;
  padding: 0;
  border: 0;
}

/* stylelint-disable selector-max-type */
sup {
  vertical-align: super;
  font-size: 50%; /* stylelint-disable-line */
  line-height: 0;
}

sub {
  vertical-align: sub;
  font-size: 50%; /* stylelint-disable-line */
  line-height: 0;
}

/* stylelint-enable selector-max-type */
.au-hidden {
  position: absolute;
  overflow: hidden;
  clip: rect(0 0 0 0);
  width: 1px;
  height: 1px;
  padding: 0;
  border: 0;
}

.tab-head,
.au-tab-head {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.tab-head .pointer,
.au-tab-head .pointer {
  color: var(--au-color-secondary-medium);
  font-size: 1.4rem;
  cursor: pointer;
}
.tab-head .pointer.active-graph,
.au-tab-head .pointer.active-graph {
  color: var(--au-color-black);
}
.tab-head .shadow-graph:nth-child(2),
.au-tab-head .shadow-graph:nth-child(2) {
  color: var(--au-color-secondary-medium);
  margin-right: 10px;
  margin-left: 10px;
}

header .navbar-collapse {
  padding: 0 !important;
}

.form-control,
.au-form-control {
  min-height: 40px;
  border: 1px solid var(--au-color-secondary-light);
  padding: 8px 10px;
  color: var(--au-color-black) !important;
  font-weight: 400 !important;
  font-size: 1.4rem !important;
  height: 34px;
  border-radius: 5px;
}
.form-controlselect,
.au-form-controlselect {
  display: none;
}

.searchbox,
.au-searchbox {
  border-radius: 5px;
  border: 1px solid #e1e1e1;
  position: relative;
}
.searchbox input[placeholder=Search], .searchbox input[placeholder=search],
.au-searchbox input[placeholder=Search],
.au-searchbox input[placeholder=search] {
  padding-right: 50px;
  background: url("/assets/images/svg/search.svg") no-repeat;
  background-size: 15px;
  background-position: 98% 12px;
}
.searchbox .form-control,
.au-searchbox .form-control {
  height: 34px;
  border-radius: 0;
  border: none;
}
.searchbox .search-icon,
.au-searchbox .search-icon {
  position: absolute;
  width: 15px;
  height: 15px;
  right: 10px;
  top: 11px;
  display: none;
}

.dropdown-menu .dropdown-item {
  font-size: 1.2rem;
  display: flex;
  align-items: center;
  transition: 0.3s;
}
.dropdown-menu .dropdown-item .au-icon {
  margin-inline-end: 8px;
}
.dropdown-menu .dropdown-item:active {
  background-color: var(--au-color-primary);
  color: var(--au-color-white);
}
.dropdown-menu .dropdown-item.au-color--red:active {
  background-color: var(--au-color-red);
  color: var(--au-color-white) !important;
}
.dropdown-menu .la {
  width: 22px;
  display: inline-block;
}
.dropdown-menu .icon-view {
  /* stylelint-disable-next-line font-plugin/font-check */
  font-size: 0.6rem;
}
.dropdown-menu .icon-edit-box {
  /* stylelint-disable-next-line font-plugin/font-check */
  font-size: 0.8rem;
}

table .dropdown-menu[class*=icon-],
table .dropdown-menu [class^=icon-] {
  width: 10px;
}

.option-menu {
  cursor: pointer;
}
.option-menu .icon-actions {
  /* stylelint-disable-next-line font-plugin/font-check */
  font-size: 1.5rem;
}
.option-menu .icon-actions::before {
  color: var(--au-color-secondary-medium);
}

.step-wizard li a,
.au-step-wizard li a {
  padding: 10px 30px;
  font-size: 1.4rem;
  font-weight: 400;
  border-radius: 25px;
  pointer-events: none;
  background-color: var(--au-color-white);
  color: var(--au-color-secondary);
  box-shadow: 0 1px 5px 0 #dcdcdc;
  text-align: center;
}
.step-wizard li.nav-item a,
.au-step-wizard li.nav-item a {
  background: #115caa;
  color: var(--au-color-white);
}

.ngx-dropdown-container button {
  display: inline-block;
  margin-bottom: 0;
  font-weight: 400;
  line-height: 1.4286;
  vertical-align: middle;
  touch-action: manipulation;
  cursor: pointer;
  user-select: none;
  border: 1px solid #e9ecef !important;
  border-radius: 4px;
  color: var(--au-color-grey);
  background-color: var(--au-color-white);
  white-space: nowrap;
  overflow-x: hidden;
  text-overflow: ellipsis;
  text-align: left;
}
.ngx-dropdown-container .available-items li {
  font-size: 1.4rem;
  color: var(--au-color-secondary);
  padding: 5px 10px;
}

.form-area .form-item,
.au-form-area .form-item {
  margin-bottom: 20px;
}
.form-area .form-box .label-box,
.au-form-area .form-box .label-box {
  display: flex;
  justify-content: space-between;
}
.form-area .form-box .label-box .input-label-text,
.form-area .form-box .label-box .input-label-btn,
.au-form-area .form-box .label-box .input-label-text,
.au-form-area .form-box .label-box .input-label-btn {
  color: var(--au-color-secondary-medium);
  font-size: 1.2rem;
  margin-bottom: 10px;
}
.form-area .form-box .label-box .input-label-btn,
.au-form-area .form-box .label-box .input-label-btn {
  cursor: pointer;
  /* stylelint-disable-next-line max-nesting-depth */
}
.form-area .form-box .label-box .input-label-btn:hover,
.au-form-area .form-box .label-box .input-label-btn:hover {
  color: var(--au-color-primary);
}

.cover-item,
.au-cover-item {
  background-color: var(--au-color-secondary-light-2);
  border-radius: 17px;
  border: 1px solid #e1e1e1;
  font-weight: 400 !important;
  color: var(--au-color-secondary-dark);
}
.cover-item span,
.au-cover-item span {
  display: flex;
  align-items: center;
}
.cover-item span img,
.au-cover-item span img {
  width: 23px;
  margin-right: 15px;
  border-radius: 50%;
}

.au-browser-alert {
  width: 95%;
  margin: 15px auto;
}
.au-browser-alert__img {
  margin-top: 24px;
}
.au-browser-alert__item {
  width: calc(20% - 16px);
  display: inline-block;
  margin: 0 8px;
  /* stylelint-disable-next-line no-descending-specificity */
}
@media (max-width: 599.98px) {
  .au-browser-alert__item {
    width: calc(48% - 16px);
    margin-top: 8px;
  }
}
.au-browser-alert__item-text {
  display: block;
}
.au-browser-alert__item img {
  height: 54px;
  width: auto;
  transition: 0.3s;
}
.au-browser-alert__item:hover img {
  transform: scale(1.1);
}
.au-browser-alert__wrapper .swal2-popup {
  padding: 32px 24px;
}
.au-browser-alert__wrapper .swal2-actions {
  margin-bottom: 0 !important;
}

.duration-head label.left-area span,
.au-duration-head label.left-area span {
  display: block;
  margin: 0 20px;
}
.duration-head label.right-area span,
.au-duration-head label.right-area span {
  display: block;
  margin: 0 20px;
  /* stylelint-disable-next-line max-nesting-depth */
}
.duration-head label.right-area span.switch,
.au-duration-head label.right-area span.switch {
  margin: 0;
}

.donut-legend,
.au-donut-legend {
  padding: 0;
  border-top: 0;
  background: transparent;
}

.au-d3 {
  display: flex;
  justify-content: center;
}
.au-d3 svg {
  width: 100%;
  max-width: 300px;
  margin: 0 auto;
}

/* stylelint-disable-next-line selector-id-pattern */
#d3Gauge svg {
  max-width: 300px;
}

.flex-auto {
  flex: auto;
  text-align: center;
}

footer {
  padding: 10px 0;
  font-size: 1.2rem;
  margin-top: 20px;
  border-top: 1px solid #f9f9f9;
  border-bottom: 1px solid #f9f9f9;
  z-index: 2;
  position: relative;
}

.modal .modal-lg {
  max-width: 1200px;
}
.modal .modal-header .close {
  padding: 10px;
  margin: 0;
}
.modal .modal-header .close i {
  /* stylelint-disable-next-line font-plugin/font-check */
  font-size: 0.875rem;
}
.modal .modal-content {
  border-radius: 10px;
}
.modal .modal-content .modal-header .app-modal-header h3 {
  padding: 0;
  margin: 0 !important;
}
.modal .app-modal-header {
  display: flex;
  justify-content: space-between;
  width: 100%;
  align-items: center;
}
.modal .app-modal-footer {
  width: 100%;
}

.au-col,
.au-row {
  margin-bottom: 20px;
}

.au-graph {
  position: relative;
}

.au-chart-tooltip {
  min-width: 60px !important;
}

.au-page {
  padding-top: 15px;
}
@media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
  .au-page {
    width: 100%;
  }
}
.au-page__action {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 15px;
}
.au-page__action .au-left {
  width: 70%;
}
.au-page__action .au-right {
  width: 30%;
  text-align: right;
}
.au-page__action .au-breadcrumb {
  margin-bottom: 0;
}
.au-page__action .au-breadcrumb__body {
  margin-bottom: 0;
}
.au-page__title {
  margin-bottom: 15px;
}
.au-page__title-heading {
  margin-bottom: 0.375rem;
}
@media (max-width: 599.98px) {
  .au-page__title-heading {
    margin-bottom: 1.125rem;
  }
}
.au-page__title h5 {
  margin-bottom: 5px;
  color: var(--au-color-secondary);
}
.au-page__description .au-label {
  margin-bottom: 5px;
}
.au-page__footer {
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 10px;
  margin-top: 15px;
}
.au-page__footer .au-left {
  display: flex;
  align-items: center;
  gap: 10px;
}
.au-page__footer .au-right {
  display: flex;
  align-items: center;
  gap: 10px;
}

.au-border-none {
  border: 0 !important;
}

.au-w--100 {
  width: 100%;
  max-width: 100%;
}
.au-w--75 {
  width: 75%;
  max-width: 75%;
}
.au-w--50 {
  width: 50%;
  max-width: 50%;
}
.au-w--25 {
  width: 25%;
  max-width: 25%;
}
@media (max-width: 599.98px) {
  .au-wm-100 {
    width: 100% !important;
    max-width: 100% !important;
  }
}

.au-h--100 {
  height: 100%;
}

.dropdown-toggle::after {
  display: none !important;
}

.au-table-option {
  width: 40px;
  min-width: 40px;
  max-width: 40px;
  text-align: center;
}

.no-scroll {
  position: fixed;
  height: 100%;
  top: 0;
  overflow: hidden;
}

.au-focus-id {
  font-size: 1.4rem;
  color: var(--au-color-primary);
  font-weight: 600;
}

.desktop,
.only-desktop {
  display: none;
}

.mobile,
.only-mobile {
  display: block;
}

@media (max-width: 599.98px) {
  .page-wrapper {
    margin-left: 0 !important;
  }
}

[hidden],
.au-hidden,
.au-hide {
  animation: ani-d-none 250ms ease-in both !important;
  display: none !important;
}

.au-scroll,
.overflow-scroll {
  scrollbar-width: none;
}
.au-scroll::-webkit-scrollbar,
.overflow-scroll::-webkit-scrollbar {
  width: 0;
  height: 0;
  background: transparent;
}

.au-scroll-x {
  overflow-x: auto;
  overflow-y: hidden;
  scrollbar-color: var(--au-color-secondary-light) transparent;
}
.au-scroll-x::-webkit-scrollbar {
  height: 7px;
}
.au-scroll-x::-webkit-scrollbar-track {
  border-radius: 10px;
  background-color: var(--au-color-transparent);
}
.au-scroll-x::-webkit-scrollbar-thumb {
  background: var(--au-color-secondary-light);
  border-radius: 10px;
}

.au-scroll-y {
  overflow-y: auto;
  overflow-x: hidden;
  scrollbar-color: var(--au-color-secondary-light) var(--au-color-white);
}
.au-scroll-y::-webkit-scrollbar {
  width: 5px;
}
.au-scroll-y::-webkit-scrollbar-track {
  border-radius: 5px;
  background-color: var(--au-color-white);
}
.au-scroll-y::-webkit-scrollbar-thumb {
  background: var(--au-color-secondary-light);
  border-radius: 5px;
}

@media (min-width: 600px) {
  .desktop,
.only-desktop {
    display: block;
  }
  .mobile,
.only-mobile {
    display: none;
  }
}
.au-overflow--hidden {
  overflow: hidden;
}
.au-overflow--auto {
  overflow: auto;
}

.au-position-static {
  position: static !important;
}

.au-input-label-text {
  color: var(--au-color-secondary-medium);
  font-weight: 400;
}

.au-footer {
  padding: 15px 20px;
  font-size: 1.2rem;
}

.au-page-title {
  color: var(--au-color-primary);
  font-weight: 1.2rem;
  white-space: nowrap;
}

.au-tool-tip {
  display: none;
  position: absolute;
  z-index: 999;
  /* stylelint-disable-next-line color-plugin/variable */
  background-color: rgba(0, 0, 0, 0.75);
  padding: 5px;
  border-radius: 2px;
  font-family: sans-serif;
  color: var(--au-color-white);
  pointer-events: none;
  box-shadow: 0 0 5px #999;
  min-width: 50px;
  align-items: center;
  justify-content: center;
}
.au-tool-tip--chart {
  min-width: 100px;
  max-width: 200px;
}

.au-cursor--pointer {
  cursor: pointer;
}
.au-cursor--default {
  cursor: default !important;
}
.au-cursor--no-drop {
  cursor: no-drop;
}

.au-vertical--top {
  vertical-align: top !important;
}

.au-display--none {
  display: none !important;
}
.au-display--block {
  display: block;
}

/* stylelint-enable selector-max-type */
.au-inline-block {
  display: inline-block;
}

.au-text-center {
  text-align: center;
}

.au-progess-bar {
  max-width: 120px;
}
.au-progess-bar__text {
  font-size: 1.2rem;
  line-height: 1;
  margin-bottom: 2px;
  color: var(--au-color-black);
  /* stylelint-disable-next-line no-descending-specificity */
}
.au-progess-bar__text span {
  font-weight: 500;
}
.au-progess-bar .progressbar {
  width: 100%;
  border: 0;
  border-radius: 5px;
}
.au-progess-bar .progress {
  width: 100%;
  height: 2px;
  border-radius: 5px;
  background-color: var(--au-color-white-light-7);
}
.au-progess-bar .progress::after {
  display: none;
}
.au-progess-bar .progress-bar {
  background-color: var(--au-color-primary);
  border: 0;
  border-width: 0;
  border-radius: 5px;
}

.au-nodata {
  min-height: 200px;
  align-items: center;
  display: flex;
  justify-content: center;
  color: var(--au-color-secondary-light);
  font-weight: 400;
  font-size: 1.6rem;
  flex-direction: column;
}
.au-nodata .au-calender-list__no-data-icon {
  width: 80px;
  height: 89px;
  display: block;
  margin-bottom: 8px;
}
.au-nodata .au-table-list__no-data-icon {
  width: 80px;
  height: auto;
  display: block;
  margin-bottom: 8px;
}

/* stylelint-disable selector-class-pattern */
@font-face {
  font-family: au-icon-font;
  src: url("/assets/au-iconfont/au-icons.eot?llwpox");
  src: url("/assets/au-iconfont/au-icons.eot?llwpox#iefix") format("embedded-opentype"), url("/assets/au-iconfont/au-icons.ttf?llwpox") format("truetype"), url("/assets/au-iconfont/au-icons.woff?llwpox") format("woff"), url("/assets/au-iconfont/au-icons.svg?llwpox#icomoon") format("svg");
  font-weight: normal;
  font-style: normal;
  font-display: block;
}
.ultra-thin-italic {
  font-family: "Poppins", sans-serif;
  font-weight: 100;
  font-style: italic;
  font-stretch: normal;
}

.light-italic {
  font-family: "Poppins", sans-serif;
  font-weight: 300;
  font-style: italic;
  font-stretch: normal;
}

.thin {
  font-family: "Poppins", sans-serif;
  font-weight: 200;
  font-style: normal;
  font-stretch: normal;
}

.regular {
  font-family: "Poppins", sans-serif;
  font-weight: 400;
  font-style: normal;
  font-stretch: normal;
}

.ultra-thin {
  font-family: "Poppins", sans-serif;
  font-weight: 100;
  font-style: normal;
  font-stretch: normal;
}

.thin-italic {
  font-family: "Poppins", sans-serif;
  font-weight: 200;
  font-style: italic;
  font-stretch: normal;
}

.bold {
  font-family: "Poppins", sans-serif;
  font-weight: 700;
  font-style: normal;
  font-stretch: normal;
}

.italic {
  font-family: "Poppins", sans-serif;
  font-weight: 400;
  font-style: italic;
  font-stretch: normal;
}

.light {
  font-family: "Poppins", sans-serif;
  font-weight: 300;
  font-style: normal;
  font-stretch: normal;
}

.bold-italic {
  font-family: "Poppins", sans-serif;
  font-weight: 700;
  font-style: italic;
  font-stretch: normal;
}

[class^=au-icon-],
[class*=" au-icon-"] {
  /* use !important to prevent issues with browser extensions that change fonts */
  /* stylelint-disable-next-line font-family-no-missing-generic-family-keyword */
  font-family: au-icon-font !important;
  speak: never;
  font-style: normal;
  font-weight: 500;
  font-variant: normal;
  text-transform: none;
  line-height: 1;
  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.au-icon--drag::before {
  content: "쀂";
  color: var(--au-color-currentcolor);
}

.au-icon--info::before {
  content: "퀁";
  color: var(--au-color-currentcolor);
}

.au-icon--mail::before {
  content: "퀃";
  color: var(--au-color-currentcolor);
}

.au-icon--option::before {
  content: "퀄";
  color: var(--au-color-currentcolor);
}

.au-icon--pin::before {
  content: "퀅";
  color: var(--au-color-currentcolor);
}

.au-icon--copy::before {
  content: "퀆";
  color: var(--au-color-currentcolor);
}

.au-icon--minus::before {
  content: "퀇";
  color: var(--au-color-currentcolor);
}

.au-icon--signature::before {
  content: "퀐";
  color: var(--au-color-currentcolor);
}

.au-icon--dot::before {
  content: "퀑";
  color: var(--au-color-currentcolor);
}

.au-icon--tick::before {
  content: "퀒";
  color: var(--au-color-currentcolor);
}

.au-icon--hash::before {
  content: "퀓";
  color: var(--au-color-currentcolor);
}

.au-icon--clean::before {
  content: "퀕";
  color: var(--au-color-currentcolor);
}

.au-icon--add-data::before {
  content: "퀖";
  color: var(--au-color-currentcolor);
}

.au-icon--arrow-bottom::before {
  content: "퀗";
  color: var(--au-color-currentcolor);
}

.au-icon--arrow-left::before {
  content: "퀘";
  color: var(--au-color-currentcolor);
}

.au-icon--arrow-right::before {
  content: "퀙";
  color: var(--au-color-currentcolor);
}

.au-icon--arrow-top::before {
  content: "퀠";
  color: var(--au-color-currentcolor);
}

.au-icon--change::before {
  content: "퀡";
  color: var(--au-color-currentcolor);
}

.au-icon--cloud-upload::before {
  content: "퀢";
  color: var(--au-color-currentcolor);
}

.au-icon--file-open::before {
  content: "퀤";
  color: var(--au-color-currentcolor);
}

.au-icon--instance::before {
  content: "퀥";
  color: var(--au-color-currentcolor);
}

.au-icon--ml::before {
  content: "퀦";
  color: var(--au-color-currentcolor);
}

.au-icon--none::before {
  content: "퀧";
  color: var(--au-color-currentcolor);
}

.au-icon--reactivate::before {
  content: "퀨";
  color: var(--au-color-currentcolor);
}

.au-icon--read-tick::before {
  content: "퀩";
  color: var(--au-color-currentcolor);
}

.au-icon--schedule::before {
  content: "퀰";
  color: var(--au-color-currentcolor);
}

.au-icon--time::before {
  content: "퀱";
  color: var(--au-color-currentcolor);
}

.au-icon--user::before {
  content: "퀲";
  color: var(--au-color-currentcolor);
}

.au-icon--object-management::before {
  content: "퀳";
  color: var(--au-color-currentcolor);
}

.au-icon--sort-text::before {
  content: "퀴";
  color: var(--au-color-currentcolor);
}

.au-icon--redo::before {
  content: "퀵";
  color: var(--au-color-currentcolor);
}

.au-icon--undo::before {
  content: "퀶";
  color: var(--au-color-currentcolor);
}

.au-icon--policy-management::before {
  content: "퀷";
  color: var(--au-color-currentcolor);
}

.au-icon--business-continuity::before {
  content: "퀹";
  color: var(--au-color-currentcolor);
}

.au-icon--globe::before {
  content: "큁";
  color: var(--au-color-currentcolor);
}

.au-icon--abc::before {
  content: "턔";
  color: var(--au-color-currentcolor);
}

.au-icon--file::before {
  content: "턣";
  color: var(--au-color-currentcolor);
}

.au-icon--top-icon::before {
  content: "\e900";
  color: var(--au-color-currentcolor);
}

.au-icon--user-management::before {
  content: "\e901";
  color: var(--au-color-currentcolor);
}

.au-icon--human-resources::before {
  content: "\e903";
  color: var(--au-color-currentcolor);
}

.au-icon--inventory::before {
  content: "\e904";
  color: var(--au-color-currentcolor);
}

.au-icon--left-icon::before {
  content: "\e905";
  color: var(--au-color-currentcolor);
}

.au-icon--link-arrow::before {
  content: "\e906";
  color: var(--au-color-currentcolor);
}

.au-icon--logistics::before {
  content: "\e907";
  color: var(--au-color-currentcolor);
}

.au-icon--loglout::before {
  content: "\e908";
  color: var(--au-color-currentcolor);
}

.au-icon--logo-configure::before {
  content: "\e909";
  color: var(--au-color-currentcolor);
}

.au-icon--manufacturing::before {
  content: "\e90a";
  color: var(--au-color-currentcolor);
}

.au-icon--mark-read::before {
  content: "\e90b";
  color: var(--au-color-currentcolor);
}

.au-icon--modules::before {
  content: "\e90d";
  color: var(--au-color-currentcolor);
}

.au-icon--my-time::before {
  content: "\e90e";
  color: var(--au-color-currentcolor);
}

.au-icon--notification::before {
  content: "\e90f";
  color: var(--au-color-currentcolor);
}

.au-icon--object-manage::before {
  content: "\e910";
  color: var(--au-color-currentcolor);
}

.au-icon--overview::before {
  content: "\e911";
  color: var(--au-color-currentcolor);
}

.au-icon--procurement::before {
  content: "\e912";
  color: var(--au-color-currentcolor);
}

.au-icon--profile::before {
  content: "\e913";
  color: var(--au-color-currentcolor);
}

.au-icon--reply::before {
  content: "\e914";
  color: var(--au-color-currentcolor);
}

.au-icon--right-icon::before {
  content: "\e915";
  color: var(--au-color-currentcolor);
}

.au-icon--risk-aggregation::before {
  content: "\e916";
  color: var(--au-color-currentcolor);
}

.au-icon--risk-controls::before {
  content: "\e917";
  color: var(--au-color-currentcolor);
}

.au-icon--risk-management::before {
  content: "\e918";
  color: var(--au-color-currentcolor);
}

.au-icon--risk-universe::before {
  content: "\e919";
  color: var(--au-color-currentcolor);
}

.au-icon--sales::before {
  content: "\e91a";
  color: var(--au-color-currentcolor);
}

.au-icon--security::before {
  content: "\e91c";
  color: var(--au-color-currentcolor);
}

.au-icon--sessions-history::before {
  content: "\e91d";
  color: var(--au-color-currentcolor);
}

.au-icon--snapshot::before {
  content: "\e91f";
  color: var(--au-color-currentcolor);
}

.au-icon--teams-time::before {
  content: "\e920";
  color: var(--au-color-currentcolor);
}

.au-icon--time-management::before {
  content: "\e921";
  color: var(--au-color-currentcolor);
}

.au-icon--time-management-1::before {
  content: "\e922";
  color: var(--au-color-currentcolor);
}

.au-icon--down-icon::before {
  content: "\e923";
  color: var(--au-color-currentcolor);
}

.au-icon--edit-home::before {
  content: "\e924";
  color: var(--au-color-currentcolor);
}

.au-icon--administration::before {
  content: "\e926";
  color: var(--au-color-currentcolor);
}

.au-icon--analytics-scenarios::before {
  content: "\e927";
  color: var(--au-color-currentcolor);
}

.au-icon--ask-auraa::before {
  content: "\e928";
  color: var(--au-color-currentcolor);
}

.au-icon--audit-analytics::before {
  content: "\e929";
  color: var(--au-color-currentcolor);
}

.au-icon--audit-issue-tracker::before {
  content: "\e92a";
  color: var(--au-color-currentcolor);
}

.au-icon--audit-management::before {
  content: "\e92b";
  color: var(--au-color-currentcolor);
}

.au-icon--audit-planning::before {
  content: "\e92c";
  color: var(--au-color-currentcolor);
}

.au-icon--audit-reporting::before {
  content: "\e92d";
  color: var(--au-color-currentcolor);
}

.au-icon--back-home::before {
  content: "\e92e";
  color: var(--au-color-currentcolor);
}

.au-icon--budget-management::before {
  content: "\e92f";
  color: var(--au-color-currentcolor);
}

.au-icon--budget-management-1::before {
  content: "\e930";
  color: var(--au-color-currentcolor);
}

.au-icon--camera::before {
  content: "\e932";
  color: var(--au-color-currentcolor);
}

.au-icon--connection::before {
  content: "\e933";
  color: var(--au-color-currentcolor);
}

.au-icon--education::before {
  content: "\e934";
  color: var(--au-color-currentcolor);
}

.au-icon--file-download::before {
  content: "\e935";
  color: var(--au-color-currentcolor);
}

.au-icon--file-history::before {
  content: "\e936";
  color: var(--au-color-currentcolor);
}

.au-icon--flag::before {
  content: "\e937";
  color: var(--au-color-currentcolor);
}

.au-icon--flag-selected::before {
  content: "\e938";
  color: var(--au-color-currentcolor);
}

.au-icon--job::before {
  content: "\e939";
  color: var(--au-color-currentcolor);
}

.au-icon--read::before {
  content: "\e93a";
  color: var(--au-color-currentcolor);
}

.au-icon--sand-clock::before {
  content: "\e93b";
  color: var(--au-color-currentcolor);
}

.au-icon--time-left::before {
  content: "\e93c";
  color: var(--au-color-currentcolor);
}

.au-icon--timer::before {
  content: "\e93d";
  color: var(--au-color-currentcolor);
}

.au-icon--trophy::before {
  content: "\e93e";
  color: var(--au-color-currentcolor);
}

.au-icon--sort-za::before {
  content: "\e93f";
  color: var(--au-color-currentcolor);
}

.au-icon--menu-manage::before {
  content: "\e940";
  color: var(--au-color-currentcolor);
}

.au-icon--celery-manage::before {
  content: "\e946";
  color: var(--au-color-currentcolor);
}

.au-icon--clients::before {
  content: "\e947";
  color: var(--au-color-currentcolor);
}

.au-icon--dashboards::before {
  content: "\e948";
  color: var(--au-color-currentcolor);
}

.au-icon--delete::before {
  content: "\e949";
  color: var(--au-color-currentcolor);
}

.au-icon--edit::before {
  content: "\e94a";
  color: var(--au-color-currentcolor);
}

.au-icon--edit-box::before {
  content: "\e94b";
  color: var(--au-color-currentcolor);
}

.au-icon--expand-down::before {
  content: "\e94c";
  color: var(--au-color-currentcolor);
}

.au-icon--features::before {
  content: "\e94d";
  color: var(--au-color-currentcolor);
}

.au-icon--feedback::before {
  content: "\e94e";
  color: var(--au-color-currentcolor);
}

.au-icon--feedback-1::before {
  content: "\e94f";
  color: var(--au-color-currentcolor);
}

.au-icon--filter::before {
  content: "\e950";
  color: var(--au-color-currentcolor);
}

.au-icon--filter-mob::before {
  content: "\e951";
  color: var(--au-color-currentcolor);
}

.au-icon--finance-accounts::before {
  content: "\e952";
  color: var(--au-color-currentcolor);
}

.au-icon--actions::before {
  content: "\e954";
  color: var(--au-color-currentcolor);
}

.au-icon--actions-collapse::before {
  content: "\e955";
  color: var(--au-color-currentcolor);
}

.au-icon--help::before {
  content: "\e956";
  color: var(--au-color-currentcolor);
}

.au-icon--close::before {
  content: "\e957";
  color: var(--au-color-currentcolor);
}

.au-icon--plus::before {
  content: "\e958";
  color: var(--au-color-currentcolor);
}

.au-icon--down-fill::before {
  content: "򠀁";
  color: var(--au-color-currentcolor);
}

.au-icon--left-fill::before {
  content: "򠀂";
  color: var(--au-color-currentcolor);
}

.au-icon--right-fill::before {
  content: "򠀃";
  color: var(--au-color-currentcolor);
}

.au-icon--top-fill::before {
  content: "򠀄";
  color: var(--au-color-currentcolor);
}

.au-icon--calendar::before {
  content: "򠀅";
  color: var(--au-color-currentcolor);
}

.au-icon--attach::before {
  content: "򠀆";
  color: var(--au-color-currentcolor);
}

.au-icon--chat::before {
  content: "򠀇";
  color: var(--au-color-currentcolor);
}

.au-icon--database::before {
  content: "򠀗";
  color: var(--au-color-currentcolor);
}

.au-icon--down::before {
  content: "򠀘";
  color: var(--au-color-currentcolor);
}

.au-icon--export::before {
  content: "򠀙";
  color: var(--au-color-currentcolor);
}

.au-icon--left::before {
  content: "򠀟";
  color: var(--au-color-currentcolor);
}

.au-icon--hide::before {
  content: "򠀠";
  color: var(--au-color-currentcolor);
}

.au-icon--import::before {
  content: "򠀡";
  color: var(--au-color-currentcolor);
}

.au-icon--link::before {
  content: "򠀣";
  color: var(--au-color-currentcolor);
}

.au-icon--lock::before {
  content: "򠀤";
  color: var(--au-color-currentcolor);
}

.au-icon--message::before {
  content: "򠀥";
  color: var(--au-color-currentcolor);
}

.au-icon--print::before {
  content: "򠀦";
  color: var(--au-color-currentcolor);
}

.au-icon--refresh::before {
  content: "򠀧";
  color: var(--au-color-currentcolor);
}

.au-icon--reload::before {
  content: "򠀨";
  color: var(--au-color-currentcolor);
}

.au-icon--right::before {
  content: "򠀩";
  color: var(--au-color-currentcolor);
}

.au-icon--search::before {
  content: "򠀰";
  color: var(--au-color-currentcolor);
}

.au-icon--settings::before {
  content: "򠀱";
  color: var(--au-color-currentcolor);
}

.au-icon--sort-grid::before {
  content: "򠀲";
  color: var(--au-color-currentcolor);
}

.au-icon--sort-list::before {
  content: "򠀳";
  color: var(--au-color-currentcolor);
}

.au-icon--top::before {
  content: "򠀵";
  color: var(--au-color-currentcolor);
}

.au-icon--typing::before {
  content: "򠀶";
  color: var(--au-color-currentcolor);
}

.au-icon--unlock::before {
  content: "򠀷";
  color: var(--au-color-currentcolor);
}

.au-icon--view::before {
  content: "򠀸";
  color: var(--au-color-currentcolor);
}

.au-icon--warning::before {
  content: "򠀹";
  color: var(--au-color-currentcolor);
}

.au-icon--zoom-in::before {
  content: "򠁀";
  color: var(--au-color-currentcolor);
}

.au-icon--zoom-out::before {
  content: "򠁁";
  color: var(--au-color-currentcolor);
}

.au-icon--compliance::before {
  content: "豈";
  color: var(--au-color-currentcolor);
}

.au-icon--assessment::before {
  content: "更";
  color: var(--au-color-currentcolor);
}

.au-icon--incident-reporting::before {
  content: "車";
  color: var(--au-color-currentcolor);
}

.au-icon--down-line::before {
  content: "賈";
  color: var(--au-color-currentcolor);
}

.au-icon--left-line::before {
  content: "滑";
  color: var(--au-color-currentcolor);
}

.au-icon--right-line::before {
  content: "串";
  color: var(--au-color-currentcolor);
}

.au-icon--top-bottom-change::before {
  content: "句";
  color: var(--au-color-currentcolor);
}

.au-icon--top-line::before {
  content: "龜";
  color: var(--au-color-currentcolor);
}

.au-icon--list-view::before {
  content: "龜";
  color: var(--au-color-currentcolor);
}

.au-icon--run::before {
  content: "蘿";
  color: var(--au-color-currentcolor);
}

.au-icon--h-bar-chart-1::before {
  content: "\e90c";
  color: var(--au-color-currentcolor);
}

.au-icon--h-bar-chart-2::before {
  content: "\e95d";
  color: var(--au-color-currentcolor);
}

.au-icon--round-chart::before {
  content: "\e91e";
  color: var(--au-color-currentcolor);
}

.au-icon--half-round-chart::before {
  content: "\e925";
  color: var(--au-color-currentcolor);
}

.au-icon--table-view::before {
  content: "\e931";
  color: var(--au-color-currentcolor);
}

.au-icon--round-loader::before {
  content: "\e941";
  color: var(--au-color-currentcolor);
}

.au-icon--horizontal-bar-cart::before {
  content: "\e942";
  color: var(--au-color-currentcolor);
}

.au-icon--dasboard-card::before {
  content: "\e943";
  color: var(--au-color-currentcolor);
}

.au-icon--move::before {
  content: "\e944";
  color: var(--au-color-currentcolor);
}

.au-icon--next-tab::before {
  content: "\e945";
  color: var(--au-color-currentcolor);
}

.au-icon--graph::before {
  content: "\e959";
  color: var(--au-color-currentcolor);
}

.au-icon--filter-grid::before {
  content: "\e95a";
  color: var(--au-color-currentcolor);
}

.au-icon--file-upload::before {
  content: "\e95f";
  color: var(--au-color-currentcolor);
}

.au-icon--approval-requst::before {
  content: "\e95e";
  color: var(--au-color-currentcolor);
}

.au-icon--requst::before {
  content: "\e960";
  color: var(--au-color-currentcolor);
}

.au-icon--view-template::before {
  content: "\e961";
  color: var(--au-color-currentcolor);
}

.au-icon--move-square::before {
  content: "\e962";
  color: var(--au-color-currentcolor);
}

.au-icon--one-line::before {
  content: "\e963";
  color: var(--au-color-currentcolor);
}

.au-icon--swich-arrow::before {
  content: "\e964";
  color: var(--au-color-currentcolor);
}

.au-icon--two-line::before {
  content: "\e965";
  color: var(--au-color-currentcolor);
}

.au-icon--trend-arrow::before {
  content: "\e966";
  color: var(--au-color-currentcolor);
}

.au-icon--search-shot::before {
  content: "\e91b";
  color: var(--au-color-currentcolor);
}

.au-icon--assisted-modelling::before {
  content: "\e967";
  color: var(--au-color-currentcolor);
}

.au-icon-pdf-generate::before {
  content: "\e968";
  color: var(--au-color-currentcolor);
}

.au-row__gap > [class^=col-] {
  margin-bottom: 15px;
}

.au-gap--1 {
  column-gap: 10px;
}

.au-flex {
  display: flex;
}
.au-flex--right {
  justify-content: flex-end;
}
.au-flex--left {
  justify-content: flex-start;
}
.au-flex--center {
  justify-content: center;
}
.au-flex--space-between, .au-flex--left-right {
  justify-content: space-between;
}
.au-flex--start {
  justify-content: start;
}
.au-flex--stretch {
  justify-content: stretch;
}
.au-flex--inherit {
  justify-content: inherit;
}
.au-flex--top {
  align-items: flex-start;
}
.au-flex--bottom {
  align-items: flex-end;
}
.au-flex--middle {
  align-items: center;
}
.au-flex--vh-center {
  justify-content: center;
  align-items: center;
}
.au-flex--dr {
  flex-direction: row;
}
.au-flex--dc {
  flex-direction: column;
}
.au-flex--wrap {
  flex-wrap: wrap;
}
.au-flex--nowrap {
  flex-wrap: nowrap;
}
.au-flex--flex-1 {
  flex: 1;
}
.au-flex--self-center {
  align-self: center;
}
.au-flex--self-end {
  align-self: flex-end;
}

.au-block {
  display: block !important;
}

.au-align--left {
  text-align: left;
}
.au-align--right {
  text-align: right;
}
.au-align--center {
  text-align: center;
}

.au-color--primary, .au-color--blue {
  color: var(--au-color-primary) !important;
}
.au-color--primary-light, .au-color--blue-light {
  color: var(--au-color-primary-light) !important;
}
.au-color--primary-dark, .au-color--blue-dark {
  color: var(--au-color-primary-dark) !important;
}
.au-color--secondary, .au-color--gray {
  color: var(--au-color-secondary) !important;
}
.au-color--secondary-dark, .au-color--gray-dark {
  color: var(--au-color-secondary-dark) !important;
}
.au-color--secondary-medium {
  color: var(--au-color-secondary-medium) !important;
}
.au-color--secondary-light {
  color: var(--au-color-secondary-light) !important;
}
.au-color--secondary-light-2 {
  color: var(--au-color-secondary-light-2) !important;
}
.au-color--black {
  color: var(--au-color-black) !important;
}
.au-color--black-50 {
  /* stylelint-disable-next-line color-plugin/variable, color-function-notation, alpha-value-notation */
  color: rgba(0, 0, 0, 0.5);
}
.au-color--white {
  color: var(--au-color-white) !important;
}
.au-color--green {
  color: var(--au-color-green) !important;
}
.au-color--red {
  color: var(--au-color-red) !important;
}
.au-color--yellow {
  color: var(--au-color-yellow) !important;
}
.au-color--orange {
  color: var(--au-color-orange) !important;
}
.au-color--q1 {
  color: var(--au-color-q1) !important;
}
.au-color--q2 {
  color: var(--au-color-q2) !important;
}
.au-color--q3 {
  color: var(--au-color-q3) !important;
}
.au-color--q4 {
  color: var(--au-color-q4) !important;
}
.au-color--q5 {
  color: var(--au-color-q5) !important;
}
.au-color--q6 {
  color: var(--au-color-q6) !important;
}
.au-color--q7 {
  color: var(--au-color-q7) !important;
}
.au-color--q8 {
  color: var(--au-color-q8) !important;
}
.au-color--d1 {
  color: var(--au-color-d1) !important;
}
.au-color--d2 {
  color: var(--au-color-d2) !important;
}
.au-color--d3 {
  color: var(--au-color-d3) !important;
}
.au-color--d4 {
  color: var(--au-color-d4) !important;
}
.au-color--d5 {
  color: var(--au-color-d5) !important;
}
.au-color--d6 {
  color: var(--au-color-d6) !important;
}
.au-color--d7 {
  color: var(--au-color-d7) !important;
}
.au-color--s1 {
  color: var(--au-color-s1) !important;
}
.au-color--s2 {
  color: var(--au-color-s2) !important;
}
.au-color--s3 {
  color: var(--au-color-s3) !important;
}
.au-color--s4 {
  color: var(--au-color-s4) !important;
}
.au-color--s5 {
  color: var(--au-color-s5) !important;
}
.au-color--s6 {
  color: var(--au-color-s6) !important;
}
.au-color--s7 {
  color: var(--au-color-s7) !important;
}

.au-bg--primary, .au-bg--blue {
  background-color: var(--au-color-primary) !important;
}
.au-bg--primary-light, .au-bg--blue-light {
  background-color: var(--au-color-primary-light) !important;
}
.au-bg--primary-dark, .au-bg--blue-dark {
  background-color: var(--au-color-primary-dark) !important;
}
.au-bg--secondary, .au-bg--gray {
  background-color: var(--au-color-secondary) !important;
}
.au-bg--secondary-dark, .au-bg--gray-dark {
  background-color: var(--au-color-secondary-dark) !important;
}
.au-bg--secondary-medium {
  background-color: var(--au-color-secondary-medium) !important;
}
.au-bg--secondary-light {
  background-color: var(--au-color-secondary-light) !important;
}
.au-bg--secondary-light-2 {
  background-color: var(--au-color-secondary-light-2) !important;
}
.au-bg--black {
  background-color: var(--au-color-black) !important;
}
.au-bg--white {
  background-color: var(--au-color-white) !important;
}
.au-bg--green {
  background-color: var(--au-color-green) !important;
}
.au-bg--red {
  background-color: var(--au-color-red) !important;
}
.au-bg--yellow {
  background-color: var(--au-color-yellow) !important;
}
.au-bg--orange {
  background-color: var(--au-color-orange) !important;
}
.au-bg--q1 {
  background-color: var(--au-color-q1) !important;
}
.au-bg--q2 {
  background-color: var(--au-color-q2) !important;
}
.au-bg--q3 {
  background-color: var(--au-color-q3) !important;
}
.au-bg--q4 {
  background-color: var(--au-color-q4) !important;
}
.au-bg--q5 {
  background-color: var(--au-color-q5) !important;
}
.au-bg--q6 {
  background-color: var(--au-color-q6) !important;
}
.au-bg--q7 {
  background-color: var(--au-color-q7) !important;
}
.au-bg--q8 {
  color: var(--au-color-q8) !important;
}
.au-bg--d1 {
  background-color: var(--au-color-d1) !important;
}
.au-bg--d2 {
  background-color: var(--au-color-d2) !important;
}
.au-bg--d3 {
  background-color: var(--au-color-d3) !important;
}
.au-bg--d4 {
  background-color: var(--au-color-d4) !important;
}
.au-bg--d5 {
  background-color: var(--au-color-d5) !important;
}
.au-bg--d6 {
  background-color: var(--au-color-d6) !important;
}
.au-bg--d7 {
  background-color: var(--au-color-d7) !important;
}
.au-bg--s1 {
  background-color: var(--au-color-s1) !important;
}
.au-bg--s2 {
  background-color: var(--au-color-s2) !important;
}
.au-bg--s3 {
  background-color: var(--au-color-s3) !important;
}
.au-bg--s4 {
  background-color: var(--au-color-s4) !important;
}
.au-bg--s5 {
  background-color: var(--au-color-s5) !important;
}
.au-bg--s6 {
  background-color: var(--au-color-s6) !important;
}
.au-bg--s7 {
  background-color: var(--au-color-s7) !important;
}

.au-fs--1 {
  font-size: 1rem;
}
.au-fs--2 {
  font-size: 1.2rem;
}
.au-fs--3 {
  font-size: 1.4rem;
}
.au-fs--4 {
  font-size: 1.6rem;
}
.au-fs--5 {
  font-size: 1.8rem;
}
.au-fs--6 {
  font-size: 2rem;
}
.au-fs--7 {
  font-size: 2.2rem;
}
.au-fs--8 {
  font-size: 2.4rem;
}
.au-fs--9 {
  font-size: 2.6rem;
}
.au-fs--10 {
  font-size: 2.8rem;
}
.au-fs--11 {
  font-size: 3rem;
}
.au-fs--12 {
  font-size: 3.2rem;
}
.au-fs--13 {
  font-size: 3.4rem;
}

.au-fw--bold {
  font-weight: 700;
}

.au-fw--semi {
  font-weight: 600;
}

.au-fw--semi {
  font-weight: 500;
}

.au-fw--normal {
  font-weight: 400;
}

.au-fw--light {
  font-weight: 300;
}

.au-fw--thin {
  font-weight: 200;
}

:root {
  --space-0: 0;
  --space-1: 5px;
  --space-2: 10px;
  --space-3: 15px;
  --space-4: 20px;
  --space-5: 25px;
}

.au-m--0 {
  margin: var(--space-0) !important;
}
.au-m--1 {
  margin: var(--space-1) !important;
}
.au-m--2 {
  margin: var(--space-2) !important;
}
.au-m--3 {
  margin: var(--space-3) !important;
}
.au-m--4 {
  margin: var(--space-4) !important;
}
.au-m--5 {
  margin: var(--space-5) !important;
}
.au-m--auto {
  margin: auto;
}
.au-mt--0 {
  margin-top: var(--space-0) !important;
}
.au-mt--1 {
  margin-top: var(--space-1) !important;
}
.au-mt--2 {
  margin-top: var(--space-2) !important;
}
.au-mt--3 {
  margin-top: var(--space-3) !important;
}
.au-mt--4 {
  margin-top: var(--space-4) !important;
}
.au-mt--5 {
  margin-top: var(--space-5) !important;
}
.au-mt--auto {
  margin-top: auto;
}
.au-mr--0 {
  margin-right: var(--space-0) !important;
}
.au-mr--1 {
  margin-right: var(--space-1) !important;
}
.au-mr--2 {
  margin-right: var(--space-2) !important;
}
.au-mr--3 {
  margin-right: var(--space-3) !important;
}
.au-mr--4 {
  margin-right: var(--space-4) !important;
}
.au-mr--5 {
  margin-right: var(--space-5) !important;
}
.au-mr--auto {
  margin-right: auto;
}
.au-mb--0 {
  margin-bottom: var(--space-0) !important;
}
.au-mb--1 {
  margin-bottom: var(--space-1) !important;
}
.au-mb--2 {
  margin-bottom: var(--space-2) !important;
}
.au-mb--3 {
  margin-bottom: var(--space-3) !important;
}
.au-mb--4 {
  margin-bottom: var(--space-4) !important;
}
.au-mb--5 {
  margin-bottom: var(--space-5) !important;
}
.au-mb--auto {
  margin-bottom: auto;
}
.au-ml--0 {
  margin-left: var(--space-0) !important;
}
.au-ml--1 {
  margin-left: var(--space-1) !important;
}
.au-ml--2 {
  margin-left: var(--space-2) !important;
}
.au-ml--3 {
  margin-left: var(--space-3) !important;
}
.au-ml--4 {
  margin-left: var(--space-4) !important;
}
.au-ml--5 {
  margin-left: var(--space-5) !important;
}
.au-ml--auto {
  margin-left: auto;
}

.au-p--0 {
  padding: var(--space-0) !important;
}
.au-p--1 {
  padding: var(--space-1) !important;
}
.au-p--2 {
  padding: var(--space-2) !important;
}
.au-p--3 {
  padding: var(--space-3) !important;
}
.au-p--4 {
  padding: var(--space-4) !important;
}
.au-p--5 {
  padding: var(--space-5) !important;
}
.au-pt--0 {
  padding-top: var(--space-0) !important;
}
.au-pt--1 {
  padding-top: var(--space-1) !important;
}
.au-pt--2 {
  padding-top: var(--space-2) !important;
}
.au-pt--3 {
  padding-top: var(--space-3) !important;
}
.au-pt--4 {
  padding-top: var(--space-4) !important;
}
.au-pt--5 {
  padding-top: var(--space-5) !important;
}
.au-pr--0 {
  padding-right: var(--space-0) !important;
}
.au-pr--1 {
  padding-right: var(--space-1) !important;
}
.au-pr--2 {
  padding-right: var(--space-2) !important;
}
.au-pr--3 {
  padding-right: var(--space-3) !important;
}
.au-pr--4 {
  padding-right: var(--space-4) !important;
}
.au-pr--5 {
  padding-right: var(--space-5) !important;
}
.au-pb--0 {
  padding-bottom: var(--space-0) !important;
}
.au-pb--1 {
  padding-bottom: var(--space-1) !important;
}
.au-pb--2 {
  padding-bottom: var(--space-2) !important;
}
.au-pb--3 {
  padding-bottom: var(--space-3) !important;
}
.au-pb--4 {
  padding-bottom: var(--space-4) !important;
}
.au-pb--5 {
  padding-bottom: var(--space-5) !important;
}
.au-pl--0 {
  padding-left: var(--space-0) !important;
}
.au-pl--1 {
  padding-left: var(--space-1) !important;
}
.au-pl--2 {
  padding-left: var(--space-2) !important;
}
.au-pl--3 {
  padding-left: var(--space-3) !important;
}
.au-pl--4 {
  padding-left: var(--space-4) !important;
}
.au-pl--5 {
  padding-left: var(--space-5) !important;
}

.au-gap--0 {
  gap: var(--space-0) !important;
}
.au-gap--1 {
  gap: var(--space-1) !important;
}
.au-gap--2 {
  gap: var(--space-2) !important;
}
.au-gap--3 {
  gap: var(--space-3) !important;
}
.au-gap--4 {
  gap: var(--space-4) !important;
}
.au-gap--5 {
  gap: var(--space-5) !important;
}
.au-gap-col--0 {
  column-gap: var(--space-0) !important;
}
.au-gap-col--1 {
  column-gap: var(--space-1) !important;
}
.au-gap-col--2 {
  column-gap: var(--space-2) !important;
}
.au-gap-col--3 {
  column-gap: var(--space-3) !important;
}
.au-gap-col--4 {
  column-gap: var(--space-4) !important;
}
.au-gap-col--5 {
  column-gap: var(--space-5) !important;
}
.au-gap-row--0 {
  row-gap: var(--space-0) !important;
}
.au-gap-row--1 {
  row-gap: var(--space-1) !important;
}
.au-gap-row--2 {
  row-gap: var(--space-2) !important;
}
.au-gap-row--3 {
  row-gap: var(--space-3) !important;
}
.au-gap-row--4 {
  row-gap: var(--space-4) !important;
}
.au-gap-row--5 {
  row-gap: var(--space-5) !important;
}

@keyframes d-none {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
@keyframes ani-d-none {
  from {
    opacity: 1;
  }
  to {
    opacity: 0;
    display: none;
  }
}
@keyframes ani-d-block {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
    display: block;
  }
}
@keyframes ani-d-flex {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
    display: flex;
  }
}
/* stylelint-disable selector-class-pattern */
[dir=rtl] .au-menu__icon {
  margin-right: 15px;
  margin-left: 0;
}
[dir=rtl] .au-menu__link::before {
  left: auto;
  right: 0;
  border-radius: 50px 0 0 50px !important;
}
[dir=rtl] .au-menu__sub-icon {
  margin-right: 17px !important;
  margin-left: 28px;
}
[dir=rtl] .au-page__action .au-right {
  text-align: left;
}
[dir=rtl] .au-calendar input {
  text-align: right;
}
[dir=rtl] .au-accordion .card .card-header button {
  padding: 20px 25px 20px 20px;
}
[dir=rtl] .au-accordion .accordion-item .accordion-header button {
  padding: 20px 25px 20px 20px !important;
}
[dir=rtl] .au-accordion .accordion-item .accordion-body .au-right {
  margin-right: auto;
  margin-left: 0;
}
[dir=rtl] .au-tab__item .au-tab__dropdown {
  right: auto;
  left: 0;
}
[dir=rtl] .ql-toolbar {
  display: flex;
}
[dir=rtl] .ql-editor {
  direction: rtl;
  text-align: right;
}
[dir=rtl] .ql-editor ol li,
[dir=rtl] .ql-editor ul li {
  padding-right: 1.5em;
  direction: rtl;
}
[dir=rtl] .ql-editor li:not(.ql-direction-rtl)::before {
  margin-left: 0;
}

/* stylelint-disable selector-class-pattern */
.au-card {
  width: 100%;
  height: 100%;
  border: 0;
  /* stylelint-disable-next-line color-function-notation, alpha-value-notation, number-leading-zero */
  box-shadow: 0 0 8px rgba(225, 225, 225, 0.46);
  padding: 20px;
  background: var(--au-color-white);
  display: flex;
  align-items: stretch;
  flex-direction: column;
}
.au-card__body {
  padding: 0;
}
.au-card__header {
  border: 0;
  background: transparent;
  padding: 0;
  margin-bottom: 20px;
}
.au-card__footer {
  margin-top: 5px;
}
.au-card__pagination {
  width: 100%;
}
.au-card__pagination .pagination {
  display: flex;
  gap: 5px;
}
.au-card__pagination .pagination li {
  margin-bottom: 0;
}
.au-card__pagination p {
  font-size: 1.2rem;
}
.au-card__item {
  padding: 40px 0;
  border-top: 1px solid var(--au-color-secondary-light);
}
.au-card__item:first-child {
  padding-top: 0;
  border-top: none;
}
.au-card__item:last-child {
  padding-bottom: 0;
}
.au-card__item p {
  white-space: normal;
}
.au-card__header, .au-card__body, .au-card__footer {
  width: 100%;
}
.au-card__footer {
  display: flex;
  align-items: flex-end;
  margin-top: auto;
}
.au-card .au-card,
.au-card .au-grid {
  padding: 0 !important;
  box-shadow: none !important;
}
.au-card .au-card.au-style-view,
.au-card .au-grid.au-style-view {
  /* stylelint-disable-next-line color-function-notation, alpha-value-notation, number-leading-zero */
  box-shadow: 0 0 8px rgba(225, 225, 225, 0.46);
  padding: 20px !important;
}
.au-card app-horizontal-stacked-chart {
  display: block;
  height: 318px;
  overflow: auto;
  scrollbar-width: none;
}
.au-card app-horizontal-stacked-chart::-webkit-scrollbar {
  width: 0;
  height: 0;
  background: transparent;
}
.au-card.au-header-card {
  margin-top: -15px;
}

.au-nospace-col {
  width: calc(100% + 40px) !important;
  margin-left: -20px;
  margin-right: -20px;
  margin-bottom: -21px;
}
.au-nospace-col > .row {
  margin-right: 0;
  margin-left: 0;
}
.au-nospace-col div[class^=col] {
  padding: 0 !important;
}
.au-nospace-col .au-card {
  padding: 20px !important;
  background: var(--au-color-secondary-light-2);
  border: 1px solid var(--au-color-secondary-light);
  flex-direction: column;
}
.au-nospace-col .au-card__head {
  min-height: 34px;
}
.au-nospace-col .au-card__footer {
  margin-top: auto;
}
.au-nospace-col .au-card__header {
  padding: 0;
}

@media print {
  .au-card__footer {
    margin-top: 16px;
    padding-bottom: 16px;
    height: 100%;
  }
}
/* stylelint-disable max-nesting-depth */
/* stylelint-disable no-descending-specificity */
/* stylelint-disable no-eol-whitespace */
/* stylelint-disable selector-class-pattern */
/* stylelint-disable custom-property-no-missing-var-function */
/* stylelint-disable color-plugin/variable */
:root {
  --au-color-primary: #0b3a62;
  --au-color-primary-light: #4aafff;
  --au-color-primary-dark: #115caa;
  --au-color-secondary: #555;
  --au-color-secondary-dark: #000;
  --au-color-secondary-medium: #999;
  --au-color-secondary-light: #e1e1e1;
  --au-color-secondary-light-2: #f9f9f9;
  --au-color-secondary-light-3: #ccc;
  --au-color-secondary-light-4: #efefef;
  --au-color-black: #000;
  --au-color-black-1: #0f0f0f;
  --au-color-dark-blue: #011826;
  --au-color-dark-blue-1: #212529;
  --au-color-dark-blue-2: #0c3a62;
  --au-color-dark-blue-3: #1f3953;
  --au-color-white: #fff;
  --au-color-green: #6b6;
  --au-color-green-1: #7dbb42;
  --au-color-green-2: #61dbb5;
  --au-color-green-3: #1dbb99;
  --au-color-red: #e53535;
  --au-color-red-1: #ef6e6e;
  --au-color-red-2: #ad0000;
  --au-color-yellow: #ffa600;
  --au-color-orange: #fdae61;
  --au-color-orange-1: #fcce56;
  --au-color-orange-2: #ffdeb4;
  --au-blue-violet: #8a2be2;
  --au-blue-green: #91d8e4;
  --au-color-gray-light-3: #f2f4f5;
  --au-color-gray-light-2: #f5f5f5;
  --au-color-gray-light-4: #eee;
  --au-color-gray-light-5: #dedbda;
  --au-color-gray-light-6: #d1d1d1;
  --au-color-gray-light-7: #f1f1f1;
  --au-color-gray-light-8: #c4c2c2;
  --au-color-gray-light-9: #f6f6f6;
  --au-color-currentcolor: currentcolor;
  --au-color-grey: #333;
  --au-color-grey2: #323338;
  --au-color-grey3: #5b5b5b;
  --au-color-grey4: #777;
  --au-color-grey5: #ced4da;
  --au-color-grey6: #6c757d;
  --au-color-grey7: #5c5957;
  --au-color-grey8: #575757;
  --au-color-grey9: #2c2c2c;
  --au-color-grey10: #4f5467;
  --au-color-grey11: #e8e8e8;
  --au-color-white-light-4: #eaeef4;
  --au-color-white-light-5: #cde0f0;
  --au-color-white-light-6: #e5e5e5;
  --au-color-white-light-7: #dee2e6;
  --au-color-white-light-8: #dee2e6;
  --au-color-white-light-9: #efefef;
  --au-color-blue: #134c88;
  --au-color-blue-1: #1c8adb;
  --au-color-blue-2: #1f91f2;
  --au-color-blue-3: #337ab7;
  --au-color-blue-4: #2c65ae;
  --au-color-blue-5: #2962ff;
  --au-color-blue-light-1: #a3c8e4;
  --au-color-blue-light-2: #5b8ca5;
  --au-color-blue-light-3: #115caa33;
  --au-color-dark-green: #008000;
  --au-color-q1: #115caa;
  --au-color-q2: #8559b4;
  --au-color-q3: #cf4fa3;
  --au-color-q4: #ff537c;
  --au-color-q5: #ff764b;
  --au-color-q6: #ffa600;
  --au-color-q7: #003b6d;
  --au-color-q8: #4aafff;
  --au-color-q9: #ccd9e9;
  --au-color-q10: #aec4df;
  --au-color-q11: #c9ecce;
  --au-color-d1: #6b6;
  --au-color-d2: #9ecc6c;
  --au-color-d3: #d0dc7a;
  --au-color-d4: #d0dc7a;
  --au-color-d5: #f9b859;
  --au-color-d6: #f27e3a;
  --au-color-d7: #e53535;
  --au-color-d8: #ffbfc1;
  --au-color-s1: #115caa;
  --au-color-s2: #0878bc;
  --au-color-s3: #2a94cb;
  --au-color-s4: #4fb0d7;
  --au-color-s5: #77cae3;
  --au-color-s6: #a0e4f0;
  --au-color-s7: #cafeff;
  --au-color-s8: #b8dbff;
  --au-color-s9: #cde0f0;
  --au-color-s10: #cee8ff;
  --au-color-s11: #e2e9ec;
  --au-color-s12: #ebf5ff;
  --au-color-transparent: transparent;
  --au-theme-white: #fff;
  --au-theme-black: #000;
}

/* stylelint-disable property-no-vendor-prefix */
/* Fixed Spacing Variables */
/* Em Based Spacing Variables */
/* stylelint-disable-next-line color-function-notation, alpha-value-notation */
/* stylelint-disable-next-line color-function-notation, alpha-value-notation */
/* stylelint-disable-next-line scss/dollar-variable-pattern */
/* stylelint-disable selector-class-pattern */
.au-grid {
  width: 100%;
  height: 100%;
  border: 0;
  /* stylelint-disable-next-line color-function-notation, alpha-value-notation, number-leading-zero */
  box-shadow: 0 0 8px rgba(225, 225, 225, 0.46);
  padding: 20px;
  background: var(--au-color-white);
}
.au-grid__body {
  padding: 0;
}
.au-grid__header {
  border: 0;
  background: transparent;
  padding: 0;
  margin-bottom: 20px;
}
.au-grid__footer {
  margin-top: 5px;
}
.au-grid__pagination {
  width: 100%;
}
.au-grid__pagination .pagination {
  display: flex;
  gap: 5px;
}
.au-grid__pagination .pagination li {
  margin-bottom: 0;
}
.au-grid__pagination p {
  font-size: 1.2rem;
}
.au-grid__item {
  padding: 40px 0;
  border-top: 1px solid var(--au-color-secondary-light);
}
.au-grid__item:first-child {
  padding-top: 0;
  border-top: none;
}
.au-grid__item:last-child {
  padding-bottom: 0;
}
.au-grid__item p {
  white-space: normal;
}
.au-grid__body {
  overflow: hidden;
}
.au-grid__table {
  width: 100%;
}
.au-grid__table tr th {
  /* stylelint-disable-next-line alpha-value-notation, color-function-notation */
  border-bottom: 1px solid rgba(0, 0, 0, 0.25);
  font-size: 1.2rem !important;
  font-weight: 400 !important;
  color: var(--au-color-secondary) !important;
}
.au-grid__table tr th,
.au-grid__table tr td {
  padding: 10px 5px !important;
  color: var(--au-color-secondary);
  font-size: 1.4rem;
  border-top: 1px solid #dee2e6;
  vertical-align: middle;
}
.au-grid__table .dropdown.show > .au-icon {
  color: var(--au-color-black);
}
.au-grid__table--border {
  border-spacing: 2px;
  border-collapse: inherit;
}
.au-grid__table--border th {
  background-color: var(--au-color-gray-light-2);
}
.au-grid__table--border th span {
  font-weight: 500;
  color: var(--au-color-black);
  text-transform: capitalize;
}
.au-grid__table--border th,
.au-grid__table--border td {
  border: 1px solid var(--au-color-white-light-7) !important;
  border-radius: 5px;
}
.au-grid__table--border .au-grid__headtitle {
  font-weight: 500;
}
.au-grid__table--border tbody td {
  padding: 0 !important;
}
.au-grid__table--border .au-input {
  height: 30px;
  background-color: transparent;
  border: 0;
}
.au-grid__table--border .au-input:hover {
  background-color: var(--au-color-gray-light-3);
}
.au-grid__table--border .au-input:focus {
  border: 0;
}
.au-grid__table--border-grid {
  border-spacing: 2px;
  border-collapse: inherit;
}
.au-grid__table--border-grid td {
  border: 1px solid #dee2e6 !important;
  border-radius: 1px;
}
.au-grid__table--border-grid th {
  background: var(--au-color-gray-light-3) !important;
  border-left: 1px solid #dee2e6 !important;
  border-right: 1px solid #dee2e6 !important;
}
.au-grid__table--border-grid th span {
  font-weight: 500;
  color: var(--au-color-black);
  text-transform: capitalize;
}
.au-grid__table--border-grid tr:nth-child(even) {
  background: var(--au-color-gray-light-3);
}
.au-grid__table--border-grid .au-grid__headtitle {
  font-weight: 500;
}
.au-grid__table--border-grid tbody td {
  padding: 25 !important;
}
.au-grid__table--border-grid .au-input {
  height: 30px;
  background-color: transparent;
  border: 0;
}
.au-grid__table--border-grid .au-input:focus {
  border: 0;
}
.au-grid__table td.au-link {
  cursor: default;
}
.au-grid__table td.au-link > div {
  cursor: pointer;
  display: inline-block;
}
.au-grid__select {
  width: 100px;
}
.au-grid__select select {
  width: 100%;
}
.au-grid__search {
  width: calc(100% - 100px);
  border-radius: 5px;
  border: 1px solid #e1e1e1;
  position: relative;
}
.au-grid__search input[placeholder=Search], .au-grid__search input[placeholder=search] {
  padding-right: 50px;
  background: url("/assets/images/svg/search.svg") no-repeat;
  background-size: 15px;
  background-position: 98% 12px;
}
.au-grid__search .form-control {
  height: 34px;
  border-radius: 0;
  border: none;
}
.au-grid__search .search-icon {
  position: absolute;
  width: 15px;
  height: 15px;
  right: 10px;
  top: 11px;
  display: none;
}
.au-grid__table-move {
  display: flex;
  align-items: center;
  background-color: var(--au-color-gray-light-3);
  cursor: move;
}
.au-grid__table-move-icon {
  padding: 8px 4px;
  background-color: var(--au-color-s11);
  color: var(--au-color-white);
}
.au-grid__table-block {
  width: 100%;
  border-spacing: 3px;
  border-collapse: inherit;
}
.au-grid__table-block tr th {
  font-size: 1.2rem;
  background-color: var(--au-color-gray-light-2);
  box-shadow: none;
  color: var(--au-color-black);
  padding: 8px;
  font-weight: 500;
}
.au-grid__table-block tr th span {
  font-weight: 500;
  color: var(--au-color-black);
  text-transform: capitalize;
}
.au-grid__table-block th,
.au-grid__table-block td {
  padding: 10px 5px;
  color: var(--au-color-secondary);
  font-size: 1.2rem;
  border: 1px solid var(--au-color-white-light-7);
  border-radius: 5px;
  vertical-align: middle;
}
.au-grid__table-block tbody td {
  background-color: var(--au-color-white);
  padding: 6px 8px !important;
  color: var(--au-color-black);
  position: relative;
}
.au-grid__table-block .dropdown.show > .au-icon {
  color: var(--au-color-black);
}

.cdk-drag-preview.au-grid__body, .cdk-drag-preview.au-grid__tr {
  border-spacing: 2px;
  border-collapse: inherit;
  background-color: var(--au-color-white);
  display: inline-table;
}
.cdk-drag-preview.au-grid__body td, .cdk-drag-preview.au-grid__tr td {
  border: 1px solid #dee2e6 !important;
  border-radius: 5px;
  min-width: 40px;
}
.cdk-drag-preview.au-grid__body td .au-input, .cdk-drag-preview.au-grid__tr td .au-input {
  height: 30px;
  background-color: transparent;
  border: 0;
}
.cdk-drag-preview.au-grid__body .au-table-more,
.cdk-drag-preview.au-grid__body .au-table-checkbox, .cdk-drag-preview.au-grid__tr .au-table-more,
.cdk-drag-preview.au-grid__tr .au-table-checkbox {
  width: 40px !important;
  min-width: 40px !important;
  vertical-align: middle;
}
.cdk-drag-preview.au-grid__body .au-grid__table-more-option, .cdk-drag-preview.au-grid__tr .au-grid__table-more-option {
  padding-right: 8px;
}
.cdk-drag-preview .au-table-checkbox app-au-grid app-au-table-checkbox-option {
  /* stylelint-disable-next-line rule-empty-line-before */
}
.cdk-drag-preview .au-table-checkbox app-au-grid app-au-table-checkbox-option .au-checkbox label {
  width: 18px;
  height: 18px;
  padding: 0 !important;
}

.au-btn {
  height: fit-content;
  padding: 10px 18px;
  font-size: 1.2rem;
  font-weight: 400;
  text-align: center;
  white-space: nowrap;
  vertical-align: middle;
  user-select: none;
  border-radius: 12px;
  line-height: 100%;
  display: inline-flex;
  gap: 5px;
  cursor: pointer;
  border-width: 1px;
  border-style: solid;
  border-color: var(--au-color-primary);
  transition: all 0.3s;
}
.au-btn--primary {
  background: var(--au-color-primary);
  color: var(--au-color-white);
  border-color: var(--au-color-primary);
}
.au-btn--primary i::before {
  color: var(--au-color-white);
}
.au-btn--primary:hover {
  background: var(--au-color-primary-dark);
  border: 1px solid var(--au-color-primary);
  color: var(--au-color-white);
}
.au-btn--primary.au-btn--icon {
  background: var(--au-color-primary);
  color: var(--au-color-white);
  border-color: var(--au-color-primary);
  font-size: 1.4rem;
}
.au-btn--secondary {
  background: var(--au-color-white);
  color: var(--au-color-primary);
  /* stylelint-disable-next-line value-keyword-case */
  border: 1px solid currentColor;
}
.au-btn--secondary:hover {
  background: var(--au-color-gray-light-3);
}
.au-btn--secondary.au-btn--icon {
  background: var(--au-color-white);
  color: var(--au-color-primary);
  /* stylelint-disable-next-line value-keyword-case */
  border: 1px solid currentColor;
  font-size: 1.4rem;
}
.au-btn--link, .au-btn--icon {
  background: transparent;
  color: var(--au-color-primary);
  border: 1px solid transparent;
  padding: 3px 8px !important;
}
.au-btn--sm {
  padding: 2px 4px !important;
}
.au-btn--md {
  padding: 3px 8px !important;
}
.au-btn--lg {
  padding: 10px 20px !important;
}

.au-print {
  background: transparent;
  color: var(--au-color-secondary);
  border: 1px solid transparent;
  padding: 3px 8px !important;
}
.au-print i,
.au-print em {
  font-size: 1.4rem;
}

:disabled,
.au-disabled {
  opacity: 0.5;
  cursor: default;
}

.au-link {
  cursor: pointer;
}

.au-checkbox-width {
  width: 20px;
}

/* stylelint-disable selector-class-pattern */
.au-tag {
  min-width: 56px;
  height: fit-content;
  padding: 4px 12px;
  font-size: 1.4rem;
  align-items: center;
  justify-content: center;
  border-radius: 50px;
  display: inline-flex;
  text-align: center;
  white-space: nowrap;
  gap: 5px;
  cursor: pointer;
  line-height: 1;
}
.au-tag--sm {
  padding: 2px 8px;
  font-size: 1.2rem;
}
.au-tag--md {
  padding: 5px 8px;
  font-size: 1.2rem;
}
.au-tag--lg {
  padding: 10px 20px;
  font-size: 1.2rem;
}
.au-tag--line {
  color: var(--au-color-secondary-medium);
  border: 1px solid var(--au-color-secondary-medium);
  background: transparent;
}
.au-tag--line.green, .au-tag--line.au-tag-green, .au-tag--line.Low, .au-tag--line.low {
  border: 1px solid var(--au-color-green);
  color: var(--au-color-green);
}
.au-tag--line.orange, .au-tag--line.au-tag-orange, .au-tag--line.Medium, .au-tag--line.medium {
  border: 1px solid var(--au-color-orange);
  color: var(--au-color-orange);
}
.au-tag--line.red, .au-tag--line.au-tag-red, .au-tag--line.High, .au-tag--line.high {
  border: 1px solid var(--au-color-red);
  color: var(--au-color-red);
}
.au-tag--line.gray, .au-tag--line.au-tag-grey {
  border: 1px solid #555;
  color: var(--au-color-secondary);
}
.au-tag--line.blue, .au-tag--line.au-tag-blue {
  border: 1px solid var(--au-color-primary);
  color: var(--au-color-primary);
}
.au-tag--fill {
  border: 1px solid var(--au-color-secondary-medium);
  background: var(--au-color-secondary-medium);
  color: var(--au-color-white);
  cursor: auto;
}
.au-tag--fill.green, .au-tag--fill.au-badge-green, .au-tag--fill.Low, .au-tag--fill.low {
  border: 1px solid var(--au-color-green);
  background: var(--au-color-green);
}
.au-tag--fill.orange, .au-tag--fill.au-badge-orange, .au-tag--fill.Medium, .au-tag--fill.medium {
  border: 1px solid var(--au-color-orange);
  background: var(--au-color-orange);
}
.au-tag--fill.red, .au-tag--fill.au-badge-red, .au-tag--fill.High, .au-tag--fill.high {
  border: 1px solid var(--au-color-red);
  background: var(--au-color-red);
}
.au-tag--fill.gray, .au-tag--fill.au-badge-gray {
  border: 1px solid var(--au-color-secondary);
  background: var(--au-color-secondary);
}
.au-tag--fill.blue, .au-tag--fill.au-badge-blue {
  border: 1px solid var(--au-color-primary);
  background: var(--au-color-primary);
}
.au-tag--img {
  display: flex;
  align-items: center;
  border: 1px solid var(--au-color-primary-light);
  position: relative;
}
.au-tag--img img {
  position: absolute;
  width: 100%;
  left: 0;
}
.au-tag--number {
  padding: 0;
}
.au-tag__number {
  display: flex;
  align-items: center;
  background: var(--au-color-q9);
  padding: 1px;
  border-radius: 50px;
  text-decoration: none;
  transition: all 0.4s;
}
.au-tag__number:hover {
  background: var(--au-color-q10);
}
.au-tag__number-box {
  font-size: 1.2rem;
  padding: 4px;
  background: var(--au-color-primary);
  color: var(--au-color-white);
  border-radius: 50px;
  line-height: 1;
  min-width: 24px;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
}
.au-tag__number-text {
  padding: 0 5px;
  color: var(--au-color-primary);
  display: flex;
  align-items: center;
}
.au-tag__number-text span {
  font-size: 1.2rem;
  line-height: 1;
}
.au-tag__number-text .au-icon {
  margin-left: 3px;
  color: var(--au-color-primary);
}
.au-tag--square {
  border-radius: 0;
}
.au-tag--empty {
  min-height: 17px;
}

[dir=rtl] .au-tag__number-text {
  /* stylelint-disable-next-line max-nesting-depth */
}
[dir=rtl] .au-tag__number-text .au-icon {
  transform: rotate(180deg);
  margin-left: 0;
  margin-right: 3px;
}

.au-form {
  margin-bottom: 20px;
}
.au-form__header {
  display: flex;
  justify-content: space-between;
  gap: 10px;
  width: 100%;
  margin-bottom: 8px;
}
.au-form__header .au-reload,
.au-form__header .au-plus {
  font-size: 1.4rem;
  line-height: 100%;
  cursor: pointer;
}
@media (max-width: 1023.98px) {
  .au-form__header .au-reload,
.au-form__header .au-plus {
    display: block;
  }
}
.au-form__body {
  font-size: 1.4rem;
}
.au-form__body .au-input {
  font-size: 1.2rem;
}
.au-form__error, .au-form__warning {
  font-size: 1.2rem;
  display: block;
  margin-top: 4px;
  margin-bottom: 0;
}
.au-form__error {
  color: var(--au-color-red);
}
.au-form__warning {
  color: var(--au-color-orange);
}
.au-form__controller, .au-form__controls {
  display: flex;
  opacity: 0;
  align-items: center;
  gap: 5px;
  transition: all 0.4s;
}
.au-form:hover .au-form__controller,
.au-form:hover .au-form__controls {
  opacity: 1;
}
.au-form .au-label {
  position: relative;
  max-width: 100%;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
.au-form .au-label span {
  margin-left: 4px;
}
.au-form .au-flex {
  overflow: hidden;
}
.au-form--right {
  justify-content: end;
}
.au-form--right .au-form__header {
  justify-content: end;
}
.au-form--control .au-label {
  display: block;
  text-overflow: unset;
  width: 100%;
}
.au-form--control .au-label .au-label__inner {
  margin-left: 0;
}
.au-form--control .au-label .au-icon--info {
  position: static;
  margin-left: 4px;
}

/* stylelint-disable selector-class-pattern */
.au-input {
  border: 1px solid var(--au-color-secondary-light);
  padding: 8px 12px;
  display: block;
  border-radius: 5px;
  font-size: 1.4rem;
  color: var(--au-color-secondary-dark);
  width: 100%;
  height: 39px;
  display: inline-flex;
  flex-wrap: wrap;
  align-items: center;
  transition: all 0.4s;
  margin: 0;
  word-break: break-word;
}
.au-input.w-auto {
  width: auto;
}
.au-input[type=radio], .au-input[type=checkbox] {
  width: auto;
}
.au-input:focus {
  border: 1px solid var(--au-color-secondary-light);
}
.au-input.au-disabled {
  pointer-events: none;
}

input.au-input {
  display: block;
  background: var(--au-color-gray-light-3);
  border: 1px solid var(--au-color-grey11);
}

/* stylelint-disable selector-class-pattern */
.au-textarea {
  border: 1px solid var(--au-color-secondary-light);
  padding: 8px 12px;
  display: block;
  border-radius: 5px;
  font-size: 1.4rem;
  color: var(--au-color-secondary-dark);
  margin: 0;
  white-space: break-spaces;
  word-break: break-word;
}

textarea {
  min-height: 48px;
  max-height: 300px;
  width: 100%;
  display: block;
}
textarea.au-textarea {
  background: var(--au-color-gray-light-3);
  border: 1px solid var(--au-color-grey11);
}
textarea.au-textarea.au-textarea-auto {
  height: 100% !important;
  display: inline-table;
  max-height: auto;
  max-height: none;
}
textarea.au-textarea.au-textarea-auto-right {
  height: 100% !important;
  display: block;
  max-height: auto;
  resize: none;
  min-height: 280px;
}
textarea.w-auto {
  width: auto;
}
textarea:focus {
  border: 1px solid var(--au-color-secondary-light);
}

.au-textarea--print {
  display: none;
}

.cdk-textarea-autosize.au-textarea {
  max-height: 100%;
  overflow: hidden;
  box-sizing: content-box;
  width: calc(100% - 26px);
}

@media print {
  textarea {
    display: none;
  }
  .au-textarea--print {
    background: var(--au-color-gray-light-3);
    border: 1px solid var(--au-color-gray-light-3);
    display: block;
    min-height: 66px;
  }
}
.au-checkbox input {
  display: none !important;
}
.au-checkbox label {
  cursor: pointer !important;
  position: relative !important;
  padding-left: 28px !important;
  font-size: 1.4rem;
  min-height: 18px;
}
.au-checkbox label::after {
  content: "" !important;
  display: block !important;
  width: 18px !important;
  height: 18px !important;
  position: absolute !important;
  left: 0 !important;
  top: 0 !important;
  border: 1px solid var(--au-color-grey11) !important;
  border-radius: 2px;
}
.au-checkbox label::before {
  content: "" !important;
  display: none !important;
  position: absolute !important;
  width: 12px !important;
  height: 5px !important;
  left: 3px !important;
  top: 5px !important;
  z-index: 2 !important;
  border-left: 1px solid var(--au-color-primary) !important;
  border-bottom: 1px solid var(--au-color-primary) !important;
  background-color: var(--au-color-transparent) !important;
  border-top: none !important;
  border-right: none !important;
  border-radius: 0 !important;
  transform: rotate(312deg);
}
.au-checkbox input:checked + label::before {
  display: block !important;
}
.au-checkbox-multi input[type=checkbox] {
  opacity: 0;
}
.au-checkbox-multi__view {
  display: inline-block;
  width: 80px;
  height: 25px;
  border: 1px solid transparent;
  border-radius: 2px;
  position: relative;
  cursor: pointer;
}
.au-checkbox-multi__view::after {
  /* stylelint-disable-next-line font-family-name-quotes, string-quotes */
  font-family: "au-icon-font", sans-serif;
  content: "";
  font-size: 1.4rem;
  position: absolute;
  line-height: 1;
  top: 4px;
  right: 0;
  left: 0;
  margin: 0 auto;
  text-align: center;
  display: none;
}
.au-checkbox-multi__view::before {
  display: none !important;
}
.au-checkbox-multi input[status=checked] + .au-checkbox-multi__view,
.au-checkbox-multi .au-checkbox-multi__view[status=checked] {
  background-color: var(--au-color-q11);
  border-color: var(--au-color-q11);
  color: var(--au-color-q7);
}
.au-checkbox-multi input[status=checked] + .au-checkbox-multi__view::after,
.au-checkbox-multi .au-checkbox-multi__view[status=checked]::after {
  content: "퀒";
  display: block;
}
.au-checkbox-multi input[status=crossed] + .au-checkbox-multi__view,
.au-checkbox-multi .au-checkbox-multi__view[status=crossed] {
  background-color: var(--au-color-d8);
  border-color: var(--au-color-d8);
  color: var(--au-color-q7);
}
.au-checkbox-multi input[status=crossed] + .au-checkbox-multi__view::after,
.au-checkbox-multi .au-checkbox-multi__view[status=crossed]::after {
  content: "\e957";
  font-size: 1.2rem;
  display: block;
}
.au-checkbox-multi input[status=unchecked] + .au-checkbox-multi__view,
.au-checkbox-multi .au-checkbox-multi__view[status=unchecked] {
  background-color: var(--au-color-white);
  border-color: var(--au-color-secondary-medium);
  color: var(--au-color-q7);
}

.au-radio {
  display: inline-block;
}
.au-radio input {
  display: none !important;
}
.au-radio label {
  cursor: pointer !important;
  position: relative !important;
  padding-left: 24px !important;
  font-size: 1.4rem;
  min-height: 18px;
}
.au-radio label::after {
  content: "" !important;
  display: block !important;
  width: 18px !important;
  height: 18px !important;
  position: absolute !important;
  left: 0 !important;
  top: 0 !important;
  border: 1px solid #e1e1e1 !important;
  border-radius: 100px !important;
}
.au-radio label::before {
  content: "" !important;
  display: none !important;
  position: absolute !important;
  width: 10px !important;
  height: 10px !important;
  left: 4px !important;
  top: 4px !important;
  z-index: 2 !important;
  background-color: var(--au-color-primary);
  border-radius: 100px !important;
}
.au-radio input:checked + label::before {
  display: block !important;
}

.au-progressbar__value {
  font-size: 3.2rem;
  color: var(--au-color-black);
  text-align: center;
  line-height: 1;
  margin-bottom: 10px;
}
.au-progressbar__item {
  display: flex;
  align-items: center;
  gap: 5px;
  justify-content: space-between;
}
.au-progressbar__number {
  font-size: 1.4rem;
  white-space: nowrap;
  line-height: 1;
  color: var(--au-color-primary);
}
.au-progressbar__bar {
  width: 100%;
  height: 30px;
  position: relative;
  display: inline-flex;
  flex-wrap: wrap;
  align-items: center;
}
.au-progressbar__bar-status {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  border-right: 2px solid var(--au-color-white);
}
.au-progressbar__bar .au-icon {
  color: var(--au-color-q1);
  font-size: 1.6rem;
  position: absolute;
  right: -9px;
  top: -5px;
}
.au-progressbar__bar-line {
  width: 100%;
  height: 15px;
  background: linear-gradient(90deg, var(--au-color-d1) 0%, var(--au-color-yellow) 50.49%, var(--au-color-red) 100%);
  border-radius: 5px;
}

.au-circle-progress {
  display: flex;
  width: 60px;
  height: 60px;
  background: none;
  position: relative;
  background-color: var(--au-color-transparent);
  border-radius: 0.25rem !important;
  overflow: hidden;
  font-size: 0.6563rem;
  margin: 0 0 0 auto;
}
.au-circle-progress::after {
  content: "";
  width: 100%;
  height: 100%;
  border-radius: 50%;
  border: 4px solid #e5e5e5;
  position: absolute;
  top: 0;
  left: 0;
}
.au-circle-progress > span {
  width: 50%;
  height: 100%;
  overflow: hidden;
  position: absolute;
  top: 0;
  z-index: 1;
}
.au-circle-progress__bar {
  width: 100%;
  height: 100%;
  background: none;
  border-width: 4px;
  border-style: solid;
  position: absolute;
  top: 0;
  border-radius: 0;
  color: var(--au-color-secondary) !important;
  display: flex;
  flex-direction: column;
  justify-content: center;
  text-align: center;
  white-space: nowrap;
  background-color: var(--au-color-transparent);
  transition: width 0.6s cubic-bezier(0.785, 0.135, 0.15, 0.86);
}
.au-circle-progress__left {
  left: 0;
}
.au-circle-progress__left .au-circle-progress__bar {
  left: 100%;
  border-top-right-radius: 80px;
  border-bottom-right-radius: 80px;
  border-left: 0;
  /* stylelint-disable-next-line property-no-vendor-prefix */
  -webkit-transform-origin: center left;
  transform-origin: center left;
}
.au-circle-progress__right {
  right: 0;
}
.au-circle-progress__right .au-circle-progress__bar {
  left: -100%;
  border-top-left-radius: 80px;
  border-bottom-left-radius: 80px;
  border-right: 0;
  /* stylelint-disable-next-line property-no-vendor-prefix */
  -webkit-transform-origin: center right;
  transform-origin: center right;
}
.au-circle-progress__value {
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
}
.au-circle-progress__value-text {
  font-size: 1.4rem;
}

/* stylelint-disable-next-line selector-class-pattern */
.progress-active::after {
  border: 4px solid var(--au-color-primary) !important;
}

.au-progress {
  width: 60px;
  height: 60px;
  background: none;
  position: relative;
}
.au-progress::after {
  content: "";
  width: 100%;
  height: 100%;
  border-radius: 50%;
  border: 4px solid var(--au-color-secondary-light-2);
  position: absolute;
  top: 0;
  left: 0;
}
.au-progress--active::after {
  border: 4px solid var(--au-color-primary);
}
.au-progress > span {
  width: 50%;
  height: 100%;
  overflow: hidden;
  position: absolute;
  top: 0;
  z-index: 1;
}
.au-progress__left {
  left: 0;
}
.au-progress__left-bar {
  left: 100%;
  border-top-right-radius: 80px;
  border-bottom-right-radius: 80px;
  border-left: 0;
  -webkit-transform-origin: center left;
  transform-origin: center left;
}
.au-progress__right {
  right: 0;
}
.au-progress__right-bar {
  left: -100%;
  border-top-left-radius: 80px;
  border-bottom-left-radius: 80px;
  border-right: 0;
  -webkit-transform-origin: center right;
  transform-origin: center right;
}
.au-progress__left-bar, .au-progress__right-bar {
  width: 100%;
  height: 100%;
  background: none;
  border-width: 4px;
  border-style: solid;
  border-right: 0;
  position: absolute;
  top: 0;
  border-color: var(--au-color-white);
}
.au-progress__value {
  position: absolute;
  top: 0;
  left: 0;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
}

.au-breadcrumb__body {
  list-style: none;
  display: flex;
  flex-wrap: wrap;
  margin-bottom: 15px;
}
.au-breadcrumb__item {
  font-size: 1.2rem;
  position: relative;
  margin: 0;
  padding-left: 14px;
  padding-right: 6px;
}
.au-breadcrumb__item a {
  font-size: 1.2rem;
  color: var(--au-color-primary-light);
}
.au-breadcrumb__item:last-child {
  color: var(--au-color-secondary-medium);
}
.au-breadcrumb__item::before {
  content: "";
  display: block;
  position: absolute;
  left: 0;
  top: 9px;
  color: var(--au-color-secondary);
  width: 6px;
  height: 6px;
  border: 1px solid var(--au-color-currentcolor);
  border-left: 0;
  border-bottom: 0;
  transform: rotate(45deg);
}
.au-breadcrumb__item:first-child {
  padding-left: 0;
}
.au-breadcrumb__item:first-child::before {
  display: none;
}

[dir=rtl] .au-breadcrumb__item {
  padding-left: 6px;
  padding-right: 14px;
}
[dir=rtl] .au-breadcrumb__item:first-child {
  padding-right: 0;
}
[dir=rtl] .au-breadcrumb__item::before {
  right: 0;
  left: auto;
  transform: rotate(224deg);
}

.au-upload__body {
  padding: 50px;
  border: 2px dashed var(--au-color-secondary-light);
  border-radius: 10px;
  background-color: var(--au-color-gray-light-3) !important;
}
.au-upload__box {
  text-align: center;
}
.au-upload__footer {
  margin-top: 5px;
}
.au-upload__support {
  font-size: 1rem;
  color: var(--au-color-secondary);
}
.au-upload__uploaded {
  display: flex;
  flex-wrap: wrap;
  margin-top: 10px;
  gap: 10px;
}
.au-upload__uploaded-item {
  padding: 4px 8px;
  border-radius: 10px;
  background: var(--au-color-secondary-light-2);
  color: var(--au-color-secondary);
  border: 1px solid var(--au-color-secondary-light);
  display: flex;
  align-items: center;
  gap: 5px;
}
.au-upload__delete {
  border: 0;
  padding: 0;
  font-size: 1.4rem;
  background: transparent;
}
.au-upload__file-name {
  font-size: 1.2rem;
}
.au-upload--sm .au-upload__body {
  padding: 20px;
}
.au-upload--disabled {
  pointer-events: none;
}

/* stylelint-disable max-nesting-depth */
/* stylelint-disable no-descending-specificity */
/* stylelint-disable font-family-no-missing-generic-family-keyword */
/* stylelint-disable color-plugin/variable */
/* stylelint-disable selector-class-pattern */
.au-accordion {
  overflow: visible;
}
.au-accordion--au-accordion-no-toggle .accordion-item .accordion-header button {
  pointer-events: none !important;
}
.au-accordion--full-width-left-arrow .accordion-item .accordion-header button {
  width: 100% !important;
}
.au-accordion--static .accordion-header button {
  position: relative !important;
}
.au-accordion .accordion-item {
  border: 0;
  border-top: 1px solid var(--au-color-secondary-light);
  box-shadow: none !important;
  margin-bottom: 0;
}
.au-accordion .accordion-item .accordion-header {
  position: relative;
  background: var(--au-color-white);
  padding: 0;
  border-bottom: none;
}
.au-accordion .accordion-item .accordion-header button {
  border: none;
  z-index: 2;
  background-color: transparent;
  font-size: 1.4rem !important;
  box-shadow: unset;
  display: block;
  padding: 20px 20px 20px 25px !important;
  text-decoration: none;
  text-align: left;
  height: auto !important;
  margin-bottom: 0 !important;
  width: 24%;
  white-space: initial;
  position: absolute;
  color: var(--au-color-black);
  font-size: 1.9rem;
  font-weight: 500;
  line-height: 1.2;
  margin-bottom: 10px;
}
.au-accordion .accordion-item .accordion-header button::before {
  content: "򠀘";
  font-family: au-icon-font;
  font-size: 1.2rem;
  position: absolute;
  color: var(--au-color-white);
  left: 0;
  top: 18px;
  background: var(--au-color-black);
  width: 18px;
  height: 18px;
  border-radius: 50px;
  padding-top: 3px;
  padding-left: 3px;
  transform: rotate(180deg);
  transition: 0.3s;
}
.au-accordion .accordion-item .accordion-header button.collapsed {
  width: 100%;
  position: relative;
}
.au-accordion .accordion-item .accordion-header button.collapsed::before {
  transform: rotate(0deg);
}
@media (max-width: 599.98px) {
  .au-accordion .accordion-item .accordion-header button {
    position: relative;
    width: 100%;
  }
}
@media print {
  .au-accordion .accordion-item .accordion-header button {
    display: block !important;
  }
}
.au-accordion .accordion-item .accordion-body {
  position: relative;
  z-index: 1;
  display: flex;
  flex-wrap: wrap;
  padding: 20px 0;
}
@media (max-width: 599.98px) {
  .au-accordion .accordion-item .accordion-body {
    display: block;
  }
}
.au-accordion .accordion-item .accordion-body .au-left {
  width: 25%;
  position: relative;
}
@media (max-width: 599.98px) {
  .au-accordion .accordion-item .accordion-body .au-left {
    width: 100%;
  }
}
.au-accordion .accordion-item .accordion-body .au-left p {
  white-space: pre-wrap;
}
.au-accordion .accordion-item .accordion-body .au-right {
  position: relative;
  width: 75%;
  margin-left: auto;
  padding-left: 30px;
}
@media (max-width: 599.98px) {
  .au-accordion .accordion-item .accordion-body .au-right {
    width: 100%;
    margin-top: 0;
    padding-top: 0;
    padding-left: 0;
  }
}
.au-accordion .accordion-item .accordion-body .au-center {
  position: relative;
  width: 100%;
}
.au-accordion .accordion-item .accordion-body .au-center:first-child {
  padding-top: 40px;
}
.au-accordion--full-width .accordion-item .accordion-header button {
  position: relative;
  width: 100%;
  padding: 20px 40px 20px 20px !important;
  transition: none;
}
.au-accordion--full-width .accordion-item .accordion-header button[aria-expanded=true] {
  background-color: var(--au-color-gray-light-2);
}
.au-accordion--full-width .accordion-item .accordion-header button::before {
  top: 22px;
  left: auto;
  right: 20px;
}
.au-accordion--full-width .accordion-item .accordion-body {
  background-color: var(--au-color-gray-light-2);
  padding: 10px 20px 20px;
  display: block;
}
.au-accordion.au-full-width .accordion-item .accordion-body {
  display: block;
}
.au-accordion .accordion .accordion-item:first-child {
  border-top: 0;
}
.au-accordion--modal .au-accordion__body {
  width: 100%;
  margin-top: -20px;
}
.au-accordion--modal .accordion-button {
  width: 100% !important;
  position: relative !important;
}

.au-card .accordion .accordion-item:first-child {
  margin-top: -15px;
}
.au-card .accordion .accordion-item:last-child {
  margin-bottom: -19px;
}

.au-card-accordion .au-card {
  margin-bottom: 4px;
}

[dir=rtl] .au-accordion .accordion-item .accordion-header button {
  text-align: right;
}
[dir=rtl] .au-accordion .accordion-item .accordion-header button::before {
  padding-right: 3px;
  left: auto;
  right: 0;
}

.au-wizard__step {
  display: flex;
  gap: 10px;
  margin-bottom: 24px;
}
.au-wizard__item {
  box-shadow: 0 0 8px #e1e1e1;
  border-radius: 100px;
  width: 100%;
  overflow: hidden;
}
.au-wizard__link {
  display: block;
  width: 100%;
  font-size: 1.4rem;
  text-align: center;
  padding: 10px 20px;
  background: var(--au-color-white);
  /* stylelint-disable-next-line selector-class-pattern */
}
.au-wizard__link.active, .au-wizard__link.au-active {
  color: var(--au-color-white) !important;
  background: var(--au-color-primary);
}
.au-wizard__content {
  position: relative;
}

.au-legend {
  display: block;
}
.au-legend__item {
  display: inline-flex;
  padding: 2px;
  column-gap: 5px;
  margin-right: 5px;
}
.au-legend__marker {
  background: #fefefe;
  height: 6px;
  width: 20px;
  min-width: 20px;
  margin-top: 4px;
  display: inline-block;
  border: 0 none;
}
.au-legend__text {
  color: var(--au-color-secondary);
  font-size: 1.2rem;
  margin: 0;
}

.au-calendar {
  position: relative;
}
.au-calendar input {
  background: var(--au-color-gray-light-3);
  border: 1px solid var(--au-color-gray-light-3);
  padding-right: 32px;
  line-height: 1.6;
}
.au-calendar input:focus {
  border: 1px solid var(--au-color-secondary-light);
}
@-moz-document url-prefix("") {
  .au-calendar input {
    padding-right: 34px;
  }
}
.au-calendar::after {
  content: "򠀅";
  /* stylelint-disable-next-line font-family-name-quotes, string-quotes */
  font-family: "au-icon-font", sans-serif;
  font-size: 1.8rem;
  color: var(--au-color-black);
  position: absolute;
  top: 9px;
  right: 11px;
  pointer-events: none;
}

[dir=rtl] .au-calendar input {
  padding-right: 37px;
}
[dir=rtl] .au-calendar::after {
  right: auto;
  left: 11px;
}

.au-time {
  position: relative;
}
.au-time input {
  background: var(--au-color-gray-light-3);
  border: 1px solid var(--au-color-gray-light-3);
  padding-right: 32px;
  line-height: 1.6;
}
.au-time input:focus {
  border: 1px solid var(--au-color-secondary-light);
}
@-moz-document url-prefix("") {
  .au-time input {
    padding-right: 34px;
  }
}
.au-time::after {
  content: "퀱";
  /* stylelint-disable-next-line font-family-name-quotes */
  font-family: "au-icon-font", sans-serif;
  font-size: 1.8rem;
  color: var(--au-color-black);
  position: absolute;
  top: 9px;
  right: 11px;
}
.au-time.au-time-picker::after {
  content: none;
}

[dir=rtl] .au-time input {
  padding-right: 37px;
}
[dir=rtl] .au-time::after {
  right: auto;
  left: 11px;
}

/* stylelint-disable max-nesting-depth */
/* stylelint-disable selector-class-pattern */
.au-dropdown .ngx-dropdown-container .ngx-dropdown-button {
  min-height: 39px !important;
  height: 39px !important;
}
.au-dropdown .ngx-dropdown-container input {
  padding: 5px 0 !important;
  border-bottom: var(--au-color-secondary-light);
}
.au-dropdown .ngx-dropdown-container input,
.au-dropdown .ngx-dropdown-container label {
  font-size: 1.4rem !important;
}
.au-dropdown .ngx-dropdown-container .available-items li {
  font-size: 1.4rem !important;
  padding: 0 !important;
  color: var(--au-color-secondary) !important;
}
.au-dropdown .ngx-dropdown-container span {
  font-size: 1.4rem !important;
  white-space: break-spaces;
}
.au-dropdown .ngx-dropdown-container .selected-items span {
  font-size: 1.4rem !important;
}
.au-dropdown .ngx-dropdown-container .selected-items li {
  padding: 10px !important;
}
.au-dropdown .ngx-dropdown-container button {
  border: 1px solid var(--au-color-secondary-light);
  padding: 8px 12px;
  display: block;
  border-radius: 5px;
  font-size: 1.4rem;
  color: var(--au-color-secondary-dark);
  background-color: var(--au-color-gray-light-3) !important;
  border: 1px solid var(--au-color-gray-light-3) !important;
}
.au-dropdown .ngx-dropdown-container button .nsdicon-angle-down::before {
  display: none !important;
  width: 8px !important;
  height: 8px !important;
  border: 1px solid var(--au-color-currentcolor);
  border-left: 0;
  border-bottom: 0;
  color: var(--au-color-secondary-medium);
  top: 3px !important;
}
.au-dropdown .ngx-dropdown-container button .nsdicon-angle-down::after {
  content: "򠀁";
  font-family: au-icon-font, sans-serif;
  position: absolute;
  top: 0;
  right: 0;
  color: var(--au-color-secondary);
}
.au-dropdown .ng-select-container {
  background-color: var(--au-color-gray-light-3) !important;
  border: 1px solid var(--au-color-grey11) !important;
  min-height: 39px !important;
  height: 39px !important;
}
.au-dropdown .ng-select-container input,
.au-dropdown .ng-select-container .ng-placeholder,
.au-dropdown .ng-select-container .ng-value-label,
.au-dropdown .ng-select-container .ng-value-icon {
  font-size: 1.2rem;
}
.au-dropdown .ng-select-container .ng-value-icon.right {
  margin-right: 0 !important;
}
.au-dropdown .ng-select-container .ng-placeholder {
  opacity: 0;
}
.au-dropdown .ng-select-container .ng-arrow-wrapper {
  margin-top: -2px;
}
.au-dropdown--autoheight .ng-select-container {
  max-height: 100px;
  height: auto !important;
}
.au-dropdown--autoheight .ng-select-container .ng-value-container .ng-input > input {
  caret-color: var(--au-color-black) !important;
}
.au-dropdown .ng-select.ng-select-single .ng-select-container .ng-value-container .ng-input {
  top: 8px;
}
.au-dropdown .ng-select .ng-value {
  position: relative;
  z-index: 1;
}
.au-dropdown .ng-select.ng-select-multiple .ng-value-label,
.au-dropdown .ng-select.ng-select-multiple .ng-value-icon {
  border: 1px solid var(--au-color-s8);
}
.au-dropdown .ng-select.ng-select-multiple .ng-value-icon.right {
  border: 1px solid var(--au-color-s8);
  border-left: 0 !important;
}
.au-dropdown .ng-select .ng-select-container .ng-value-container .ng-input > input {
  position: relative;
  top: 0;
  white-space: nowrap;
  text-overflow: ellipsis;
}
.au-dropdown--icon .ng-select-container {
  min-height: 30px !important;
  background-color: var(--au-theme-white) !important;
}
.au-dropdown--icon .ng-select.ng-select-multiple .ng-select-container .ng-value-container {
  padding-top: 0;
}
.au-dropdown--icon .ng-select.ng-select-multiple .ng-select-container .ng-value-container .ng-value {
  margin-bottom: 0;
  display: flex;
  align-items: center;
  border: 1px solid var(--au-color-secondary-light);
  border-radius: 30px;
  overflow: hidden;
  background-color: var(--au-color-transparent);
}
.au-dropdown--icon .ng-select.ng-select-multiple .ng-select-container .ng-value-container .ng-value .ng-value-label {
  display: flex;
  align-items: center;
  border: 0;
  padding-left: 0;
}
.au-dropdown--icon .ng-select.ng-select-multiple .ng-select-container .ng-value-container .ng-value .ng-value-icon:hover {
  background-color: var(--au-color-transparent);
}
.au-dropdown--icon .ng-select.ng-select-multiple .ng-select-container .ng-value-container .ng-value .ng-value-icon.right {
  border: 0;
}
.au-dropdown--white .ng-select-container {
  background-color: var(--au-color-white) !important;
}
.au-dropdown--small .ng-select-container {
  min-height: 30px !important;
}

.ng-select-multiple .ng-select-container {
  height: auto !important;
}

.ng-dropdown-panel {
  z-index: 99999 !important;
}
.ng-dropdown-panel .ng-dropdown-panel-items .ng-option {
  font-size: 1.2rem;
  color: var(--au-color-secondary-medium);
}
.ng-dropdown-panel .ng-dropdown-panel-items .ng-option .tooltip-inner {
  word-break: break-all;
}
.ng-dropdown-panel .ng-dropdown-panel-items .ng-option .ng-pristine {
  position: relative;
  top: 2px;
}

/* stylelint-disable selector-class-pattern */
.au-select {
  border: 1px solid var(--au-color-secondary-light);
  padding: 8px 12px;
  display: block;
  border-radius: 5px;
  font-size: 1.4rem;
  color: var(--au-color-secondary-dark);
  display: inline-flex;
  flex-wrap: wrap;
  width: 100%;
  align-items: center;
  height: 39px;
  padding: 8px 12px !important;
  appearance: none;
  position: relative;
}
.au-select__option-span {
  margin: -10px;
}
.au-select__item-span {
  padding: 10px;
}

select {
  width: 100%;
  display: block;
  transition: all 0.4s;
}
select.au-select {
  background-color: var(--au-color-gray-light-3);
  background-image: url('data:image/svg+xml;utf8,<svg id="Layer_1" data-name="Layer 1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 30 30"><path d="M15,23a.55.55,0,0,1-.42-.19L1.64,8a.56.56,0,0,1,.05-.79.57.57,0,0,1,.8.05L15,21.54,27.51,7.23a.58.58,0,0,1,.8-.05.56.56,0,0,1,.05.79L15.42,22.77A.55.55,0,0,1,15,23Z" fill="1dbb99"/></svg>');
  background-position: calc(100% - 10px) 50%;
  background-size: 14px;
  background-repeat: no-repeat;
  border: 1px solid var(--au-color-grey11);
  padding-right: 28px !important;
  white-space: nowrap;
  text-overflow: ellipsis;
}
select.w-auto {
  width: auto;
}
select::after {
  content: "1";
  position: absolute;
  top: 0;
  right: 0;
}
select:focus {
  border: 1px solid var(--au-color-secondary-light);
}

/* stylelint-disable selector-class-pattern */
.au-search {
  border: 1px solid var(--au-color-secondary-light);
  padding: 8px 12px;
  display: block;
  border-radius: 5px;
  font-size: 1.4rem;
  color: var(--au-color-secondary-dark);
  padding: 8px 12px !important;
  width: 100%;
  display: block;
  transition: all 0.4s;
}
.au-search.w-auto {
  width: auto;
}
.au-search:focus {
  border: 1px solid var(--au-color-secondary-light);
}

input.au-search {
  background-color: var(--au-color-gray-light-3);
  background-image: url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 30 30"><g id="search"><path d="M11.06,20.62a9.56,9.56,0,1,1,9.56-9.56A9.57,9.57,0,0,1,11.06,20.62Zm0-18a8.44,8.44,0,1,0,8.44,8.44A8.45,8.45,0,0,0,11.06,2.62Z"/><path d="M27.94,28.5a.55.55,0,0,1-.4-.16L17,17.83a.57.57,0,0,1,.8-.8L28.34,27.54a.59.59,0,0,1,0,.8A.57.57,0,0,1,27.94,28.5Z"/></g></svg>');
  background-position: calc(100% - 10px) 50%;
  background-size: 16px;
  background-repeat: no-repeat;
  border: 1px solid var(--au-color-grey11);
  padding-right: 37px !important;
}

[dir=rtl] input.au-search {
  background-position: 2% 50%;
}

.au-find {
  display: flex;
  column-gap: 10px;
}
@media (max-width: 1023.98px) {
  .au-find {
    margin-bottom: 8px;
  }
}
.au-find .au-select {
  width: 100px;
}
.au-find app-au-search {
  display: block;
  width: calc(100% - 120px);
}

.au-label {
  font-size: 1.2rem;
  color: var(--au-color-secondary-medium);
  text-transform: uppercase;
}
.au-label__inner {
  display: inline-block;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: 100%;
}
.au-label__inner--adjust {
  max-width: calc(100% - 26px);
}
.au-label span {
  line-height: 1;
}

.au-status-card-progress {
  display: flex;
  margin-bottom: 15px;
  gap: 15px;
  overflow: auto;
  padding-bottom: 4px;
  scrollbar-color: var(--au-color-secondary-light) transparent;
}
.au-status-card-progress::-webkit-scrollbar {
  height: 7px;
}
.au-status-card-progress::-webkit-scrollbar-track {
  border-radius: 10px;
  background-color: transparent;
}
.au-status-card-progress::-webkit-scrollbar-thumb {
  background: #e1e1e1;
  border-radius: 10px;
}
.au-status-card-progress__item {
  width: 100%;
  min-width: 180px;
}
@media (max-width: 1023.98px) {
  .au-status-card-progress__item {
    min-width: 210px;
  }
}
.au-status-card-progress__title {
  margin-bottom: 5px;
  font-size: 1.4rem;
  display: block;
}
.au-status-card-progress__title .au-icon {
  font-size: 1rem;
}
.au-status-card-progress__value {
  display: block;
  font-size: 2.8rem;
  font-weight: 500;
}
.au-status-card-progress .au-card {
  padding: 8px;
}
.au-status-card-progress .au-card__body {
  display: flex;
}
.au-status-card-progress .au-left {
  width: calc(100% - 60px);
}
.au-status-card-progress .au-right {
  width: 60px;
}

.au-status-card {
  display: flex;
  margin-bottom: 8px;
  padding-bottom: 4px;
  overflow: auto;
  scrollbar-color: var(--au-color-secondary-light) transparent;
}
.au-status-card::-webkit-scrollbar {
  height: 7px;
}
.au-status-card::-webkit-scrollbar-track {
  border-radius: 10px;
  background-color: transparent;
}
.au-status-card::-webkit-scrollbar-thumb {
  background: var(--au-color-secondary-light);
  border-radius: 10px;
}
.au-status-card__head {
  margin-bottom: 5px;
}
.au-status-card__title, .au-status-card__value {
  display: block;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
.au-status-card__title {
  font-size: 1.2rem;
  color: var(--au-color-secondary-medium);
  line-height: 1.35;
}
.au-status-card__value {
  font-size: 2.8rem;
  line-height: 100%;
  color: var(--au-color-primary);
  font-weight: 500;
}
.au-status-card__item {
  width: 100%;
  background: var(--au-color-white);
  /* stylelint-disable-next-line color-function-notation, alpha-value-notation, number-leading-zero */
  box-shadow: 0 0 8px rgba(225, 225, 225, 0.46);
  padding: 10px;
  border-top-width: 2px;
  border-style: solid;
  display: flex;
  justify-content: space-between;
  cursor: pointer;
  min-width: 100px;
}
@media (max-width: 1023.98px) {
  .au-status-card__item {
    min-width: 210px;
  }
}
.au-status-card__item.au-active {
  background-color: var(--au-color-primary);
  border-top-color: var(--au-color-primary);
}
.au-status-card__item.au-active .au-status-card__title,
.au-status-card__item.au-active .au-status-card__value {
  color: var(--au-color-white);
}
.au-status-card .au-left {
  width: 100%;
}
.au-status-card .au-right {
  width: 30%;
}
.au-status-card .au-right:empty {
  display: none;
}

.au-form-matrix td,
.au-form-rating td {
  padding: 10px 6px !important;
  white-space: normal;
}
.au-form-matrix__item .au-radio,
.au-form-rating__item .au-radio {
  width: 18px;
  height: 18px;
}
.au-form-matrix__item .au-radio label,
.au-form-rating__item .au-radio label {
  display: block;
}
.au-form-matrix__side,
.au-form-rating__side {
  font-size: 1.8rem;
  color: var(--au-color-primary);
}
.au-form-matrix__number,
.au-form-rating__number {
  font-size: 1.8rem;
  color: var(--au-color-secondary-medium);
  text-align: center;
}
.au-form-matrix .au-form-matrix__head th,
.au-form-rating .au-form-matrix__head th {
  border: 0 !important;
}

.au-toggle {
  position: relative;
  display: inline-block;
  width: 60px;
  height: 34px;
}
.au-toggle__checkbox {
  opacity: 0;
  width: 100%;
  height: 100%;
  position: absolute;
  z-index: 2;
  cursor: pointer;
}
.au-toggle__checkbox:checked + .au-toggle__slider {
  background-color: var(--au-color-secondary-light-2);
}
.au-toggle__checkbox:focus + .au-toggle__slider {
  box-shadow: 0 0 1px var(--au-color-secondary-light);
}
.au-toggle__checkbox:checked + .au-toggle__slider:before {
  background-color: var(--au-color-primary);
  -webkit-transform: translateX(26px);
  -ms-transform: translateX(26px);
  transform: translateX(26px);
}
.au-toggle__slider {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: var(--au-color-secondary-light-2);
  transition: all 0.4s;
  border: 1px solid var(--au-color-secondary-light);
  border-radius: 100px;
}
.au-toggle__slider:before {
  position: absolute;
  content: "";
  height: 32px;
  width: 32px;
  left: 0;
  bottom: 0;
  background-color: var(--au-color-secondary-light);
  -webkit-transition: 0.4s;
  transition: 0.4s;
  border-radius: 100px;
}

table,
.au-table {
  width: 100%;
}
table .action-icons,
.au-table .action-icons {
  min-width: 75px;
  text-align: center;
  padding: 3px 8px;
  border-radius: 50px;
  color: #fff;
}
table th, table td,
.au-table th,
.au-table td {
  font-size: 1.2rem;
  white-space: nowrap;
}
table td,
.au-table td {
  color: #555;
}
table .icon-plus,
.au-table .icon-plus {
  /* stylelint-disable-next-line font-plugin/font-check */
  font-size: 0.875rem;
}

.au-table-responsive {
  display: block;
  width: 100%;
  overflow-x: auto;
  -webkit-overflow-scrolling: touch;
  -ms-overflow-style: -ms-autohiding-scrollbar;
}

/* stylelint-disable-line */
.au-tab {
  display: flex;
}
.au-tab__link {
  white-space: nowrap;
  display: block;
  font-size: 1.4rem;
  line-height: inherit;
  color: var(--au-color-black);
  cursor: pointer;
  padding: 8px 0;
  border-bottom: 2px solid transparent;
  position: relative;
  transition: all 0.4s;
}
.au-tab__link:hover, .au-tab__link.au-active {
  color: var(--au-color-black) !important;
  border-bottom: 2px solid var(--au-color-black);
}
.au-tab__link.au-active {
  font-weight: 600;
}
.au-tab__badge {
  position: absolute;
  line-height: 1;
  background: var(--au-color-primary-light);
  color: var(--au-color-white);
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 1rem;
  top: -7px;
  right: -10px;
  border-radius: 100px;
  padding: 3px 5px;
  cursor: pointer;
}
.au-tab__item {
  padding: 0 14px;
  position: relative;
}
.au-tab__item .au-tab__dropdown {
  position: absolute;
  display: none;
  right: 0;
  background: var(--au-color-white);
  z-index: 100000;
  padding: 10px;
  /* stylelint-disable-next-line color-function-notation, alpha-value-notation */
  box-shadow: 0 0 10px rgba(193, 193, 193, 0.69);
  /* stylelint-disable-next-line selector-class-pattern */
}
.au-tab__item .au-tab__dropdown .au-tab__badge {
  position: relative;
  top: -1px;
  right: 0;
  margin-left: 8px;
}
.au-tab__item .au-tab__dropdown .au-tab__link {
  border-bottom: 2px solid transparent;
  /* stylelint-disable-next-line max-nesting-depth */
}
.au-tab__item .au-tab__dropdown .au-tab__link:hover {
  border-bottom: 2px solid var(--au-color-black);
}
.au-tab__item .au-tab__dropdown .button-drop-active {
  font-weight: 500;
  border-bottom: 0 !important;
}
.au-tab__item:hover {
  /* stylelint-disable-next-line no-descending-specificity */
}
.au-tab__item:hover .au-tab__dropdown {
  display: block;
}
.au-tab__item:hover > .au-tab__link {
  border-bottom: 2px solid var(--au-color-black);
}
.au-tab__dropdown-item {
  margin: 6px 8px;
  color: var(--au-color-black);
  text-transform: uppercase;
  font-size: 1.4rem;
  white-space: nowrap;
  display: flex;
  align-items: center;
}
.au-tab__dropdown-item.au-active {
  color: var(--au-color-primary);
  font-weight: 500;
}
.au-tab__dropdown-inner--two-col, .au-tab__dropdown-inner--three-col {
  display: flex;
  flex-wrap: wrap;
  max-height: 250px;
  overflow-x: auto;
}
.au-tab__dropdown-inner--two-col::-webkit-scrollbar, .au-tab__dropdown-inner--three-col::-webkit-scrollbar {
  height: 5px;
}
.au-tab__dropdown-inner--two-col::-webkit-scrollbar-track, .au-tab__dropdown-inner--three-col::-webkit-scrollbar-track {
  border-radius: 10px;
  background-color: var(--au-color-transparent);
}
.au-tab__dropdown-inner--two-col::-webkit-scrollbar-thumb, .au-tab__dropdown-inner--three-col::-webkit-scrollbar-thumb {
  background: var(--au-color-secondary-light);
  border-radius: 10px;
  height: 5px;
}
.au-tab__dropdown-inner--two-col {
  min-width: 380px;
}
.au-tab__dropdown-inner--two-col .au-tab__dropdown-item {
  width: calc(50% - 16px);
}
.au-tab__dropdown-inner--three-col {
  min-width: 580px;
  flex-direction: column;
}
.au-tab__dropdown-inner--three-col .au-tab__dropdown-item {
  width: calc(33.33% - 16px);
}
.au-tab--main .au-tab__item {
  cursor: pointer;
  font-size: 1.6rem;
  font-weight: 400;
  background: var(--au-color-gray-light-3);
  padding: 12px 24px;
  text-transform: uppercase;
  color: var(--au-color-secondary);
  transition: all solid 0.4s;
  border: 0px -4px 8px rgba(225, 225, 225, 0.46); /* stylelint-disable-line */
}
.au-tab--main .au-tab__item.au-active {
  font-weight: 500;
  box-shadow: 2px -5px 5px 2px rgba(225, 225, 225, 0.46); /* stylelint-disable-line */
  color: var(--au-color-black);
  background: var(--au-color-white);
}

.au-page-tab {
  width: 100%;
  display: inline-flex;
  align-items: center;
  overflow: hidden;
  border-radius: 100px;
  border: 1px solid var(--au-color-secondary-light);
  margin-bottom: 15px;
  background: var(--au-color-white);
}
@media (max-width: 1023.98px) {
  .au-page-tab {
    overflow: auto;
    scrollbar-width: none;
  }
  .au-page-tab::-webkit-scrollbar {
    width: 0;
    height: 0;
    background: transparent;
  }
}
.au-page-tab__item {
  white-space: pre;
}
.au-page-tab__link {
  display: block;
  padding: 10px 15px;
  text-align: center;
  color: var(--au-color-primary);
  font-size: 1.4rem;
  cursor: pointer;
}
.au-page-tab__link.au-active {
  color: var(--au-color-white);
  background: var(--au-color-primary);
}

.au-link-tab {
  display: inline-flex;
  align-items: center;
  gap: 8px;
}
.au-link-tab__item {
  display: block;
  font-size: 1.2rem;
  color: var(--au-color-secondary-medium);
  cursor: pointer;
}
.au-link-tab__item:hover, .au-link-tab__item.au-active {
  color: var(--au-color-primary);
}
.au-link-tab__item.au-active {
  font-weight: 600;
}

.au-v-line {
  display: block;
  width: 2px;
  background: var(--au-color-secondary-light);
  min-height: 10px;
  max-height: 100%;
}

.au-head-tab {
  display: inline-flex;
  align-items: center;
  margin-bottom: 15px;
}
.au-head-tab__item {
  display: block;
  font-size: 1.4rem;
  color: var(--au-color-secondary-medium);
  padding: 2px 14px;
  border-right: 1px solid var(--au-color-secondary-light);
  position: relative;
  line-height: 1;
  cursor: pointer;
}
.au-head-tab__item.au-active {
  color: var(--au-color-primary);
  font-size: 1.4rem;
  font-weight: 600;
}
.au-head-tab__item:first-child {
  padding-left: 0;
}
.au-head-tab__item:last-child {
  border-right: none;
}
.au-head-tab__badge {
  position: absolute;
  line-height: 1;
  background: var(--au-color-primary-light); /* stylelint-disable-line */
  color: var(--au-color-white);
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 1.2rem;
  top: -14px;
  right: 2px;
  border-radius: 100px;
  padding: 3px 5px;
  cursor: pointer;
}

@media (max-width: 599.98px) {
  .au-tab__item--more {
    position: static;
  }
  .au-tab__item .au-tab__dropdown {
    width: calc(100vw - 20px);
    left: 50%;
    transform: translateX(-50%);
  }
  .au-tab__dropdown-inner--two-col {
    min-width: 0;
    width: 100%;
  }
  .au-tab__dropdown-inner--three-col {
    min-width: 0;
    width: 100%;
    max-height: 300px;
  }
  .au-tab__dropdown-inner--three-col .au-tab__dropdown-item {
    width: calc(50% - 16px);
  }
}
.au-timeline__item {
  display: flex;
  align-items: flex-start;
}
.au-timeline__item:last-child .au-timeline__right {
  border-left: 1px dashed transparent;
}
.au-timeline__item.au-active .au-timeline__left::after {
  background: var(--au-color-primary);
}
.au-timeline.au-dynamic .au-timeline__left::after {
  display: none;
}
.au-timeline__left {
  position: relative;
  padding-right: 20px;
  padding-bottom: 35px;
}
.au-timeline__left span {
  font-size: 1.2rem;
  color: var(--au-color-secondary-medium);
}
.au-timeline__left::after {
  content: "";
  display: block;
  width: 18px;
  height: 18px;
  border: 1px solid var(--au-color-primary-light);
  background: var(--au-color-white);
  border-radius: 100px;
  position: absolute;
  right: -10px;
  top: 0;
}
.au-timeline__left .au-dot {
  display: block;
  width: 18px;
  height: 18px;
  border: 1px solid var(--au-color-primary-light);
  background: transparent;
  border-radius: 100px;
  position: absolute;
  right: -10px;
  top: 0;
}
.au-timeline__right {
  padding-left: 35px;
  padding-bottom: 35px;
  border-left: 1px dashed var(--au-color-primary-light);
}
.au-timeline__right h4 {
  color: var(--au-color-primary);
  margin-bottom: 5px;
}

.au-h-timeline {
  display: flex;
  width: 100%;
  min-width: 300px;
  padding: 5px 0;
}
.au-h-timeline__list {
  display: flex;
  width: 100%;
}
.au-h-timeline__item {
  list-style-type: none;
  width: 25%;
  float: left;
  font-size: 12px;
  position: relative;
  text-align: center;
  text-transform: uppercase;
  color: var(--au-color-black);
}
.au-h-timeline__item:before {
  width: 10px;
  height: 10px;
  content: "";
  line-height: 10px;
  border: 2px solid var(--au-color-secondary-medium);
  display: block;
  text-align: center;
  margin: 0 auto 3px auto;
  border-radius: 50%;
  position: relative;
  z-index: 2;
  background-color: var(--au-color-white);
}
.au-h-timeline__item:after {
  width: 100%;
  height: 1px;
  content: "";
  position: absolute;
  border-bottom: 1px dotted var(--au-color-secondary-medium);
  top: 4px;
  left: -50%;
  z-index: 0;
}
.au-h-timeline__item:first-child:after {
  content: none;
}
.au-h-timeline__item.au-active {
  color: var(--au-color-black);
  font-weight: bold;
}
.au-h-timeline__item.au-active:before {
  border-color: var(--au-color-d1);
  background: var(--au-color-green);
}
.au-h-timeline__item.au-active + .au-h-timeline__item:after {
  background-color: var(--au-color-d1);
}

[dir=rtl] .au-timeline__item:last-child .au-timeline__right {
  border-right: 0;
}
[dir=rtl] .au-timeline__right {
  padding-left: 0;
  padding-right: 35px;
  border-left: 0;
  border-right: 1px dashed var(--au-color-primary-light);
}
[dir=rtl] .au-timeline__left {
  padding-right: 0;
  padding-left: 20px;
}
[dir=rtl] .au-timeline__left::after {
  right: auto;
  left: -10px;
}

/* stylelint-disable selector-class-pattern */
.au-modal {
  position: fixed;
  z-index: 99991 !important;
}
.au-modal__close {
  position: absolute;
  top: 0;
  right: 0;
  border: 0;
  background: transparent;
  padding: 20px;
  font-weight: bold;
  cursor: pointer;
}
.au-modal__close i {
  font-size: 1.4rem;
}
.au-modal .modal-content {
  position: relative;
}
.au-modal__body {
  overflow-x: hidden;
  overflow-y: auto;
  padding: 20px;
  padding-top: 15px;
  scrollbar-width: none;
}
.au-modal__body::-webkit-scrollbar {
  width: 0;
  height: 0;
  background: transparent;
}
.au-modal__body .color-picker {
  top: 50% !important;
  transform: translateY(-50%);
}
.au-modal__body .color-picker .arrow {
  display: none;
}
.au-modal__header {
  padding: 20px;
  padding-bottom: 0;
}
.au-modal__header h3 {
  padding-right: 20px;
  line-height: 1.1;
}
.au-modal__footer {
  padding: 20px;
  padding-top: 0;
}

.au-export-wrap {
  width: 100%;
  text-align: center;
  padding: 50px 0;
}
.au-export-wrap__box {
  display: flex;
  justify-content: center;
  gap: 20px;
}
.au-export-wrap__item {
  text-align: center;
  margin-bottom: 20px;
}
.au-export-wrap__item img {
  width: 60px;
  display: block;
  margin: 0 auto;
  margin-bottom: 10px;
}

.modal-backdrop {
  z-index: 99991 !important;
}

.cdk-overlay-pane {
  justify-content: center !important;
}
.cdk-overlay-pane.au-time-picker-align {
  justify-content: flex-start !important;
}
.cdk-overlay-pane .file-img {
  overflow: auto !important;
}

.au-right-menu {
  position: fixed;
  width: 280px;
  height: calc(100vh - 50px);
  right: 0;
  bottom: 0;
  z-index: 99991;
  background: var(--au-color-white);
  display: flex;
  animation: ani-d-flex 250ms ease-in-out both;
  flex-wrap: wrap;
  transition: all 0.3s;
  box-shadow: 0 0 8px #e1e1e1;
}
.au-right-menu.au-show {
  display: block;
}
.au-right-menu.au-lg {
  width: 600px;
}
.au-right-menu.au-md {
  width: 450px;
}
.au-right-menu.au-sm {
  width: 320px;
}
.au-right-menu__header {
  width: 100%;
  position: relative;
  padding: 15px;
  padding-bottom: 0;
  height: 85px;
}
.au-right-menu__header h3 {
  overflow-wrap: break-word;
  margin-bottom: 0;
}
.au-right-menu__header .au-close {
  position: absolute;
  right: 10px;
  top: 15px;
  cursor: pointer;
}
.au-right-menu__header .au-close .au-icon {
  font-size: 1.4rem;
}
.au-right-menu__body {
  width: 100%;
  height: calc(100% - 140px);
  overflow: auto;
  padding: 15px;
}
.au-right-menu__body:not(.au-scroll-y)::-webkit-scrollbar {
  width: 0;
  /* Remove scrollbar space */
  background: transparent;
  /* Optional: just make scrollbar invisible */
}
.au-right-menu__footer {
  width: 100%;
  height: 60px;
  display: flex;
  align-items: center;
  gap: 5px;
  padding: 15px;
}
.au-right-menu__item.au-hide {
  display: none;
}
.au-right-menu.au-right-menu-2 {
  width: 400px;
  display: flex;
}
.au-right-menu.au-right-menu-2 .au-right-menu__item {
  width: 50%;
  border-right: 1px solid var(--au-color-secondary-light);
}
.au-right-menu.au-aside-filter .au-right-menu__header, .au-right-menu.au-aside-askauraa .au-right-menu__header {
  height: 35px;
}

[dir=rtl] .au-right-menu {
  right: auto;
  left: 0;
  /* stylelint-disable-next-line no-descending-specificity */
}
[dir=rtl] .au-right-menu__header .au-close {
  right: auto;
  left: 10px;
}

.au-box {
  display: flex;
  flex-wrap: wrap;
}
.au-box__item {
  padding: 20px;
  border: 1px solid var(--au-color-secondary-light);
  margin-right: -1px;
  margin-bottom: -1px;
  display: flex;
  flex-wrap: wrap;
  align-content: space-between;
  min-height: 220px;
}
.au-box__top {
  width: 100%;
  margin-bottom: 10px;
}
.au-box__top .au-h1,
.au-box__top .au-h4 {
  display: block;
}
.au-box__bottom {
  width: 100%;
}
.au-box .au-content {
  position: relative;
  margin-bottom: 10px;
}
@media (max-width: 599.98px) {
  .au-box .au-content svg {
    width: 100%;
    max-width: 100%;
  }
}
.au-box .au-content:last-child {
  margin-bottom: 0;
}
.au-box .au-doughnut {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  gap: 8px;
  max-height: 240px;
  margin-right: -20px;
  margin-left: -20px;
  padding: 0 20px;
  overflow-y: auto;
  overflow-x: hidden;
  scrollbar-color: #e1e1e1 var(--au-color-gray-light-3);
}
.au-box .au-doughnut::-webkit-scrollbar {
  width: 7px;
}
.au-box .au-doughnut::-webkit-scrollbar-track {
  border-radius: 10px;
  background-color: var(--au-color-gray-light-3);
}
.au-box .au-doughnut::-webkit-scrollbar-thumb {
  background: #e1e1e1;
  border-radius: 10px;
}
.au-box .au-doughnut .au-content__item {
  width: calc(25% - 8px) !important;
}
.au-box .au-doughnut-audit {
  display: flex;
  flex-wrap: wrap;
  gap: 8px;
  max-height: 240px;
  margin-right: -20px;
  margin-left: -20px;
  padding: 0 20px;
  overflow-y: auto;
  overflow-x: hidden;
  scrollbar-color: #e1e1e1 var(--au-color-gray-light-3);
}
.au-box .au-doughnut-audit::-webkit-scrollbar {
  width: 7px;
}
.au-box .au-doughnut-audit::-webkit-scrollbar-track {
  border-radius: 10px;
  background-color: var(--au-color-gray-light-3);
}
.au-box .au-doughnut-audit::-webkit-scrollbar-thumb {
  background: #e1e1e1;
  border-radius: 10px;
}
.au-box .au-doughnut-audit .au-content__item {
  width: calc(25% - 8px) !important;
}
.au-box .au-split {
  gap: 5px;
  display: flex;
  justify-content: space-between;
}
@media (max-width: 599.98px) {
  .au-box .au-split {
    flex-wrap: wrap;
  }
}
.au-box-row {
  margin-left: -20px;
  margin-right: -20px;
}
.au-box--1 .au-box__item {
  width: 100%;
}
.au-box--2 .au-box__item {
  width: calc(50% + 0.5px);
}
@media (max-width: 599.98px) {
  .au-box--2 .au-box__item {
    width: 100%;
  }
}
.au-box--3 .au-box__item {
  width: calc(33.33333% + 0.6666666667px);
}
@media (max-width: 1023.98px) {
  .au-box--3 .au-box__item {
    width: calc(50% + 0.5px);
  }
}
@media (max-width: 599.98px) {
  .au-box--3 .au-box__item {
    width: 100%;
  }
}
.au-box--4 .au-box__item {
  width: calc(25% + 0.75px);
}
@media (max-width: 1023.98px) {
  .au-box--4 .au-box__item {
    width: calc(50% + 0.5px);
  }
}
@media (max-width: 599.98px) {
  .au-box--4 .au-box__item {
    width: 100%;
  }
}
.au-box--5 .au-box__item {
  width: calc(20% + 0.8px);
}
@media (max-width: 1023.98px) {
  .au-box--5 .au-box__item {
    width: calc(50% + 0.5px);
  }
}
@media (max-width: 599.98px) {
  .au-box--5 .au-box__item {
    width: 100%;
  }
}

#main-wrapper.mini-sidebar .left-sidebar {
  width: 60px;
}
#main-wrapper.mini-sidebar .au-menu__text {
  opacity: 0;
  transition: all 0.3s;
}
#main-wrapper.mini-sidebar .au-menu__sub-title {
  display: none;
  animation: d-none 250ms ease-in-out both;
}
#main-wrapper.mini-sidebar .au-menu__sub .au-menu__sub-text {
  opacity: 0;
}
#main-wrapper .left-sidebar {
  width: 265px;
  transition: all 0.3s;
}
#main-wrapper .left-sidebar:hover {
  width: 265px;
}
#main-wrapper .left-sidebar:hover .au-menu__text {
  opacity: 1;
}
#main-wrapper .left-sidebar:hover .au-menu__item.active .au-menu__sub .au-menu__sub-text {
  opacity: 1;
}
#main-wrapper .left-sidebar:hover .au-menu__item .au-menu__sub-title {
  display: block;
}

.au-suggestion-list {
  z-index: 99996 !important;
  max-height: 240px;
  overflow-y: auto;
  overflow-x: hidden;
  color: var(--au-color-secondary-dark);
  text-align: left;
  list-style: none;
  background-color: var(--au-color-white);
  background-clip: padding-box;
  /* stylelint-disable-next-line color-function-notation, alpha-value-notation */
  border: 1px solid rgba(0, 0, 0, 0.15);
}
.au-suggestion-list__main {
  padding: 5px 0;
  margin: 0;
  font-size: 1.2rem;
}
.au-suggestion-list__items:hover {
  background-color: var(--au-color-gray-light-3);
}
.au-suggestion-list__text {
  cursor: pointer;
  padding: 6px 10px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
.au-suggestion-list__opacity--0 {
  opacity: 0;
}
.au-suggestion-list__opacity--1 {
  opacity: 1;
}

/* ==============================================
:: USER IMAGE STRUCTURE
=============================================== */
.au-profile {
  width: 100%;
  position: relative;
}
.au-profile__container {
  position: relative;
  max-width: fit-content;
  margin: auto;
}
.au-profile__img {
  width: 120px;
  height: 120px;
  max-width: 100%;
  cursor: pointer;
  border-radius: 50%;
  object-fit: cover;
  object-position: center;
}
.au-profile__icon {
  position: absolute;
  right: 0;
  top: 0;
  background: #fff;
  width: 33px;
  height: 33px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
  box-shadow: 1px 1px 5px rgba(0, 0, 0, 0.25);
  cursor: pointer;
}

/* stylelint-disable selector-class-pattern */
/* Customization of ngb-pagination, au class cannot be added */
.pagination-sm .page-link {
  font-size: 1.2rem;
}
.pagination .page-link {
  border: none;
  min-width: 20px;
  line-height: 1;
  padding: 4px 5px;
  color: var(--au-color-primary);
  text-align: center;
  border-radius: 50px;
  transition: background-color 0.15s ease-in-out;
}
.pagination .page-link:hover {
  background-color: var(--au-color-secondary-light);
}
.pagination .page-link[aria-label=Previous], .pagination .page-link[aria-label=Next] {
  padding: 4px 8px;
}
.pagination .page-item:first-child .page-link, .pagination .page-item:last-child .page-link {
  width: auto;
  height: auto;
  border-radius: 2px;
}
.pagination .page-item.active .page-link {
  background-color: var(--au-color-primary);
  color: var(--au-color-white);
}

.au-aside-filter .au-right-menu__header,
.au-aside-filter .au-form__header {
  font-weight: 500;
  text-transform: uppercase;
}
.au-aside-filter .au-right-menu__header h4,
.au-aside-filter .au-form__header h4 {
  font-size: 1.4rem;
}
.au-aside-filter .au-checkbox {
  color: var(--au-color-secondary-medium);
}
.au-aside-filter .au-nav-filter {
  max-height: 0;
  margin-bottom: 0;
  overflow: hidden;
}
.au-aside-filter .au-nav-filter.au-scroll-wrap {
  border-bottom: 1px solid #e1e1e1;
}
.au-aside-filter .au-nav-filter__item {
  margin-bottom: 10px;
  text-transform: none;
}
.au-aside-filter__accordion {
  display: none;
}
.au-aside-filter__accordion + .au-form__header {
  display: flex;
  align-items: center;
  cursor: pointer;
  margin: 0;
  padding: 8px 0;
}
.au-aside-filter__accordion + .au-form__header::before {
  content: none;
}
.au-aside-filter__accordion:checked + .au-form__header .au-icon {
  transform: rotate(180deg);
}
.au-aside-filter__accordion:checked + .au-form__header ~ .au-nav-filter {
  max-height: 500vh;
  overflow: auto;
  margin-bottom: 4px;
  scrollbar-width: none;
}
.au-aside-filter__accordion:checked + .au-form__header ~ .au-nav-filter::-webkit-scrollbar {
  width: 0;
  height: 0;
  background: transparent;
}
.au-aside-filter .au-form {
  margin-bottom: 0;
}

/* stylelint-disable-next-line selector-class-pattern */
.tooltip {
  z-index: 99999;
}
.tooltip[data-popper-placement=bottom-start], .tooltip[data-popper-placement=top-start] {
  /* stylelint-disable-next-line selector-class-pattern */
}
.tooltip[data-popper-placement=bottom-start] .tooltip-arrow::before, .tooltip[data-popper-placement=top-start] .tooltip-arrow::before {
  transform: translateX(-100%);
}
.tooltip-inner {
  overflow: hidden;
  text-overflow: ellipsis;
  /* stylelint-disable-next-line value-no-vendor-prefix */
  display: -webkit-box;
  -webkit-line-clamp: 15;
  line-clamp: 2;
  -webkit-box-orient: vertical;
  max-width: 250px;
  max-height: 250px;
  line-height: 16px;
}

/* stylelint-disable selector-class-pattern */
@media print {
  .au-btn,
.au-dropdown {
    display: none !important;
  }
  .col-sm-1,
.col-md-1,
.col-lg-1 {
    flex: 1 0 8.3333%;
  }
  .col-sm-2,
.col-md-2,
.col-lg-2 {
    flex: 1 0 16.6667%;
  }
  .col-sm-3,
.col-md-3,
.col-lg-3 {
    flex: 1 0 25%;
  }
  .col-sm-4,
.col-md-4,
.col-lg-4 {
    flex: 1 0 33.3333%;
  }
  .col-sm-5,
.col-md-5,
.col-lg-5 {
    flex: 1 0 41.6667%;
  }
  .col-sm-6,
.col-md-6,
.col-lg-6 {
    flex: 1 0 50%;
  }
  .col-sm-7,
.col-md-7,
.col-lg-7 {
    flex: 1 0 58.3333%;
  }
  .col-sm-8,
.col-md-8,
.col-lg-8 {
    flex: 1 0 66.6667%;
  }
  .col-sm-9,
.col-md-9,
.col-lg-9 {
    flex: 1 0 75%;
  }
  .col-sm-10,
.col-md-10,
.col-lg-10 {
    flex: 1 0 83.3333%;
  }
  .col-sm-11,
.col-md-11,
.col-lg-11 {
    flex: 1 0 91.6667%;
  }
  .col-sm-12,
.col-md-12,
.col-lg-12 {
    flex: 1 0 100%;
  }
  app-kri-process .row,
.au-chart .row {
    display: flex !important;
  }
  app-kri-process .row .col-md-3,
.au-chart .row .col-md-3 {
    width: 25% !important;
  }
  app-kri-process .row .col-md-4,
.au-chart .row .col-md-4 {
    width: 33.33% !important;
  }
  app-kri-process .row .col-md-5,
.au-chart .row .col-md-5 {
    width: 41.5% !important;
  }
  app-kri-process .row .col-md-6,
.au-chart .row .col-md-6 {
    width: 50% !important;
  }
  app-kri-process .row .col-md-7,
.au-chart .row .col-md-7 {
    width: 58.3% !important;
  }
  app-kri-process .row .col-md-8,
.au-chart .row .col-md-8 {
    width: 66.5% !important;
  }
  .au-status-card .au-status-card__item {
    width: 100% !important;
    min-width: unset !important;
  }
  .au-filter__block, .au-grid__header .row .col-12:last-child, .au-page__footer {
    display: block !important;
  }
  .au-grid__responsive {
    max-height: 100% !important;
    overflow: visible !important;
  }
  .au-tag {
    display: inline-block;
    overflow: hidden;
    text-overflow: ellipsis;
    max-width: 100%;
    height: auto;
  }
  table .au-tag {
    display: inline-block !important;
    min-height: 19px;
  }
  .au-filter__block .au-tag {
    margin-right: 5px;
  }
  body {
    /* stylelint-disable-next-line color-plugin/variable */
    -webkit-print-color-adjust: exact;
  }
  header.topbar,
aside.left-sidebar,
.table-footer,
.search-row,
.header-breadcrumb,
.breadcrumb {
    display: none !important;
  }
  button {
    display: inline-block !important;
  }
  button .au-icon {
    display: none !important;
  }
  button.au-btn-clr {
    display: none !important;
  }
  .comments-floating-icon-div-open {
    display: none !important;
  }
  .au-comment-bubble {
    display: none !important;
  }
  .print-logo {
    margin: 10px;
    display: block !important;
  }
  a {
    text-decoration: none !important;
  }
  .page-wrapper {
    margin: 0 !important;
    background: white !important;
  }
  .breadcrumbPadding {
    padding: 25px;
  }
  #main-wrapper .left-sidebar {
    display: none !important;
  }
  #main-wrapper[data-layout=vertical][data-sidebartype=full] .page-wrapper {
    margin-left: 0 !important;
  }
  .au-navbar__menu-item {
    display: none;
  }
  .au-navbar__list .au-navbar__item:first-child {
    display: none;
  }
  .topbar .top-navbar {
    border-bottom: 0;
  }
  .au-gap--0 {
    row-gap: unset !important;
  }
  .au-gap--1 {
    row-gap: unset !important;
  }
  .au-gap--2 {
    row-gap: unset !important;
  }
  .au-gap--3 {
    row-gap: unset !important;
  }
  .au-gap--4 {
    row-gap: unset !important;
  }
  .au-gap--5 {
    row-gap: unset !important;
  }
  .au-page-break-inside {
    -webkit-column-break-inside: avoid;
    page-break-inside: avoid;
    break-inside: avoid;
    width: 100%;
  }
  .au-d3 svg {
    height: auto;
  }
  .au-form * {
    gap: unset !important;
  }
  .au-form__header {
    margin-bottom: 8px;
  }
  .au-form__header .au-label {
    margin-right: 4px;
  }
  .au-page__action * {
    gap: unset !important;
  }
  .au-card * {
    gap: unset !important;
  }
  .au-card .au-gap--2 {
    margin: 0 -5px;
  }
  .au-card .au-gap--2 > div {
    margin: 0 5px;
  }
  .au-box--3 .au-box__item {
    flex: 1 1 calc(33.3333% + 0.6667px);
  }
  .au-box .au-doughnut {
    overflow: visible;
    max-height: none;
    justify-content: flex-start;
  }
  .au-box .au-doughnut .au-content__item {
    margin: 4px;
  }
  .au-box-row {
    width: auto;
  }
  .au-legend__text {
    padding-left: 6px;
  }
}
.au-ie-only {
  display: none !important;
}

@media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
  .au-ie-only {
    display: block !important;
  }
  .au-ie {
    padding: 32px 24px;
    height: 100vh;
    text-align: center;
    display: flex !important;
    align-items: center;
    justify-content: center;
  }
  .au-ie__inner {
    width: 100%;
    max-width: 700px;
    margin: 0 auto;
    padding: 40px;
    box-shadow: 0px 0px 24px rgba(0, 0, 0, 0.16);
  }
  .au-ie__inner .au-browser-alert__item img {
    height: 45px;
  }
  .au-ie__inner .au-browser-alert__item-text {
    font-size: 1.2rem;
    margin-top: 8px;
  }
  .au-ie__logo {
    width: 140px;
    height: auto;
    display: block;
    margin: 0 auto 40px;
  }
  .au-ie__head {
    font-size: 2.4rem;
    line-height: 1.3;
    color: var(--au-color-primary);
    font-weight: 500;
    margin-bottom: 24px;
  }
  .au-ie__desc {
    line-height: 1.3;
  }
}
/* stylelint-disable selector-class-pattern */ /* stylelint-disable custom-property-no-missing-var-function */
/* stylelint-disable color-plugin/variable */
:root {
  --au-color-primary: #0b3a62;
  --au-color-primary-light: #4aafff;
  --au-color-primary-dark: #115caa;
  --au-color-secondary: #555;
  --au-color-secondary-dark: #000;
  --au-color-secondary-medium: #999;
  --au-color-secondary-light: #e1e1e1;
  --au-color-secondary-light-2: #f9f9f9;
  --au-color-secondary-light-3: #ccc;
  --au-color-secondary-light-4: #efefef;
  --au-color-black: #000;
  --au-color-black-1: #0f0f0f;
  --au-color-dark-blue: #011826;
  --au-color-dark-blue-1: #212529;
  --au-color-dark-blue-2: #0c3a62;
  --au-color-dark-blue-3: #1f3953;
  --au-color-white: #fff;
  --au-color-green: #6b6;
  --au-color-green-1: #7dbb42;
  --au-color-green-2: #61dbb5;
  --au-color-green-3: #1dbb99;
  --au-color-red: #e53535;
  --au-color-red-1: #ef6e6e;
  --au-color-red-2: #ad0000;
  --au-color-yellow: #ffa600;
  --au-color-orange: #fdae61;
  --au-color-orange-1: #fcce56;
  --au-color-orange-2: #ffdeb4;
  --au-blue-violet: #8a2be2;
  --au-blue-green: #91d8e4;
  --au-color-gray-light-3: #f2f4f5;
  --au-color-gray-light-2: #f5f5f5;
  --au-color-gray-light-4: #eee;
  --au-color-gray-light-5: #dedbda;
  --au-color-gray-light-6: #d1d1d1;
  --au-color-gray-light-7: #f1f1f1;
  --au-color-gray-light-8: #c4c2c2;
  --au-color-gray-light-9: #f6f6f6;
  --au-color-currentcolor: currentcolor;
  --au-color-grey: #333;
  --au-color-grey2: #323338;
  --au-color-grey3: #5b5b5b;
  --au-color-grey4: #777;
  --au-color-grey5: #ced4da;
  --au-color-grey6: #6c757d;
  --au-color-grey7: #5c5957;
  --au-color-grey8: #575757;
  --au-color-grey9: #2c2c2c;
  --au-color-grey10: #4f5467;
  --au-color-grey11: #e8e8e8;
  --au-color-white-light-4: #eaeef4;
  --au-color-white-light-5: #cde0f0;
  --au-color-white-light-6: #e5e5e5;
  --au-color-white-light-7: #dee2e6;
  --au-color-white-light-8: #dee2e6;
  --au-color-white-light-9: #efefef;
  --au-color-blue: #134c88;
  --au-color-blue-1: #1c8adb;
  --au-color-blue-2: #1f91f2;
  --au-color-blue-3: #337ab7;
  --au-color-blue-4: #2c65ae;
  --au-color-blue-5: #2962ff;
  --au-color-blue-light-1: #a3c8e4;
  --au-color-blue-light-2: #5b8ca5;
  --au-color-blue-light-3: #115caa33;
  --au-color-dark-green: #008000;
  --au-color-q1: #115caa;
  --au-color-q2: #8559b4;
  --au-color-q3: #cf4fa3;
  --au-color-q4: #ff537c;
  --au-color-q5: #ff764b;
  --au-color-q6: #ffa600;
  --au-color-q7: #003b6d;
  --au-color-q8: #4aafff;
  --au-color-q9: #ccd9e9;
  --au-color-q10: #aec4df;
  --au-color-q11: #c9ecce;
  --au-color-d1: #6b6;
  --au-color-d2: #9ecc6c;
  --au-color-d3: #d0dc7a;
  --au-color-d4: #d0dc7a;
  --au-color-d5: #f9b859;
  --au-color-d6: #f27e3a;
  --au-color-d7: #e53535;
  --au-color-d8: #ffbfc1;
  --au-color-s1: #115caa;
  --au-color-s2: #0878bc;
  --au-color-s3: #2a94cb;
  --au-color-s4: #4fb0d7;
  --au-color-s5: #77cae3;
  --au-color-s6: #a0e4f0;
  --au-color-s7: #cafeff;
  --au-color-s8: #b8dbff;
  --au-color-s9: #cde0f0;
  --au-color-s10: #cee8ff;
  --au-color-s11: #e2e9ec;
  --au-color-s12: #ebf5ff;
  --au-color-transparent: transparent;
  --au-theme-white: #fff;
  --au-theme-black: #000;
}

/* stylelint-disable property-no-vendor-prefix */
/* Fixed Spacing Variables */
/* Em Based Spacing Variables */
/* stylelint-disable-next-line color-function-notation, alpha-value-notation */
/* stylelint-disable-next-line color-function-notation, alpha-value-notation */
/* stylelint-disable-next-line scss/dollar-variable-pattern */
.ngx-toastr {
  background-image: url("data:image/svg+xml,%3Csvg width='30' height='30' viewBox='0 0 30 30' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M28.9987 14.8125C28.8924 7.08193 22.543 0.901205 14.8124 1.0012C7.08189 1.10119 0.901205 7.4569 1.0012 15.1875C1.10744 22.9181 7.45686 29.0988 15.1874 28.9988C22.918 28.8926 29.1049 22.5431 28.9987 14.8125ZM14.9999 5.99452C16.1061 5.99452 16.9997 6.88819 16.9997 7.99435C16.9997 9.1005 16.1061 9.99418 14.9999 9.99418C13.8938 9.99418 13.0001 9.1005 13.0001 7.99435C13.0001 6.88819 13.8938 5.99452 14.9999 5.99452ZM17.7497 23.6868H12.2502V22.9993H13.625V13.0002H12.2502V12.2502H16.3748V22.9993H17.7497V23.6868Z' fill='%232c65ae'/%3E%3C/svg%3E%0A");
}
.ngx-toastr::before {
  background-color: var(--au-color-blue-4);
}
.ngx-toastr.toast-success {
  background-image: url("data:image/svg+xml,%3Csvg width='30' height='30' viewBox='0 0 30 30' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M15 1C12.2311 1 9.52431 1.82109 7.22202 3.35942C4.91973 4.89776 3.12532 7.08427 2.06569 9.64243C1.00607 12.2006 0.728819 15.0155 1.26901 17.7313C1.80921 20.447 3.14258 22.9416 5.10051 24.8995C7.05845 26.8574 9.55301 28.1908 12.2687 28.731C14.9845 29.2712 17.7994 28.9939 20.3576 27.9343C22.9157 26.8747 25.1022 25.0803 26.6406 22.778C28.1789 20.4757 29 17.7689 29 15C29 11.287 27.525 7.72601 24.8995 5.1005C22.274 2.475 18.713 1 15 1ZM22.2635 12.0816L13.3544 20.9907C13.1157 21.2293 12.792 21.3634 12.4545 21.3634C12.1171 21.3634 11.7934 21.2293 11.5547 20.9907L7.73655 17.1725C7.50471 16.9325 7.37643 16.611 7.37933 16.2773C7.38223 15.9436 7.51608 15.6244 7.75205 15.3884C7.98803 15.1524 8.30724 15.0186 8.64095 15.0157C8.97465 15.0128 9.29615 15.1411 9.53619 15.3729L12.4545 18.2913L20.4638 10.282C20.7039 10.0502 21.0254 9.92188 21.3591 9.92478C21.6928 9.92768 22.012 10.0615 22.248 10.2975C22.4839 10.5335 22.6178 10.8527 22.6207 11.1864C22.6236 11.5201 22.4953 11.8416 22.2635 12.0816Z' fill='%236b6'/%3E%3C/svg%3E");
}
.ngx-toastr.toast-success::before {
  background-color: var(--au-color-green);
}
.ngx-toastr.toast-error {
  background-image: url("data:image/svg+xml,%3Csvg width='30' height='30' viewBox='0 0 30 30' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M15 29C22.732 29 29 22.732 29 15C29 7.26801 22.732 1 15 1C7.26801 1 1 7.26801 1 15C1 22.732 7.26801 29 15 29Z' fill='%23e53535'/%3E%3Cpath d='M20.194 8.81674L21.1618 9.78466C21.4959 10.1187 21.4959 10.6603 21.1618 10.9944L11.0014 21.1547C10.6673 21.4888 10.1257 21.4888 9.79163 21.1547L8.82377 20.1868C8.48973 19.8528 8.48973 19.3112 8.82377 18.9771L18.9842 8.81663C19.3182 8.48265 19.8599 8.48265 20.194 8.81674Z' fill='white'/%3E%3Cpath d='M21.1618 20.1869L20.1939 21.1548C19.8599 21.4888 19.3182 21.4888 18.9842 21.1548L8.82377 10.9944C8.48973 10.6603 8.48973 10.1187 8.82377 9.7846L9.79168 8.81669C10.1257 8.48265 10.6673 8.48265 11.0014 8.81669L21.1618 18.9771C21.4959 19.3112 21.4959 19.8528 21.1618 20.1869Z' fill='white'/%3E%3C/svg%3E%0A");
}
.ngx-toastr.toast-error::before {
  background-color: var(--au-color-red);
}
.ngx-toastr.toast-warning {
  background-image: url("data:image/svg+xml,%3Csvg width='30' height='30' viewBox='0 0 30 30' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath fill-rule='evenodd' clip-rule='evenodd' d='M15 1C7.3 1 1 7.3 1 15C1 22.7 7.3 29 15 29C22.7 29 29 22.7 29 15C29 7.3 22.7 1 15 1ZM16.4 22H13.6V19.2H16.4V22ZM16.4 16.4H13.6V8H16.4V16.4Z' fill='%23ffa600'/%3E%3C/svg%3E%0A");
}
.ngx-toastr.toast-warning::before {
  background-color: var(--au-color-yellow);
}

.toast-container .ngx-toastr {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  background-color: var(--au-color-white);
  background-size: 20px;
  background-position: 13px center;
  color: var(--au-color-black);
  padding: 16px 30px 16px 44px;
  width: 320px;
  min-height: 60px;
  /* stylelint-disable-next-line color-function-notation, alpha-value-notation */
  box-shadow: 0 0 20px rgba(0, 0, 0, 0.25);
  animation: slide-down 0.3s linear forwards;
  word-break: break-word;
}
.toast-container .ngx-toastr::before {
  content: "";
  width: 3px;
  height: calc(100% - 25px);
  border-radius: 0 10px 10px 0;
  position: absolute;
  top: 50%;
  left: 0;
  transform: translateY(-50%);
}
.toast-container .ngx-toastr:hover {
  /* stylelint-disable-next-line color-function-notation, alpha-value-notation */
  box-shadow: 0 0 20px rgba(0, 0, 0, 0.35);
}

.toast-title {
  width: 100%;
  font-size: 1.4rem;
  font-weight: 600;
  margin-bottom: 4px;
}

.toast-close-button {
  color: var(--au-color-secondary);
  position: absolute;
  font-weight: 400;
  top: 50%;
  right: 10px;
  transform: translateY(-50%);
  opacity: 0.6;
  transition: color 0.3s;
}
.toast-close-button:hover {
  opacity: 1;
}

@keyframes slide-down {
  from {
    transform: translateY(-10px);
  }
  to {
    transform: translateY(0);
  }
}
@media print {
  .toast-container {
    display: none;
  }
}
header .nav-link {
  display: flex;
  align-items: center;
}
header .nav-item-user-name {
  font-size: 1.4rem;
}
header .nav-item {
  margin-bottom: 0;
}
header .ti-angle-down {
  font-size: 1.4rem;
}
header .navbar-nav.core-links {
  gap: 4px;
  margin-top: 14px !important;
}
header .logo-icon img {
  margin-top: 15px !important;
}
header .dropdown-menu-header i {
  font-size: 1rem;
}
header .notification-arrow {
  margin-left: 239px;
}
header .notifi-line-height {
  line-height: 1.8rem;
}
header .notifi-line-height .notification-list-type small {
  font-size: 1.2rem;
}

.sidebar-nav .sidebar-item {
  margin-bottom: 0;
}

.collapse.in {
  display: block;
}

.page-breadcrumb {
  margin-bottom: 10px !important;
}

.globalfilter .card-header {
  padding: 1.2rem 1.6rem;
  background-color: #ffffff;
}
.globalfilter .card-footer {
  width: 100%;
}
.globalfilter .card-footer .background-dark-btn {
  background-color: #0b3a62;
  color: #fff;
  border-radius: 25px;
  margin-left: 10px;
  padding: 6px 15px !important;
}
.globalfilter .card-footer .background-white-btn {
  background-color: #fff;
  color: #0b3a62;
  margin-left: 8px;
  border-radius: 25px;
  border: 1px solid #0b3a62;
}

.commentSectionOfSidebar .transitionForTextarea {
  height: 40px;
  min-height: 40px !important;
  max-height: 300px;
}
.commentSectionOfSidebar .btn.disabled,
.commentSectionOfSidebar .btn:disabled {
  opacity: 0.65;
}
.commentSectionOfSidebar .comment-sidebar-add-btn {
  padding: 0 15px !important;
  height: 21px !important;
}
.commentSectionOfSidebar .background-dark-btn {
  background-color: #0b3a62;
  color: #fff;
  border-radius: 25px;
  margin-left: 10px;
  padding: 6px 15px !important;
  border: 1px solid #0b3a62;
  font-size: 11px !important;
}
.commentSectionOfSidebar .background-dark-btn,
.commentSectionOfSidebar .background-white-btn {
  border-radius: 25px;
  padding: 2px 15px !important;
  border: 1px solid #0b3a62;
}
.commentSectionOfSidebar .editCheckbox {
  font-size: 11px !important;
}
.commentSectionOfSidebar .comment-wrapper {
  margin-bottom: 10px;
}
.commentSectionOfSidebar .comment-link {
  margin-top: 5px;
  display: block;
}

.swal2-container {
  z-index: 99992 !important;
}

.dropup {
  z-index: 99991 !important;
}

.copy-right {
  font-size: 1.2rem;
}

.filter-btn {
  padding: 2px 20px !important;
}

.filter-space {
  margin-bottom: 8px !important;
}

.cover-item span img {
  margin-right: 5px;
}

.card-border {
  border: 0 !important;
}

.card-bottom {
  margin-bottom: 1.5rem !important;
}

.au-table-action-menu {
  line-height: 100%;
}

.toast-message {
  font-size: 14px !important;
}

.ng-select .ng-select-container .ng-value-container .ng-input > input {
  caret-color: transparent;
}

.breadcrumb {
  display: flex;
  flex-wrap: wrap;
  padding: 0.75rem 1rem;
  margin-bottom: 1.5rem;
  list-style: none;
  background-color: #f9f9f9;
  border-radius: 2px;
}

.breadcrumb-item + .breadcrumb-item {
  padding-left: 0.5rem;
}
.breadcrumb-item + .breadcrumb-item::before {
  display: inline-block;
  padding-right: 0.5rem;
  color: #6c757d;
  content: ">";
}
.breadcrumb-item + .breadcrumb-item:hover::before {
  text-decoration: underline;
}
.breadcrumb-item + .breadcrumb-item:hover::before {
  text-decoration: none;
}

.audit-details .breadcrumb {
  padding-left: 16px;
}