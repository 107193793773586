/*
* DO NOT EDIT THIS FILE
*/

// Spacing adjust start
/* To adjust column spacing according to bootstrap 5. Can be removed once updated to bootstrap 5 */
.row {
  & > [class ^= 'col'] {
    position: relative;
  }
}
// Spacing adjust end

// Tooltip
.tooltip{
  font-size: 1.2rem;
  font-weight: 400;
}

// Dropdown
.dropdown-item{
  padding: 0.65rem 1rem;
  &:hover,
  &:focus {
    background-color: $au-color-gray-light-3;
  }
}

.btn.focus,
.btn:focus{
  outline: none;
  box-shadow: none;
}

.accordion{
  .collapsing{
    transition: none;
  }
  & > .accordion-item {
    overflow: initial;
  }
  .au-accordion .accordion-item{
    margin-bottom: 1px;
  }
}
