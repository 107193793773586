// Status card with progress
.au-status-card-progress {
  display: flex;
  margin-bottom: 15px;
  gap: 15px;
  overflow: auto;
  padding-bottom: $spacer-1;

  &::-webkit-scrollbar {
    height: 7px;
  }

  &::-webkit-scrollbar-track {
    border-radius: 10px;
    background-color: transparent;
  }

  &::-webkit-scrollbar-thumb {
    background: $color-secondary-light;
    border-radius: 10px;
  }

  scrollbar-color: $au-color-secondary-light transparent;

  &__item {
    width: 100%;
    min-width: 180px;

    @include tablet {
      min-width: 210px;
    }
  }

  &__title {
    margin-bottom: 5px;
    font-size: $au-fs-3;
    display: block;

    .au-icon {
      font-size: $au-fs-1;
    }
  }

  &__value {
    display: block;
    font-size: $au-fs-10;
    font-weight: $font-weight-medium;
  }

  .au-card {
    padding: 8px;

    &__body {
      display: flex;
    }
  }

  .au-left {
    width: calc(100% - 60px);
  }

  .au-right {
    width: 60px;
  }
}

// Status Card
.au-status-card {
  display: flex;
  margin-bottom: 8px;
  padding-bottom: 4px;
  overflow: auto;

  &::-webkit-scrollbar {
    height: 7px;
  }

  &::-webkit-scrollbar-track {
    border-radius: 10px;
    background-color: transparent;
  }

  &::-webkit-scrollbar-thumb {
    background: $au-color-secondary-light;
    border-radius: 10px;
  }

  scrollbar-color: $au-color-secondary-light transparent;

  &__head {
    margin-bottom: 5px;
  }

  &__title,
  &__value {
    display: block;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  &__title {
    font-size: $au-fs-2;
    color: $au-color-secondary-medium;
    line-height: 1.35;
  }

  &__value {
    font-size: $au-fs-10;
    line-height: 100%;
    color: $au-color-primary;
    font-weight: $font-weight-medium;
  }

  &__item {
    width: 100%;
    background: $au-color-white;

    @include shadow-2;

    padding: 10px;
    border-top-width: 2px;
    border-style: solid;
    display: flex;
    justify-content: space-between;
    cursor: pointer;
    min-width: 100px;

    @include tablet {
      min-width: 210px;
    }

    &.au-active {
      background-color: $au-color-primary;
      border-top-color: $au-color-primary;

      .au-status-card__title,
      .au-status-card__value {
        color: $au-color-white;
      }
    }
  }

  .au-left {
    width: 100%;
  }

  .au-right {
    width: 30%;

    &:empty {
      display: none;
    }
  }
}
