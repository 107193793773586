/* stylelint-disable selector-class-pattern */

/* Customization of ngb-pagination, au class cannot be added */
.pagination {
  &-sm {
    .page-link {
      font-size: 1.2rem;
    }
  }

  .page-link {
    border: none;
    min-width: 20px;
    line-height: 1;
    padding: $spacer-1 5px;
    color: $au-color-primary;
    text-align: center;
    border-radius: 50px;
    transition: background-color 0.15s ease-in-out;

    &:hover {
      background-color: $au-color-secondary-light;
    }

    &[aria-label="Previous"],
    &[aria-label="Next"] {
      padding: $spacer-1 $spacer-2;
    }
  }

  .page-item {
    &:first-child,
    &:last-child {
      .page-link {
        width: auto;
        height: auto;
        border-radius: 2px;
      }
    }

    &.active {
      .page-link {
        background-color: $au-color-primary;
        color: $au-color-white;
      }
    }
  }
}
