/*******************/
/*Comment widgets*/
/*******************/
.comments-vertical-scroll{
    max-height: 80vh;
    overflow: scroll;
}
.comment-widgets {
    position: relative;
    margin-bottom: 10px;
    .comment-row {
        //border-bottom:1px solid transparent;
        padding: 14px;
        display: flex;
        margin: 10px 0;
        &:last-child{
            border-bottom:0px;
        }
        &:hover,
        &.active {
            background:$transparent-dark-bg;
        }
    }
}

.comment-text {
    padding-left: 15px;
    width:100%;
    &:hover .comment-footer,
    &.active .comment-footer {
        .action-icons {
            visibility: visible;
        }
    }
    p {
        max-height: 65px;
        width:100%;
        overflow: hidden;

    }
}

.comment-footer {
    .action-icons {
        visibility: hidden;
        a {
            padding-left: 7px;
            vertical-align: middle;
            color: $gray-500;
            &:hover,
            &.active {
                color: $info;
            }
        }
    }
}
.cardPadding {
    padding: 3rem;
}
.commentstextarea {
    // width: 65rem;
    height: 7rem;
    border-color: white;
    border-radius: 5px;
    padding: 1rem;
}
.commentscard-border {
    border-radius: 10px;
}
.editCheckbox {
    width: 56rem;
    border-radius: 5px;
    border-color: #d3d1d1;
}
.displayOnlyOnHover {
    display:none;
  }
.commentSection:hover + .displayOnlyOnHover {
    display: block;
}
.iconForEdit{
    height: 1.4rem;
}
.commentDropdowntext{
    color: #003b6d;
    padding-top: 0em;
    padding-bottom: 0rem;
    margin-bottom: 0rem;
}
.actionStatusDropdown{
    width: 25rem;
}
.commentsStatusbtn {
    // padding: 2px 20px !important;

    // height: 20px ;
    color: white;
    width: auto;
    margin-right: 3px;
    font-weight: 400;
    border-radius: 25px;
    padding: 0px 7px !important;
    font-size: 12px !important;
    height: 21px;
    display: inline-block;
    text-align: center;
    border: 1px solid transparent;
    line-height: 1.5;
}
.commentsSidebarStatusbtn {
  color: white;
  width: auto;
  font-weight: 400;
  border-radius: 25px;
  padding: 0px 7px !important;
  font-size: 10px !important;
  height: 16px;
  display: inline-block;
  text-align: center;
  border: 1px solid transparent;
  line-height: 1.5;
}
.uploadFile{
    margin-bottom: 0.5rem;
    font-size: 14px;
}
.z-999 {
  z-index: 999;
}
