/* stylelint-disable selector-class-pattern */
// Modal
.au-modal {
  position: fixed;
  z-index: 99991 !important;

  &__close {
    position: absolute;
    top: 0;
    right: 0;
    border: 0;
    background: transparent;
    padding: 20px;
    font-weight: bold;
    cursor: pointer;

    i {
      font-size: $au-fs-3;
    }
  }

  .modal-content {
    position: relative;
  }

  &__body {
    overflow-x: hidden;
    overflow-y: auto;
    padding: 20px;
    padding-top: 15px;

    @include hide-scroll;

    .color-picker {
      top: 50% !important;
      transform: translateY(-50%);

      .arrow {
        display: none;
      }
    }
  }

  &__header {
    padding: 20px;
    padding-bottom: 0;

    h3 {
      padding-right: 20px;
      line-height: 1.1;
    }
  }

  &__footer {
    padding: 20px;
    padding-top: 0;
  }
}

.au-export-wrap {
  width: 100%;
  text-align: center;
  padding: 50px 0;

  &__box {
    display: flex;
    justify-content: center;
    gap: 20px;
  }

  &__item {
    text-align: center;
    margin-bottom: 20px;

    img {
      width: 60px;
      display: block;
      margin: 0 auto;
      margin-bottom: 10px;
    }
  }
}

.modal-backdrop {
  z-index: 99991 !important;
}

.cdk-overlay-pane {
  justify-content: center !important;

  &.au-time-picker-align {
    justify-content: flex-start !important;
  }

  .file-img {
    overflow: auto !important;
  }
}
