/* stylelint-disable selector-class-pattern */
// Arabic

[dir="rtl"] {
  // side menu
  .au-menu {
    &__icon {
      margin-right: 15px;
      margin-left: 0;
    }

    &__link {
      &::before {
        left: auto;
        right: 0;
        border-radius: 50px 0 0 50px !important;
      }
    }

    &__sub-icon {
      margin-right: 17px !important;
      margin-left: 28px;
    }
  }

  // top print icon
  .au-page {
    &__action {
      .au-right {
        text-align: left;
      }
    }
  }

  // Calendar input
  .au-calendar {
    input {
      text-align: right;
    }
  }

  .au-accordion {
    .card .card-header {
      button {
        padding: 20px 25px 20px 20px;
      }
    }

    .accordion-item {
      .accordion-header button {
        padding: 20px 25px 20px 20px !important;
      }

      .accordion-body .au-right {
        margin-right: auto;
        margin-left: $spacer-0;
      }
    }
  }

  .au-tab {
    &__item {
      .au-tab__dropdown {
        right: auto;
        left: 0;
      }
    }
  }

  .ql-toolbar {
    display: flex;
  }

  .ql-editor {
    direction: rtl;
    text-align: right;
  }

  .ql-editor ol li,
  .ql-editor ul li {
    padding-right: 1.5em;
    direction: rtl;
  }

  .ql-editor li:not(.ql-direction-rtl)::before {
    margin-left: 0;
  }
}
