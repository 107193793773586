// Grid
// .au-row {
//   display: grid;
//   column-gap: $grid-gutter-space;
//   row-gap: $grid-gutter-space;
// }
// .au-col {
//   &--1 {
//     grid-template-columns: auto;
//   }
//   &--2 {
//     grid-template-columns: auto auto;
//   }
//   &--3 {
//     grid-template-columns: auto auto auto;
//   }
//   &--4 {
//     grid-template-columns: auto auto auto auto;
//   }
//   &--5 {
//     grid-template-columns: auto auto auto auto auto;
//   }
//   &--6 {
//     grid-template-columns: auto auto auto auto auto auto;
//   }
//   &--7 {
//     grid-template-columns: auto auto auto auto auto auto auto;
//   }
//   &--8 {
//     grid-template-columns: auto auto auto auto auto auto auto auto;
//   }
//   &--9 {
//     grid-template-columns: auto auto auto auto auto auto auto auto auto;
//   }
//   &--10 {
//     grid-template-columns: auto auto auto auto auto auto auto auto auto auto;
//   }
//   &--11 {
//     grid-template-columns: auto auto auto auto auto auto auto auto auto auto auto;
//   }
//   &--12 {
//     grid-template-columns: auto auto auto auto auto auto auto auto auto auto auto auto;
//   }

//   &--1-11 {
//     grid-template-columns: auto auto;
//   }
// }

//Flex
// .au-flex {
//   display: flex;
//   &.--v-center {
//     align-items: center;
//   }
//   &.--h-center {
//     justify-content: center;
//   }
//   &.au-align {
//     &--right {
//       justify-content: flex-end;
//     }
//   }
// }

//Colum row space
.au-row__gap {
  > [class^="col-"] {
    margin-bottom: 15px;
  }
}

//Spacer
.au-gap {
  &--1 {
    column-gap: 10px;
  }
}
