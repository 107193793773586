// Tab with dropdown
/* stylelint-disable-line */
.au-tab {
  display: flex;

  &__link {
    white-space: nowrap;
    display: block;
    font-size: 1.4rem;
    line-height: inherit;
    color: $au-color-black;
    cursor: pointer;
    padding: 8px 0;
    border-bottom: 2px solid transparent;
    position: relative;

    @include transition(0.4s);

    &:hover,
    &.au-active {
      color: $au-color-black !important;
      border-bottom: 2px solid $au-color-black;
    }

    &.au-active {
      font-weight: $font-weight-semi;
    }
  }

  &__badge {
    position: absolute;
    line-height: 1;
    background: $au-color-primary-light;
    color: $au-color-white;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: $au-fs-1;
    top: -7px;
    right: -10px;
    border-radius: 100px;
    padding: 3px 5px;
    cursor: pointer;
  }

  &__item {
    padding: 0 14px;
    position: relative;

    .au-tab__dropdown {
      position: absolute;
      display: none;
      right: 0;
      background: $au-color-white;
      z-index: 100000;
      padding: 10px;
      /* stylelint-disable-next-line color-function-notation, alpha-value-notation */
      box-shadow: 0 0 10px rgba(193, 193, 193, 0.69);

      .au-tab__badge {
        position: relative;
        top: -1px;
        right: 0;
        margin-left: $spacer-2;
      }

      .au-tab__link {
        border-bottom: 2px solid transparent;

        /* stylelint-disable-next-line max-nesting-depth */
        &:hover {
          border-bottom: 2px solid $au-color-black;
        }
      }

      /* stylelint-disable-next-line selector-class-pattern */
      .button-drop-active {
        font-weight: $font-weight-medium;
        border-bottom: 0 !important;
      }
    }

    &:hover {
      .au-tab__dropdown {
        display: block;
      }

      /* stylelint-disable-next-line no-descending-specificity */
      >.au-tab__link {
        border-bottom: 2px solid $au-color-black;
      }
    }
  }

  &__dropdown-item {
    margin: 6px $spacer-2;
    color: $au-color-black;
    text-transform: uppercase;
    font-size: 1.4rem;
    white-space: nowrap;
    display: flex;
    align-items: center;

    &.au-active {
      color: $au-color-primary;
      font-weight: $font-weight-medium;
    }
  }

  &__dropdown-inner {
    &--two-col,
    &--three-col {
      display: flex;
      flex-wrap: wrap;
      max-height: 250px;
      overflow-x: auto;

      &::-webkit-scrollbar {
        height: 5px;
      }

      &::-webkit-scrollbar-track {
        border-radius: 10px;
        background-color: $au-color-transparent;
      }

      &::-webkit-scrollbar-thumb {
        background: $au-color-secondary-light;
        border-radius: 10px;
        height: 5px;
      }
    }

    &--two-col {
      min-width: 380px;

      .au-tab__dropdown-item {
        width: calc(50% - 16px);
      }
    }

    &--three-col {
      min-width: 580px;
      flex-direction: column;

      .au-tab__dropdown-item {
        width: calc(33.33% - 16px);
      }
    }
  }

  &--main {
    .au-tab__item {
      cursor: pointer;
      font-size: $au-fs-4;
      font-weight: $font-weight-normal;
      background: $au-color-gray-light-3;
      padding: 12px 24px;
      text-transform: uppercase;
      color: $au-color-secondary;

      @include transition(solid 0.4s);
      border: 0px -4px 8px rgba(225, 225, 225, 0.46); /* stylelint-disable-line */
      &.au-active {
        font-weight: $font-weight-medium;
        box-shadow: 2px -5px 5px 2px rgba(225, 225, 225, .46); /* stylelint-disable-line */
        color: $au-color-black;
        background: $au-color-white;
      }
    }
  }
}

// Page header tab
.au-page-tab {
  width: 100%;
  display: inline-flex;
  align-items: center;
  overflow: hidden;
  border-radius: 100px;
  border: 1px solid $au-color-secondary-light;
  margin-bottom: 15px;
  background: $au-color-white;

  @include tablet {
    overflow: auto;

    @include hide-scroll;
  }

  &__item {
    white-space: pre;
  }

  &__link {
    display: block;
    padding: 10px 15px;
    text-align: center;
    color: $au-color-primary;
    font-size: 1.4rem;
    cursor: pointer;

    &.au-active {
      color: $au-color-white;
      background: $au-color-primary;
    }
  }
}

// Link tab in cards
.au-link-tab {
  display: inline-flex;
  align-items: center;
  gap: 8px;

  &__item {
    display: block;
    font-size: 1.2rem;
    color: $au-color-secondary-medium;
    cursor: pointer;

    &:hover,
    &.au-active {
      color: $au-color-primary;
    }

    &.au-active {
      font-weight: 600;
    }
  }
}

.au-v-line {
  display: block;
  width: 2px;
  background: $au-color-secondary-light;
  min-height: 10px;
  max-height: 100%;
}

// Grid head Tab

.au-head-tab {
  display: inline-flex;
  align-items: center;
  margin-bottom: 15px;

  &__item {
    display: block;
    font-size: $au-fs-3;
    color: $au-color-secondary-medium;
    padding: 2px 14px;
    border-right: 1px solid $au-color-secondary-light;
    position: relative;
    line-height: 1;
    cursor: pointer;

    &.au-active {
      color: $au-color-primary;
      font-size: $au-fs-3;
      font-weight: 600;
    }

    &:first-child {
      padding-left: 0;
    }

    &:last-child {
      border-right: none;
    }
  }

  &__badge {
    position: absolute;
    line-height: 1;
    background: $au-color-primary-light; /* stylelint-disable-line */
    color: $au-color-white;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: $au-fs-2;
    top: -14px;
    right: 2px;
    border-radius: 100px;
    padding: 3px 5px;
    cursor: pointer;
  }
}

@include mobile {
  .au-tab {
    &__item {
      &--more {
        position: static;
      }

      .au-tab__dropdown {
        width: calc(100vw - 20px);
        left: 50%;
        transform: translateX(-50%);
      }
    }

    &__dropdown-inner {
      &--two-col {
        min-width: 0;
        width: 100%;
      }

      &--three-col {
        min-width: 0;
        width: 100%;
        max-height: 300px;

        .au-tab__dropdown-item {
          width: calc(50% - 16px);
        }
      }
    }
  }
}
