.audit-timeline-chart-border{
    // border-style: ridge;
    border: 1px ridge gray;
}
.tableItemCenter{
    text-align: center;
    background-color: #efc0c0;
}
.audit-timeline-table{
    width: 100%;
    margin-top: 3.5rem;
    margin-bottom: 2rem;
}
.audit-timeline-button{
    background-color: rgb(225, 233, 240);
    color: black;
    height: 3rem;
    border-radius: 15px;
}
.audit-timeline-row{
    // background-color:  rgb(225, 233, 240);
    display: flex;
    justify-content: center;
}
#conta{
    line, .apexcharts-xaxis.apexcharts-yaxis-inversed {
    display: block !important;
}
}
