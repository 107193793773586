.todo-widget {
    .todo-list {
        .todo-item {
            border: 0px;
            margin-bottom: 0px;
            padding: 20px 15px 15px 0px;
            .custom-checkbox {
                width: 100%;
                .todo-label {
                    font-weight: 400;
                    width: 100%;
                }
            }
            .assignedto {
                padding: 0 0 0 25px;
                margin: 0px;
                .assignee {
                    padding: 0px;
                    display: inline-block;
                    border: 0px;
                    margin-right: 2px;
                    .assignee-img {
                        width: 30px;
                        border-radius: 100%;
                    }
                }
            }
            .item-date {
                padding-left: 25px;
                font-size: 12px;
                margin-top: 5px;
                display: inline-block;
                color: $text-muted;
            }
        }
    }
}

.list-task .task-done {
    .todo-desc,
    .badge {
        text-decoration: line-through;
    }
}