/* stylelint-disable selector-class-pattern */
@font-face {
  font-family: icomoon;
  src: url("fonts/icomoon.eot?-rdmvgc");
  src:
    url("fonts/icomoon.eot?#iefix-rdmvgc") format("embedded-opentype"),
    url("fonts/icomoon.woff?-rdmvgc") format("woff"),
    url("fonts/icomoon.ttf?-rdmvgc") format("truetype"),
    url("fonts/icomoon.svg?-rdmvgc#icomoon") format("svg");
  font-weight: normal;
  font-style: normal;
}

[class^="icon-"],
[class*=" icon-"] {
  font-family: icomoon, sans-serif;
  speak: none;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;

  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.icon-A-Z::before {
  content: "\e600";
}

.icon-Aa::before {
  content: "\e601";
}

.icon-Add-Bag::before {
  content: "\e602";
}

.icon-Add-Basket::before {
  content: "\e603";
}

.icon-Add-Cart::before {
  content: "\e604";
}

.icon-Add-File::before {
  content: "\e605";
}

.icon-Add-SpaceAfterParagraph::before {
  content: "\e606";
}

.icon-Add-SpaceBeforeParagraph::before {
  content: "\e607";
}

.icon-Add-User::before {
  content: "\e608";
}

.icon-Add-UserStar::before {
  content: "\e609";
}

.icon-Add-Window::before {
  content: "\e60a";
}

.icon-Add::before {
  content: "\e60b";
}

.icon-Address-Book::before {
  content: "\e60c";
}

.icon-Address-Book2::before {
  content: "\e60d";
}

.icon-Administrator::before {
  content: "\e60e";
}

.icon-Aerobics-2::before {
  content: "\e60f";
}

.icon-Aerobics-3::before {
  content: "\e610";
}

.icon-Aerobics::before {
  content: "\e611";
}

.icon-Affiliate::before {
  content: "\e612";
}

.icon-Aim::before {
  content: "\e613";
}

.icon-Air-Balloon::before {
  content: "\e614";
}

.icon-Airbrush::before {
  content: "\e615";
}

.icon-Airship::before {
  content: "\e616";
}

.icon-Alarm-Clock::before {
  content: "\e617";
}

.icon-Alarm-Clock2::before {
  content: "\e618";
}

.icon-Alarm::before {
  content: "\e619";
}

.icon-Alien-2::before {
  content: "\e61a";
}

.icon-Alien::before {
  content: "\e61b";
}

.icon-Aligator::before {
  content: "\e61c";
}

.icon-Align-Center::before {
  content: "\e61d";
}

.icon-Align-JustifyAll::before {
  content: "\e61e";
}

.icon-Align-JustifyCenter::before {
  content: "\e61f";
}

.icon-Align-JustifyLeft::before {
  content: "\e620";
}

.icon-Align-JustifyRight::before {
  content: "\e621";
}

.icon-Align-Left::before {
  content: "\e622";
}

.icon-Align-Right::before {
  content: "\e623";
}

.icon-Alpha::before {
  content: "\e624";
}

.icon-Ambulance::before {
  content: "\e625";
}

.icon-AMX::before {
  content: "\e626";
}

.icon-Anchor-2::before {
  content: "\e627";
}

.icon-Anchor::before {
  content: "\e628";
}

.icon-Android-Store::before {
  content: "\e629";
}

.icon-Android::before {
  content: "\e62a";
}

.icon-Angel-Smiley::before {
  content: "\e62b";
}

.icon-Angel::before {
  content: "\e62c";
}

.icon-Angry::before {
  content: "\e62d";
}

.icon-Apple-Bite::before {
  content: "\e62e";
}

.icon-Apple-Store::before {
  content: "\e62f";
}

.icon-Apple::before {
  content: "\e630";
}

.icon-Approved-Window::before {
  content: "\e631";
}

.icon-Aquarius-2::before {
  content: "\e632";
}

.icon-Aquarius::before {
  content: "\e633";
}

.icon-Archery-2::before {
  content: "\e634";
}

.icon-Archery::before {
  content: "\e635";
}

.icon-Argentina::before {
  content: "\e636";
}

.icon-Aries-2::before {
  content: "\e637";
}

.icon-Aries::before {
  content: "\e638";
}

.icon-Army-Key::before {
  content: "\e639";
}

.icon-Arrow-Around::before {
  content: "\e63a";
}

.icon-Arrow-Back3::before {
  content: "\e63b";
}

.icon-Arrow-Back::before {
  content: "\e63c";
}

.icon-Arrow-Back2::before {
  content: "\e63d";
}

.icon-Arrow-Barrier::before {
  content: "\e63e";
}

.icon-Arrow-Circle::before {
  content: "\e63f";
}

.icon-Arrow-Cross::before {
  content: "\e640";
}

.icon-Arrow-Down::before {
  content: "\e641";
}

.icon-Arrow-Down2::before {
  content: "\e642";
}

.icon-Arrow-Down3::before {
  content: "\e643";
}

.icon-Arrow-DowninCircle::before {
  content: "\e644";
}

.icon-Arrow-Fork::before {
  content: "\e645";
}

.icon-Arrow-Forward::before {
  content: "\e646";
}

.icon-Arrow-Forward2::before {
  content: "\e647";
}

.icon-Arrow-From::before {
  content: "\e648";
}

.icon-Arrow-Inside::before {
  content: "\e649";
}

.icon-Arrow-Inside45::before {
  content: "\e64a";
}

.icon-Arrow-InsideGap::before {
  content: "\e64b";
}

.icon-Arrow-InsideGap45::before {
  content: "\e64c";
}

.icon-Arrow-Into::before {
  content: "\e64d";
}

.icon-Arrow-Join::before {
  content: "\e64e";
}

.icon-Arrow-Junction::before {
  content: "\e64f";
}

.icon-Arrow-Left::before {
  content: "\e650";
}

.icon-Arrow-Left2::before {
  content: "\e651";
}

.icon-Arrow-LeftinCircle::before {
  content: "\e652";
}

.icon-Arrow-Loop::before {
  content: "\e653";
}

.icon-Arrow-Merge::before {
  content: "\e654";
}

.icon-Arrow-Mix::before {
  content: "\e655";
}

.icon-Arrow-Next::before {
  content: "\e656";
}

.icon-Arrow-OutLeft::before {
  content: "\e657";
}

.icon-Arrow-OutRight::before {
  content: "\e658";
}

.icon-Arrow-Outside::before {
  content: "\e659";
}

.icon-Arrow-Outside45::before {
  content: "\e65a";
}

.icon-Arrow-OutsideGap::before {
  content: "\e65b";
}

.icon-Arrow-OutsideGap45::before {
  content: "\e65c";
}

.icon-Arrow-Over::before {
  content: "\e65d";
}

.icon-Arrow-Refresh::before {
  content: "\e65e";
}

.icon-Arrow-Refresh2::before {
  content: "\e65f";
}

.icon-Arrow-Right::before {
  content: "\e660";
}

.icon-Arrow-Right2::before {
  content: "\e661";
}

.icon-Arrow-RightinCircle::before {
  content: "\e662";
}

.icon-Arrow-Shuffle::before {
  content: "\e663";
}

.icon-Arrow-Squiggly::before {
  content: "\e664";
}

.icon-Arrow-Through::before {
  content: "\e665";
}

.icon-Arrow-To::before {
  content: "\e666";
}

.icon-Arrow-TurnLeft::before {
  content: "\e667";
}

.icon-Arrow-TurnRight::before {
  content: "\e668";
}

.icon-Arrow-Up::before {
  content: "\e669";
}

.icon-Arrow-Up2::before {
  content: "\e66a";
}

.icon-Arrow-Up3::before {
  content: "\e66b";
}

.icon-Arrow-UpinCircle::before {
  content: "\e66c";
}

.icon-Arrow-XLeft::before {
  content: "\e66d";
}

.icon-Arrow-XRight::before {
  content: "\e66e";
}

.icon-Ask::before {
  content: "\e66f";
}

.icon-Assistant::before {
  content: "\e670";
}

.icon-Astronaut::before {
  content: "\e671";
}

.icon-At-Sign::before {
  content: "\e672";
}

.icon-ATM::before {
  content: "\e673";
}

.icon-Atom::before {
  content: "\e674";
}

.icon-Audio::before {
  content: "\e675";
}

.icon-Auto-Flash::before {
  content: "\e676";
}

.icon-Autumn::before {
  content: "\e677";
}

.icon-Baby-Clothes::before {
  content: "\e678";
}

.icon-Baby-Clothes2::before {
  content: "\e679";
}

.icon-Baby-Cry::before {
  content: "\e67a";
}

.icon-Baby::before {
  content: "\e67b";
}

.icon-Back2::before {
  content: "\e67c";
}

.icon-Back-Media::before {
  content: "\e67d";
}

.icon-Back-Music::before {
  content: "\e67e";
}

.icon-Back::before {
  content: "\e67f";
}

.icon-Background::before {
  content: "\e680";
}

.icon-Bacteria::before {
  content: "\e681";
}

.icon-Bag-Coins::before {
  content: "\e682";
}

.icon-Bag-Items::before {
  content: "\e683";
}

.icon-Bag-Quantity::before {
  content: "\e684";
}

.icon-Bag::before {
  content: "\e685";
}

.icon-Bakelite::before {
  content: "\e686";
}

.icon-Ballet-Shoes::before {
  content: "\e687";
}

.icon-Balloon::before {
  content: "\e688";
}

.icon-Banana::before {
  content: "\e689";
}

.icon-Band-Aid::before {
  content: "\e68a";
}

.icon-Bank::before {
  content: "\e68b";
}

.icon-Bar-Chart::before {
  content: "\e68c";
}

.icon-Bar-Chart2::before {
  content: "\e68d";
}

.icon-Bar-Chart3::before {
  content: "\e68e";
}

.icon-Bar-Chart4::before {
  content: "\e68f";
}

.icon-Bar-Chart5::before {
  content: "\e690";
}

.icon-Bar-Code::before {
  content: "\e691";
}

.icon-Barricade-2::before {
  content: "\e692";
}

.icon-Barricade::before {
  content: "\e693";
}

.icon-Baseball::before {
  content: "\e694";
}

.icon-Basket-Ball::before {
  content: "\e695";
}

.icon-Basket-Coins::before {
  content: "\e696";
}

.icon-Basket-Items::before {
  content: "\e697";
}

.icon-Basket-Quantity::before {
  content: "\e698";
}

.icon-Bat-2::before {
  content: "\e699";
}

.icon-Bat::before {
  content: "\e69a";
}

.icon-Bathrobe::before {
  content: "\e69b";
}

.icon-Batman-Mask::before {
  content: "\e69c";
}

.icon-Battery-0::before {
  content: "\e69d";
}

.icon-Battery-25::before {
  content: "\e69e";
}

.icon-Battery-50::before {
  content: "\e69f";
}

.icon-Battery-75::before {
  content: "\e6a0";
}

.icon-Battery-100::before {
  content: "\e6a1";
}

.icon-Battery-Charge::before {
  content: "\e6a2";
}

.icon-Bear::before {
  content: "\e6a3";
}

.icon-Beard-2::before {
  content: "\e6a4";
}

.icon-Beard-3::before {
  content: "\e6a5";
}

.icon-Beard::before {
  content: "\e6a6";
}

.icon-Bebo::before {
  content: "\e6a7";
}

.icon-Bee::before {
  content: "\e6a8";
}

.icon-Beer-Glass::before {
  content: "\e6a9";
}

.icon-Beer::before {
  content: "\e6aa";
}

.icon-Bell-2::before {
  content: "\e6ab";
}

.icon-Bell::before {
  content: "\e6ac";
}

.icon-Belt-2::before {
  content: "\e6ad";
}

.icon-Belt-3::before {
  content: "\e6ae";
}

.icon-Belt::before {
  content: "\e6af";
}

.icon-Berlin-Tower::before {
  content: "\e6b0";
}

.icon-Beta::before {
  content: "\e6b1";
}

.icon-Betvibes::before {
  content: "\e6b2";
}

.icon-Bicycle-2::before {
  content: "\e6b3";
}

.icon-Bicycle-3::before {
  content: "\e6b4";
}

.icon-Bicycle::before {
  content: "\e6b5";
}

.icon-Big-Bang::before {
  content: "\e6b6";
}

.icon-Big-Data::before {
  content: "\e6b7";
}

.icon-Bike-Helmet::before {
  content: "\e6b8";
}

.icon-Bikini::before {
  content: "\e6b9";
}

.icon-Bilk-Bottle2::before {
  content: "\e6ba";
}

.icon-Billing::before {
  content: "\e6bb";
}

.icon-Bing::before {
  content: "\e6bc";
}

.icon-Binocular::before {
  content: "\e6bd";
}

.icon-Bio-Hazard::before {
  content: "\e6be";
}

.icon-Biotech::before {
  content: "\e6bf";
}

.icon-Bird-DeliveringLetter::before {
  content: "\e6c0";
}

.icon-Bird::before {
  content: "\e6c1";
}

.icon-Birthday-Cake::before {
  content: "\e6c2";
}

.icon-Bisexual::before {
  content: "\e6c3";
}

.icon-Bishop::before {
  content: "\e6c4";
}

.icon-Bitcoin::before {
  content: "\e6c5";
}

.icon-Black-Cat::before {
  content: "\e6c6";
}

.icon-Blackboard::before {
  content: "\e6c7";
}

.icon-Blinklist::before {
  content: "\e6c8";
}

.icon-Block-Cloud::before {
  content: "\e6c9";
}

.icon-Block-Window::before {
  content: "\e6ca";
}

.icon-Blogger::before {
  content: "\e6cb";
}

.icon-Blood::before {
  content: "\e6cc";
}

.icon-Blouse::before {
  content: "\e6cd";
}

.icon-Blueprint::before {
  content: "\e6ce";
}

.icon-Board::before {
  content: "\e6cf";
}

.icon-Bodybuilding::before {
  content: "\e6d0";
}

.icon-Bold-Text::before {
  content: "\e6d1";
}

.icon-Bone::before {
  content: "\e6d2";
}

.icon-Bones::before {
  content: "\e6d3";
}

.icon-Book::before {
  content: "\e6d4";
}

.icon-Bookmark::before {
  content: "\e6d5";
}

.icon-Books-2::before {
  content: "\e6d6";
}

.icon-Books::before {
  content: "\e6d7";
}

.icon-Boom::before {
  content: "\e6d8";
}

.icon-Boot-2::before {
  content: "\e6d9";
}

.icon-Boot::before {
  content: "\e6da";
}

.icon-Bottom-ToTop::before {
  content: "\e6db";
}

.icon-Bow-2::before {
  content: "\e6dc";
}

.icon-Bow-3::before {
  content: "\e6dd";
}

.icon-Bow-4::before {
  content: "\e6de";
}

.icon-Bow-5::before {
  content: "\e6df";
}

.icon-Bow-6::before {
  content: "\e6e0";
}

.icon-Bow::before {
  content: "\e6e1";
}

.icon-Bowling-2::before {
  content: "\e6e2";
}

.icon-Bowling::before {
  content: "\e6e3";
}

.icon-Box2::before {
  content: "\e6e4";
}

.icon-Box-Close::before {
  content: "\e6e5";
}

.icon-Box-Full::before {
  content: "\e6e6";
}

.icon-Box-Open::before {
  content: "\e6e7";
}

.icon-Box-withFolders::before {
  content: "\e6e8";
}

.icon-Box::before {
  content: "\e6e9";
}

.icon-Boy::before {
  content: "\e6ea";
}

.icon-Bra::before {
  content: "\e6eb";
}

.icon-Brain-2::before {
  content: "\e6ec";
}

.icon-Brain-3::before {
  content: "\e6ed";
}

.icon-Brain::before {
  content: "\e6ee";
}

.icon-Brazil::before {
  content: "\e6ef";
}

.icon-Bread-2::before {
  content: "\e6f0";
}

.icon-Bread::before {
  content: "\e6f1";
}

.icon-Bridge::before {
  content: "\e6f2";
}

.icon-Brightkite::before {
  content: "\e6f3";
}

.icon-Broke-Link2::before {
  content: "\e6f4";
}

.icon-Broken-Link::before {
  content: "\e6f5";
}

.icon-Broom::before {
  content: "\e6f6";
}

.icon-Brush::before {
  content: "\e6f7";
}

.icon-Bucket::before {
  content: "\e6f8";
}

.icon-Bug::before {
  content: "\e6f9";
}

.icon-Building::before {
  content: "\e6fa";
}

.icon-Bulleted-List::before {
  content: "\e6fb";
}

.icon-Bus-2::before {
  content: "\e6fc";
}

.icon-Bus::before {
  content: "\e6fd";
}

.icon-Business-Man::before {
  content: "\e6fe";
}

.icon-Business-ManWoman::before {
  content: "\e6ff";
}

.icon-Business-Mens::before {
  content: "\e700";
}

.icon-Business-Woman::before {
  content: "\e701";
}

.icon-Butterfly::before {
  content: "\e702";
}

.icon-Button::before {
  content: "\e703";
}

.icon-Cable-Car::before {
  content: "\e704";
}

.icon-Cake::before {
  content: "\e705";
}

.icon-Calculator-2::before {
  content: "\e706";
}

.icon-Calculator-3::before {
  content: "\e707";
}

.icon-Calculator::before {
  content: "\e708";
}

.icon-Calendar-2::before {
  content: "\e709";
}

.icon-Calendar-3::before {
  content: "\e70a";
}

.icon-Calendar-4::before {
  content: "\e70b";
}

.icon-Calendar-Clock::before {
  content: "\e70c";
}

.icon-Calendar::before {
  content: "\e70d";
}

.icon-Camel::before {
  content: "\e70e";
}

.icon-Camera-2::before {
  content: "\e70f";
}

.icon-Camera-3::before {
  content: "\e710";
}

.icon-Camera-4::before {
  content: "\e711";
}

.icon-Camera-5::before {
  content: "\e712";
}

.icon-Camera-Back::before {
  content: "\e713";
}

.icon-Camera::before {
  content: "\e714";
}

.icon-Can-2::before {
  content: "\e715";
}

.icon-Can::before {
  content: "\e716";
}

.icon-Canada::before {
  content: "\e717";
}

.icon-Cancer-2::before {
  content: "\e718";
}

.icon-Cancer-3::before {
  content: "\e719";
}

.icon-Cancer::before {
  content: "\e71a";
}

.icon-Candle::before {
  content: "\e71b";
}

.icon-Candy-Cane::before {
  content: "\e71c";
}

.icon-Candy::before {
  content: "\e71d";
}

.icon-Cannon::before {
  content: "\e71e";
}

.icon-Cap-2::before {
  content: "\e71f";
}

.icon-Cap-3::before {
  content: "\e720";
}

.icon-Cap-Smiley::before {
  content: "\e721";
}

.icon-Cap::before {
  content: "\e722";
}

.icon-Capricorn-2::before {
  content: "\e723";
}

.icon-Capricorn::before {
  content: "\e724";
}

.icon-Car-2::before {
  content: "\e725";
}

.icon-Car-3::before {
  content: "\e726";
}

.icon-Car-Coins::before {
  content: "\e727";
}

.icon-Car-Items::before {
  content: "\e728";
}

.icon-Car-Wheel::before {
  content: "\e729";
}

.icon-Car::before {
  content: "\e72a";
}

.icon-Cardigan::before {
  content: "\e72b";
}

.icon-Cardiovascular::before {
  content: "\e72c";
}

.icon-Cart-Quantity::before {
  content: "\e72d";
}

.icon-Casette-Tape::before {
  content: "\e72e";
}

.icon-Cash-Register::before {
  content: "\e72f";
}

.icon-Cash-register2::before {
  content: "\e730";
}

.icon-Castle::before {
  content: "\e731";
}

.icon-Cat::before {
  content: "\e732";
}

.icon-Cathedral::before {
  content: "\e733";
}

.icon-Cauldron::before {
  content: "\e734";
}

.icon-CD-2::before {
  content: "\e735";
}

.icon-CD-Cover::before {
  content: "\e736";
}

.icon-CD::before {
  content: "\e737";
}

.icon-Cello::before {
  content: "\e738";
}

.icon-Celsius::before {
  content: "\e739";
}

.icon-Chacked-Flag::before {
  content: "\e73a";
}

.icon-Chair::before {
  content: "\e73b";
}

.icon-Charger::before {
  content: "\e73c";
}

.icon-Check-2::before {
  content: "\e73d";
}

.icon-Check::before {
  content: "\e73e";
}

.icon-Checked-User::before {
  content: "\e73f";
}

.icon-Checkmate::before {
  content: "\e740";
}

.icon-Checkout-Bag::before {
  content: "\e741";
}

.icon-Checkout-Basket::before {
  content: "\e742";
}

.icon-Checkout::before {
  content: "\e743";
}

.icon-Cheese::before {
  content: "\e744";
}

.icon-Cheetah::before {
  content: "\e745";
}

.icon-Chef-Hat::before {
  content: "\e746";
}

.icon-Chef-Hat2::before {
  content: "\e747";
}

.icon-Chef::before {
  content: "\e748";
}

.icon-Chemical-2::before {
  content: "\e749";
}

.icon-Chemical-3::before {
  content: "\e74a";
}

.icon-Chemical-4::before {
  content: "\e74b";
}

.icon-Chemical-5::before {
  content: "\e74c";
}

.icon-Chemical::before {
  content: "\e74d";
}

.icon-Chess-Board::before {
  content: "\e74e";
}

.icon-Chess::before {
  content: "\e74f";
}

.icon-Chicken::before {
  content: "\e750";
}

.icon-Chile::before {
  content: "\e751";
}

.icon-Chimney::before {
  content: "\e752";
}

.icon-China::before {
  content: "\e753";
}

.icon-Chinese-Temple::before {
  content: "\e754";
}

.icon-Chip::before {
  content: "\e755";
}

.icon-Chopsticks-2::before {
  content: "\e756";
}

.icon-Chopsticks::before {
  content: "\e757";
}

.icon-Christmas-Ball::before {
  content: "\e758";
}

.icon-Christmas-Bell::before {
  content: "\e759";
}

.icon-Christmas-Candle::before {
  content: "\e75a";
}

.icon-Christmas-Hat::before {
  content: "\e75b";
}

.icon-Christmas-Sleigh::before {
  content: "\e75c";
}

.icon-Christmas-Snowman::before {
  content: "\e75d";
}

.icon-Christmas-Sock::before {
  content: "\e75e";
}

.icon-Christmas-Tree::before {
  content: "\e75f";
}

.icon-Christmas::before {
  content: "\e760";
}

.icon-Chrome::before {
  content: "\e761";
}

.icon-Chrysler-Building::before {
  content: "\e762";
}

.icon-Cinema::before {
  content: "\e763";
}

.icon-Circular-Point::before {
  content: "\e764";
}

.icon-City-Hall::before {
  content: "\e765";
}

.icon-Clamp::before {
  content: "\e766";
}

.icon-Clapperboard-Close::before {
  content: "\e767";
}

.icon-Clapperboard-Open::before {
  content: "\e768";
}

.icon-Claps::before {
  content: "\e769";
}

.icon-Clef::before {
  content: "\e76a";
}

.icon-Clinic::before {
  content: "\e76b";
}

.icon-Clock-2::before {
  content: "\e76c";
}

.icon-Clock-3::before {
  content: "\e76d";
}

.icon-Clock-4::before {
  content: "\e76e";
}

.icon-Clock-Back::before {
  content: "\e76f";
}

.icon-Clock-Forward::before {
  content: "\e770";
}

.icon-Clock::before {
  content: "\e771";
}

.icon-Close-Window::before {
  content: "\e772";
}

.icon-Close::before {
  content: "\e773";
}

.icon-Clothing-Store::before {
  content: "\e774";
}

.icon-Cloud--::before {
  content: "\e775";
}

.icon-Cloud-::before {
  content: "\e776";
}

.icon-Cloud-Camera::before {
  content: "\e777";
}

.icon-Cloud-Computer::before {
  content: "\e778";
}

.icon-Cloud-Email::before {
  content: "\e779";
}

.icon-Cloud-Hail::before {
  content: "\e77a";
}

.icon-Cloud-Laptop::before {
  content: "\e77b";
}

.icon-Cloud-Lock::before {
  content: "\e77c";
}

.icon-Cloud-Moon::before {
  content: "\e77d";
}

.icon-Cloud-Music::before {
  content: "\e77e";
}

.icon-Cloud-Picture::before {
  content: "\e77f";
}

.icon-Cloud-Rain::before {
  content: "\e780";
}

.icon-Cloud-Remove::before {
  content: "\e781";
}

.icon-Cloud-Secure::before {
  content: "\e782";
}

.icon-Cloud-Settings::before {
  content: "\e783";
}

.icon-Cloud-Smartphone::before {
  content: "\e784";
}

.icon-Cloud-Snow::before {
  content: "\e785";
}

.icon-Cloud-Sun::before {
  content: "\e786";
}

.icon-Cloud-Tablet::before {
  content: "\e787";
}

.icon-Cloud-Video::before {
  content: "\e788";
}

.icon-Cloud-Weather::before {
  content: "\e789";
}

.icon-Cloud::before {
  content: "\e78a";
}

.icon-Clouds-Weather::before {
  content: "\e78b";
}

.icon-Clouds::before {
  content: "\e78c";
}

.icon-Clown::before {
  content: "\e78d";
}

.icon-CMYK::before {
  content: "\e78e";
}

.icon-Coat::before {
  content: "\e78f";
}

.icon-Cocktail::before {
  content: "\e790";
}

.icon-Coconut::before {
  content: "\e791";
}

.icon-Code-Window::before {
  content: "\e792";
}

.icon-Coding::before {
  content: "\e793";
}

.icon-Coffee-2::before {
  content: "\e794";
}

.icon-Coffee-Bean::before {
  content: "\e795";
}

.icon-Coffee-Machine::before {
  content: "\e796";
}

.icon-Coffee-toGo::before {
  content: "\e797";
}

.icon-Coffee::before {
  content: "\e798";
}

.icon-Coffin::before {
  content: "\e799";
}

.icon-Coin::before {
  content: "\e79a";
}

.icon-Coins-2::before {
  content: "\e79b";
}

.icon-Coins-3::before {
  content: "\e79c";
}

.icon-Coins::before {
  content: "\e79d";
}

.icon-Colombia::before {
  content: "\e79e";
}

.icon-Colosseum::before {
  content: "\e79f";
}

.icon-Column-2::before {
  content: "\e7a0";
}

.icon-Column-3::before {
  content: "\e7a1";
}

.icon-Column::before {
  content: "\e7a2";
}

.icon-Comb-2::before {
  content: "\e7a3";
}

.icon-Comb::before {
  content: "\e7a4";
}

.icon-Communication-Tower::before {
  content: "\e7a5";
}

.icon-Communication-Tower2::before {
  content: "\e7a6";
}

.icon-Compass-2::before {
  content: "\e7a7";
}

.icon-Compass-3::before {
  content: "\e7a8";
}

.icon-Compass-4::before {
  content: "\e7a9";
}

.icon-Compass-Rose::before {
  content: "\e7aa";
}

.icon-Compass::before {
  content: "\e7ab";
}

.icon-Computer-2::before {
  content: "\e7ac";
}

.icon-Computer-3::before {
  content: "\e7ad";
}

.icon-Computer-Secure::before {
  content: "\e7ae";
}

.icon-Computer::before {
  content: "\e7af";
}

.icon-Conference::before {
  content: "\e7b0";
}

.icon-Confused::before {
  content: "\e7b1";
}

.icon-Conservation::before {
  content: "\e7b2";
}

.icon-Consulting::before {
  content: "\e7b3";
}

.icon-Contrast::before {
  content: "\e7b4";
}

.icon-Control-2::before {
  content: "\e7b5";
}

.icon-Control::before {
  content: "\e7b6";
}

.icon-Cookie-Man::before {
  content: "\e7b7";
}

.icon-Cookies::before {
  content: "\e7b8";
}

.icon-Cool-Guy::before {
  content: "\e7b9";
}

.icon-Cool::before {
  content: "\e7ba";
}

.icon-Copyright::before {
  content: "\e7bb";
}

.icon-Costume::before {
  content: "\e7bc";
}

.icon-Couple-Sign::before {
  content: "\e7bd";
}

.icon-Cow::before {
  content: "\e7be";
}

.icon-CPU::before {
  content: "\e7bf";
}

.icon-Crane::before {
  content: "\e7c0";
}

.icon-Cranium::before {
  content: "\e7c1";
}

.icon-Credit-Card::before {
  content: "\e7c2";
}

.icon-Credit-Card2::before {
  content: "\e7c3";
}

.icon-Credit-Card3::before {
  content: "\e7c4";
}

.icon-Cricket::before {
  content: "\e7c5";
}

.icon-Criminal::before {
  content: "\e7c6";
}

.icon-Croissant::before {
  content: "\e7c7";
}

.icon-Crop-2::before {
  content: "\e7c8";
}

.icon-Crop-3::before {
  content: "\e7c9";
}

.icon-Crown-2::before {
  content: "\e7ca";
}

.icon-Crown::before {
  content: "\e7cb";
}

.icon-Crying::before {
  content: "\e7cc";
}

.icon-Cube-Molecule::before {
  content: "\e7cd";
}

.icon-Cube-Molecule2::before {
  content: "\e7ce";
}

.icon-Cupcake::before {
  content: "\e7cf";
}

.icon-Cursor-Click::before {
  content: "\e7d0";
}

.icon-Cursor-Click2::before {
  content: "\e7d1";
}

.icon-Cursor-Move::before {
  content: "\e7d2";
}

.icon-Cursor-Move2::before {
  content: "\e7d3";
}

.icon-Cursor-Select::before {
  content: "\e7d4";
}

.icon-Cursor::before {
  content: "\e7d5";
}

.icon-D-Eyeglasses::before {
  content: "\e7d6";
}

.icon-D-Eyeglasses2::before {
  content: "\e7d7";
}

.icon-Dam::before {
  content: "\e7d8";
}

.icon-Danemark::before {
  content: "\e7d9";
}

.icon-Danger-2::before {
  content: "\e7da";
}

.icon-Danger::before {
  content: "\e7db";
}

.icon-Dashboard::before {
  content: "\e7dc";
}

.icon-Data-Backup::before {
  content: "\e7dd";
}

.icon-Data-Block::before {
  content: "\e7de";
}

.icon-Data-Center::before {
  content: "\e7df";
}

.icon-Data-Clock::before {
  content: "\e7e0";
}

.icon-Data-Cloud::before {
  content: "\e7e1";
}

.icon-Data-Compress::before {
  content: "\e7e2";
}

.icon-Data-Copy::before {
  content: "\e7e3";
}

.icon-Data-Download::before {
  content: "\e7e4";
}

.icon-Data-Financial::before {
  content: "\e7e5";
}

.icon-Data-Key::before {
  content: "\e7e6";
}

.icon-Data-Lock::before {
  content: "\e7e7";
}

.icon-Data-Network::before {
  content: "\e7e8";
}

.icon-Data-Password::before {
  content: "\e7e9";
}

.icon-Data-Power::before {
  content: "\e7ea";
}

.icon-Data-Refresh::before {
  content: "\e7eb";
}

.icon-Data-Save::before {
  content: "\e7ec";
}

.icon-Data-Search::before {
  content: "\e7ed";
}

.icon-Data-Security::before {
  content: "\e7ee";
}

.icon-Data-Settings::before {
  content: "\e7ef";
}

.icon-Data-Sharing::before {
  content: "\e7f0";
}

.icon-Data-Shield::before {
  content: "\e7f1";
}

.icon-Data-Signal::before {
  content: "\e7f2";
}

.icon-Data-Storage::before {
  content: "\e7f3";
}

.icon-Data-Stream::before {
  content: "\e7f4";
}

.icon-Data-Transfer::before {
  content: "\e7f5";
}

.icon-Data-Unlock::before {
  content: "\e7f6";
}

.icon-Data-Upload::before {
  content: "\e7f7";
}

.icon-Data-Yes::before {
  content: "\e7f8";
}

.icon-Data::before {
  content: "\e7f9";
}

.icon-David-Star::before {
  content: "\e7fa";
}

.icon-Daylight::before {
  content: "\e7fb";
}

.icon-Death::before {
  content: "\e7fc";
}

.icon-Debian::before {
  content: "\e7fd";
}

.icon-Dec::before {
  content: "\e7fe";
}

.icon-Decrase-Inedit::before {
  content: "\e7ff";
}

.icon-Deer-2::before {
  content: "\e800";
}

.icon-Deer::before {
  content: "\e801";
}

.icon-Delete-File::before {
  content: "\e802";
}

.icon-Delete-Window::before {
  content: "\e803";
}

.icon-Delicious::before {
  content: "\e804";
}

.icon-Depression::before {
  content: "\e805";
}

.icon-Deviantart::before {
  content: "\e806";
}

.icon-Device-SyncwithCloud::before {
  content: "\e807";
}

.icon-Diamond::before {
  content: "\e808";
}

.icon-Dice-2::before {
  content: "\e809";
}

.icon-Dice::before {
  content: "\e80a";
}

.icon-Digg::before {
  content: "\e80b";
}

.icon-Digital-Drawing::before {
  content: "\e80c";
}

.icon-Diigo::before {
  content: "\e80d";
}

.icon-Dinosaur::before {
  content: "\e80e";
}

.icon-Diploma-2::before {
  content: "\e80f";
}

.icon-Diploma::before {
  content: "\e810";
}

.icon-Direction-East::before {
  content: "\e811";
}

.icon-Direction-North::before {
  content: "\e812";
}

.icon-Direction-South::before {
  content: "\e813";
}

.icon-Direction-West::before {
  content: "\e814";
}

.icon-Director::before {
  content: "\e815";
}

.icon-Disk::before {
  content: "\e816";
}

.icon-Dj::before {
  content: "\e817";
}

.icon-DNA-2::before {
  content: "\e818";
}

.icon-DNA-Helix::before {
  content: "\e819";
}

.icon-DNA::before {
  content: "\e81a";
}

.icon-Doctor::before {
  content: "\e81b";
}

.icon-Dog::before {
  content: "\e81c";
}

.icon-Dollar-Sign::before {
  content: "\e81d";
}

.icon-Dollar-Sign2::before {
  content: "\e81e";
}

.icon-Dollar::before {
  content: "\e81f";
}

.icon-Dolphin::before {
  content: "\e820";
}

.icon-Domino::before {
  content: "\e821";
}

.icon-Door-Hanger::before {
  content: "\e822";
}

.icon-Door::before {
  content: "\e823";
}

.icon-Doplr::before {
  content: "\e824";
}

.icon-Double-Circle::before {
  content: "\e825";
}

.icon-Double-Tap::before {
  content: "\e826";
}

.icon-Doughnut::before {
  content: "\e827";
}

.icon-Dove::before {
  content: "\e828";
}

.icon-Down-2::before {
  content: "\e829";
}

.icon-Down-3::before {
  content: "\e82a";
}

.icon-Down-4::before {
  content: "\e82b";
}

.icon-Down::before {
  content: "\e82c";
}

.icon-Download-2::before {
  content: "\e82d";
}

.icon-Download-fromCloud::before {
  content: "\e82e";
}

.icon-Download-Window::before {
  content: "\e82f";
}

.icon-Download::before {
  content: "\e830";
}

.icon-Downward::before {
  content: "\e831";
}

.icon-Drag-Down::before {
  content: "\e832";
}

.icon-Drag-Left::before {
  content: "\e833";
}

.icon-Drag-Right::before {
  content: "\e834";
}

.icon-Drag-Up::before {
  content: "\e835";
}

.icon-Drag::before {
  content: "\e836";
}

.icon-Dress::before {
  content: "\e837";
}

.icon-Drill-2::before {
  content: "\e838";
}

.icon-Drill::before {
  content: "\e839";
}

.icon-Drop::before {
  content: "\e83a";
}

.icon-Dropbox::before {
  content: "\e83b";
}

.icon-Drum::before {
  content: "\e83c";
}

.icon-Dry::before {
  content: "\e83d";
}

.icon-Duck::before {
  content: "\e83e";
}

.icon-Dumbbell::before {
  content: "\e83f";
}

.icon-Duplicate-Layer::before {
  content: "\e840";
}

.icon-Duplicate-Window::before {
  content: "\e841";
}

.icon-DVD::before {
  content: "\e842";
}

.icon-Eagle::before {
  content: "\e843";
}

.icon-Ear::before {
  content: "\e844";
}

.icon-Earphones-2::before {
  content: "\e845";
}

.icon-Earphones::before {
  content: "\e846";
}

.icon-Eci-Icon::before {
  content: "\e847";
}

.icon-Edit-Map::before {
  content: "\e848";
}

.icon-Edit::before {
  content: "\e849";
}

.icon-Eggs::before {
  content: "\e84a";
}

.icon-Egypt::before {
  content: "\e84b";
}

.icon-Eifel-Tower::before {
  content: "\e84c";
}

.icon-eject-2::before {
  content: "\e84d";
}

.icon-Eject::before {
  content: "\e84e";
}

.icon-El-Castillo::before {
  content: "\e84f";
}

.icon-Elbow::before {
  content: "\e850";
}

.icon-Electric-Guitar::before {
  content: "\e851";
}

.icon-Electricity::before {
  content: "\e852";
}

.icon-Elephant::before {
  content: "\e853";
}

.icon-Email::before {
  content: "\e854";
}

.icon-Embassy::before {
  content: "\e855";
}

.icon-Empire-StateBuilding::before {
  content: "\e856";
}

.icon-Empty-Box::before {
  content: "\e857";
}

.icon-End2::before {
  content: "\e858";
}

.icon-End-2::before {
  content: "\e859";
}

.icon-End::before {
  content: "\e85a";
}

.icon-Endways::before {
  content: "\e85b";
}

.icon-Engineering::before {
  content: "\e85c";
}

.icon-Envelope-2::before {
  content: "\e85d";
}

.icon-Envelope::before {
  content: "\e85e";
}

.icon-Environmental-2::before {
  content: "\e85f";
}

.icon-Environmental-3::before {
  content: "\e860";
}

.icon-Environmental::before {
  content: "\e861";
}

.icon-Equalizer::before {
  content: "\e862";
}

.icon-Eraser-2::before {
  content: "\e863";
}

.icon-Eraser-3::before {
  content: "\e864";
}

.icon-Eraser::before {
  content: "\e865";
}

.icon-Error-404Window::before {
  content: "\e866";
}

.icon-Euro-Sign::before {
  content: "\e867";
}

.icon-Euro-Sign2::before {
  content: "\e868";
}

.icon-Euro::before {
  content: "\e869";
}

.icon-Evernote::before {
  content: "\e86a";
}

.icon-Evil::before {
  content: "\e86b";
}

.icon-Explode::before {
  content: "\e86c";
}

.icon-Eye-2::before {
  content: "\e86d";
}

.icon-Eye-Blind::before {
  content: "\e86e";
}

.icon-Eye-Invisible::before {
  content: "\e86f";
}

.icon-Eye-Scan::before {
  content: "\e870";
}

.icon-Eye-Visible::before {
  content: "\e871";
}

.icon-Eye::before {
  content: "\e872";
}

.icon-Eyebrow-2::before {
  content: "\e873";
}

.icon-Eyebrow-3::before {
  content: "\e874";
}

.icon-Eyebrow::before {
  content: "\e875";
}

.icon-Eyeglasses-Smiley::before {
  content: "\e876";
}

.icon-Eyeglasses-Smiley2::before {
  content: "\e877";
}

.icon-Face-Style::before {
  content: "\e878";
}

.icon-Face-Style2::before {
  content: "\e879";
}

.icon-Face-Style3::before {
  content: "\e87a";
}

.icon-Face-Style4::before {
  content: "\e87b";
}

.icon-Face-Style5::before {
  content: "\e87c";
}

.icon-Face-Style6::before {
  content: "\e87d";
}

.icon-Facebook-2::before {
  content: "\e87e";
}

.icon-Facebook::before {
  content: "\e87f";
}

.icon-Factory-2::before {
  content: "\e880";
}

.icon-Factory::before {
  content: "\e881";
}

.icon-Fahrenheit::before {
  content: "\e882";
}

.icon-Family-Sign::before {
  content: "\e883";
}

.icon-Fan::before {
  content: "\e884";
}

.icon-Farmer::before {
  content: "\e885";
}

.icon-Fashion::before {
  content: "\e886";
}

.icon-Favorite-Window::before {
  content: "\e887";
}

.icon-Fax::before {
  content: "\e888";
}

.icon-Feather::before {
  content: "\e889";
}

.icon-Feedburner::before {
  content: "\e88a";
}

.icon-Female-2::before {
  content: "\e88b";
}

.icon-Female-Sign::before {
  content: "\e88c";
}

.icon-Female::before {
  content: "\e88d";
}

.icon-File-Block::before {
  content: "\e88e";
}

.icon-File-Bookmark::before {
  content: "\e88f";
}

.icon-File-Chart::before {
  content: "\e890";
}

.icon-File-Clipboard::before {
  content: "\e891";
}

.icon-File-ClipboardFileText::before {
  content: "\e892";
}

.icon-File-ClipboardTextImage::before {
  content: "\e893";
}

.icon-File-Cloud::before {
  content: "\e894";
}

.icon-File-Copy::before {
  content: "\e895";
}

.icon-File-Copy2::before {
  content: "\e896";
}

.icon-File-CSV::before {
  content: "\e897";
}

.icon-File-Download::before {
  content: "\e898";
}

.icon-File-Edit::before {
  content: "\e899";
}

.icon-File-Excel::before {
  content: "\e89a";
}

.icon-File-Favorite::before {
  content: "\e89b";
}

.icon-File-Fire::before {
  content: "\e89c";
}

.icon-File-Graph::before {
  content: "\e89d";
}

.icon-File-Hide::before {
  content: "\e89e";
}

.icon-File-Horizontal::before {
  content: "\e89f";
}

.icon-File-HorizontalText::before {
  content: "\e8a0";
}

.icon-File-HTML::before {
  content: "\e8a1";
}

.icon-File-JPG::before {
  content: "\e8a2";
}

.icon-File-Link::before {
  content: "\e8a3";
}

.icon-File-Loading::before {
  content: "\e8a4";
}

.icon-File-Lock::before {
  content: "\e8a5";
}

.icon-File-Love::before {
  content: "\e8a6";
}

.icon-File-Music::before {
  content: "\e8a7";
}

.icon-File-Network::before {
  content: "\e8a8";
}

.icon-File-Pictures::before {
  content: "\e8a9";
}

.icon-File-Pie::before {
  content: "\e8aa";
}

.icon-File-Presentation::before {
  content: "\e8ab";
}

.icon-File-Refresh::before {
  content: "\e8ac";
}

.icon-File-Search::before {
  content: "\e8ad";
}

.icon-File-Settings::before {
  content: "\e8ae";
}

.icon-File-Share::before {
  content: "\e8af";
}

.icon-File-TextImage::before {
  content: "\e8b0";
}

.icon-File-Trash::before {
  content: "\e8b1";
}

.icon-File-TXT::before {
  content: "\e8b2";
}

.icon-File-Upload::before {
  content: "\e8b3";
}

.icon-File-Video::before {
  content: "\e8b4";
}

.icon-File-Word::before {
  content: "\e8b5";
}

.icon-File-Zip::before {
  content: "\e8b6";
}

.icon-File::before {
  content: "\e8b7";
}

.icon-Files::before {
  content: "\e8b8";
}

.icon-Film-Board::before {
  content: "\e8b9";
}

.icon-Film-Cartridge::before {
  content: "\e8ba";
}

.icon-Film-Strip::before {
  content: "\e8bb";
}

.icon-Film-Video::before {
  content: "\e8bc";
}

.icon-Film::before {
  content: "\e8bd";
}

.icon-Filter-2::before {
  content: "\e8be";
}

.icon-Filter::before {
  content: "\e8bf";
}

.icon-Financial::before {
  content: "\e8c0";
}

.icon-Find-User::before {
  content: "\e8c1";
}

.icon-Finger-DragFourSides::before {
  content: "\e8c2";
}

.icon-Finger-DragTwoSides::before {
  content: "\e8c3";
}

.icon-Finger-Print::before {
  content: "\e8c4";
}

.icon-Finger::before {
  content: "\e8c5";
}

.icon-Fingerprint-2::before {
  content: "\e8c6";
}

.icon-Fingerprint::before {
  content: "\e8c7";
}

.icon-Fire-Flame::before {
  content: "\e8c8";
}

.icon-Fire-Flame2::before {
  content: "\e8c9";
}

.icon-Fire-Hydrant::before {
  content: "\e8ca";
}

.icon-Fire-Staion::before {
  content: "\e8cb";
}

.icon-Firefox::before {
  content: "\e8cc";
}

.icon-Firewall::before {
  content: "\e8cd";
}

.icon-First-Aid::before {
  content: "\e8ce";
}

.icon-First::before {
  content: "\e8cf";
}

.icon-Fish-Food::before {
  content: "\e8d0";
}

.icon-Fish::before {
  content: "\e8d1";
}

.icon-Fit-To::before {
  content: "\e8d2";
}

.icon-Fit-To2::before {
  content: "\e8d3";
}

.icon-Five-Fingers::before {
  content: "\e8d4";
}

.icon-Five-FingersDrag::before {
  content: "\e8d5";
}

.icon-Five-FingersDrag2::before {
  content: "\e8d6";
}

.icon-Five-FingersTouch::before {
  content: "\e8d7";
}

.icon-Flag-2::before {
  content: "\e8d8";
}

.icon-Flag-3::before {
  content: "\e8d9";
}

.icon-Flag-4::before {
  content: "\e8da";
}

.icon-Flag-5::before {
  content: "\e8db";
}

.icon-Flag-6::before {
  content: "\e8dc";
}

.icon-Flag::before {
  content: "\e8dd";
}

.icon-Flamingo::before {
  content: "\e8de";
}

.icon-Flash-2::before {
  content: "\e8df";
}

.icon-Flash-Video::before {
  content: "\e8e0";
}

.icon-Flash::before {
  content: "\e8e1";
}

.icon-Flashlight::before {
  content: "\e8e2";
}

.icon-Flask-2::before {
  content: "\e8e3";
}

.icon-Flask::before {
  content: "\e8e4";
}

.icon-Flick::before {
  content: "\e8e5";
}

.icon-Flickr::before {
  content: "\e8e6";
}

.icon-Flowerpot::before {
  content: "\e8e7";
}

.icon-Fluorescent::before {
  content: "\e8e8";
}

.icon-Fog-Day::before {
  content: "\e8e9";
}

.icon-Fog-Night::before {
  content: "\e8ea";
}

.icon-Folder-Add::before {
  content: "\e8eb";
}

.icon-Folder-Archive::before {
  content: "\e8ec";
}

.icon-Folder-Binder::before {
  content: "\e8ed";
}

.icon-Folder-Binder2::before {
  content: "\e8ee";
}

.icon-Folder-Block::before {
  content: "\e8ef";
}

.icon-Folder-Bookmark::before {
  content: "\e8f0";
}

.icon-Folder-Close::before {
  content: "\e8f1";
}

.icon-Folder-Cloud::before {
  content: "\e8f2";
}

.icon-Folder-Delete::before {
  content: "\e8f3";
}

.icon-Folder-Download::before {
  content: "\e8f4";
}

.icon-Folder-Edit::before {
  content: "\e8f5";
}

.icon-Folder-Favorite::before {
  content: "\e8f6";
}

.icon-Folder-Fire::before {
  content: "\e8f7";
}

.icon-Folder-Hide::before {
  content: "\e8f8";
}

.icon-Folder-Link::before {
  content: "\e8f9";
}

.icon-Folder-Loading::before {
  content: "\e8fa";
}

.icon-Folder-Lock::before {
  content: "\e8fb";
}

.icon-Folder-Love::before {
  content: "\e8fc";
}

.icon-Folder-Music::before {
  content: "\e8fd";
}

.icon-Folder-Network::before {
  content: "\e8fe";
}

.icon-Folder-Open::before {
  content: "\e8ff";
}

.icon-Folder-Open2::before {
  content: "\e900";
}

.icon-Folder-Organizing::before {
  content: "\e901";
}

.icon-Folder-Pictures::before {
  content: "\e902";
}

.icon-Folder-Refresh::before {
  content: "\e903";
}

.icon-Folder-Remove-::before {
  content: "\e904";
}

.icon-Folder-Search::before {
  content: "\e905";
}

.icon-Folder-Settings::before {
  content: "\e906";
}

.icon-Folder-Share::before {
  content: "\e907";
}

.icon-Folder-Trash::before {
  content: "\e908";
}

.icon-Folder-Upload::before {
  content: "\e909";
}

.icon-Folder-Video::before {
  content: "\e90a";
}

.icon-Folder-WithDocument::before {
  content: "\e90b";
}

.icon-Folder-Zip::before {
  content: "\e90c";
}

.icon-Folder::before {
  content: "\e90d";
}

.icon-Folders::before {
  content: "\e90e";
}

.icon-Font-Color::before {
  content: "\e90f";
}

.icon-Font-Name::before {
  content: "\e910";
}

.icon-Font-Size::before {
  content: "\e911";
}

.icon-Font-Style::before {
  content: "\e912";
}

.icon-Font-StyleSubscript::before {
  content: "\e913";
}

.icon-Font-StyleSuperscript::before {
  content: "\e914";
}

.icon-Font-Window::before {
  content: "\e915";
}

.icon-Foot-2::before {
  content: "\e916";
}

.icon-Foot::before {
  content: "\e917";
}

.icon-Football-2::before {
  content: "\e918";
}

.icon-Football::before {
  content: "\e919";
}

.icon-Footprint-2::before {
  content: "\e91a";
}

.icon-Footprint-3::before {
  content: "\e91b";
}

.icon-Footprint::before {
  content: "\e91c";
}

.icon-Forest::before {
  content: "\e91d";
}

.icon-Fork::before {
  content: "\e91e";
}

.icon-Formspring::before {
  content: "\e91f";
}

.icon-Formula::before {
  content: "\e920";
}

.icon-Forsquare::before {
  content: "\e921";
}

.icon-Forward::before {
  content: "\e922";
}

.icon-Fountain-Pen::before {
  content: "\e923";
}

.icon-Four-Fingers::before {
  content: "\e924";
}

.icon-Four-FingersDrag::before {
  content: "\e925";
}

.icon-Four-FingersDrag2::before {
  content: "\e926";
}

.icon-Four-FingersTouch::before {
  content: "\e927";
}

.icon-Fox::before {
  content: "\e928";
}

.icon-Frankenstein::before {
  content: "\e929";
}

.icon-French-Fries::before {
  content: "\e92a";
}

.icon-Friendfeed::before {
  content: "\e92b";
}

.icon-Friendster::before {
  content: "\e92c";
}

.icon-Frog::before {
  content: "\e92d";
}

.icon-Fruits::before {
  content: "\e92e";
}

.icon-Fuel::before {
  content: "\e92f";
}

.icon-Full-Bag::before {
  content: "\e930";
}

.icon-Full-Basket::before {
  content: "\e931";
}

.icon-Full-Cart::before {
  content: "\e932";
}

.icon-Full-Moon::before {
  content: "\e933";
}

.icon-Full-Screen::before {
  content: "\e934";
}

.icon-Full-Screen2::before {
  content: "\e935";
}

.icon-Full-View::before {
  content: "\e936";
}

.icon-Full-View2::before {
  content: "\e937";
}

.icon-Full-ViewWindow::before {
  content: "\e938";
}

.icon-Function::before {
  content: "\e939";
}

.icon-Funky::before {
  content: "\e93a";
}

.icon-Funny-Bicycle::before {
  content: "\e93b";
}

.icon-Furl::before {
  content: "\e93c";
}

.icon-Gamepad-2::before {
  content: "\e93d";
}

.icon-Gamepad::before {
  content: "\e93e";
}

.icon-Gas-Pump::before {
  content: "\e93f";
}

.icon-Gaugage-2::before {
  content: "\e940";
}

.icon-Gaugage::before {
  content: "\e941";
}

.icon-Gay::before {
  content: "\e942";
}

.icon-Gear-2::before {
  content: "\e943";
}

.icon-Gear::before {
  content: "\e944";
}

.icon-Gears-2::before {
  content: "\e945";
}

.icon-Gears::before {
  content: "\e946";
}

.icon-Geek-2::before {
  content: "\e947";
}

.icon-Geek::before {
  content: "\e948";
}

.icon-Gemini-2::before {
  content: "\e949";
}

.icon-Gemini::before {
  content: "\e94a";
}

.icon-Genius::before {
  content: "\e94b";
}

.icon-Gentleman::before {
  content: "\e94c";
}

.icon-Geo--::before {
  content: "\e94d";
}

.icon-Geo-::before {
  content: "\e94e";
}

.icon-Geo-Close::before {
  content: "\e94f";
}

.icon-Geo-Love::before {
  content: "\e950";
}

.icon-Geo-Number::before {
  content: "\e951";
}

.icon-Geo-Star::before {
  content: "\e952";
}

.icon-Geo::before {
  content: "\e953";
}

.icon-Geo2--::before {
  content: "\e954";
}

.icon-Geo2-::before {
  content: "\e955";
}

.icon-Geo2-Close::before {
  content: "\e956";
}

.icon-Geo2-Love::before {
  content: "\e957";
}

.icon-Geo2-Number::before {
  content: "\e958";
}

.icon-Geo2-Star::before {
  content: "\e959";
}

.icon-Geo2::before {
  content: "\e95a";
}

.icon-Geo3--::before {
  content: "\e95b";
}

.icon-Geo3-::before {
  content: "\e95c";
}

.icon-Geo3-Close::before {
  content: "\e95d";
}

.icon-Geo3-Love::before {
  content: "\e95e";
}

.icon-Geo3-Number::before {
  content: "\e95f";
}

.icon-Geo3-Star::before {
  content: "\e960";
}

.icon-Geo3::before {
  content: "\e961";
}

.icon-Gey::before {
  content: "\e962";
}

.icon-Gift-Box::before {
  content: "\e963";
}

.icon-Giraffe::before {
  content: "\e964";
}

.icon-Girl::before {
  content: "\e965";
}

.icon-Glass-Water::before {
  content: "\e966";
}

.icon-Glasses-2::before {
  content: "\e967";
}

.icon-Glasses-3::before {
  content: "\e968";
}

.icon-Glasses::before {
  content: "\e969";
}

.icon-Global-Position::before {
  content: "\e96a";
}

.icon-Globe-2::before {
  content: "\e96b";
}

.icon-Globe::before {
  content: "\e96c";
}

.icon-Gloves::before {
  content: "\e96d";
}

.icon-Go-Bottom::before {
  content: "\e96e";
}

.icon-Go-Top::before {
  content: "\e96f";
}

.icon-Goggles::before {
  content: "\e970";
}

.icon-Golf-2::before {
  content: "\e971";
}

.icon-Golf::before {
  content: "\e972";
}

.icon-Google-Buzz::before {
  content: "\e973";
}

.icon-Google-Drive::before {
  content: "\e974";
}

.icon-Google-Play::before {
  content: "\e975";
}

.icon-Google-Plus::before {
  content: "\e976";
}

.icon-Google::before {
  content: "\e977";
}

.icon-Gopro::before {
  content: "\e978";
}

.icon-Gorilla::before {
  content: "\e979";
}

.icon-Gowalla::before {
  content: "\e97a";
}

.icon-Grave::before {
  content: "\e97b";
}

.icon-Graveyard::before {
  content: "\e97c";
}

.icon-Greece::before {
  content: "\e97d";
}

.icon-Green-Energy::before {
  content: "\e97e";
}

.icon-Green-House::before {
  content: "\e97f";
}

.icon-Guitar::before {
  content: "\e980";
}

.icon-Gun-2::before {
  content: "\e981";
}

.icon-Gun-3::before {
  content: "\e982";
}

.icon-Gun::before {
  content: "\e983";
}

.icon-Gymnastics::before {
  content: "\e984";
}

.icon-Hair-2::before {
  content: "\e985";
}

.icon-Hair-3::before {
  content: "\e986";
}

.icon-Hair-4::before {
  content: "\e987";
}

.icon-Hair::before {
  content: "\e988";
}

.icon-Half-Moon::before {
  content: "\e989";
}

.icon-Halloween-HalfMoon::before {
  content: "\e98a";
}

.icon-Halloween-Moon::before {
  content: "\e98b";
}

.icon-Hamburger::before {
  content: "\e98c";
}

.icon-Hammer::before {
  content: "\e98d";
}

.icon-Hand-Touch::before {
  content: "\e98e";
}

.icon-Hand-Touch2::before {
  content: "\e98f";
}

.icon-Hand-TouchSmartphone::before {
  content: "\e990";
}

.icon-Hand::before {
  content: "\e991";
}

.icon-Hands::before {
  content: "\e992";
}

.icon-Handshake::before {
  content: "\e993";
}

.icon-Hanger::before {
  content: "\e994";
}

.icon-Happy::before {
  content: "\e995";
}

.icon-Hat-2::before {
  content: "\e996";
}

.icon-Hat::before {
  content: "\e997";
}

.icon-Haunted-House::before {
  content: "\e998";
}

.icon-HD-Video::before {
  content: "\e999";
}

.icon-HD::before {
  content: "\e99a";
}

.icon-HDD::before {
  content: "\e99b";
}

.icon-Headphone::before {
  content: "\e99c";
}

.icon-Headphones::before {
  content: "\e99d";
}

.icon-Headset::before {
  content: "\e99e";
}

.icon-Heart-2::before {
  content: "\e99f";
}

.icon-Heart::before {
  content: "\e9a0";
}

.icon-Heels-2::before {
  content: "\e9a1";
}

.icon-Heels::before {
  content: "\e9a2";
}

.icon-Height-Window::before {
  content: "\e9a3";
}

.icon-Helicopter-2::before {
  content: "\e9a4";
}

.icon-Helicopter::before {
  content: "\e9a5";
}

.icon-Helix-2::before {
  content: "\e9a6";
}

.icon-Hello::before {
  content: "\e9a7";
}

.icon-Helmet-2::before {
  content: "\e9a8";
}

.icon-Helmet-3::before {
  content: "\e9a9";
}

.icon-Helmet::before {
  content: "\e9aa";
}

.icon-Hipo::before {
  content: "\e9ab";
}

.icon-Hipster-Glasses::before {
  content: "\e9ac";
}

.icon-Hipster-Glasses2::before {
  content: "\e9ad";
}

.icon-Hipster-Glasses3::before {
  content: "\e9ae";
}

.icon-Hipster-Headphones::before {
  content: "\e9af";
}

.icon-Hipster-Men::before {
  content: "\e9b0";
}

.icon-Hipster-Men2::before {
  content: "\e9b1";
}

.icon-Hipster-Men3::before {
  content: "\e9b2";
}

.icon-Hipster-Sunglasses::before {
  content: "\e9b3";
}

.icon-Hipster-Sunglasses2::before {
  content: "\e9b4";
}

.icon-Hipster-Sunglasses3::before {
  content: "\e9b5";
}

.icon-Hokey::before {
  content: "\e9b6";
}

.icon-Holly::before {
  content: "\e9b7";
}

.icon-Home-2::before {
  content: "\e9b8";
}

.icon-Home-3::before {
  content: "\e9b9";
}

.icon-Home-4::before {
  content: "\e9ba";
}

.icon-Home-5::before {
  content: "\e9bb";
}

.icon-Home-Window::before {
  content: "\e9bc";
}

.icon-Home::before {
  content: "\e9bd";
}

.icon-Homosexual::before {
  content: "\e9be";
}

.icon-Honey::before {
  content: "\e9bf";
}

.icon-Hong-Kong::before {
  content: "\e9c0";
}

.icon-Hoodie::before {
  content: "\e9c1";
}

.icon-Horror::before {
  content: "\e9c2";
}

.icon-Horse::before {
  content: "\e9c3";
}

.icon-Hospital-2::before {
  content: "\e9c4";
}

.icon-Hospital::before {
  content: "\e9c5";
}

.icon-Host::before {
  content: "\e9c6";
}

.icon-Hot-Dog::before {
  content: "\e9c7";
}

.icon-Hotel::before {
  content: "\e9c8";
}

.icon-Hour::before {
  content: "\e9c9";
}

.icon-Hub::before {
  content: "\e9ca";
}

.icon-Humor::before {
  content: "\e9cb";
}

.icon-Hurt::before {
  content: "\e9cc";
}

.icon-Ice-Cream::before {
  content: "\e9cd";
}

.icon-ICQ::before {
  content: "\e9ce";
}

.icon-ID-2::before {
  content: "\e9cf";
}

.icon-ID-3::before {
  content: "\e9d0";
}

.icon-ID-Card::before {
  content: "\e9d1";
}

.icon-Idea-2::before {
  content: "\e9d2";
}

.icon-Idea-3::before {
  content: "\e9d3";
}

.icon-Idea-4::before {
  content: "\e9d4";
}

.icon-Idea-5::before {
  content: "\e9d5";
}

.icon-Idea::before {
  content: "\e9d6";
}

.icon-Identification-Badge::before {
  content: "\e9d7";
}

.icon-ImDB::before {
  content: "\e9d8";
}

.icon-Inbox-Empty::before {
  content: "\e9d9";
}

.icon-Inbox-Forward::before {
  content: "\e9da";
}

.icon-Inbox-Full::before {
  content: "\e9db";
}

.icon-Inbox-Into::before {
  content: "\e9dc";
}

.icon-Inbox-Out::before {
  content: "\e9dd";
}

.icon-Inbox-Reply::before {
  content: "\e9de";
}

.icon-Inbox::before {
  content: "\e9df";
}

.icon-Increase-Inedit::before {
  content: "\e9e0";
}

.icon-Indent-FirstLine::before {
  content: "\e9e1";
}

.icon-Indent-LeftMargin::before {
  content: "\e9e2";
}

.icon-Indent-RightMargin::before {
  content: "\e9e3";
}

.icon-India::before {
  content: "\e9e4";
}

.icon-Info-Window::before {
  content: "\e9e5";
}

.icon-Information::before {
  content: "\e9e6";
}

.icon-Inifity::before {
  content: "\e9e7";
}

.icon-Instagram::before {
  content: "\e9e8";
}

.icon-Internet-2::before {
  content: "\e9e9";
}

.icon-Internet-Explorer::before {
  content: "\e9ea";
}

.icon-Internet-Smiley::before {
  content: "\e9eb";
}

.icon-Internet::before {
  content: "\e9ec";
}

.icon-iOS-Apple::before {
  content: "\e9ed";
}

.icon-Israel::before {
  content: "\e9ee";
}

.icon-Italic-Text::before {
  content: "\e9ef";
}

.icon-Jacket-2::before {
  content: "\e9f0";
}

.icon-Jacket::before {
  content: "\e9f1";
}

.icon-Jamaica::before {
  content: "\e9f2";
}

.icon-Japan::before {
  content: "\e9f3";
}

.icon-Japanese-Gate::before {
  content: "\e9f4";
}

.icon-Jeans::before {
  content: "\e9f5";
}

.icon-Jeep-2::before {
  content: "\e9f6";
}

.icon-Jeep::before {
  content: "\e9f7";
}

.icon-Jet::before {
  content: "\e9f8";
}

.icon-Joystick::before {
  content: "\e9f9";
}

.icon-Juice::before {
  content: "\e9fa";
}

.icon-Jump-Rope::before {
  content: "\e9fb";
}

.icon-Kangoroo::before {
  content: "\e9fc";
}

.icon-Kenya::before {
  content: "\e9fd";
}

.icon-Key-2::before {
  content: "\e9fe";
}

.icon-Key-3::before {
  content: "\e9ff";
}

.icon-Key-Lock::before {
  content: "\ea00";
}

.icon-Key::before {
  content: "\ea01";
}

.icon-Keyboard::before {
  content: "\ea02";
}

.icon-Keyboard3::before {
  content: "\ea03";
}

.icon-Keypad::before {
  content: "\ea04";
}

.icon-King-2::before {
  content: "\ea05";
}

.icon-King::before {
  content: "\ea06";
}

.icon-Kiss::before {
  content: "\ea07";
}

.icon-Knee::before {
  content: "\ea08";
}

.icon-Knife-2::before {
  content: "\ea09";
}

.icon-Knife::before {
  content: "\ea0a";
}

.icon-Knight::before {
  content: "\ea0b";
}

.icon-Koala::before {
  content: "\ea0c";
}

.icon-Korea::before {
  content: "\ea0d";
}

.icon-Lamp::before {
  content: "\ea0e";
}

.icon-Landscape-2::before {
  content: "\ea0f";
}

.icon-Landscape::before {
  content: "\ea10";
}

.icon-Lantern::before {
  content: "\ea11";
}

.icon-Laptop-2::before {
  content: "\ea12";
}

.icon-Laptop-3::before {
  content: "\ea13";
}

.icon-Laptop-Phone::before {
  content: "\ea14";
}

.icon-Laptop-Secure::before {
  content: "\ea15";
}

.icon-Laptop-Tablet::before {
  content: "\ea16";
}

.icon-Laptop::before {
  content: "\ea17";
}

.icon-Laser::before {
  content: "\ea18";
}

.icon-Last-FM::before {
  content: "\ea19";
}

.icon-Last::before {
  content: "\ea1a";
}

.icon-Laughing::before {
  content: "\ea1b";
}

.icon-Layer-1635::before {
  content: "\ea1c";
}

.icon-Layer-1646::before {
  content: "\ea1d";
}

.icon-Layer-Backward::before {
  content: "\ea1e";
}

.icon-Layer-Forward::before {
  content: "\ea1f";
}

.icon-Leafs-2::before {
  content: "\ea20";
}

.icon-Leafs::before {
  content: "\ea21";
}

.icon-Leaning-Tower::before {
  content: "\ea22";
}

.icon-Left--Right::before {
  content: "\ea23";
}

.icon-Left--Right3::before {
  content: "\ea24";
}

.icon-Left-2::before {
  content: "\ea25";
}

.icon-Left-3::before {
  content: "\ea26";
}

.icon-Left-4::before {
  content: "\ea27";
}

.icon-Left-ToRight::before {
  content: "\ea28";
}

.icon-Left::before {
  content: "\ea29";
}

.icon-Leg-2::before {
  content: "\ea2a";
}

.icon-Leg::before {
  content: "\ea2b";
}

.icon-Lego::before {
  content: "\ea2c";
}

.icon-Lemon::before {
  content: "\ea2d";
}

.icon-Len-2::before {
  content: "\ea2e";
}

.icon-Len-3::before {
  content: "\ea2f";
}

.icon-Len::before {
  content: "\ea30";
}

.icon-Leo-2::before {
  content: "\ea31";
}

.icon-Leo::before {
  content: "\ea32";
}

.icon-Leopard::before {
  content: "\ea33";
}

.icon-Lesbian::before {
  content: "\ea34";
}

.icon-Lesbians::before {
  content: "\ea35";
}

.icon-Letter-Close::before {
  content: "\ea36";
}

.icon-Letter-Open::before {
  content: "\ea37";
}

.icon-Letter-Sent::before {
  content: "\ea38";
}

.icon-Libra-2::before {
  content: "\ea39";
}

.icon-Libra::before {
  content: "\ea3a";
}

.icon-Library-2::before {
  content: "\ea3b";
}

.icon-Library::before {
  content: "\ea3c";
}

.icon-Life-Jacket::before {
  content: "\ea3d";
}

.icon-Life-Safer::before {
  content: "\ea3e";
}

.icon-Light-Bulb::before {
  content: "\ea3f";
}

.icon-Light-Bulb2::before {
  content: "\ea40";
}

.icon-Light-BulbLeaf::before {
  content: "\ea41";
}

.icon-Lighthouse::before {
  content: "\ea42";
}

.icon-Like-2::before {
  content: "\ea43";
}

.icon-Like::before {
  content: "\ea44";
}

.icon-Line-Chart::before {
  content: "\ea45";
}

.icon-Line-Chart2::before {
  content: "\ea46";
}

.icon-Line-Chart3::before {
  content: "\ea47";
}

.icon-Line-Chart4::before {
  content: "\ea48";
}

.icon-Line-Spacing::before {
  content: "\ea49";
}

.icon-Line-SpacingText::before {
  content: "\ea4a";
}

.icon-Link-2::before {
  content: "\ea4b";
}

.icon-Link::before {
  content: "\ea4c";
}

.icon-Linkedin-2::before {
  content: "\ea4d";
}

.icon-Linkedin::before {
  content: "\ea4e";
}

.icon-Linux::before {
  content: "\ea4f";
}

.icon-Lion::before {
  content: "\ea50";
}

.icon-Livejournal::before {
  content: "\ea51";
}

.icon-Loading-2::before {
  content: "\ea52";
}

.icon-Loading-3::before {
  content: "\ea53";
}

.icon-Loading-Window::before {
  content: "\ea54";
}

.icon-Loading::before {
  content: "\ea55";
}

.icon-Location-2::before {
  content: "\ea56";
}

.icon-Location::before {
  content: "\ea57";
}

.icon-Lock-2::before {
  content: "\ea58";
}

.icon-Lock-3::before {
  content: "\ea59";
}

.icon-Lock-User::before {
  content: "\ea5a";
}

.icon-Lock-Window::before {
  content: "\ea5b";
}

.icon-Lock::before {
  content: "\ea5c";
}

.icon-Lollipop-2::before {
  content: "\ea5d";
}

.icon-Lollipop-3::before {
  content: "\ea5e";
}

.icon-Lollipop::before {
  content: "\ea5f";
}

.icon-Loop::before {
  content: "\ea60";
}

.icon-Loud::before {
  content: "\ea61";
}

.icon-Loudspeaker::before {
  content: "\ea62";
}

.icon-Love-2::before {
  content: "\ea63";
}

.icon-Love-User::before {
  content: "\ea64";
}

.icon-Love-Window::before {
  content: "\ea65";
}

.icon-Love::before {
  content: "\ea66";
}

.icon-Lowercase-Text::before {
  content: "\ea67";
}

.icon-Luggafe-Front::before {
  content: "\ea68";
}

.icon-Luggage-2::before {
  content: "\ea69";
}

.icon-Macro::before {
  content: "\ea6a";
}

.icon-Magic-Wand::before {
  content: "\ea6b";
}

.icon-Magnet::before {
  content: "\ea6c";
}

.icon-Magnifi-Glass-::before {
  content: "\ea6d";
}

.icon-Magnifi-Glass::before {
  content: "\ea6e";
}

.icon-Magnifi-Glass2::before {
  content: "\ea6f";
}

.icon-Mail-2::before {
  content: "\ea70";
}

.icon-Mail-3::before {
  content: "\ea71";
}

.icon-Mail-Add::before {
  content: "\ea72";
}

.icon-Mail-Attachement::before {
  content: "\ea73";
}

.icon-Mail-Block::before {
  content: "\ea74";
}

.icon-Mail-Delete::before {
  content: "\ea75";
}

.icon-Mail-Favorite::before {
  content: "\ea76";
}

.icon-Mail-Forward::before {
  content: "\ea77";
}

.icon-Mail-Gallery::before {
  content: "\ea78";
}

.icon-Mail-Inbox::before {
  content: "\ea79";
}

.icon-Mail-Link::before {
  content: "\ea7a";
}

.icon-Mail-Lock::before {
  content: "\ea7b";
}

.icon-Mail-Love::before {
  content: "\ea7c";
}

.icon-Mail-Money::before {
  content: "\ea7d";
}

.icon-Mail-Open::before {
  content: "\ea7e";
}

.icon-Mail-Outbox::before {
  content: "\ea7f";
}

.icon-Mail-Password::before {
  content: "\ea80";
}

.icon-Mail-Photo::before {
  content: "\ea81";
}

.icon-Mail-Read::before {
  content: "\ea82";
}

.icon-Mail-Removex::before {
  content: "\ea83";
}

.icon-Mail-Reply::before {
  content: "\ea84";
}

.icon-Mail-ReplyAll::before {
  content: "\ea85";
}

.icon-Mail-Search::before {
  content: "\ea86";
}

.icon-Mail-Send::before {
  content: "\ea87";
}

.icon-Mail-Settings::before {
  content: "\ea88";
}

.icon-Mail-Unread::before {
  content: "\ea89";
}

.icon-Mail-Video::before {
  content: "\ea8a";
}

.icon-Mail-withAtSign::before {
  content: "\ea8b";
}

.icon-Mail-WithCursors::before {
  content: "\ea8c";
}

.icon-Mail::before {
  content: "\ea8d";
}

.icon-Mailbox-Empty::before {
  content: "\ea8e";
}

.icon-Mailbox-Full::before {
  content: "\ea8f";
}

.icon-Male-2::before {
  content: "\ea90";
}

.icon-Male-Sign::before {
  content: "\ea91";
}

.icon-Male::before {
  content: "\ea92";
}

.icon-MaleFemale::before {
  content: "\ea93";
}

.icon-Man-Sign::before {
  content: "\ea94";
}

.icon-Management::before {
  content: "\ea95";
}

.icon-Mans-Underwear::before {
  content: "\ea96";
}

.icon-Mans-Underwear2::before {
  content: "\ea97";
}

.icon-Map-Marker::before {
  content: "\ea98";
}

.icon-Map-Marker2::before {
  content: "\ea99";
}

.icon-Map-Marker3::before {
  content: "\ea9a";
}

.icon-Map::before {
  content: "\ea9b";
}

.icon-Map2::before {
  content: "\ea9c";
}

.icon-Marker-2::before {
  content: "\ea9d";
}

.icon-Marker-3::before {
  content: "\ea9e";
}

.icon-Marker::before {
  content: "\ea9f";
}

.icon-Martini-Glass::before {
  content: "\eaa0";
}

.icon-Mask::before {
  content: "\eaa1";
}

.icon-Master-Card::before {
  content: "\eaa2";
}

.icon-Maximize-Window::before {
  content: "\eaa3";
}

.icon-Maximize::before {
  content: "\eaa4";
}

.icon-Medal-2::before {
  content: "\eaa5";
}

.icon-Medal-3::before {
  content: "\eaa6";
}

.icon-Medal::before {
  content: "\eaa7";
}

.icon-Medical-Sign::before {
  content: "\eaa8";
}

.icon-Medicine-2::before {
  content: "\eaa9";
}

.icon-Medicine-3::before {
  content: "\eaaa";
}

.icon-Medicine::before {
  content: "\eaab";
}

.icon-Megaphone::before {
  content: "\eaac";
}

.icon-Memory-Card::before {
  content: "\eaad";
}

.icon-Memory-Card2::before {
  content: "\eaae";
}

.icon-Memory-Card3::before {
  content: "\eaaf";
}

.icon-Men::before {
  content: "\eab0";
}

.icon-Menorah::before {
  content: "\eab1";
}

.icon-Mens::before {
  content: "\eab2";
}

.icon-Metacafe::before {
  content: "\eab3";
}

.icon-Mexico::before {
  content: "\eab4";
}

.icon-Mic::before {
  content: "\eab5";
}

.icon-Microphone-2::before {
  content: "\eab6";
}

.icon-Microphone-3::before {
  content: "\eab7";
}

.icon-Microphone-4::before {
  content: "\eab8";
}

.icon-Microphone-5::before {
  content: "\eab9";
}

.icon-Microphone-6::before {
  content: "\eaba";
}

.icon-Microphone-7::before {
  content: "\eabb";
}

.icon-Microphone::before {
  content: "\eabc";
}

.icon-Microscope::before {
  content: "\eabd";
}

.icon-Milk-Bottle::before {
  content: "\eabe";
}

.icon-Mine::before {
  content: "\eabf";
}

.icon-Minimize-Maximize-Close-Window::before {
  content: "\eac0";
}

.icon-Minimize-Window::before {
  content: "\eac1";
}

.icon-Minimize::before {
  content: "\eac2";
}

.icon-Mirror::before {
  content: "\eac3";
}

.icon-Mixer::before {
  content: "\eac4";
}

.icon-Mixx::before {
  content: "\eac5";
}

.icon-Money-2::before {
  content: "\eac6";
}

.icon-Money-Bag::before {
  content: "\eac7";
}

.icon-Money-Smiley::before {
  content: "\eac8";
}

.icon-Money::before {
  content: "\eac9";
}

.icon-Monitor-2::before {
  content: "\eaca";
}

.icon-Monitor-3::before {
  content: "\eacb";
}

.icon-Monitor-4::before {
  content: "\eacc";
}

.icon-Monitor-5::before {
  content: "\eacd";
}

.icon-Monitor-Analytics::before {
  content: "\eace";
}

.icon-Monitor-Laptop::before {
  content: "\eacf";
}

.icon-Monitor-phone::before {
  content: "\ead0";
}

.icon-Monitor-Tablet::before {
  content: "\ead1";
}

.icon-Monitor-Vertical::before {
  content: "\ead2";
}

.icon-Monitor::before {
  content: "\ead3";
}

.icon-Monitoring::before {
  content: "\ead4";
}

.icon-Monkey::before {
  content: "\ead5";
}

.icon-Monster::before {
  content: "\ead6";
}

.icon-Morocco::before {
  content: "\ead7";
}

.icon-Motorcycle::before {
  content: "\ead8";
}

.icon-Mouse-2::before {
  content: "\ead9";
}

.icon-Mouse-3::before {
  content: "\eada";
}

.icon-Mouse-4::before {
  content: "\eadb";
}

.icon-Mouse-Pointer::before {
  content: "\eadc";
}

.icon-Mouse::before {
  content: "\eadd";
}

.icon-Moustache-Smiley::before {
  content: "\eade";
}

.icon-Movie-Ticket::before {
  content: "\eadf";
}

.icon-Movie::before {
  content: "\eae0";
}

.icon-Mp3-File::before {
  content: "\eae1";
}

.icon-Museum::before {
  content: "\eae2";
}

.icon-Mushroom::before {
  content: "\eae3";
}

.icon-Music-Note::before {
  content: "\eae4";
}

.icon-Music-Note2::before {
  content: "\eae5";
}

.icon-Music-Note3::before {
  content: "\eae6";
}

.icon-Music-Note4::before {
  content: "\eae7";
}

.icon-Music-Player::before {
  content: "\eae8";
}

.icon-Mustache-2::before {
  content: "\eae9";
}

.icon-Mustache-3::before {
  content: "\eaea";
}

.icon-Mustache-4::before {
  content: "\eaeb";
}

.icon-Mustache-5::before {
  content: "\eaec";
}

.icon-Mustache-6::before {
  content: "\eaed";
}

.icon-Mustache-7::before {
  content: "\eaee";
}

.icon-Mustache-8::before {
  content: "\eaef";
}

.icon-Mustache::before {
  content: "\eaf0";
}

.icon-Mute::before {
  content: "\eaf1";
}

.icon-Myspace::before {
  content: "\eaf2";
}

.icon-Navigat-Start::before {
  content: "\eaf3";
}

.icon-Navigate-End::before {
  content: "\eaf4";
}

.icon-Navigation-LeftWindow::before {
  content: "\eaf5";
}

.icon-Navigation-RightWindow::before {
  content: "\eaf6";
}

.icon-Nepal::before {
  content: "\eaf7";
}

.icon-Netscape::before {
  content: "\eaf8";
}

.icon-Network-Window::before {
  content: "\eaf9";
}

.icon-Network::before {
  content: "\eafa";
}

.icon-Neutron::before {
  content: "\eafb";
}

.icon-New-Mail::before {
  content: "\eafc";
}

.icon-New-Tab::before {
  content: "\eafd";
}

.icon-Newspaper-2::before {
  content: "\eafe";
}

.icon-Newspaper::before {
  content: "\eaff";
}

.icon-Newsvine::before {
  content: "\eb00";
}

.icon-Next2::before {
  content: "\eb01";
}

.icon-Next-3::before {
  content: "\eb02";
}

.icon-Next-Music::before {
  content: "\eb03";
}

.icon-Next::before {
  content: "\eb04";
}

.icon-No-Battery::before {
  content: "\eb05";
}

.icon-No-Drop::before {
  content: "\eb06";
}

.icon-No-Flash::before {
  content: "\eb07";
}

.icon-No-Smoking::before {
  content: "\eb08";
}

.icon-Noose::before {
  content: "\eb09";
}

.icon-Normal-Text::before {
  content: "\eb0a";
}

.icon-Note::before {
  content: "\eb0b";
}

.icon-Notepad-2::before {
  content: "\eb0c";
}

.icon-Notepad::before {
  content: "\eb0d";
}

.icon-Nuclear::before {
  content: "\eb0e";
}

.icon-Numbering-List::before {
  content: "\eb0f";
}

.icon-Nurse::before {
  content: "\eb10";
}

.icon-Office-Lamp::before {
  content: "\eb11";
}

.icon-Office::before {
  content: "\eb12";
}

.icon-Oil::before {
  content: "\eb13";
}

.icon-Old-Camera::before {
  content: "\eb14";
}

.icon-Old-Cassette::before {
  content: "\eb15";
}

.icon-Old-Clock::before {
  content: "\eb16";
}

.icon-Old-Radio::before {
  content: "\eb17";
}

.icon-Old-Sticky::before {
  content: "\eb18";
}

.icon-Old-Sticky2::before {
  content: "\eb19";
}

.icon-Old-Telephone::before {
  content: "\eb1a";
}

.icon-Old-TV::before {
  content: "\eb1b";
}

.icon-On-Air::before {
  content: "\eb1c";
}

.icon-On-Off-2::before {
  content: "\eb1d";
}

.icon-On-Off-3::before {
  content: "\eb1e";
}

.icon-On-off::before {
  content: "\eb1f";
}

.icon-One-Finger::before {
  content: "\eb20";
}

.icon-One-FingerTouch::before {
  content: "\eb21";
}

.icon-One-Window::before {
  content: "\eb22";
}

.icon-Open-Banana::before {
  content: "\eb23";
}

.icon-Open-Book::before {
  content: "\eb24";
}

.icon-Opera-House::before {
  content: "\eb25";
}

.icon-Opera::before {
  content: "\eb26";
}

.icon-Optimization::before {
  content: "\eb27";
}

.icon-Orientation-2::before {
  content: "\eb28";
}

.icon-Orientation-3::before {
  content: "\eb29";
}

.icon-Orientation::before {
  content: "\eb2a";
}

.icon-Orkut::before {
  content: "\eb2b";
}

.icon-Ornament::before {
  content: "\eb2c";
}

.icon-Over-Time::before {
  content: "\eb2d";
}

.icon-Over-Time2::before {
  content: "\eb2e";
}

.icon-Owl::before {
  content: "\eb2f";
}

.icon-Pac-Man::before {
  content: "\eb30";
}

.icon-Paint-Brush::before {
  content: "\eb31";
}

.icon-Paint-Bucket::before {
  content: "\eb32";
}

.icon-Paintbrush::before {
  content: "\eb33";
}

.icon-Palette::before {
  content: "\eb34";
}

.icon-Palm-Tree::before {
  content: "\eb35";
}

.icon-Panda::before {
  content: "\eb36";
}

.icon-Panorama::before {
  content: "\eb37";
}

.icon-Pantheon::before {
  content: "\eb38";
}

.icon-Pantone::before {
  content: "\eb39";
}

.icon-Pants::before {
  content: "\eb3a";
}

.icon-Paper-Plane::before {
  content: "\eb3b";
}

.icon-Paper::before {
  content: "\eb3c";
}

.icon-Parasailing::before {
  content: "\eb3d";
}

.icon-Parrot::before {
  content: "\eb3e";
}

.icon-Password-2shopping::before {
  content: "\eb3f";
}

.icon-Password-Field::before {
  content: "\eb40";
}

.icon-Password-shopping::before {
  content: "\eb41";
}

.icon-Password::before {
  content: "\eb42";
}

.icon-pause-2::before {
  content: "\eb43";
}

.icon-Pause::before {
  content: "\eb44";
}

.icon-Paw::before {
  content: "\eb45";
}

.icon-Pawn::before {
  content: "\eb46";
}

.icon-Paypal::before {
  content: "\eb47";
}

.icon-Pen-2::before {
  content: "\eb48";
}

.icon-Pen-3::before {
  content: "\eb49";
}

.icon-Pen-4::before {
  content: "\eb4a";
}

.icon-Pen-5::before {
  content: "\eb4b";
}

.icon-Pen-6::before {
  content: "\eb4c";
}

.icon-Pen::before {
  content: "\eb4d";
}

.icon-Pencil-Ruler::before {
  content: "\eb4e";
}

.icon-Pencil::before {
  content: "\eb4f";
}

.icon-Penguin::before {
  content: "\eb50";
}

.icon-Pentagon::before {
  content: "\eb51";
}

.icon-People-onCloud::before {
  content: "\eb52";
}

.icon-Pepper-withFire::before {
  content: "\eb53";
}

.icon-Pepper::before {
  content: "\eb54";
}

.icon-Petrol::before {
  content: "\eb55";
}

.icon-Petronas-Tower::before {
  content: "\eb56";
}

.icon-Philipines::before {
  content: "\eb57";
}

.icon-Phone-2::before {
  content: "\eb58";
}

.icon-Phone-3::before {
  content: "\eb59";
}

.icon-Phone-3G::before {
  content: "\eb5a";
}

.icon-Phone-4G::before {
  content: "\eb5b";
}

.icon-Phone-Simcard::before {
  content: "\eb5c";
}

.icon-Phone-SMS::before {
  content: "\eb5d";
}

.icon-Phone-Wifi::before {
  content: "\eb5e";
}

.icon-Phone::before {
  content: "\eb5f";
}

.icon-Photo-2::before {
  content: "\eb60";
}

.icon-Photo-3::before {
  content: "\eb61";
}

.icon-Photo-Album::before {
  content: "\eb62";
}

.icon-Photo-Album2::before {
  content: "\eb63";
}

.icon-Photo-Album3::before {
  content: "\eb64";
}

.icon-Photo::before {
  content: "\eb65";
}

.icon-Photos::before {
  content: "\eb66";
}

.icon-Physics::before {
  content: "\eb67";
}

.icon-Pi::before {
  content: "\eb68";
}

.icon-Piano::before {
  content: "\eb69";
}

.icon-Picasa::before {
  content: "\eb6a";
}

.icon-Pie-Chart::before {
  content: "\eb6b";
}

.icon-Pie-Chart2::before {
  content: "\eb6c";
}

.icon-Pie-Chart3::before {
  content: "\eb6d";
}

.icon-Pilates-2::before {
  content: "\eb6e";
}

.icon-Pilates-3::before {
  content: "\eb6f";
}

.icon-Pilates::before {
  content: "\eb70";
}

.icon-Pilot::before {
  content: "\eb71";
}

.icon-Pinch::before {
  content: "\eb72";
}

.icon-Ping-Pong::before {
  content: "\eb73";
}

.icon-Pinterest::before {
  content: "\eb74";
}

.icon-Pipe::before {
  content: "\eb75";
}

.icon-Pipette::before {
  content: "\eb76";
}

.icon-Piramids::before {
  content: "\eb77";
}

.icon-Pisces-2::before {
  content: "\eb78";
}

.icon-Pisces::before {
  content: "\eb79";
}

.icon-Pizza-Slice::before {
  content: "\eb7a";
}

.icon-Pizza::before {
  content: "\eb7b";
}

.icon-Plane-2::before {
  content: "\eb7c";
}

.icon-Plane::before {
  content: "\eb7d";
}

.icon-Plant::before {
  content: "\eb7e";
}

.icon-Plasmid::before {
  content: "\eb7f";
}

.icon-Plaster::before {
  content: "\eb80";
}

.icon-Plastic-CupPhone::before {
  content: "\eb81";
}

.icon-Plastic-CupPhone2::before {
  content: "\eb82";
}

.icon-Plate::before {
  content: "\eb83";
}

.icon-Plates::before {
  content: "\eb84";
}

.icon-Plaxo::before {
  content: "\eb85";
}

.icon-Play-Music::before {
  content: "\eb86";
}

.icon-Plug-In::before {
  content: "\eb87";
}

.icon-Plug-In2::before {
  content: "\eb88";
}

.icon-Plurk::before {
  content: "\eb89";
}

.icon-Pointer::before {
  content: "\eb8a";
}

.icon-Poland::before {
  content: "\eb8b";
}

.icon-Police-Man::before {
  content: "\eb8c";
}

.icon-Police-Station::before {
  content: "\eb8d";
}

.icon-Police-Woman::before {
  content: "\eb8e";
}

.icon-Police::before {
  content: "\eb8f";
}

.icon-Polo-Shirt::before {
  content: "\eb90";
}

.icon-Portrait::before {
  content: "\eb91";
}

.icon-Portugal::before {
  content: "\eb92";
}

.icon-Post-Mail::before {
  content: "\eb93";
}

.icon-Post-Mail2::before {
  content: "\eb94";
}

.icon-Post-Office::before {
  content: "\eb95";
}

.icon-Post-Sign::before {
  content: "\eb96";
}

.icon-Post-Sign2ways::before {
  content: "\eb97";
}

.icon-Posterous::before {
  content: "\eb98";
}

.icon-Pound-Sign::before {
  content: "\eb99";
}

.icon-Pound-Sign2::before {
  content: "\eb9a";
}

.icon-Pound::before {
  content: "\eb9b";
}

.icon-Power-2::before {
  content: "\eb9c";
}

.icon-Power-3::before {
  content: "\eb9d";
}

.icon-Power-Cable::before {
  content: "\eb9e";
}

.icon-Power-Station::before {
  content: "\eb9f";
}

.icon-Power::before {
  content: "\eba0";
}

.icon-Prater::before {
  content: "\eba1";
}

.icon-Present::before {
  content: "\eba2";
}

.icon-Presents::before {
  content: "\eba3";
}

.icon-Press::before {
  content: "\eba4";
}

.icon-Preview::before {
  content: "\eba5";
}

.icon-Previous::before {
  content: "\eba6";
}

.icon-Pricing::before {
  content: "\eba7";
}

.icon-Printer::before {
  content: "\eba8";
}

.icon-Professor::before {
  content: "\eba9";
}

.icon-Profile::before {
  content: "\ebaa";
}

.icon-Project::before {
  content: "\ebab";
}

.icon-Projector-2::before {
  content: "\ebac";
}

.icon-Projector::before {
  content: "\ebad";
}

.icon-Pulse::before {
  content: "\ebae";
}

.icon-Pumpkin::before {
  content: "\ebaf";
}

.icon-Punk::before {
  content: "\ebb0";
}

.icon-Punker::before {
  content: "\ebb1";
}

.icon-Puzzle::before {
  content: "\ebb2";
}

.icon-QIK::before {
  content: "\ebb3";
}

.icon-QR-Code::before {
  content: "\ebb4";
}

.icon-Queen-2::before {
  content: "\ebb5";
}

.icon-Queen::before {
  content: "\ebb6";
}

.icon-Quill-2::before {
  content: "\ebb7";
}

.icon-Quill-3::before {
  content: "\ebb8";
}

.icon-Quill::before {
  content: "\ebb9";
}

.icon-Quotes-2::before {
  content: "\ebba";
}

.icon-Quotes::before {
  content: "\ebbb";
}

.icon-Radio::before {
  content: "\ebbc";
}

.icon-Radioactive::before {
  content: "\ebbd";
}

.icon-Rafting::before {
  content: "\ebbe";
}

.icon-Rain-Drop::before {
  content: "\ebbf";
}

.icon-Rainbow-2::before {
  content: "\ebc0";
}

.icon-Rainbow::before {
  content: "\ebc1";
}

.icon-Ram::before {
  content: "\ebc2";
}

.icon-Razzor-Blade::before {
  content: "\ebc3";
}

.icon-Receipt-2::before {
  content: "\ebc4";
}

.icon-Receipt-3::before {
  content: "\ebc5";
}

.icon-Receipt-4::before {
  content: "\ebc6";
}

.icon-Receipt::before {
  content: "\ebc7";
}

.icon-Record2::before {
  content: "\ebc8";
}

.icon-Record-3::before {
  content: "\ebc9";
}

.icon-Record-Music::before {
  content: "\ebca";
}

.icon-Record::before {
  content: "\ebcb";
}

.icon-Recycling-2::before {
  content: "\ebcc";
}

.icon-Recycling::before {
  content: "\ebcd";
}

.icon-Reddit::before {
  content: "\ebce";
}

.icon-Redhat::before {
  content: "\ebcf";
}

.icon-Redirect::before {
  content: "\ebd0";
}

.icon-Redo::before {
  content: "\ebd1";
}

.icon-Reel::before {
  content: "\ebd2";
}

.icon-Refinery::before {
  content: "\ebd3";
}

.icon-Refresh-Window::before {
  content: "\ebd4";
}

.icon-Refresh::before {
  content: "\ebd5";
}

.icon-Reload-2::before {
  content: "\ebd6";
}

.icon-Reload-3::before {
  content: "\ebd7";
}

.icon-Reload::before {
  content: "\ebd8";
}

.icon-Remote-Controll::before {
  content: "\ebd9";
}

.icon-Remote-Controll2::before {
  content: "\ebda";
}

.icon-Remove-Bag::before {
  content: "\ebdb";
}

.icon-Remove-Basket::before {
  content: "\ebdc";
}

.icon-Remove-Cart::before {
  content: "\ebdd";
}

.icon-Remove-File::before {
  content: "\ebde";
}

.icon-Remove-User::before {
  content: "\ebdf";
}

.icon-Remove-Window::before {
  content: "\ebe0";
}

.icon-Remove::before {
  content: "\ebe1";
}

.icon-Rename::before {
  content: "\ebe2";
}

.icon-Repair::before {
  content: "\ebe3";
}

.icon-Repeat-2::before {
  content: "\ebe4";
}

.icon-Repeat-3::before {
  content: "\ebe5";
}

.icon-Repeat-4::before {
  content: "\ebe6";
}

.icon-Repeat-5::before {
  content: "\ebe7";
}

.icon-Repeat-6::before {
  content: "\ebe8";
}

.icon-Repeat-7::before {
  content: "\ebe9";
}

.icon-Repeat::before {
  content: "\ebea";
}

.icon-Reset::before {
  content: "\ebeb";
}

.icon-Resize::before {
  content: "\ebec";
}

.icon-Restore-Window::before {
  content: "\ebed";
}

.icon-Retouching::before {
  content: "\ebee";
}

.icon-Retro-Camera::before {
  content: "\ebef";
}

.icon-Retro::before {
  content: "\ebf0";
}

.icon-Retweet::before {
  content: "\ebf1";
}

.icon-Reverbnation::before {
  content: "\ebf2";
}

.icon-Rewind::before {
  content: "\ebf3";
}

.icon-RGB::before {
  content: "\ebf4";
}

.icon-Ribbon-2::before {
  content: "\ebf5";
}

.icon-Ribbon-3::before {
  content: "\ebf6";
}

.icon-Ribbon::before {
  content: "\ebf7";
}

.icon-Right-2::before {
  content: "\ebf8";
}

.icon-Right-3::before {
  content: "\ebf9";
}

.icon-Right-4::before {
  content: "\ebfa";
}

.icon-Right-ToLeft::before {
  content: "\ebfb";
}

.icon-Right::before {
  content: "\ebfc";
}

.icon-Road-2::before {
  content: "\ebfd";
}

.icon-Road-3::before {
  content: "\ebfe";
}

.icon-Road::before {
  content: "\ebff";
}

.icon-Robot-2::before {
  content: "\ec00";
}

.icon-Robot::before {
  content: "\ec01";
}

.icon-Rock-andRoll::before {
  content: "\ec02";
}

.icon-Rocket::before {
  content: "\ec03";
}

.icon-Roller::before {
  content: "\ec04";
}

.icon-Roof::before {
  content: "\ec05";
}

.icon-Rook::before {
  content: "\ec06";
}

.icon-Rotate-Gesture::before {
  content: "\ec07";
}

.icon-Rotate-Gesture2::before {
  content: "\ec08";
}

.icon-Rotate-Gesture3::before {
  content: "\ec09";
}

.icon-Rotation-390::before {
  content: "\ec0a";
}

.icon-Rotation::before {
  content: "\ec0b";
}

.icon-Router-2::before {
  content: "\ec0c";
}

.icon-Router::before {
  content: "\ec0d";
}

.icon-RSS::before {
  content: "\ec0e";
}

.icon-Ruler-2::before {
  content: "\ec0f";
}

.icon-Ruler::before {
  content: "\ec10";
}

.icon-Running-Shoes::before {
  content: "\ec11";
}

.icon-Running::before {
  content: "\ec12";
}

.icon-Safari::before {
  content: "\ec13";
}

.icon-Safe-Box::before {
  content: "\ec14";
}

.icon-Safe-Box2::before {
  content: "\ec15";
}

.icon-Safety-PinClose::before {
  content: "\ec16";
}

.icon-Safety-PinOpen::before {
  content: "\ec17";
}

.icon-Sagittarus-2::before {
  content: "\ec18";
}

.icon-Sagittarus::before {
  content: "\ec19";
}

.icon-Sailing-Ship::before {
  content: "\ec1a";
}

.icon-Sand-watch::before {
  content: "\ec1b";
}

.icon-Sand-watch2::before {
  content: "\ec1c";
}

.icon-Santa-Claus::before {
  content: "\ec1d";
}

.icon-Santa-Claus2::before {
  content: "\ec1e";
}

.icon-Santa-onSled::before {
  content: "\ec1f";
}

.icon-Satelite-2::before {
  content: "\ec20";
}

.icon-Satelite::before {
  content: "\ec21";
}

.icon-Save-Window::before {
  content: "\ec22";
}

.icon-Save::before {
  content: "\ec23";
}

.icon-Saw::before {
  content: "\ec24";
}

.icon-Saxophone::before {
  content: "\ec25";
}

.icon-Scale::before {
  content: "\ec26";
}

.icon-Scarf::before {
  content: "\ec27";
}

.icon-Scissor::before {
  content: "\ec28";
}

.icon-Scooter-Front::before {
  content: "\ec29";
}

.icon-Scooter::before {
  content: "\ec2a";
}

.icon-Scorpio-2::before {
  content: "\ec2b";
}

.icon-Scorpio::before {
  content: "\ec2c";
}

.icon-Scotland::before {
  content: "\ec2d";
}

.icon-Screwdriver::before {
  content: "\ec2e";
}

.icon-Scroll-Fast::before {
  content: "\ec2f";
}

.icon-Scroll::before {
  content: "\ec30";
}

.icon-Scroller-2::before {
  content: "\ec31";
}

.icon-Scroller::before {
  content: "\ec32";
}

.icon-Sea-Dog::before {
  content: "\ec33";
}

.icon-Search-onCloud::before {
  content: "\ec34";
}

.icon-Search-People::before {
  content: "\ec35";
}

.icon-secound::before {
  content: "\ec36";
}

.icon-secound2::before {
  content: "\ec37";
}

.icon-Security-Block::before {
  content: "\ec38";
}

.icon-Security-Bug::before {
  content: "\ec39";
}

.icon-Security-Camera::before {
  content: "\ec3a";
}

.icon-Security-Check::before {
  content: "\ec3b";
}

.icon-Security-Settings::before {
  content: "\ec3c";
}

.icon-Security-Smiley::before {
  content: "\ec3d";
}

.icon-Securiy-Remove::before {
  content: "\ec3e";
}

.icon-Seed::before {
  content: "\ec3f";
}

.icon-Selfie::before {
  content: "\ec40";
}

.icon-Serbia::before {
  content: "\ec41";
}

.icon-Server-2::before {
  content: "\ec42";
}

.icon-Server::before {
  content: "\ec43";
}

.icon-Servers::before {
  content: "\ec44";
}

.icon-Settings-Window::before {
  content: "\ec45";
}

.icon-Sewing-Machine::before {
  content: "\ec46";
}

.icon-Sexual::before {
  content: "\ec47";
}

.icon-Share-onCloud::before {
  content: "\ec48";
}

.icon-Share-Window::before {
  content: "\ec49";
}

.icon-Share::before {
  content: "\ec4a";
}

.icon-Sharethis::before {
  content: "\ec4b";
}

.icon-Shark::before {
  content: "\ec4c";
}

.icon-Sheep::before {
  content: "\ec4d";
}

.icon-Sheriff-Badge::before {
  content: "\ec4e";
}

.icon-Shield::before {
  content: "\ec4f";
}

.icon-Ship-2::before {
  content: "\ec50";
}

.icon-Ship::before {
  content: "\ec51";
}

.icon-Shirt::before {
  content: "\ec52";
}

.icon-Shoes-2::before {
  content: "\ec53";
}

.icon-Shoes-3::before {
  content: "\ec54";
}

.icon-Shoes::before {
  content: "\ec55";
}

.icon-Shop-2::before {
  content: "\ec56";
}

.icon-Shop-3::before {
  content: "\ec57";
}

.icon-Shop-4::before {
  content: "\ec58";
}

.icon-Shop::before {
  content: "\ec59";
}

.icon-Shopping-Bag::before {
  content: "\ec5a";
}

.icon-Shopping-Basket::before {
  content: "\ec5b";
}

.icon-Shopping-Cart::before {
  content: "\ec5c";
}

.icon-Short-Pants::before {
  content: "\ec5d";
}

.icon-Shoutwire::before {
  content: "\ec5e";
}

.icon-Shovel::before {
  content: "\ec5f";
}

.icon-Shuffle-2::before {
  content: "\ec60";
}

.icon-Shuffle-3::before {
  content: "\ec61";
}

.icon-Shuffle-4::before {
  content: "\ec62";
}

.icon-Shuffle::before {
  content: "\ec63";
}

.icon-Shutter::before {
  content: "\ec64";
}

.icon-Sidebar-Window::before {
  content: "\ec65";
}

.icon-Signal::before {
  content: "\ec66";
}

.icon-Singapore::before {
  content: "\ec67";
}

.icon-Skate-Shoes::before {
  content: "\ec68";
}

.icon-Skateboard-2::before {
  content: "\ec69";
}

.icon-Skateboard::before {
  content: "\ec6a";
}

.icon-Skeleton::before {
  content: "\ec6b";
}

.icon-Ski::before {
  content: "\ec6c";
}

.icon-Skirt::before {
  content: "\ec6d";
}

.icon-Skrill::before {
  content: "\ec6e";
}

.icon-Skull::before {
  content: "\ec6f";
}

.icon-Skydiving::before {
  content: "\ec70";
}

.icon-Skype::before {
  content: "\ec71";
}

.icon-Sled-withGifts::before {
  content: "\ec72";
}

.icon-Sled::before {
  content: "\ec73";
}

.icon-Sleeping::before {
  content: "\ec74";
}

.icon-Sleet::before {
  content: "\ec75";
}

.icon-Slippers::before {
  content: "\ec76";
}

.icon-Smart::before {
  content: "\ec77";
}

.icon-Smartphone-2::before {
  content: "\ec78";
}

.icon-Smartphone-3::before {
  content: "\ec79";
}

.icon-Smartphone-4::before {
  content: "\ec7a";
}

.icon-Smartphone-Secure::before {
  content: "\ec7b";
}

.icon-Smartphone::before {
  content: "\ec7c";
}

.icon-Smile::before {
  content: "\ec7d";
}

.icon-Smoking-Area::before {
  content: "\ec7e";
}

.icon-Smoking-Pipe::before {
  content: "\ec7f";
}

.icon-Snake::before {
  content: "\ec80";
}

.icon-Snorkel::before {
  content: "\ec81";
}

.icon-Snow-2::before {
  content: "\ec82";
}

.icon-Snow-Dome::before {
  content: "\ec83";
}

.icon-Snow-Storm::before {
  content: "\ec84";
}

.icon-Snow::before {
  content: "\ec85";
}

.icon-Snowflake-2::before {
  content: "\ec86";
}

.icon-Snowflake-3::before {
  content: "\ec87";
}

.icon-Snowflake-4::before {
  content: "\ec88";
}

.icon-Snowflake::before {
  content: "\ec89";
}

.icon-Snowman::before {
  content: "\ec8a";
}

.icon-Soccer-Ball::before {
  content: "\ec8b";
}

.icon-Soccer-Shoes::before {
  content: "\ec8c";
}

.icon-Socks::before {
  content: "\ec8d";
}

.icon-Solar::before {
  content: "\ec8e";
}

.icon-Sound-Wave::before {
  content: "\ec8f";
}

.icon-Sound::before {
  content: "\ec90";
}

.icon-Soundcloud::before {
  content: "\ec91";
}

.icon-Soup::before {
  content: "\ec92";
}

.icon-South-Africa::before {
  content: "\ec93";
}

.icon-Space-Needle::before {
  content: "\ec94";
}

.icon-Spain::before {
  content: "\ec95";
}

.icon-Spam-Mail::before {
  content: "\ec96";
}

.icon-Speach-Bubble::before {
  content: "\ec97";
}

.icon-Speach-Bubble2::before {
  content: "\ec98";
}

.icon-Speach-Bubble3::before {
  content: "\ec99";
}

.icon-Speach-Bubble4::before {
  content: "\ec9a";
}

.icon-Speach-Bubble5::before {
  content: "\ec9b";
}

.icon-Speach-Bubble6::before {
  content: "\ec9c";
}

.icon-Speach-Bubble7::before {
  content: "\ec9d";
}

.icon-Speach-Bubble8::before {
  content: "\ec9e";
}

.icon-Speach-Bubble9::before {
  content: "\ec9f";
}

.icon-Speach-Bubble10::before {
  content: "\eca0";
}

.icon-Speach-Bubble11::before {
  content: "\eca1";
}

.icon-Speach-Bubble12::before {
  content: "\eca2";
}

.icon-Speach-Bubble13::before {
  content: "\eca3";
}

.icon-Speach-BubbleAsking::before {
  content: "\eca4";
}

.icon-Speach-BubbleComic::before {
  content: "\eca5";
}

.icon-Speach-BubbleComic2::before {
  content: "\eca6";
}

.icon-Speach-BubbleComic3::before {
  content: "\eca7";
}

.icon-Speach-BubbleComic4::before {
  content: "\eca8";
}

.icon-Speach-BubbleDialog::before {
  content: "\eca9";
}

.icon-Speach-Bubbles::before {
  content: "\ecaa";
}

.icon-Speak-2::before {
  content: "\ecab";
}

.icon-Speak::before {
  content: "\ecac";
}

.icon-Speaker-2::before {
  content: "\ecad";
}

.icon-Speaker::before {
  content: "\ecae";
}

.icon-Spell-Check::before {
  content: "\ecaf";
}

.icon-Spell-CheckABC::before {
  content: "\ecb0";
}

.icon-Spermium::before {
  content: "\ecb1";
}

.icon-Spider::before {
  content: "\ecb2";
}

.icon-Spiderweb::before {
  content: "\ecb3";
}

.icon-Split-FourSquareWindow::before {
  content: "\ecb4";
}

.icon-Split-Horizontal::before {
  content: "\ecb5";
}

.icon-Split-Horizontal2Window::before {
  content: "\ecb6";
}

.icon-Split-Vertical::before {
  content: "\ecb7";
}

.icon-Split-Vertical2::before {
  content: "\ecb8";
}

.icon-Split-Window::before {
  content: "\ecb9";
}

.icon-Spoder::before {
  content: "\ecba";
}

.icon-Spoon::before {
  content: "\ecbb";
}

.icon-Sport-Mode::before {
  content: "\ecbc";
}

.icon-Sports-Clothings1::before {
  content: "\ecbd";
}

.icon-Sports-Clothings2::before {
  content: "\ecbe";
}

.icon-Sports-Shirt::before {
  content: "\ecbf";
}

.icon-Spot::before {
  content: "\ecc0";
}

.icon-Spray::before {
  content: "\ecc1";
}

.icon-Spread::before {
  content: "\ecc2";
}

.icon-Spring::before {
  content: "\ecc3";
}

.icon-Spurl::before {
  content: "\ecc4";
}

.icon-Spy::before {
  content: "\ecc5";
}

.icon-Squirrel::before {
  content: "\ecc6";
}

.icon-SSL::before {
  content: "\ecc7";
}

.icon-St-BasilsCathedral::before {
  content: "\ecc8";
}

.icon-St-PaulsCathedral::before {
  content: "\ecc9";
}

.icon-Stamp-2::before {
  content: "\ecca";
}

.icon-Stamp::before {
  content: "\eccb";
}

.icon-Stapler::before {
  content: "\eccc";
}

.icon-Star-Track::before {
  content: "\eccd";
}

.icon-Star::before {
  content: "\ecce";
}

.icon-Starfish::before {
  content: "\eccf";
}

.icon-Start2::before {
  content: "\ecd0";
}

.icon-Start-3::before {
  content: "\ecd1";
}

.icon-Start-ways::before {
  content: "\ecd2";
}

.icon-Start::before {
  content: "\ecd3";
}

.icon-Statistic::before {
  content: "\ecd4";
}

.icon-Stethoscope::before {
  content: "\ecd5";
}

.icon-stop--2::before {
  content: "\ecd6";
}

.icon-Stop-Music::before {
  content: "\ecd7";
}

.icon-Stop::before {
  content: "\ecd8";
}

.icon-Stopwatch-2::before {
  content: "\ecd9";
}

.icon-Stopwatch::before {
  content: "\ecda";
}

.icon-Storm::before {
  content: "\ecdb";
}

.icon-Street-View::before {
  content: "\ecdc";
}

.icon-Street-View2::before {
  content: "\ecdd";
}

.icon-Strikethrough-Text::before {
  content: "\ecde";
}

.icon-Stroller::before {
  content: "\ecdf";
}

.icon-Structure::before {
  content: "\ece0";
}

.icon-Student-Female::before {
  content: "\ece1";
}

.icon-Student-Hat::before {
  content: "\ece2";
}

.icon-Student-Hat2::before {
  content: "\ece3";
}

.icon-Student-Male::before {
  content: "\ece4";
}

.icon-Student-MaleFemale::before {
  content: "\ece5";
}

.icon-Students::before {
  content: "\ece6";
}

.icon-Studio-Flash::before {
  content: "\ece7";
}

.icon-Studio-Lightbox::before {
  content: "\ece8";
}

.icon-Stumbleupon::before {
  content: "\ece9";
}

.icon-Suit::before {
  content: "\ecea";
}

.icon-Suitcase::before {
  content: "\eceb";
}

.icon-Sum-2::before {
  content: "\ecec";
}

.icon-Sum::before {
  content: "\eced";
}

.icon-Summer::before {
  content: "\ecee";
}

.icon-Sun-CloudyRain::before {
  content: "\ecef";
}

.icon-Sun::before {
  content: "\ecf0";
}

.icon-Sunglasses-2::before {
  content: "\ecf1";
}

.icon-Sunglasses-3::before {
  content: "\ecf2";
}

.icon-Sunglasses-Smiley::before {
  content: "\ecf3";
}

.icon-Sunglasses-Smiley2::before {
  content: "\ecf4";
}

.icon-Sunglasses-W::before {
  content: "\ecf5";
}

.icon-Sunglasses-W2::before {
  content: "\ecf6";
}

.icon-Sunglasses-W3::before {
  content: "\ecf7";
}

.icon-Sunglasses::before {
  content: "\ecf8";
}

.icon-Sunrise::before {
  content: "\ecf9";
}

.icon-Sunset::before {
  content: "\ecfa";
}

.icon-Superman::before {
  content: "\ecfb";
}

.icon-Support::before {
  content: "\ecfc";
}

.icon-Surprise::before {
  content: "\ecfd";
}

.icon-Sushi::before {
  content: "\ecfe";
}

.icon-Sweden::before {
  content: "\ecff";
}

.icon-Swimming-Short::before {
  content: "\ed00";
}

.icon-Swimming::before {
  content: "\ed01";
}

.icon-Swimmwear::before {
  content: "\ed02";
}

.icon-Switch::before {
  content: "\ed03";
}

.icon-Switzerland::before {
  content: "\ed04";
}

.icon-Sync-Cloud::before {
  content: "\ed05";
}

.icon-Sync::before {
  content: "\ed06";
}

.icon-Synchronize-2::before {
  content: "\ed07";
}

.icon-Synchronize::before {
  content: "\ed08";
}

.icon-T-Shirt::before {
  content: "\ed09";
}

.icon-Tablet-2::before {
  content: "\ed0a";
}

.icon-Tablet-3::before {
  content: "\ed0b";
}

.icon-Tablet-Orientation::before {
  content: "\ed0c";
}

.icon-Tablet-Phone::before {
  content: "\ed0d";
}

.icon-Tablet-Secure::before {
  content: "\ed0e";
}

.icon-Tablet-Vertical::before {
  content: "\ed0f";
}

.icon-Tablet::before {
  content: "\ed10";
}

.icon-Tactic::before {
  content: "\ed11";
}

.icon-Tag-2::before {
  content: "\ed12";
}

.icon-Tag-3::before {
  content: "\ed13";
}

.icon-Tag-4::before {
  content: "\ed14";
}

.icon-Tag-5::before {
  content: "\ed15";
}

.icon-Tag::before {
  content: "\ed16";
}

.icon-Taj-Mahal::before {
  content: "\ed17";
}

.icon-Talk-Man::before {
  content: "\ed18";
}

.icon-Tap::before {
  content: "\ed19";
}

.icon-Target-Market::before {
  content: "\ed1a";
}

.icon-Target::before {
  content: "\ed1b";
}

.icon-Taurus-2::before {
  content: "\ed1c";
}

.icon-Taurus::before {
  content: "\ed1d";
}

.icon-Taxi-2::before {
  content: "\ed1e";
}

.icon-Taxi-Sign::before {
  content: "\ed1f";
}

.icon-Taxi::before {
  content: "\ed20";
}

.icon-Teacher::before {
  content: "\ed21";
}

.icon-Teapot::before {
  content: "\ed22";
}

.icon-Technorati::before {
  content: "\ed23";
}

.icon-Teddy-Bear::before {
  content: "\ed24";
}

.icon-Tee-Mug::before {
  content: "\ed25";
}

.icon-Telephone-2::before {
  content: "\ed26";
}

.icon-Telephone::before {
  content: "\ed27";
}

.icon-Telescope::before {
  content: "\ed28";
}

.icon-Temperature-2::before {
  content: "\ed29";
}

.icon-Temperature-3::before {
  content: "\ed2a";
}

.icon-Temperature::before {
  content: "\ed2b";
}

.icon-Temple::before {
  content: "\ed2c";
}

.icon-Tennis-Ball::before {
  content: "\ed2d";
}

.icon-Tennis::before {
  content: "\ed2e";
}

.icon-Tent::before {
  content: "\ed2f";
}

.icon-Test-Tube::before {
  content: "\ed30";
}

.icon-Test-Tube2::before {
  content: "\ed31";
}

.icon-Testimonal::before {
  content: "\ed32";
}

.icon-Text-Box::before {
  content: "\ed33";
}

.icon-Text-Effect::before {
  content: "\ed34";
}

.icon-Text-HighlightColor::before {
  content: "\ed35";
}

.icon-Text-Paragraph::before {
  content: "\ed36";
}

.icon-Thailand::before {
  content: "\ed37";
}

.icon-The-WhiteHouse::before {
  content: "\ed38";
}

.icon-This-SideUp::before {
  content: "\ed39";
}

.icon-Thread::before {
  content: "\ed3a";
}

.icon-Three-ArrowFork::before {
  content: "\ed3b";
}

.icon-Three-Fingers::before {
  content: "\ed3c";
}

.icon-Three-FingersDrag::before {
  content: "\ed3d";
}

.icon-Three-FingersDrag2::before {
  content: "\ed3e";
}

.icon-Three-FingersTouch::before {
  content: "\ed3f";
}

.icon-Thumb::before {
  content: "\ed40";
}

.icon-Thumbs-DownSmiley::before {
  content: "\ed41";
}

.icon-Thumbs-UpSmiley::before {
  content: "\ed42";
}

.icon-Thunder::before {
  content: "\ed43";
}

.icon-Thunderstorm::before {
  content: "\ed44";
}

.icon-Ticket::before {
  content: "\ed45";
}

.icon-Tie-2::before {
  content: "\ed46";
}

.icon-Tie-3::before {
  content: "\ed47";
}

.icon-Tie-4::before {
  content: "\ed48";
}

.icon-Tie::before {
  content: "\ed49";
}

.icon-Tiger::before {
  content: "\ed4a";
}

.icon-Time-Backup::before {
  content: "\ed4b";
}

.icon-Time-Bomb::before {
  content: "\ed4c";
}

.icon-Time-Clock::before {
  content: "\ed4d";
}

.icon-Time-Fire::before {
  content: "\ed4e";
}

.icon-Time-Machine::before {
  content: "\ed4f";
}

.icon-Time-Window::before {
  content: "\ed50";
}

.icon-Timer-2::before {
  content: "\ed51";
}

.icon-Timer::before {
  content: "\ed52";
}

.icon-To-Bottom::before {
  content: "\ed53";
}

.icon-To-Bottom2::before {
  content: "\ed54";
}

.icon-To-Left::before {
  content: "\ed55";
}

.icon-To-Right::before {
  content: "\ed56";
}

.icon-To-Top::before {
  content: "\ed57";
}

.icon-To-Top2::before {
  content: "\ed58";
}

.icon-Token-::before {
  content: "\ed59";
}

.icon-Tomato::before {
  content: "\ed5a";
}

.icon-Tongue::before {
  content: "\ed5b";
}

.icon-Tooth-2::before {
  content: "\ed5c";
}

.icon-Tooth::before {
  content: "\ed5d";
}

.icon-Top-ToBottom::before {
  content: "\ed5e";
}

.icon-Touch-Window::before {
  content: "\ed5f";
}

.icon-Tourch::before {
  content: "\ed60";
}

.icon-Tower-2::before {
  content: "\ed61";
}

.icon-Tower-Bridge::before {
  content: "\ed62";
}

.icon-Tower::before {
  content: "\ed63";
}

.icon-Trace::before {
  content: "\ed64";
}

.icon-Tractor::before {
  content: "\ed65";
}

.icon-traffic-Light::before {
  content: "\ed66";
}

.icon-Traffic-Light2::before {
  content: "\ed67";
}

.icon-Train-2::before {
  content: "\ed68";
}

.icon-Train::before {
  content: "\ed69";
}

.icon-Tram::before {
  content: "\ed6a";
}

.icon-Transform-2::before {
  content: "\ed6b";
}

.icon-Transform-3::before {
  content: "\ed6c";
}

.icon-Transform-4::before {
  content: "\ed6d";
}

.icon-Transform::before {
  content: "\ed6e";
}

.icon-Trash-withMen::before {
  content: "\ed6f";
}

.icon-Tree-2::before {
  content: "\ed70";
}

.icon-Tree-3::before {
  content: "\ed71";
}

.icon-Tree-4::before {
  content: "\ed72";
}

.icon-Tree-5::before {
  content: "\ed73";
}

.icon-Tree::before {
  content: "\ed74";
}

.icon-Trekking::before {
  content: "\ed75";
}

.icon-Triangle-ArrowDown::before {
  content: "\ed76";
}

.icon-Triangle-ArrowLeft::before {
  content: "\ed77";
}

.icon-Triangle-ArrowRight::before {
  content: "\ed78";
}

.icon-Triangle-ArrowUp::before {
  content: "\ed79";
}

.icon-Tripod-2::before {
  content: "\ed7a";
}

.icon-Tripod-andVideo::before {
  content: "\ed7b";
}

.icon-Tripod-withCamera::before {
  content: "\ed7c";
}

.icon-Tripod-withGopro::before {
  content: "\ed7d";
}

.icon-Trophy-2::before {
  content: "\ed7e";
}

.icon-Trophy::before {
  content: "\ed7f";
}

.icon-Truck::before {
  content: "\ed80";
}

.icon-Trumpet::before {
  content: "\ed81";
}

.icon-Tumblr::before {
  content: "\ed82";
}

.icon-Turkey::before {
  content: "\ed83";
}

.icon-Turn-Down::before {
  content: "\ed84";
}

.icon-Turn-Down2::before {
  content: "\ed85";
}

.icon-Turn-DownFromLeft::before {
  content: "\ed86";
}

.icon-Turn-DownFromRight::before {
  content: "\ed87";
}

.icon-Turn-Left::before {
  content: "\ed88";
}

.icon-Turn-Left3::before {
  content: "\ed89";
}

.icon-Turn-Right::before {
  content: "\ed8a";
}

.icon-Turn-Right3::before {
  content: "\ed8b";
}

.icon-Turn-Up::before {
  content: "\ed8c";
}

.icon-Turn-Up2::before {
  content: "\ed8d";
}

.icon-Turtle::before {
  content: "\ed8e";
}

.icon-Tuxedo::before {
  content: "\ed8f";
}

.icon-TV::before {
  content: "\ed90";
}

.icon-Twister::before {
  content: "\ed91";
}

.icon-Twitter-2::before {
  content: "\ed92";
}

.icon-Twitter::before {
  content: "\ed93";
}

.icon-Two-Fingers::before {
  content: "\ed94";
}

.icon-Two-FingersDrag::before {
  content: "\ed95";
}

.icon-Two-FingersDrag2::before {
  content: "\ed96";
}

.icon-Two-FingersScroll::before {
  content: "\ed97";
}

.icon-Two-FingersTouch::before {
  content: "\ed98";
}

.icon-Two-Windows::before {
  content: "\ed99";
}

.icon-Type-Pass::before {
  content: "\ed9a";
}

.icon-Ukraine::before {
  content: "\ed9b";
}

.icon-Umbrela::before {
  content: "\ed9c";
}

.icon-Umbrella-2::before {
  content: "\ed9d";
}

.icon-Umbrella-3::before {
  content: "\ed9e";
}

.icon-Under-LineText::before {
  content: "\ed9f";
}

.icon-Undo::before {
  content: "\eda0";
}

.icon-United-Kingdom::before {
  content: "\eda1";
}

.icon-United-States::before {
  content: "\eda2";
}

.icon-University-2::before {
  content: "\eda3";
}

.icon-University::before {
  content: "\eda4";
}

.icon-Unlike-2::before {
  content: "\eda5";
}

.icon-Unlike::before {
  content: "\eda6";
}

.icon-Unlock-2::before {
  content: "\eda7";
}

.icon-Unlock-3::before {
  content: "\eda8";
}

.icon-Unlock::before {
  content: "\eda9";
}

.icon-Up--Down::before {
  content: "\edaa";
}

.icon-Up--Down3::before {
  content: "\edab";
}

.icon-Up-2::before {
  content: "\edac";
}

.icon-Up-3::before {
  content: "\edad";
}

.icon-Up-4::before {
  content: "\edae";
}

.icon-Up::before {
  content: "\edaf";
}

.icon-Upgrade::before {
  content: "\edb0";
}

.icon-Upload-2::before {
  content: "\edb1";
}

.icon-Upload-toCloud::before {
  content: "\edb2";
}

.icon-Upload-Window::before {
  content: "\edb3";
}

.icon-Upload::before {
  content: "\edb4";
}

.icon-Uppercase-Text::before {
  content: "\edb5";
}

.icon-Upward::before {
  content: "\edb6";
}

.icon-URL-Window::before {
  content: "\edb7";
}

.icon-Usb-2::before {
  content: "\edb8";
}

.icon-Usb-Cable::before {
  content: "\edb9";
}

.icon-Usb::before {
  content: "\edba";
}

.icon-User::before {
  content: "\edbb";
}

.icon-Ustream::before {
  content: "\edbc";
}

.icon-Vase::before {
  content: "\edbd";
}

.icon-Vector-2::before {
  content: "\edbe";
}

.icon-Vector-3::before {
  content: "\edbf";
}

.icon-Vector-4::before {
  content: "\edc0";
}

.icon-Vector-5::before {
  content: "\edc1";
}

.icon-Vector::before {
  content: "\edc2";
}

.icon-Venn-Diagram::before {
  content: "\edc3";
}

.icon-Vest-2::before {
  content: "\edc4";
}

.icon-Vest::before {
  content: "\edc5";
}

.icon-Viddler::before {
  content: "\edc6";
}

.icon-Video-2::before {
  content: "\edc7";
}

.icon-Video-3::before {
  content: "\edc8";
}

.icon-Video-4::before {
  content: "\edc9";
}

.icon-Video-5::before {
  content: "\edca";
}

.icon-Video-6::before {
  content: "\edcb";
}

.icon-Video-GameController::before {
  content: "\edcc";
}

.icon-Video-Len::before {
  content: "\edcd";
}

.icon-Video-Len2::before {
  content: "\edce";
}

.icon-Video-Photographer::before {
  content: "\edcf";
}

.icon-Video-Tripod::before {
  content: "\edd0";
}

.icon-Video::before {
  content: "\edd1";
}

.icon-Vietnam::before {
  content: "\edd2";
}

.icon-View-Height::before {
  content: "\edd3";
}

.icon-View-Width::before {
  content: "\edd4";
}

.icon-Vimeo::before {
  content: "\edd5";
}

.icon-Virgo-2::before {
  content: "\edd6";
}

.icon-Virgo::before {
  content: "\edd7";
}

.icon-Virus-2::before {
  content: "\edd8";
}

.icon-Virus-3::before {
  content: "\edd9";
}

.icon-Virus::before {
  content: "\edda";
}

.icon-Visa::before {
  content: "\eddb";
}

.icon-Voice::before {
  content: "\eddc";
}

.icon-Voicemail::before {
  content: "\eddd";
}

.icon-Volleyball::before {
  content: "\edde";
}

.icon-Volume-Down::before {
  content: "\eddf";
}

.icon-Volume-Up::before {
  content: "\ede0";
}

.icon-VPN::before {
  content: "\ede1";
}

.icon-Wacom-Tablet::before {
  content: "\ede2";
}

.icon-Waiter::before {
  content: "\ede3";
}

.icon-Walkie-Talkie::before {
  content: "\ede4";
}

.icon-Wallet-2::before {
  content: "\ede5";
}

.icon-Wallet-3::before {
  content: "\ede6";
}

.icon-Wallet::before {
  content: "\ede7";
}

.icon-Warehouse::before {
  content: "\ede8";
}

.icon-Warning-Window::before {
  content: "\ede9";
}

.icon-Watch-2::before {
  content: "\edea";
}

.icon-Watch-3::before {
  content: "\edeb";
}

.icon-Watch::before {
  content: "\edec";
}

.icon-Wave-2::before {
  content: "\eded";
}

.icon-Wave::before {
  content: "\edee";
}

.icon-Webcam::before {
  content: "\edef";
}

.icon-weight-Lift::before {
  content: "\edf0";
}

.icon-Wheelbarrow::before {
  content: "\edf1";
}

.icon-Wheelchair::before {
  content: "\edf2";
}

.icon-Width-Window::before {
  content: "\edf3";
}

.icon-Wifi-2::before {
  content: "\edf4";
}

.icon-Wifi-Keyboard::before {
  content: "\edf5";
}

.icon-Wifi::before {
  content: "\edf6";
}

.icon-Wind-Turbine::before {
  content: "\edf7";
}

.icon-Windmill::before {
  content: "\edf8";
}

.icon-Window-2::before {
  content: "\edf9";
}

.icon-Window::before {
  content: "\edfa";
}

.icon-Windows-2::before {
  content: "\edfb";
}

.icon-Windows-Microsoft::before {
  content: "\edfc";
}

.icon-Windows::before {
  content: "\edfd";
}

.icon-Windsock::before {
  content: "\edfe";
}

.icon-Windy::before {
  content: "\edff";
}

.icon-Wine-Bottle::before {
  content: "\ee00";
}

.icon-Wine-Glass::before {
  content: "\ee01";
}

.icon-Wink::before {
  content: "\ee02";
}

.icon-Winter-2::before {
  content: "\ee03";
}

.icon-Winter::before {
  content: "\ee04";
}

.icon-Wireless::before {
  content: "\ee05";
}

.icon-Witch-Hat::before {
  content: "\ee06";
}

.icon-Witch::before {
  content: "\ee07";
}

.icon-Wizard::before {
  content: "\ee08";
}

.icon-Wolf::before {
  content: "\ee09";
}

.icon-Woman-Sign::before {
  content: "\ee0a";
}

.icon-WomanMan::before {
  content: "\ee0b";
}

.icon-Womans-Underwear::before {
  content: "\ee0c";
}

.icon-Womans-Underwear2::before {
  content: "\ee0d";
}

.icon-Women::before {
  content: "\ee0e";
}

.icon-Wonder-Woman::before {
  content: "\ee0f";
}

.icon-Wordpress::before {
  content: "\ee10";
}

.icon-Worker-Clothes::before {
  content: "\ee11";
}

.icon-Worker::before {
  content: "\ee12";
}

.icon-Wrap-Text::before {
  content: "\ee13";
}

.icon-Wreath::before {
  content: "\ee14";
}

.icon-Wrench::before {
  content: "\ee15";
}

.icon-X-Box::before {
  content: "\ee16";
}

.icon-X-ray::before {
  content: "\ee17";
}

.icon-Xanga::before {
  content: "\ee18";
}

.icon-Xing::before {
  content: "\ee19";
}

.icon-Yacht::before {
  content: "\ee1a";
}

.icon-Yahoo-Buzz::before {
  content: "\ee1b";
}

.icon-Yahoo::before {
  content: "\ee1c";
}

.icon-Yelp::before {
  content: "\ee1d";
}

.icon-Yes::before {
  content: "\ee1e";
}

.icon-Ying-Yang::before {
  content: "\ee1f";
}

.icon-Youtube::before {
  content: "\ee20";
}

.icon-Z-A::before {
  content: "\ee21";
}

.icon-Zebra::before {
  content: "\ee22";
}

.icon-Zombie::before {
  content: "\ee23";
}

.icon-Zoom-Gesture::before {
  content: "\ee24";
}

.icon-Zootool::before {
  content: "\ee25";
}
