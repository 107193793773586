/* stylelint-disable selector-class-pattern */
// Forms Select
.au-select {
  @include form-field;

  display: inline-flex;
  flex-wrap: wrap;
  width: 100%;
  align-items: center;
  height: 39px;
  padding: 8px 12px !important;
  appearance: none;
  position: relative;

  &__option-span {
    margin: -10px;
  }

  &__item-span {
    padding: 10px;
  }
}

select {
  &.au-select {
    background-color: $au-color-gray-light-3;
    background-image: url('data:image/svg+xml;utf8,<svg id="Layer_1" data-name="Layer 1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 30 30"><path d="M15,23a.55.55,0,0,1-.42-.19L1.64,8a.56.56,0,0,1,.05-.79.57.57,0,0,1,.8.05L15,21.54,27.51,7.23a.58.58,0,0,1,.8-.05.56.56,0,0,1,.05.79L15.42,22.77A.55.55,0,0,1,15,23Z" fill="#{1dbb99}"/></svg>');
    background-position: calc(100% - 10px) 50%;
    background-size: 14px;
    background-repeat: no-repeat;
    border: 1px solid $au-color-grey11;
    padding-right: 28px !important;
    white-space: nowrap;
    text-overflow: ellipsis;
  }

  width: 100%;
  display: block;

  @include transition (0.4s);

  &.w-auto {
    width: auto;
  }

  &::after {
    content: "1";
    position: absolute;
    top: 0;
    right: 0;
  }

  @include focus;
}
