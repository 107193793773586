/* stylelint-disable selector-class-pattern */
/* stylelint-disable selector-max-type */
html {
  background-color: $au-color-secondary-light-2;
  font-family: $default-font-family;
  width: 100%;
  min-height: 100%;
  overflow: initial;
  /* stylelint-disable-next-line font-plugin/font-check */
  font-size: 62.5%;

  // @include hide-scroll;
}

body {
  background-color: $au-color-secondary-light-2;
  color: $au-color-black;
  font-family: $default-font-family;
  font-weight: $default-font-weight;
  line-height: $default-line-height !important;
  width: 100%;
  min-height: 100%;
  overflow-x: hidden;

  // temp style
  min-width: auto !important;
}

button,
input {
  font-family: $default-font-family;
}

a,
button,
input {
  outline: none;
}

// Word break for longer words even without space
h1,
h2,
h3,
h4,
h5,
h6,
p,
span,
a,
label {
  word-break: break-word;
}

a {
  color: $au-color-primary;
  text-decoration: none;

  &:hover {
    color: $au-color-primary;
    text-decoration: none;
  }
}

.cover,
.background {
  position: relative;
  overflow: hidden;
}

.clearfix {
  clear: both;
}

.hide {
  display: none !important;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  color: $au-color-black;
}

h1,
.au-h1 {
  @include h1;
}

h2,
.au-h2 {
  @include h2;
}

h3,
.au-h3 {
  @include h3;

  @include tablet {
    font-size: $au-fs-4;
  }
}

h4,
.au-h4 {
  @include h4;

  @include tablet {
    font-size: $au-fs-3;
  }
}

h5,
.au-h5 {
  @include h5;
}

h6,
.au-h6 {
  @include h6;
}

p,
li {
  font-size: 1.4rem;
  color: $au-color-secondary;
  margin-bottom: $spacer-2;
  line-height: 1.2;
}

.au-content-wrapper {
  @include content-wrapper;
}

.helper-svg-gradients {
  @include visuallyhidden;
}

@include au-sub-sup-fix;

.au-hidden {
  @include visuallyhidden;
}

// Tabs
.tab-head,
.au-tab-head {
  display: flex;
  align-items: center;
  justify-content: space-between;

  .pointer {
    color: $au-color-secondary-medium;
    font-size: 1.4rem;
    cursor: pointer;

    &.active-graph {
      color: $au-color-black;
    }
  }

  .shadow-graph {
    &:nth-child(2) {
      color: $au-color-secondary-medium;
      margin-right: 10px;
      margin-left: 10px;
    }
  }
}

// header area
header {
  .navbar-collapse {
    padding: 0 !important;
  }
}

// Table search
.form-control,
.au-form-control {
  min-height: 40px;
  border: 1px solid $au-color-secondary-light;
  padding: 8px 10px;
  color: $au-color-black !important;
  font-weight: 400 !important;
  font-size: 1.4rem !important;
  height: 34px;
  border-radius: 5px;

  &select {
    display: none;
  }
}

.searchbox,
.au-searchbox {
  @include grid-search;
}

.dropdown-menu {
  .dropdown-item {
    font-size: 1.2rem;
    display: flex;
    align-items: center;
    transition: 0.3s;

    .au-icon {
      margin-inline-end: 8px;
    }

    &:active {
      background-color: $au-color-primary;
      color: $au-color-white;
    }

    &.au-color--red {
      &:active {
        background-color: $au-color-red;
        color: $au-color-white !important;
      }
    }
  }

  .la {
    width: 22px;
    display: inline-block;
  }

  .icon-view {
    /* stylelint-disable-next-line font-plugin/font-check */
    font-size: $font-size-base * 0.6;
  }

  .icon-edit-box {
    /* stylelint-disable-next-line font-plugin/font-check */
    font-size: $font-size-base * 0.8;
  }
}

// Icon
table {
  .dropdown-menu {
    &[class*="icon-"],
    [class^="icon-"] {
      width: 10px;
    }
  }
}

.option-menu {
  cursor: pointer;

  .icon-actions {
    /* stylelint-disable-next-line font-plugin/font-check */
    font-size: $font-size-base * 1.5;

    &::before {
      color: $au-color-secondary-medium;
    }
  }
}

.step-wizard,
.au-step-wizard {
  li {
    a {
      padding: 10px 30px;
      font-size: 1.4rem;
      font-weight: 400;
      border-radius: 25px;
      pointer-events: none;
      background-color: $au-color-white;
      color: $au-color-secondary;
      box-shadow: 0 1px 5px 0 #dcdcdc;
      text-align: center;
    }

    &.nav-item {
      a {
        background: $color-primary-dark;
        color: $au-color-white;
      }
    }
  }
}

.ngx-dropdown-container {
  button {
    display: inline-block;
    margin-bottom: 0;
    font-weight: 400;
    line-height: 1.4286;
    vertical-align: middle;
    touch-action: manipulation;
    cursor: pointer;
    user-select: none;
    border: 1px solid #e9ecef !important;
    border-radius: 4px;
    color: $au-color-grey;
    background-color: $au-color-white;
    white-space: nowrap;
    overflow-x: hidden;
    text-overflow: ellipsis;
    text-align: left;
  }

  .available-items {
    li {
      font-size: 1.4rem;
      color: $au-color-secondary;
      padding: 5px 10px;
    }
  }
}

.form-area,
.au-form-area {
  .form-item {
    margin-bottom: $grid-gutter-space;
  }

  .form-box {
    .label-box {
      display: flex;
      justify-content: space-between;

      .input-label-text,
      .input-label-btn {
        color: $au-color-secondary-medium;
        font-size: 1.2rem;
        margin-bottom: 10px;
      }

      .input-label-btn {
        cursor: pointer;

        /* stylelint-disable-next-line max-nesting-depth */
        &:hover {
          color: $au-color-primary;
        }
      }
    }
  }
}

// image label
.cover-item,
.au-cover-item {
  background-color: $au-color-secondary-light-2;
  border-radius: 17px;
  border: 1px solid $color-secondary-light;
  font-weight: 400 !important;
  color: $au-color-secondary-dark;

  span {
    display: flex;
    align-items: center;

    img {
      width: 23px;
      margin-right: 15px;
      border-radius: 50%;
    }
  }
}

// old browser detection
.au-browser-alert {
  width: 95%;
  margin: 15px auto;

  &__img {
    margin-top: 24px;
  }

  &__item {
    width: calc(20% - 16px);
    display: inline-block;
    margin: 0 8px;

    @include mobile {
      width: calc(48% - 16px);
      margin-top: 8px;
    }

    &-text {
      display: block;
    }

    /* stylelint-disable-next-line no-descending-specificity */
    img {
      height: 54px;
      width: auto;
      transition: 0.3s;
    }

    &:hover {
      img {
        transform: scale(1.1);
      }
    }
  }

  &__wrapper {
    .swal2-popup {
      padding: $spacer-5 $spacer-4;
    }

    .swal2-actions {
      margin-bottom: $spacer-0 !important;
    }
  }
}

.duration-head,
.au-duration-head {
  label {
    &.left-area {
      span {
        display: block;
        margin: 0 20px;
      }
    }

    &.right-area {
      span {
        display: block;
        margin: 0 20px;

        /* stylelint-disable-next-line max-nesting-depth */
        &.switch {
          margin: 0;
        }
      }
    }
  }
}

// Legend
.donut-legend,
.au-donut-legend {
  padding: 0;
  border-top: 0;
  background: transparent;
}

// D3 Width
.au-d3 {
  display: flex;
  justify-content: center;

  svg {
    width: 100%;
    max-width: 300px;
    margin: 0 auto;
  }
}

// graph
/* stylelint-disable-next-line selector-id-pattern */
#d3Gauge svg {
  max-width: 300px;
}

.flex-auto {
  flex: auto;
  text-align: center;
}

// footer
footer {
  padding: 10px 0;
  font-size: 1.2rem;
  margin-top: 20px;
  border-top: 1px solid $color-secondary-light-2;
  border-bottom: 1px solid $color-secondary-light-2;
  z-index: 2;
  position: relative;
}

// modal
.modal {
  .modal-lg {
    max-width: 1200px;
  }

  .modal-header {
    .close {
      padding: 10px;
      margin: 0;

      i {
        @include font-size(14px);
      }
    }
  }

  .modal-content {
    border-radius: 10px;

    .modal-header {
      .app-modal-header h3 {
        padding: 0;
        margin: 0 !important;
      }
    }
  }

  .app-modal-header {
    display: flex;
    justify-content: space-between;
    width: 100%;
    align-items: center;
  }

  .app-modal-footer {
    width: 100%;
  }
}

// Auraa custom classes
.au-col,
.au-row {
  margin-bottom: $grid-gutter-space;
}

// Graph
.au-graph {
  position: relative;
}

// Chart tooltip
.au-chart-tooltip {
  min-width: 60px !important;
}

// Page Space

.au-page {
  @include ie-browser {
    width: 100%;
  }

  padding-top: 15px;

  &__action {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 15px;

    .au-left {
      width: 70%;
    }

    .au-right {
      width: 30%;
      text-align: right;
    }

    .au-breadcrumb {
      margin-bottom: 0;

      &__body {
        margin-bottom: 0;
      }
    }
  }

  &__title {
    margin-bottom: 15px;

    &-heading {
      margin-bottom: $rem-space-1;

      @include mobile {
        margin-bottom: $rem-space-3;
      }
    }

    h5 {
      margin-bottom: 5px;
      color: $au-color-secondary;
    }
  }

  &__description {
    .au-label {
      margin-bottom: 5px;
    }
  }

  &__footer {
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: 10px;
    margin-top: 15px;

    @extend %hide-print;

    .au-left {
      display: flex;
      align-items: center;
      gap: 10px;
    }

    .au-right {
      display: flex;
      align-items: center;
      gap: 10px;
    }
  }
}

// Border none
.au-border-none {
  border: 0 !important;
}

// Full Width
.au-w {
  &--100 {
    width: 100%;
    max-width: 100%;
  }

  &--75 {
    width: 75%;
    max-width: 75%;
  }

  &--50 {
    width: 50%;
    max-width: 50%;
  }

  &--25 {
    width: 25%;
    max-width: 25%;
  }

  @include mobile {
    &m-100 {
      width: 100% !important;
      max-width: 100% !important;
    }
  }
}

// Full height
.au-h {
  &--100 {
    height: 100%;
  }
}

.dropdown-toggle::after {
  display: none !important;
}

.au-table-option {
  width: 40px;
  min-width: 40px;
  max-width: 40px;
  text-align: center;
}

.no-scroll {
  position: fixed;
  height: 100%;
  top: 0;
  overflow: hidden;
}

.au-focus-id {
  font-size: $au-fs-3;
  color: $au-color-primary;
  font-weight: $font-weight-semi;
}

.desktop,
.only-desktop {
  display: none;
}

.mobile,
.only-mobile {
  display: block;
}

// main wrapper {
.page-wrapper {
  @include mobile {
    margin-left: 0 !important;
  }
}

// Hide element
[hidden],
.au-hidden,
.au-hide {
  @include ani-d-none;
}

// Scroll hide
.au-scroll,
.overflow-scroll {
  @include hide-scroll;
}

.au-scroll-x {
  overflow-x: auto;
  overflow-y: hidden;

  &::-webkit-scrollbar {
    height: 7px;
  }

  &::-webkit-scrollbar-track {
    border-radius: 10px;
    background-color: $au-color-transparent;
  }

  &::-webkit-scrollbar-thumb {
    background: $au-color-secondary-light;
    border-radius: 10px;
  }

  scrollbar-color: $au-color-secondary-light transparent;
}

.au-scroll-y {
  @include au-scroll-y($au-color-white, $au-color-secondary-light, 5px, 5px);
}

@include tablet-desktop {
  .desktop,
  .only-desktop {
    display: block;
  }

  .mobile,
  .only-mobile {
    display: none;
  }
}

.au-overflow {
  &--hidden {
    overflow: hidden;
  }

  &--auto {
    overflow: auto;
  }
}

.au-position-static {
  position: static !important;
}

.au-input-label-text {
  color: $au-color-secondary-medium;
  font-weight: $font-weight-normal;
}

.au-footer {
  padding: 15px 20px;
  font-size: 1.2rem;
}

.au-page-title {
  color: $au-color-primary;
  font-weight: 1.2rem;
  white-space: nowrap;
}

.au-tool-tip {
  display: none;
  position: absolute;
  z-index: 999;
  /* stylelint-disable-next-line color-plugin/variable */
  background-color: rgb(0 0 0 / 75%);
  padding: 5px;
  border-radius: 2px;
  font-family: sans-serif;
  color: $au-color-white;
  pointer-events: none;
  box-shadow: 0 0 5px #999;
  min-width: 50px;
  align-items: center;
  justify-content: center;

  &--chart {
    min-width: 100px;
    max-width: 200px;
  }
}

.au-cursor {
  &--pointer {
    cursor: pointer;
  }

  &--default {
    cursor: default !important;
  }

  &--no-drop {
    cursor: no-drop;
  }
}

.au-vertical {
  &--top {
    vertical-align: top !important;
  }
}

.au-display {
  &--none {
    display: none !important;
  }

  &--block {
    display: block;
  }
}
/* stylelint-enable selector-max-type */
.au-inline-block {
  display: inline-block;
}

.au-text-center {
  text-align: center;
}

.au-progess-bar {
  max-width: 120px;

  &__text {
    font-size: 1.2rem;
    line-height: 1;
    margin-bottom: 2px;
    color: $au-color-black;
    /* stylelint-disable-next-line no-descending-specificity */
    span {
      font-weight: $font-weight-medium;
    }
  }

  .progressbar {
    width: 100%;
    border: 0;
    border-radius: 5px;
  }

  .progress {
    width: 100%;
    height: 2px;
    border-radius: 5px;
    background-color: $au-color-white-light-7;

    &::after {
      display: none;
    }

    &-bar {
      background-color: $au-color-primary;
      border: 0;
      border-width: 0;
      border-radius: 5px;
    }
  }
}

.au-nodata {
  min-height: 200px;
  align-items: center;
  display: flex;
  justify-content: center;
  color: $au-color-secondary-light;
  font-weight: 400;
  font-size: 1.6rem;
  flex-direction: column;

  .au-calender-list__no-data-icon {
    width: 80px;
    height: 89px;
    display: block;
    margin-bottom: $spacer-2;
  }

  .au-table-list__no-data-icon {
    width: 80px;
    height: auto;
    display: block;
    margin-bottom: $spacer-2;
  }
}
