/* stylelint-disable alpha-value-notation */
/* stylelint-disable color-plugin/variable */
/* stylelint-disable property-no-vendor-prefix */
/* stylelint-disable selector-class-pattern */
.ps-container {
  -ms-touch-action: auto;
  touch-action: auto;
  overflow: hidden !important;
  -ms-overflow-style: none;
}

@supports (-ms-overflow-style: none) {
  .ps-container {
    overflow: auto !important;
  }
}

@media screen and (-ms-high-contrast: active), (-ms-high-contrast: none) {
  .ps-container {
    overflow: auto !important;
  }
}

.ps-container > .ps-scrollbar-x-rail {
  display: none;
  position: absolute;
  opacity: 0;
  -webkit-transition: background-color 0.2s linear, opacity 0.2s linear;
  -o-transition: background-color 0.2s linear, opacity 0.2s linear;
  -moz-transition: background-color 0.2s linear, opacity 0.2s linear;
  transition: background-color 0.2s linear, opacity 0.2s linear;
  bottom: 0;
  height: 15px;
}

.ps-container > .ps-scrollbar-y-rail {
  display: none;
  position: absolute;
  opacity: 0;
  -webkit-transition: background-color 0.2s linear, opacity 0.2s linear;
  -o-transition: background-color 0.2s linear, opacity 0.2s linear;
  -moz-transition: background-color 0.2s linear, opacity 0.2s linear;
  transition: background-color 0.2s linear, opacity 0.2s linear;
  right: 0;
  width: 15px;
}

.ps-container .ps-scrollbar-y-rail {
  position: absolute;

  /* please don't change 'position' */
  right: 3px;

  /* there must be 'right' for ps-scrollbar-y-rail */
  width: 8px;
  -webkit-border-radius: 4px;
  -moz-border-radius: 4px;
  border-radius: 4px;
  opacity: 0;
  filter: alpha(opacity=0);
  -o-transition: background-color 0.2s linear, opacity 0.2s linear;
  -webkit-transition: background-color 0.2s linear, opacity 0.2s linear;
  -moz-transition: background-color 0.2s linear, opacity 0.2s linear;
  transition: background-color 0.2s linear, opacity 0.2s linear;
}

.ps-container.ps-active-x > .ps-scrollbar-x-rail,
.ps-container.ps-active-y > .ps-scrollbar-y-rail {
  display: block;
  background-color: transparent;
}

.ps-container:hover > .ps-scrollbar-x-rail,
.ps-container:hover > .ps-scrollbar-y-rail {
  opacity: 0.6;
}

.ps-container.ps-in-scrolling.ps-x > .ps-scrollbar-x-rail {
  background-color: transparent;
  opacity: 0.9;
}

.ps-container > .ps-scrollbar-x-rail > .ps-scrollbar-x {
  position: absolute;
  background-color: rgba(0 0 0 / 0.2);
  -webkit-border-radius: 6px;
  -moz-border-radius: 6px;
  border-radius: 6px;
  -webkit-transition:
    background-color 0.2s linear,
    height 0.2s linear,
    width 0.2s ease-in-out,
    -webkit-border-radius 0.2s ease-in-out;
  -o-transition:
    background-color 0.2s linear,
    height 0.2s linear,
    width 0.2s ease-in-out,
    border-radius 0.2s ease-in-out;
  -moz-transition:
    background-color 0.2s linear,
    height 0.2s linear,
    width 0.2s ease-in-out,
    border-radius 0.2s ease-in-out,
    -moz-border-radius 0.2s ease-in-out;
  transition:
    background-color 0.2s linear,
    height 0.2s linear,
    width 0.2s ease-in-out,
    border-radius 0.2s ease-in-out;
  transition:
    background-color 0.2s linear,
    height 0.2s linear,
    width 0.2s ease-in-out,
    border-radius 0.2s ease-in-out,
    -webkit-border-radius 0.2s ease-in-out,
    -moz-border-radius 0.2s ease-in-out;
  bottom: 2px;
  height: 6px;
}

.ps-container > .ps-scrollbar-x-rail:hover > .ps-scrollbar-x,
.ps-container > .ps-scrollbar-x-rail:active > .ps-scrollbar-x {
  height: 6px;
}

.ps-container.ps-in-scrolling.ps-x > .ps-scrollbar-x-rail > .ps-scrollbar-x {
  background-color: rgba(0 0 0 / 0.2);
  height: 11px;
}

.ps-container.ps-in-scrolling.ps-y > .ps-scrollbar-y-rail {
  background-color: transparent;
  opacity: 0.9;
}

.ps-container > .ps-scrollbar-y-rail > .ps-scrollbar-y {
  position: absolute;
  background-color: rgba(0 0 0 / 0.2);
  -webkit-border-radius: 6px;
  -moz-border-radius: 6px;
  border-radius: 6px;
  -webkit-transition:
    background-color 0.2s linear,
    height 0.2s linear,
    width 0.2s ease-in-out,
    -webkit-border-radius 0.2s ease-in-out;
  -o-transition:
    background-color 0.2s linear,
    height 0.2s linear,
    width 0.2s ease-in-out,
    border-radius 0.2s ease-in-out;
  -moz-transition:
    background-color 0.2s linear,
    height 0.2s linear,
    width 0.2s ease-in-out,
    border-radius 0.2s ease-in-out,
    -moz-border-radius 0.2s ease-in-out;
  transition:
    background-color 0.2s linear,
    height 0.2s linear,
    width 0.2s ease-in-out,
    border-radius 0.2s ease-in-out;
  transition:
    background-color 0.2s linear,
    height 0.2s linear,
    width 0.2s ease-in-out,
    border-radius 0.2s ease-in-out,
    -webkit-border-radius 0.2s ease-in-out,
    -moz-border-radius 0.2s ease-in-out;
  right: 2px;
  width: 6px;
}

.ps-container > .ps-scrollbar-y-rail:hover > .ps-scrollbar-y,
.ps-container > .ps-scrollbar-y-rail:active > .ps-scrollbar-y {
  width: 6px;
}

.ps-container.ps-in-scrolling.ps-y > .ps-scrollbar-y-rail > .ps-scrollbar-y {
  background-color: rgba(0 0 0 / 0.2);
  width: 11px;
}

.ps-container:hover > .ps-scrollbar-x-rail:hover {
  background-color: transparent;
  opacity: 0.9;
}

.ps-container:hover.ps-in-scrolling.ps-x > .ps-scrollbar-x-rail {
  background-color: transparent;
  opacity: 0.9;
}

.ps-container:hover > .ps-scrollbar-x-rail:hover > .ps-scrollbar-x {
  background-color: rgba(0 0 0 / 0.2);
}

.ps-container:hover.ps-in-scrolling.ps-x > .ps-scrollbar-x-rail > .ps-scrollbar-x {
  background-color: rgba(0 0 0 / 0.2);
  height: 6px;
}

.ps-container:hover > .ps-scrollbar-y-rail:hover {
  background-color: transparent;
  opacity: 0.9;
}

.ps-container:hover.ps-in-scrolling.ps-y > .ps-scrollbar-y-rail {
  background-color: transparent;
  opacity: 0.9;
}

.ps-container:hover > .ps-scrollbar-y-rail:hover > .ps-scrollbar-y {
  background-color: rgba(0 0 0 / 0.2);
}

.ps-container:hover.ps-in-scrolling.ps-y > .ps-scrollbar-y-rail > .ps-scrollbar-y {
  background-color: rgba(0 0 0 / 0.2);
  width: 6px;
}
