//Box with no margin

.au-box {
  display: flex;
  flex-wrap: wrap;
  &__item {
    padding: 20px;
    border: 1px solid $au-color-secondary-light;
    margin-right: -1px;
    margin-bottom: -1px;
    display: flex;
    flex-wrap: wrap;
    align-content: space-between;
    min-height: 220px;
  }
  &__top {
    width: 100%;
    margin-bottom: 10px;
    .au-h1,
    .au-h4 {
      display: block;
    }
  }
  &__bottom {
    width: 100%;
  }
  .au-content {
    position: relative;
    margin-bottom: 10px;
    svg {
      @include mobile {
        width: 100%;
        max-width: 100%;
      }
    }
    &:last-child{
      margin-bottom: $spacer-0;
    }
  }
  .au-doughnut {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    gap: 8px;
    max-height: 240px;
    margin-right: -20px;
    margin-left: -20px;
    padding: 0 20px;
    @include au-scroll-y($au-color-gray-light-3,$color-secondary-light,7px,10px);
    .au-content__item {
      width: calc(25% - 8px) !important;
    }
  }
  .au-doughnut-audit {
    display: flex;
    flex-wrap: wrap;
    gap: 8px;
    max-height: 240px;
    margin-right: -20px;
    margin-left: -20px;
    padding: 0 20px;
    @include au-scroll-y($au-color-gray-light-3,$color-secondary-light,7px,10px);
    .au-content__item {
      width: calc(25% - 8px) !important;
    }
  }
  .au-split {
    gap: 5px;
    display: flex;
    justify-content: space-between;
    @include mobile {
      flex-wrap: wrap;
    }
  }
  &-row {
    margin-left: -20px;
    margin-right: -20px;
  }
  &--1 {
    .au-box__item {
      width: 100%;
    }
  }
  &--2 {
    .au-box__item {
      width: calc(50% + 0.5px);
      @include mobile {
        width: 100%;
      }
    }
  }
  &--3 {
    .au-box__item {
      width: calc(33.33333% + 0.66666666666px);
      @include tablet {
        width: calc(50% + 0.5px);
      }
      @include mobile {
        width: 100%;
      }
    }
  }
  &--4 {
    .au-box__item {
      width: calc(25% + 0.75px);
      @include tablet {
        width: calc(50% + 0.5px);
      }
      @include mobile {
        width: 100%;
      }
    }
  }
  &--5 {
    .au-box__item {
      width: calc(20% + 0.8px);
      @include tablet {
        width: calc(50% + 0.5px);
      }
      @include mobile {
        width: 100%;
      }
    }
  }
}
