// Keyframe

// Display
@keyframes d-none {
  from {opacity: 0;}
  to {opacity: 1;}
}
// Display none
@keyframes ani-d-none {
  from {opacity: 1;}
  to {opacity: 0; display: none;}
}
// Display block
@keyframes ani-d-block {
  from {opacity: 0;}
  to {opacity: 1; display: block;}
}
// Display flex
@keyframes ani-d-flex {
  from {opacity: 0;}
  to {opacity: 1; display: flex;}
}

