.au-progressbar {
  &__value {
    font-size: $au-fs-12;
    color: $au-color-black;
    text-align: center;
    line-height: 1;
    margin-bottom: 10px;
  }

  &__item {
    display: flex;
    align-items: center;
    gap: 5px;
    justify-content: space-between;
  }

  &__number {
    font-size: $au-fs-3;
    white-space: nowrap;
    line-height: 1;
    color: $au-color-primary;
  }

  &__bar {
    width: 100%;
    height: 30px;
    position: relative;
    display: inline-flex;
    flex-wrap: wrap;
    align-items: center;

    &-status {
      position: absolute;
      top: 0;
      bottom: 0;
      left: 0;
      border-right: 2px solid $au-color-white;
    }

    .au-icon {
      color: $au-color-q1;
      font-size: $au-fs-4;
      position: absolute;
      right: -9px;
      top: -5px;
    }

    &-line {
      width: 100%;
      height: 15px;
      background: linear-gradient(90deg, $au-color-d1 0%, $au-color-yellow 50.49%, $au-color-red 100%);
      border-radius: 5px;
    }
  }
}

// Circle progress
.au-circle-progress {
  display: flex;
  width: 60px;
  height: 60px;
  background: none;
  position: relative;
  background-color: $au-color-transparent;
  border-radius: 0.25rem !important;
  overflow: hidden;
  font-size: 0.6563rem;
  margin: 0 0 0 auto;

  &::after {
    content: "";
    width: 100%;
    height: 100%;
    border-radius: 50%;
    border: 4px solid #e5e5e5;
    position: absolute;
    top: 0;
    left: 0;
  }

  & > span {
    width: 50%;
    height: 100%;
    overflow: hidden;
    position: absolute;
    top: 0;
    z-index: 1;
  }

  &__bar {
    width: 100%;
    height: 100%;
    background: none;
    border-width: 4px;
    border-style: solid;
    position: absolute;
    top: 0;
    border-radius: 0;
    color: $au-color-secondary !important;
    display: flex;
    flex-direction: column;
    justify-content: center;
    text-align: center;
    white-space: nowrap;
    background-color: $au-color-transparent;
    transition: width 0.6s cubic-bezier(0.785, 0.135, 0.15, 0.86);
  }

  &__left {
    left: 0;

    .au-circle-progress__bar {
      left: 100%;
      border-top-right-radius: 80px;
      border-bottom-right-radius: 80px;
      border-left: 0;
      /* stylelint-disable-next-line property-no-vendor-prefix */
      -webkit-transform-origin: center left;
      transform-origin: center left;
    }
  }

  &__right {
    right: 0;

    .au-circle-progress__bar {
      left: -100%;
      border-top-left-radius: 80px;
      border-bottom-left-radius: 80px;
      border-right: 0;
      /* stylelint-disable-next-line property-no-vendor-prefix */
      -webkit-transform-origin: center right;
      transform-origin: center right;
    }
  }

  &__value {
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    text-align: center;
    display: flex;
    align-items: center;
    justify-content: center;

    &-text {
      font-size: 1.4rem;
    }
  }
}

/* stylelint-disable-next-line selector-class-pattern */
.progress-active::after {
  border: 4px solid $au-color-primary !important;
}
