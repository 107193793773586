
/* ==============================================
:: USER IMAGE STRUCTURE
=============================================== */
.au-profile {
  width: 100%;
  position: relative;

  &__container {
    position: relative;
    max-width: fit-content;
    margin: auto;
  }

  &__img {
    width: 120px;
    height: 120px;
    max-width: 100%;
    cursor: pointer;
    border-radius: 50%;
    object-fit: cover;
    object-position: center;
  }

  &__icon {
    position: absolute;
    right: 0;
    top: 0;
    background: $color-white;
    width: 33px;
    height: 33px;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 50%;
    box-shadow: 1px 1px 5px rgba(0, 0, 0, 0.25);
    cursor: pointer;
  }
}
