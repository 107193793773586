// this is margin-padding class override
@import "rtl-padding-margin";
// this is bootstrap override
[dir="rtl"] {
    text-align: right;
    direction: rtl;
    .anchor-nav a.nav-link:first-child {
        border-top-right-radius: 50px;
        border-bottom-right-radius: 50px;
        border-top-left-radius: unset;
        border-bottom-left-radius: unset;
        margin-left: -1px;
    }
    .page-breadcrumb .breadcrumb .breadcrumb-item+.breadcrumb-item {
        padding-right: 0.5rem;
        padding-left: 0;
        &::before {
            content: "\e64a";
            padding-right: 0;
            padding-left: 0.5rem;
        }
    }
    .rtlMoreOptionComment {
        transform: translate(114px, 22px) !important;
    }
    .arabic-dropdown-menu {
        transform: translate(647px, 20px) !important;
    }
    .search-icon-position {
        right: -25px !important;
    }
    .custom-select {
        padding: 0.375rem 0.75rem 0.375rem 1.75rem;
        //background: url(../../../assets/images/custom-select.png) left 0.35rem center no-repeat $white;
    }
    .custom-control {
        padding-left: 10px;
        padding-right: 1.5rem;
    }
    .custom-control-label::before {
        left: auto;
        right: 0px;
    }
    .custom-control-label::after {
        left: auto;
        right: 3px;
    }
    .left-sidebar {
        .side-menu-img {
            border-radius: 25px 0 0 25px;
            -webkit-border-radius: 25px 0 0 25px;
            -moz-border-radius: 25px 0 0 25px;
            -ms-border-radius: 25px 0 0 25px;
            -o-border-radius: 25px 0 0 25px;
            margin-right: 0;
            padding-right: 10px;
            &.selected {
                margin-left: 10px;
            }
        }
    }
    .list-group {
        padding: 0px;
    }
    .float-left {
        float: right !important;
    }
    .float-right {
        float: left !important;
    }
    .radio-list-item {
        input[type="radio"] {
            &:after {
                left: 1px;
            }
        }
    }
    .radio-list-label {
        margin-right: 20px !important;
    }
    .option-menu {
        .background-dark-btn {
            margin-left: 10px !important;
        }
        .background-white-btn {
            margin-left: 15px !important;
            margin-right: 0 !important;
        }
    }
    .text-left {
        text-align: right !important;
    }
    .text-right {
        text-align: left !important;
    }
    .la-angle-right {
        transform: rotate(180deg);
    }
    .close {
        float: left;
    }
    .modal-header .close {
        float: left;
        margin: -1rem auto -1rem -1rem;
    }
    .modal-footer> :not(:last-child) {
        margin-left: 0.25rem;
        margin-right: 0;
    }
    .ml-auto {
        margin-right: auto !important;
        margin-left: 0px !important;
    }
    .mr-auto {
        margin-left: auto !important;
        margin-right: 0px !important;
    }
    .pro-pic {
        padding-left: 0 !important;
    }
    .sidebartoggler {
        padding-right: 0 !important;
    }
    .border-right {
        border-right: none !important;
        border-left: 1px solid $border-color !important;
    }
    .border-left {
        border-left: none !important;
        border-right: 1px solid $border-color !important;
    }
    .alert-dismissible {
        padding-left: 3.8125rem;
        padding-right: 1.25rem;
        .close {
            left: 0;
            right: auto;
        }
    }
    .custom-file-label {
        left: auto;
        right: 0;
        width: 100%;
        &::after {
            right: auto;
            left: 0;
        }
    }
    .filter-box-new {
        left: -12px !important;
        // .la-sliders {
        //     position: absolute;
        //     left: -12px;
        // }
    }
    .printer-button {
        left: 0 !important;
        right: auto !important;
    }
    .search-button {
        left: 50px !important;
        right: auto !important;
    }
    .list-icon-position {
        left: 30px !important;
        right: auto !important;
    }
    .page-breadcrumb {
        padding-right: 0;
    }
    .dropdown-menu {
        text-align: right;
        &.dropdown-menu-right {
            right: auto !important;
            left: 0 !important;
        }
    }
    select {
        direction: ltr;
    }
    .topbar .navbar-collapse {
        padding: 0 0 0 10px;
    }
    .sidebar-nav {
        ul {
            .sidebar-item {
                .first-level {
                    padding-right: 15px;
                    .hide-menu {
                        margin-left: 0 !important;
                        margin-right: 2rem;
                    }
                }
            }
        }
    }
    .navbar-nav,
    .nav,
    .list-unstyled,
    .pagination {
        padding-right: 0;
    }
    .topbar .top-navbar .navbar-header .navbar-brand .logo-icon {
        margin-right: 0;
    }
    .topbar .dropdown-menu.dropdown-menu-right .with-arrow {
        left: 0;
        right: auto;
    }
    .topbar .dropdown-menu.dropdown-menu-right .with-arrow>span {
        left: 20px;
        right: auto;
    }
    .search-box .app-search .srh-btn {
        right: auto;
        left: 20px;
    }
    .mailbox .message-center .message-item .mail-contnet {
        padding-right: 10px;
        padding-left: 0;
    }
    .customizer {
        left: 0 !important;
        right: auto !important;
        &.show-service-panel {
            left: 0;
            right: auto;
        }
        .service-panel-toggle {
            right: -54px;
            left: auto;
        }
    }
    .customizer-notification {
        left: 0 !important;
        right: auto !important;
        &.show-service-panel {
            left: 0;
            right: auto;
        }
        .service-panel-toggle {
            right: -54px;
            left: auto;
        }
    }
    .sidebar-nav .has-arrow::after {
        margin-left: 0;
        margin-right: 10px;
        right: auto;
        left: 15px;
    }
    .createConnectionFormCard .tab-content {
        >.tab-pane {
            display: none;
        }
        >.active {
            display: block;
            border: 1px solid $light-text-gray !important;
            padding-bottom: 50px;
            border-bottom-left-radius: 10px;
            border-bottom-right-radius: 10px;
            border-top-left-radius: 10px;
            border-top-right-radius: 0px;
        }
    }
    .scheduleAndLoadLabel {
        padding-right: 15px !important;
    }
    .search-icon-selectTable {
        left: 10px;
        right: unset;
    }
    .createAnalyticsScenarioPublishDiv {
        padding-left: 1.5rem !important;
    }
    .connectedDataSourceHeaderPaddingForArabic {
        padding-right: 30px;
    }
    .scenarioListHeaderPaddingForArabic {
        padding-right: 35px;
    }
    .search-icon-analyticsScenario {
        left: 17px;
        right: unset;
    }
    .connectToDataArabic-img-box {
        text-align: left !important;
    }
    .dashboard-draft-list-div {
        margin-right: -8px;
    }
    .previewPaddingForArabic {
        padding-left: 1rem !important;
    }
    .viewAllDraftsPaddingLeftForArabic {
        padding-left: 8px;
    }
    .moduleDivMarginRightForArabic {
        margin-right: 25px !important;
        margin-left: 3px !important;
    }
    .fieldsMarginRightForArabic {
        margin-right: 19px;
    }
    .selected-module-arrow {
        left: 10px;
        right: unset;
        transform: rotate(270deg);
    }
    .scrollableFieldsDiv {
        margin-right: -1px;
    }
    .moduleNamePaddingForArabic {
        padding-right: 20px;
    }
    .menubar-holder {
        left: 0;
        right: unset !important;
    }
    .switchDiv {
        left: 15px;
        right: unset !important;
    }
    .colorDiv {
        right: -33px;
    }
    .search-icon-position {
        left: 25px;
        right: unset;
    }
    .expressionTextAreaZoom {
        left: 3px;
        right: unset;
    }
    .selectTableNextBtn {
        padding-right: 7px !important;
    }
    .dashboard-published-list-div {
        margin-right: unset;
    }
    .expressionsZoomClose {
        left: 0;
        right: unset;
    }
    .labelDivPadding {
        padding-right: 28px !important;
    }
    .publishBtn {
        margin-left: unset;
        margin-right: 15px;
    }
    .draftsNoDataCard {
        margin-right: 9px !important;
    }
    .ds-menu-item .ds-icon {
        margin-left: 8px;
    }
    .breadcrumb {
        .breadcrumb-item+.breadcrumb-item::before {
            padding-left: 0.5rem !important;
            padding-right: 0.5rem !important;
        }
    }
    .dashboardPublishDeleteIcon {
        position: absolute;
        right: unset;
        left: 11px;
    }
    .dashboardHomeDraftDeleteIcon {
        position: absolute;
        right: unset;
        left: 24px;
        top: 7px;
    }
    .dashboardDraftDeleteIcon {
        position: absolute;
        right: unset;
        left: 52px;
    }
    .createScenarioBody {
        padding-right: 2px !important;
    }
    .field-module {
        right: unset;
        left: 0;
    }
    .text-right-data {
        text-align: left !important;
    }
    .pull-left {
        float: right !important;
    }
    .pull-right {
        float: left !important;
    }
    .mobile-click {
        margin-right: unset;
        padding-right: unset;
        margin-left: 3rem;
        padding-left: 14px;
    }
    .validateTableCard input[type="radio"]:after {
        top: -2px;
        right: -1px;
    }
    .validateTableCard input[type="radio"]:checked:after {
        top: -2px;
        right: -1px;
    }
    .table_header {
        padding-left: 0px !important;
        padding-right: 10px !important;
    }
    .tableWithFilter {
        text-align: right !important;
    }
    /* Stickey inner-left-part */
    $left-part-width: 260px;
    .left-part {
        border-left: 1px solid $border-color;
        border-right: none;
        .show-left-part {
            left: -41px;
            right: auto;
        }
    }
    .right-part {
        margin-right: $left-part-width;
        margin-left: 0;
        .dropdown-menu {
            right: auto !important;
            left: 0 !important;
        }
    }
    .reverse-mode {
        .left-part {
            right: auto;
            left: 0;
        }
        .show-left-part {
            left: auto;
            right: -41px;
        }
        .right-part {
            margin-right: 0px;
            margin-left: $left-part-width;
        }
    }
    .profiletimeline {
        padding-right: 40px;
        padding-left: 0;
        margin: 40px 30px 0 10px;
        border-right: 1px solid $border-color;
        border-left: none;
        .sl-left {
            float: right;
            margin-right: -60px;
            margin-left: 15px;
        }
    }
    .feed-widget .feed-body .feed-item>.feed-icon {
        margin-right: 0;
        margin-left: 10px;
    }
    .chat-list {
        .chat-item {
            .chat-content {
                padding-right: 15px;
                padding-left: 0;
            }
            .chat-time {
                margin: 5px 65px 15px 0;
            }
            &.odd .chat-content,
            &.odd .chat-time {
                text-align: left;
            }
        }
    }
    /* plugins */
    div.dataTables_wrapper div.dataTables_filter {
        text-align: left;
    }
    table.table-bordered.dataTable th,
    table.table-bordered.dataTable td {
        border-left-width: 1px;
    }
    div.dataTables_wrapper div.dataTables_filter input {
        margin-right: 0.5rem;
        margin-left: 0;
    }
    div.table-responsive>div.dataTables_wrapper>div.row>div[class^="col-"]:last-child {
        padding-left: 1px;
    }
    .lobilists {
        .lobilist-actions {
            right: auto;
            left: 8px;
        }
        .lobilist-item .todo-actions {
            right: auto;
            left: 4px;
        }
        .lobilist-check {
            left: auto;
            right: 12px;
        }
        .lobilist-item,
        .lobilist-item-placeholder {
            padding-left: 0;
            padding-right: 35px;
        }
        .lobilist-item .drag-handler {
            left: auto;
            right: 0;
        }
        .lobilist-wrapper,
        .lobilist-placeholder {
            margin-left: 16px;
            margin-right: 0;
        }
    }
    .datepicker {
        direction: rtl;
    }
    .minicolors .minicolors-grid {
        right: 22px;
        left: auto;
    }
    .wizard>.steps>ul>li,
    .wizard>.actions>ul>li {
        float: right;
    }
    .wizard-content .wizard>.steps>ul>li:after {
        right: auto;
        left: 0;
    }
    .wizard-content .wizard>.steps>ul>li:before {
        left: auto;
        right: 0;
    }
    .wizard-content .wizard.vertical>.steps {
        float: right;
    }
    /*.sl-icon i,
    [class*=" ti-"],
    [class^=ti-] {
        float: right;
        margin-left: 10px;
        line-height: 20px;
    }*/
    .css-bar>i {
        margin-left: 0;
        margin-right: 5px;
    }
    .treeview span.icon {
        margin-right: 0;
        margin-left: 5px;
    }
    .apex-legend-marker {
        margin-right: 0;
        margin-left: 5px;
    }
    &#main-wrapper {
        /*sidebar type*/
        &[data-sidebartype="full"] {
            .page-wrapper {
                margin-right: $sidebar-width-full;
                margin-left: 0;
            }
            .dashboard-page-wrapper {
                margin-right: $dashboard-sidebar-width-full;
                margin-left: 0 !important;
            }
        }
        &[data-sidebartype="iconbar"] {
            .page-wrapper {
                margin-right: $sidebar-width-iconbar;
                margin-left: 0;
            }
        }
        &[data-sidebartype="overlay"] {
            .left-sidebar {
                right: -$sidebar-width-full;
                left: auto;
            }
            &.show-sidebar {
                .left-sidebar {
                    right: 0;
                    left: auto;
                }
            }
        }
    }
    .dz-hidden-input {
        display: none;
    }
    /* Vertical responsive layout */
    @media (min-width: 767.98px) {
        &#main-wrapper {
            /*Sidebar position*/
            &[data-sidebar-position="fixed"][data-sidebartype="full"],
            &[data-sidebar-position="fixed"][data-sidebartype="overlay"] {
                .topbar .top-navbar .navbar-collapse {
                    // margin-right: $sidebar-width-full;
                    margin-left: 0;
                }
            }
            &[data-sidebar-position="fixed"][data-sidebartype="mini-sidebar"] {
                .topbar .top-navbar .navbar-collapse {
                    // margin-right: $sidebar-width-mini;
                    margin-left: 0;
                }
            }
            &[data-sidebar-position="fixed"][data-sidebartype="iconbar"] {
                .topbar .top-navbar .navbar-collapse {
                    margin-right: $sidebar-width-iconbar;
                    margin-left: 0;
                }
            }
            /*Fixed sidebar with minisidebar*/
            &[data-sidebar-position="fixed"][data-sidebartype="mini-sidebar"] {
                .topbar .top-navbar .navbar-collapse {
                    // margin-right: $sidebar-width-mini;
                    margin-left: 0;
                }
            }
            &[data-sidebartype="mini-sidebar"] {
                .page-wrapper {
                    margin-right: $sidebar-width-mini;
                    margin-left: 0;
                }
            }
        }
    }
    @media (max-width: 766px) {
        &#main-wrapper {
            &[data-sidebartype="mini-sidebar"] {
                .left-sidebar {
                    right: -$sidebar-width-full;
                    left: auto;
                }
            }
            &.show-sidebar {
                .left-sidebar {
                    right: 0;
                    left: auto;
                }
            }
        }
        .left-part {
            right: -260px;
            left: auto;
            &.show-panel {
                right: 0px;
                left: auto;
            }
        }
        .right-part {
            margin-right: 0px;
        }
        .connected-table-cols {
            left: 0;
        }
        .scenario-list-table-cols {
            left: 0;
        }
        .job-list-table-cols {
            left: 0;
        }
        .schedule-list-table-cols {
            left: 0;
        }
        .pl-mq-ar-0 {
            padding-left: 0;
        }
        .pr-mq-ar-0 {
            padding-right: 0;
        }
        .pr-mq-ar-30 {
            padding-right: 30px !important;
        }
        .pr-mq-ar-40 {
            padding-right: 40px !important;
        }
        .pr-mq-ar-43 {
            padding-right: 43px !important;
        }
        .pr-mq-ar-46 {
            padding-right: 46px !important;
        }
        .mq-db-pub-created {
            padding-left: unset;
            padding-right: 55px;
        }
        .mq-db-pub-modified {
            padding-left: unset;
            padding-right: 55px;
        }
        .mq-db-pub-date {
            padding-left: unset;
            padding-right: 56px;
        }
        .mq-db-pub-owner {
            padding-left: unset;
            padding-right: 56px;
        }
        .mobile-delete {
            text-align: left !important;
        }
        .mobile-click {
            margin-left: 0px !important;
            padding-left: 0rem !important;
        }
    }
    .mr-ar_-1 {
        margin-right: -1px !important;
    }
    .mr-ar-13 {
        margin-right: 13px !important;
    }
    .ml-ar_-14 {
        margin-left: -14px;
    }
    .pr-ar-14 {
        padding-right: 14px;
    }
    .pl-ar-5 {
        padding-left: 7px !important;
    }
    .pr-ar-12 {
        padding-right: 12px !important;
    }
    .pl-ar-30 {
        padding-left: 30px !important;
    }
    .audit-topleft-bar {
        border-top-left-radius: 0px !important;
        border-bottom-left-radius: 0px !important;
        margin-left: -1px;
        border-top-right-radius: 50px;
        border-bottom-right-radius: 50px;
    }
    .pr-ar-0 {
        padding-right: 0px !important;
    }
    .pr-ar-15 {
        padding-right: 15px !important;
    }
    .mr-ar-0 {
        margin-right: 0px !important;
    }
    .pl-ar-10 {
        padding-left: 10px !important;
    }
    .pr-ar-10 {
        padding-right: 10px !important;
    }
    .pr-ar-5 {
        padding-right: 5px;
    }
    .pl-ar-13 {
        padding-left: 13px;
    }
    .pr-ar-13 {
        padding-right: 13px;
    }
    .pl-ar-0 {
        padding-left: 0;
    }
    .pr-ar-0 {
        padding-right: 0;
    }
    .pr-ar-21 {
        padding-right: 21px;
    }
    .pr-ar-12 {
        padding-right: 12px;
    }
    .pr-ar-25 {
        padding-right: 25px;
    }
    .pr-ar-35 {
        padding-right: 35px;
    }
    .pr-ar-38 {
        padding-right: 38px;
    }
    .mr-ar_-13 {
        margin-right: -13px;
    }
    .mr-ar_-4 {
        margin-right: -4px;
    }
    .pr-ar-45 {
        padding-right: 45px;
    }
    .mr-ar-0-15r {
        margin-right: 0.15rem;
    }
    .mr-ar_-0-55r {
        margin-right: -0.55rem !important;
    }
    .pr-ar-0-25r {
        padding-right: 0.25rem !important;
    }
    .pr-ar-1-5r {
        padding-right: 1.5rem !important;
    }
    .pr-ar-6 {
        padding-right: 6px;
    }
    .pr-ar-8 {
        padding-right: 8px;
    }
    .pr-ar-16 {
        padding-right: 16px;
    }
    .pr-ar-17 {
        padding-right: 17px;
    }
    .pr-ar-unset {
        padding-right: unset !important;
    }
    .mr-ar-unset {
        margin-right: unset !important;
    }
    .ml-ar-0-5r-mr-unset {
        margin-left: 0.5rem;
        margin-right: unset !important;
    }
    .mr-ar_-10 {
        margin-right: -10px !important;
    }
    .square-tab {
        margin-right: -3px;
        padding-right: 5px !important;
    }
    .mr-ar-20 {
        margin-right: 20px !important;
        margin-left: 0px !important;
    }
    .search-icon-position {
        position: relative;
        right: -27px;
        top: 13px;
        width: 12px;
        height: 12px;
        z-index: 3;
    }
    .ml-n9 {
        margin-left: -9px !important;
    }
    .left-n252 {
        left: -252px !important;
    }
    .ml-n59 {
        margin-left: -59px !important;
    }
    .ml-326 {
        margin-right: 326px !important;
        margin-left: 0px !important;
    }
    .ml-n39 {
        margin-left: -39px;
    }
    .ml-n26 {
        margin-left: -26px;
    }
    .pr-19 {
        padding-right: 19px;
    }
    .ml-n14 {
        margin-left: -14px;
    }
    .ml-n22 {
        margin-left: -22px;
    }
    .pr-16 {
        padding-right: 17px !important;
    }
    .ml-n24 {
        margin-left: -24px;
    }
    .ml-n3 {
        margin-left: -3px !important;
    }
    .pr-10 {
        padding-right: 10px;
    }
    .mr-n18 {
        margin-right: -18px;
    }
    .p-r-ar {
        padding-right: 0px !important;
        @media screen and (max-width: 426px) {
            .pr-mq2-ar-10 {
                padding-right: 10px !important;
            }
            .pr-mq2-ar-40 {
                padding-right: 40px !important;
            }
            .pr-mq2-ar-43 {
                padding-right: 43px !important;
            }
            .comments-floating-icon-div-open {
                width: 45px !important;
                height: 45px !important;
            }
            .comments-floating-icon-div-close {
                width: 45px !important;
                height: 45px !important;
            }
            .comments-floating-icon {
                font-size: 16px;
                margin-top: 16px;
            }
            .comment-count-indicator {
                width: 11px;
                height: 11px;
                font-size: 6px;
                line-height: 13px;
                left: 26px !important;
                right: unset;
            }
        }
    }
    .option-menu.dropdown {
        float: right;
    }
    .mr-45 {
        margin-right: 45px !important;
    }
    .topbar .dropdown-menu-translated {
        margin-right: -50px !important;
    }
    .notification-count-indicator-translate {
        width: 21px;
        height: 20px;
        border-radius: 50%;
        background-color: #115caa;
        position: absolute;
        font-size: 10px;
        color: #ffffff;
        font-weight: 400;
        line-height: 17px;
        text-align: center;
        right: 12px;
        padding-top: 1px;
    }
    .mr-ar-10 {
        margin-right: 10px !important;
    }
    .mr-ar-15 {
        margin-right: -15px;
    }
    .pr-ar-37 {
        padding-right: 36px;
    }
    .mr-ar-1 {
        margin-right: 1px;
    }
    .pr-ar-23 {
        padding-right: 23px;
    }
    #mdItem {
        text-align: right;
    }
    .pullCardHeaderToTop {
        margin-left: -7px !important;
    }
    #dependencyTitle {
        margin-right: -1px !important;
    }
    .dependency-navs {
        direction: rtl;
        margin-right: -36px !important;
    }
    .pullButtonToLeft {
        margin-left: -5px !important;
    }
    .comments-floating-icon-div-open {
        left: unset !important;
        right: 255px !important;
    }
    .comments-floating-icon-div-close {
        left: unset !important;
        right: 325px !important;
    }
    .mini-sidebar-comments-floating-icon-div {
        left: unset !important;
        right: 45px !important;
    }
    .comments-floating-icon {
        font-size: 22px;
        margin-top: 21px;
        margin-left: unset !important;
        margin-right: -2px;
    }
    .pr-ar-3px {
        padding-right: 3px;
    }
    .pr-ar-0-5r {
        padding-right: 0.5rem;
        padding-left: unset !important;
    }
    .pr-ar-1r {
        padding-right: 1rem;
    }
    .pl-15px {
        padding-right: 15px !important;
        padding-left: unset !important;
    }
    .pr-5px {
        padding-right: unset;
        padding-left: 5px;
    }
    .comment-sidebar-close-icon {
        right: unset !important;
        left: 0;
    }
    .ml-ar-0-25r-mr-unset {
        margin-left: 0.25rem;
        margin-right: unset !important;
    }
    .mr-ar-0-25r-ml-unset {
        margin-right: 0.25rem;
        margin-left: unset !important;
    }
    .cancelTextEdit,
    .cancelTextReply {
        padding-right: 10px;
        padding-left: unset !important;
    }
    .commentSidebarNavTabPadding {
        padding-left: unset;
    }
    .pos-abs-r_-4 {
        left: -4px;
        right: unset !important;
    }
    .commentSectionOfSidebar {
        border-radius: 0px !important;
        box-shadow: 0 1px 5px 0 rgba(0, 0, 0, 0.15);
        height: 95vh;
    }
    @media (min-width: 426px) {
        .comment-count-indicator {
            left: 32px;
            right: unset;
        }
    }
    @media (min-width: 767px) {
        .ml-350px {
            margin-right: 350px !important;
            margin-left: unset !important;
        }
        .left-sidebar-comments {
            width: 350px !important;
        }
    }
    @media (max-width: 767px) {
        .comments-floating-icon-div-open {
            right: 22px !important;
            left: unset !important;
        }
        .comments-floating-icon-div-close {
            right: 22px !important;
            left: unset !important;
        }
        .left-sidebar-comments {
            right: 0 !important;
            left: unset !important;
        }
    }
    .mr-2px {
        margin-right: unset !important;
    }
    .rightSidebarWidth {
        width: 450px;
    }
    .notification-box-rtl {
        direction: rtl;
        text-align: right;
        margin-right: 20px;
    }
    .bg-white-notification {
        right: unset;
        left: 0;
    }
    .mr-ar-32px {
        margin-right: 32px;
    }
    .pr-ar-20px {
        padding-right: 20px;
    }
    .ml-20px-en {
        margin-left: unset !important;
    }
    .ml-ar-8px {
        margin-left: 8px;
    }
    .pl-10px {
        padding-right: 10px !important;
        padding-left: unset !important;
    }
    .pr-10px {
        padding-left: 10px !important;
        padding-right: unset !important;
    }
    @media (min-width: 200px) and (max-width: 570px) {
        .lineReviewPending {
            width: 123px !important;
            margin-right: -36px !important;
            margin-top: -1px !important;
        }
    }
    @media (min-width: 200px) and (max-width: 470px) {
        .reviewDone {
            width: 87px !important;
            margin-right: -17px;
        }
    }
    .mr-ar-23 {
        margin-right: -23px;
    }
    .analytics-grid {
        height: 675px !important;
    }
    .page-print {
        position: absolute;
        right: auto !important;
        left: 15px;
        top: 0px;
        z-index: 100;
    }
    .mr-ar-17 {
        margin-right: -17px;
    }
    mat-sidenav-container mat-sidenav-content mat-toolbar .prio-navs button:first-child {
        margin-right: 0px !important;
    }
    app-user-template div .cover-item:first-child {
        margin-right: -2px !important;
    }
    app-user-template div .cover-item span img {
        margin-right: -7px !important;
    }
    app-user-template div .cover-item span span {
        margin-right: 4px !important;
    }
    .policy-step-wizard ul li:first-of-type {
        margin-right: -3px !important;
    }
    .ml_-10 {
        margin-left: unset !important;
        margin-right: -10px;
    }
    .page-print {
        right: unset !important;
        left: 13px;
    }
    .margin-left-0-5rem {
        margin-left: unset !important;
        margin-right: 0.5rem;
    }
    .dotsConnectingLine {
        left: unset !important;
        right: 16px;
    }
    .pl-29 {
        padding-left: 10px !important;
        padding-right: 29px;
    }
    .status_pos {
        margin-left: unset !important;
        margin-right: 10px;
    }
    .auditProgramSidebarCloseIcon {
        left: -28px !important;
    }
    .sidebar-svg-icons-bcm-clock {
        right: auto !important;
        left: 10px;
    }
    .single-duration {
        margin: 0 0 0 15px !important;
    }
    .ml-0-0 {
        margin-left: unset !important;
        padding-right: 19px !important;
        padding-left: 13px !important;
    }
}
