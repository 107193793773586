/* stylelint-disable property-no-vendor-prefix */
// Spacing

@use "sass:math";

$spacer: 1rem !default; // Assumes the browser default, typically `16px`

$rem-space-1: math.div($spacer * 6, 16) !default;
$rem-space-2: math.div($spacer * 12, 16) !default;
$rem-space-3: math.div($spacer * 18, 16) !default;
$rem-space-4: math.div($spacer * 24, 16) !default;
$rem-space-5: math.div($spacer * 30, 16) !default;
$rem-space-desktop-1: math.div($spacer * 8, 16) !default;
$rem-space-desktop-2: math.div($spacer * 16, 16) !default;
$rem-space-desktop-3: math.div($spacer * 24, 16) !default;
$rem-space-desktop-4: math.div($spacer * 32, 16) !default;
$rem-space-desktop-5: math.div($spacer * 40, 16) !default;

/* Fixed Spacing Variables */

$spacer-0: 0;
$spacer-1: 4px;
$spacer-2: 8px;
$spacer-3: 16px;
$spacer-4: 24px;
$spacer-5: 32px;
$spacer-6: 40px;

/* Em Based Spacing Variables */
$em-spacer-1: 0.0625em;
$em-spacer-2: 0.125em;
$em-spacer-3: 0.25em;
$em-spacer-4: 0.375em;
$em-spacer-5: 0.5em;
$em-spacer-6: 0.75em;
$em-spacer-7: 0.875em;
$em-spacer-8: 1em;
$em-spacer-9: 1.25em;
$em-spacer-10: 1.5em;

// Grid spacer
$grid-gutter-space: 20px;

// Breakpoints.
$bp-mobile: 600px;
$bp-tablet: 1024px;
$bp-desktop: 1280px;

// Container width

$container-max-width: 1404px;

// Imported from old scss
$topbar-height: 49px;
$topbar-navlink-padding: 0 15px;
$topbar-navlink-font-size: 0.875rem;
$topbar-navlink-height: 49px;
$topbar-navbrand-padding: 0 15px 0 15px;
$sidebar-width-full: 265px;
$sidebar-width-iconbar: 180px;
$sidebar-width-mini: 60px;
$boxed-width: 1200px;
/* stylelint-disable-next-line color-function-notation, alpha-value-notation */
$shadow: 1px 0 20px rgba(0, 0, 0, 0.08);
$transitions: 0.2s ease-in;
/* stylelint-disable-next-line color-function-notation, alpha-value-notation */
$transparent-dark-bg: rgba(0, 0, 0, 0.05);
$lft: left;
$rgt: right;
$card-alt: #e4e9ef;
%square {
  border-radius: 0;
}
%rotate45 {
  transform: rotate(45deg);
  -ms-transform: rotate(45deg);
  -webkit-transform: rotate(45deg);
  -o-transform: rotate(45deg);
  -moz-transform: rotate(45deg);
}

$primary: $color-primary !default;
$enable-caret: true !default;
$enable-rounded: true !default;
$enable-shadows: false !default;
$enable-gradients: false !default;
$enable-transitions: true !default;
$enable-hover-media-query: false !default;
$enable-grid-classes: true !default;
$enable-print-styles: true !default;
$breadcrumb-margin-bottom: 1.5rem;
$card-border-width: 1px !default;
$card-border-radius: 0 !default;
$dropdown-item-padding-x: 1rem !default;
$dropdown-item-padding-y: 0.65rem !default;
$table-cell-padding: 10px 12px 9px !default;
$table-bg-level: -10 !default;
$table-bg-level2: 1 !default;
$table-bg-level3: -5 !default;
$badge-pill-padding-x: 0.2em !default;
$badge-pill-padding-y: 1em !default;
$custom-select-indicator: url("/assets/images/custom-select.png") !default;
$input-btn-focus-box-shadow: transparent !default;
$custom-select-focus-box-shadow: transparent !default;
$custom-select-indicator: str-replace(url(""), "%23") !default;
$border-width: 1px !default;
$border-radius: 2px !default;
$border-radius-lg: 2px !default;
$border-radius-sm: 1px !default;
$au-curve: 5px;
$au-round: 100px;
$au-btn-round: 12px;
$progress-height: 5px !default;
$nav-tabs-link-active-bg: $color-white !default;
$grid-breakpoints: (
  xs: 0,
  sm: 576px,
  md: 768px,
  lg: 992px,
  xl: 1600px,
) !default;
/* stylelint-disable-next-line scss/dollar-variable-pattern */
$btnBorderRadius: 25px;
$card-info-height: 62px;
$title-font-size: 1.125rem;
$normal-font: 13px;
$input-min-height: 40px ;
